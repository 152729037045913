import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { SearchBreadcrumbsProps } from './interface';

import BreadcrumbSeparatorIcon from '@assets/icons/BreadcrumbSeparator.svg';
import HomeIcon from '@assets/icons/HomeIcon.svg';

export const SearchBreadcrumbs = ({
  breadcrumbSteps,
  onClickDefault,
  hasBrandSelected,
}: SearchBreadcrumbsProps): JSX.Element => {
  const defaultBreadcrumb = [
    <Link
      onClick={onClickDefault}
      underline='hover'
      key='1'
      color='inherit'
      href='#/bluon-search?tab=searchEquipment'
      data-testid='breadCrumbHome'
    >
      <img alt='defaultBreadcrumbIcon' src={HomeIcon} />
    </Link>,
  ];

  return (
    <Box
      data-testid='searchBreadcrumbsContainer'
      className='bl-search-breadcrumb-container'
    >
      <Stack spacing={2}>
        <Breadcrumbs
          separator={
            <img
              className='bl-search-breadcrumb-separator'
              alt='breadCrumbSeparator'
              src={BreadcrumbSeparatorIcon}
            />
          }
          aria-label='breadcrumb'
        >
          {defaultBreadcrumb}
          {!hasBrandSelected && (
            <Typography
              className='bl-search-breadcrumb-select-txt'
              key={1}
              variant='caption'
              color='initial'
            >
              Select a brand below to view lines
            </Typography>
          )}
          {breadcrumbSteps}
          {hasBrandSelected && <Link key='empty' color='inherit' />}
        </Breadcrumbs>
      </Stack>
    </Box>
  );
};
