import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles.scss';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { LoginFormKeys } from './interfaces';
import { LoginValidationSchema } from './validations';

import { useAppSelector } from '@hooks/state';
import { loginRequest } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { FormTextInputLengths } from '@utils/FormInputLengths';

export const LoginComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginResponseErrors, loading } = useAppSelector((state) => ({
    loading: isRequestRunning(state, String(loginRequest)),
    loginResponseErrors: getRequestError(state, String(loginRequest)),
  }));

  useEffect(() => {
    if (loginResponseErrors.password)
      setError('password', {
        type: 'Invalid',
        message: loginResponseErrors.password[0],
      });

    if (loginResponseErrors.email)
      setError('email', {
        type: 'Invalid',
        message: loginResponseErrors.email[0],
      });
  }, [loginResponseErrors]);

  const initialValues: LoginFormKeys = {
    email: '',
    password: '',
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect');

  const handleLoginNavigation = (route: string) => {
    if (redirect) route = redirect;
    navigate(route);
  };

  const handleForgotPasswordClick = () => navigate('/login/forgot-password');

  const { control, setError, handleSubmit } = useForm<LoginFormKeys>({
    resolver: yupResolver(LoginValidationSchema),
    defaultValues: initialValues,
  });

  const handleSubmitForm = (values: LoginFormKeys) => {
    dispatch(
      loginRequest({
        email: values.email,
        password: values.password,
        callback: handleLoginNavigation,
      }),
    );
  };

  return (
    <WelcomeContainer>
      <Box className='bl-login-form-container'>
        <Grid container justifyContent='center'>
          <Grid item xs={5} className='bl-login-form-title'>
            <AuthFormHeader
              title='Log in to Bluon'
              subTitle='Please enter your email and password.'
            />
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <Controller
                name='email'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextInput
                    id='email'
                    label='Email'
                    data-testid='email'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    defaultValue={field.value}
                    error={!!fieldState.error}
                    maxLength={FormTextInputLengths.profile.email}
                    errorMessage={formState.errors.email?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <Controller
                name='password'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextInput
                    id='password'
                    label='Password'
                    type='password'
                    data-testid='password'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    defaultValue={field.value}
                    error={!!fieldState.error}
                    errorMessage={formState.errors.password?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent='center'
            className='bl-login-form-button-container'
          >
            <Grid item xs={5}>
              <Button
                loading={loading}
                disabled={loading}
                fullWidth
                data-testid='loginButton'
                variant='contained'
                type='submit'
                color='primary'
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container justifyContent='center'>
          <Grid item xs={5}>
            <Button
              variant='text'
              color='primary'
              data-testid='forgotPasswordTextButton'
              onClick={handleForgotPasswordClick}
            >
              Forgot Password? click here
            </Button>
          </Grid>
        </Grid>
        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
