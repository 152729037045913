import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, Button, Link, Typography } from '@mui/material';

import { AutomaticQuoteMessageProps } from './interface';

import LocalSupplyLogo from '@assets/images/LocalSupplyLogo.png';
import { useAppSelector } from '@hooks/state';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { emptyFunction } from '@utils/functions';
import { getDateTimeMessage } from '@utils/Strings/dateFormat';
import { urlGenerator } from '@utils/UrlGenerator';

export const AutomaticQuoteMessage = ({
  message,
}: AutomaticQuoteMessageProps): JSX.Element => {
  const messageObject = message.message;
  const { logo } = useAppSelector((state) => ({
    data: getStoreInfoData(state),
    get logo() {
      return this.data?.logo?.url;
    },
  }));

  const shareLink = urlGenerator(messageObject?.order_id);

  return (
    <div className='item-chat'>
      <Box className='time-message'>
        {getDateTimeMessage(messageObject.timetoken, 'h:mm a')}
      </Box>
      <Box className='own-no-wrap relative min-w-1/8 max-w-3/5 rounded-lg bg-primary p-4'>
        <Box className='py-2r mb-2 flex h-10 items-center rounded-t bg-white px-4'>
          <img
            className='mr-5 h-auto max-w-[6rem]'
            alt='Logo'
            src={logo ?? LocalSupplyLogo}
          />

          <Typography
            variant='caption'
            className='font-bold leading-5 tracking-tight text-bluon_navy'
          >
            <a
              href={shareLink}
              target='_blank'
              rel='noopener noreferrer'
              className='text-base text-bluon_navy no-underline'
            >
              Quote
            </a>
          </Typography>
        </Box>
        {messageObject?.text && (
          <Typography
            variant='body1'
            className='text-base font-normal leading-5 tracking-tight text-white'
          >
            {messageObject.text}
          </Typography>
        )}
        <Box className='mb-3 mt-4'>
          <Link href={shareLink} target='_blank' rel='noopener noreferrer'>
            <Button
              className='text-base font-bold leading-5 tracking-tight shadow-none'
              sx={{
                backgroundColor: 'black',
              }}
              variant='contained'
              fullWidth
              onClick={emptyFunction}
            >
              Click Here to View Quote
            </Button>
          </Link>
        </Box>
        <a
          href={shareLink}
          target='_blank'
          rel='noopener noreferrer'
          className='text-bluon_navy no-underline'
        >
          <Box className='flex items-end justify-end'>
            <Typography
              className='mr-1/8 flex items-center text-base font-medium leading-normal tracking-tight text-white'
              variant='body1'
            >
              Share Quote:
            </Typography>
            <IosShareIcon className='ml-1 text-white' />
          </Box>
        </a>
      </Box>
    </div>
  );
};
