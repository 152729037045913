import { Box, Checkbox, TableHead, TextField, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useMemo } from 'react';

import { ExtraItemsListProps } from './interface';

import Button from '@components/Button';
import { ExtraItem } from '@config/api/task/interface';

export const ExtraItemsList = ({
  items,
  onSave,
  setListDirty,
  selectedItems,
  setSelectedItems,
  isSaving,
}: ExtraItemsListProps) => {
  useEffect(() => {
    if (!items) return;

    const allPending = items.every(
      (item) =>
        item.status === 'pending' && item.quantity === item.quantity_requested,
    );

    if (allPending) {
      setSelectedItems([...items]);
    } else {
      const availableItems = items.filter(
        (item) => item.status === 'available',
      );
      setSelectedItems(availableItems);
    }
  }, [items]);

  const isSelected = (id: string) =>
    selectedItems.findIndex((item) => item.id === id);

  const handleClick = (item: ExtraItem) => {
    const selectedItem = isSelected(item.id);
    const newArray = [...selectedItems];

    if (selectedItem === -1)
      newArray.push({ ...item, quantity: item.quantity_requested });
    else newArray.splice(selectedItem, 1);

    setSelectedItems(newArray);
    if (setListDirty) setListDirty(true);
  };

  const onChangeInput = (value: string, index: number) => {
    const newArray = [...selectedItems];

    newArray[index].quantity = +value;

    setSelectedItems(newArray);
    if (setListDirty) setListDirty(true);
  };

  const compareQuantity = (
    item: ExtraItem,
    isSelected: boolean,
    index: number,
  ): { id: string; hasError: boolean } => {
    if (isSelected) {
      const selectedItem = selectedItems[index];

      if (selectedItem)
        return {
          id: item.id,
          hasError: selectedItem?.quantity > selectedItem?.quantity_requested,
        };
    }

    return { id: item.id, hasError: false };
  };

  const onClickSave = () => {
    if (!isButtonDisabled) onSave();
  };

  const isButtonDisabled = useMemo(
    () => selectedItems.some((item) => item.quantity > item.quantity_requested),
    [selectedItems],
  );

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!items) return;

    if (event.target.checked) {
      const mappedItems = items.map((item) => ({
        ...item,
        quantity: item.quantity_requested,
      }));

      setSelectedItems(mappedItems);
    } else {
      setSelectedItems([]);
    }
  };

  const rowRender = (item: ExtraItem) => {
    const indexSelected = isSelected(item.id);
    const itemStatus = compareQuantity(
      item,
      indexSelected !== -1,
      indexSelected,
    );
    const hasError = itemStatus.id === item.id ? itemStatus.hasError : false;

    return (
      <TableRow key={item.id} selected={indexSelected !== -1}>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            checked={indexSelected !== -1}
            onClick={() => handleClick(item)}
          />
        </TableCell>
        <TableCell component='th' scope='row' padding='none'>
          {item.item?.info?.name}
        </TableCell>

        <TableCell align='right' colSpan={1}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            gap='2rem'
          >
            <Box display='flex' alignItems='center' gap='1rem'>
              {hasError && (
                <Typography className='error-message' variant='subtitle1'>
                  Quantity cannot exceed initial request
                </Typography>
              )}

              <TextField
                className='quantity-input'
                inputProps={{
                  type: 'number',
                  style: { textAlign: 'center' },
                  onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/^0+/, '');
                  },
                }}
                value={
                  indexSelected !== -1
                    ? selectedItems[indexSelected]?.quantity ?? item.quantity
                    : 0
                }
                variant='outlined'
                size='small'
                disabled={indexSelected === -1}
                onChange={(event) =>
                  onChangeInput(event.target.value, indexSelected)
                }
                error={hasError}
              />
              <Typography
                variant='subtitle1'
                sx={{
                  color: (theme) =>
                    indexSelected === -1 ? theme.palette.text.disabled : '',
                }}
              >
                /
              </Typography>
            </Box>

            <Typography
              variant='subtitle1'
              sx={{
                color: (theme) =>
                  indexSelected === -1 ? theme.palette.text.disabled : '',
              }}
            >
              {item.quantity_requested}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow selected={selectedItems.length === items.length}>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                checked={selectedItems.length === items.length}
                onChange={onSelectAllClick}
              />
            </TableCell>
            <TableCell align='left' padding='none'>
              <Typography variant='subtitle1' className='head-title'>
                Item Availability
              </Typography>
            </TableCell>
            <TableCell align='right' colSpan={2}>
              <Typography variant='subtitle1' className='head-title'>
                Qty Available / Requested
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{items.map((item) => rowRender(item))}</TableBody>
      </Table>

      <Box className='mx-auto my-2 mt-4 max-w-[5.125rem] pb-2'>
        <Button
          variant='contained'
          fullWidth
          onClick={onClickSave}
          disabled={isButtonDisabled || isSaving}
          loading={isSaving}
          size='large'
        >
          SAVE
        </Button>
      </Box>
    </TableContainer>
  );
};
