/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { all, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import makeRequest from '../requests/make-request';
import { getRequestError } from '../requests/selectors';

import {
  brandsRequest,
  brandsResponse,
  getCountriesRequest,
  getCountriesResponse,
  getLimitedStoreInfoRequest,
  getLimitedStoreInfoResponse,
  getStatesRequest,
  getStatesResponse,
  moreBrandsRequest,
  moreBrandsResponse,
  postBranchHours,
  postBranchHoursResponse,
  reasonsRequest,
  storeInfoBrandsResponse,
  storeInfoGetRequest,
  storeInfoGetResponse,
  storeInfoPatchRequest,
  storeInfoPostBrands,
} from './actions';
import {
  GetBrandsActionParamsProps,
  HoursActionParamProps,
  LimitedStoreInfoActionParamsProps,
  ReasonsRequestProps,
  StateActionParamProps,
  StoreBrandsRequest,
  StoreInfoActionParamsProps,
  StoreInfoPatchActionParamProps,
  StoreInfoStateProps,
} from './interface';

import {
  getBrands,
  getCountriesApi,
  getLimitedStoreInfo,
  getReasonsByStatus,
  getStatesApi,
  getStoreInfo,
  patchStoreInfo,
  postBranchHoursApi,
  postStoreInfoBrands,
} from '@config/api/storeInfo';

export function* workerGetLimitedStoreInfo(
  action: LimitedStoreInfoActionParamsProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getLimitedStoreInfo,
    requestAction: action,
    receiveAction: getLimitedStoreInfoResponse,
  });
}

export function* workerGetStoreInfo(
  action: StoreInfoActionParamsProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getStoreInfo,
    requestAction: action,
    receiveAction: storeInfoGetResponse,
  });
}

export function* workerMoreBrandsRequest(
  action: GetBrandsActionParamsProps,
): Generator<any> {
  const call: any = Eff.call;
  const state = yield select((state) => state);
  const storeInfo = (state as any).storeInfo as StoreInfoStateProps;
  const search = action.payload?.search ?? '';

  yield call(makeRequest, {
    endpoint: getBrands,
    params: { page: storeInfo.nextPage, search },
    requestAction: action,
    receiveAction: moreBrandsResponse,
  });
}

export function* workerGetBrands(
  action: GetBrandsActionParamsProps,
): Generator<any> {
  const call: any = Eff.call;
  const search = action.payload?.search ?? '';

  yield call(makeRequest, {
    endpoint: getBrands,
    params: { page: 1, search },
    requestAction: action,
    receiveAction: brandsResponse,
  });
}

export function* workerPatchStoreInfo(
  action: StoreInfoPatchActionParamProps,
): Generator<any> {
  const param = action.payload.data;
  param.counter_staff = param.counter_staff?.filter(
    (item) => item.name.length > 0,
  );
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: patchStoreInfo,
    params: { ...param },
    requestAction: action,
    receiveAction: storeInfoGetResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, storeInfoPatchRequest),
  );

  if (isEmpty(error)) {
    yield call(action.payload.callback);
  } else {
    let errorMsg = 'An error ocurred, please check the fields.';
    const imageErrorMsg =
      'The image must be a file of type: jpg, jpeg, png, gif.';
    errorMsg = (error as any)?.image ? imageErrorMsg : errorMsg;
    yield call(action.payload.error, errorMsg, {
      severity: 'error',
      autoHide: true,
    });
  }
}

export function* workerPostBrands(action: StoreBrandsRequest): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: postStoreInfoBrands,
    params: action.payload,
    requestAction: action,
    receiveAction: storeInfoBrandsResponse,
  });
}

export function* workerCountriesRequest(
  action: StoreInfoActionParamsProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getCountriesApi,
    requestAction: action,
    receiveAction: getCountriesResponse,
  });
}

export function* workerStatesRequest(
  action: StateActionParamProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getStatesApi,
    requestAction: action,
    receiveAction: getStatesResponse,
    params: action.payload,
  });
}

export function* workerPostBranchHours(
  action: HoursActionParamProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: postBranchHoursApi,
    requestAction: action,
    receiveAction: postBranchHoursResponse,
    params: { hours: action.payload.hours },
  });
  const error = yield select((state) =>
    getRequestError(state, postBranchHours),
  );
  if (isEmpty(error)) {
    yield call(action.payload.callback);
    yield call(action.payload.setValueCallback);
  }
}

export function* workerReasonsRequest(
  action: ReasonsRequestProps,
): Generator<any> {
  const call: any = Eff.call;
  const { status, errorCallback, receiveAction } = action.payload;

  yield call(makeRequest, {
    endpoint: getReasonsByStatus,
    params: status,
    requestAction: action,
    receiveAction: receiveAction,
  });
  const error = yield select((state) => getRequestError(state, reasonsRequest));
  if (!isEmpty(error)) yield call(errorCallback);
}

export default function* storeSagas(): Generator<any> {
  yield all([
    takeLatest(getLimitedStoreInfoRequest, workerGetLimitedStoreInfo),
    takeLatest(storeInfoGetRequest, workerGetStoreInfo),
    takeLatest(brandsRequest, workerGetBrands),
    takeLatest(moreBrandsRequest, workerMoreBrandsRequest),
    takeLatest(storeInfoPatchRequest, workerPatchStoreInfo),
    takeLatest(storeInfoPostBrands, workerPostBrands),
    takeLatest(getCountriesRequest, workerCountriesRequest),
    takeLatest(getStatesRequest, workerStatesRequest),
    takeLatest(postBranchHours, workerPostBranchHours),
    takeLatest(reasonsRequest, workerReasonsRequest),
  ]);
}
