import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import './styles.scss';

import EnhancedTable from './components/SupplierTable';
import { generateSkeletonRows } from './components/SupplierTable/utils/skeleton';

import { useSnackbar } from '@components/Snackbar/hooks';
import useRealTimeDistributorUpdate from '@hooks/fireBase/useRealTimeDistributorUpdate';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { getLoginInfoData } from '@state/auth/selectors';
import {
  clearSupplierList,
  getSupplierList as getSupplierListAction,
} from '@state/manager/actions';
import { getSupplierList } from '@state/manager/selectors';
import { isRequestRunning } from '@state/requests/selectors';

export const StoreOverview = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [searchCriteria, setSearchCriteria] = useState<string>('');

  const { suppliers, suppliersAreLoading, distributorInfo } = useAppSelector(
    (state) => ({
      suppliers: getSupplierList(state),
      suppliersAreLoading: isRequestRunning(
        state,
        String(getSupplierListAction),
      ),
      distributorInfo: getLoginInfoData(state),
    }),
  );

  const errorCallback = () => {
    showMessage('Oops, something wrong happened!', {
      severity: 'error',
      autoHide: true,
      subMessage: 'Please try again.',
    });
  };

  const fetchSuppliers = (criteria = searchCriteria) => {
    dispatch(
      getSupplierListAction({ searchCriteria: criteria, errorCallback }),
    );
  };

  useRealTimeDistributorUpdate(
    distributorInfo?.id,
    fetchSuppliers,
    searchCriteria,
  );

  useEffect(() => {
    fetchSuppliers();

    //  Clearing orders state on unrender to avoid UI issues
    return () => {
      dispatch(clearSupplierList());
    };
  }, []);

  const handleSearchCriteriaChange = (criteria: string) => {
    if (criteria.length >= 3 || criteria.length === 0) fetchSuppliers(criteria);
    setSearchCriteria(criteria);
  };

  const skeletonRows = useMemo(
    () => generateSkeletonRows(),
    [generateSkeletonRows, searchCriteria],
  );

  return (
    <Container
      maxWidth={false}
      className='manager-dashboard-container flex bg-[var(--bluon--ds--lightgray)]'
    >
      <Box className='align-center mb-6 mt-8 flex justify-center'>
        <Box className='text-center font-normal'>
          <Typography className='title'>Store Overview Manager</Typography>
        </Box>
      </Box>
      <EnhancedTable
        data={suppliersAreLoading ? skeletonRows : suppliers}
        isLoading={suppliersAreLoading}
        searchCriteria={searchCriteria}
        setSearchCriteria={handleSearchCriteriaChange}
      />
    </Container>
  );
};
