import { Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import ListItem from '../ListItem';
import ListItemSkeleton from '../ListItemSkeleton';

import { ListProps } from './interface';

import './styles.scss';
import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';
import { Order } from '@config/api/task/interface';
import { useAppDispatch } from '@hooks/state';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { setSelectedClaimingModal } from '@state/task/actions';
import { EOrderSubstatus } from '@utils/enums';

export const List = ({
  title,
  message,
  emptyMessage,
  items,
  isRequestLoading,
  initialFetchCompleted,
  canFetchMore,
  fetchMore,
  showTimer,
  loadingAllOrders,
}: ListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { changeSelectedOrder } = useSetSelectedOrder();

  const handleSelectOrder = (
    order: Order,
    techName: string,
    companyName: string,
  ) => {
    if (
      order &&
      !order.working_on_it &&
      order.current_status.substatus === EOrderSubstatus.PENDING_REQUESTED
    )
      return dispatch(
        setSelectedClaimingModal({ order, techName, companyName }),
      );

    return changeSelectedOrder(order.id, order);
  };

  const renderContent = useCallback(() => {
    if ((isRequestLoading && !initialFetchCompleted) || loadingAllOrders)
      return Array.from({ length: 4 }, (_, i) => <ListItemSkeleton key={i} />);

    if (initialFetchCompleted && items.length === 0)
      return (
        <Typography
          variant='h4'
          className='empty-message font-normal text-[var(--bluon--ds--darkblue)]'
        >
          {emptyMessage}
        </Typography>
      );

    return (
      <VirtualizedInfiniteList
        list={items}
        isLoading={initialFetchCompleted && isRequestLoading}
        hasNextPage={canFetchMore}
        onLoadMore={fetchMore}
        rowHeight={106}
      >
        {(props: any) => {
          const index = props.index;

          return (
            <ListItem
              key={props.list[index]?.id}
              order={props.list[index]}
              showTimer={showTimer}
              handleSelectOrder={handleSelectOrder}
            />
          );
        }}
      </VirtualizedInfiniteList>
    );
  }, [items, emptyMessage, isRequestLoading, initialFetchCompleted]);

  return (
    <div
      className={classNames(
        'list-container flex h-20 flex-col items-start rounded-xl bg-[var(--bluon--default--white)] px-8 py-6',
        {
          empty: initialFetchCompleted && items.length === 0,
        },
      )}
    >
      <div className='list-title font-normal text-[var(--bluon--default--black)] sm:mb-3'>
        {title}
      </div>
      {renderContent()}
      {initialFetchCompleted && items.length === 0 && (
        <Typography
          variant='h5'
          className='bottom-message font-normal text-[var(--bluon--ds--lightgray-1)] sm:text-2xl'
        >
          {message}
        </Typography>
      )}
    </div>
  );
};
