import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Card, ClickAwayListener, Collapse, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ChatHeader } from './Header';
import InboxList from './InboxList';
import { MessageInput } from './MessageInput';
import MessageList from './MessageList';
import MessageHeader from './MessageList/MessageHeader';
import { useChat } from './useChat';
import { sumUnreadMessageChat } from './utils';

import { useAppSelector } from '@hooks/state';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { getMoreChannelUsers, setChatOpenedResolve } from '@state/chat/actions';
import {
  getChannelList,
  getChatListHasNextPage,
  getChatOpened,
  getChatUnreadMessages,
  getCurrentChatSelected,
} from '@state/chat/selectors';
import { getRequestSuccess, isRequestRunning } from '@state/requests/selectors';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { setOrderDetailsRequest } from '@state/task/actions';

const ChatComponent = () => {
  const dispatch = useDispatch();
  const { handleSendMessage } = useChat();
  const { changeSelectedOrder } = useSetSelectedOrder();

  const {
    channelList,
    currentChatSelected,
    chatOpened,
    isLoadingNextPageUsers,
    isLoadingOrderDetails,
    chatListHasNextPage,
    unreadChatMessages,
    orderSuccess,
  } = useAppSelector((state) => ({
    // supplier
    supplierLoggedId: getStoreInfoData(state)?.id,
    // chat
    chatOpened: getChatOpened(state),
    channelList: getChannelList(state),
    currentChatSelected: getCurrentChatSelected(state),
    unreadChatMessages: getChatUnreadMessages(state),
    chatListHasNextPage: getChatListHasNextPage(state),
    // loaders
    isLoadingNextPageUsers: isRequestRunning(
      state,
      String(getMoreChannelUsers),
    ),
    isLoadingOrderDetails: isRequestRunning(
      state,
      String(setOrderDetailsRequest),
    ),
    orderSuccess: getRequestSuccess(state, String(setOrderDetailsRequest)),
  }));

  const handleLoadMore = () => dispatch(getMoreChannelUsers());
  const unreadChatSum = sumUnreadMessageChat(unreadChatMessages) ?? 0;

  let titleChat = 'Customer Chats';
  if (unreadChatSum > 0) titleChat += ` (${unreadChatSum})`;

  const handleClick = () => {
    dispatch(setChatOpenedResolve({ opened: true }));
  };

  const handleClose = () => {
    if (!chatOpened) return;
    dispatch(setChatOpenedResolve({ opened: false }));
  };

  const handleClickViewOrder = () => {
    changeSelectedOrder(currentChatSelected?.last_order.id, undefined);
  };

  //  Watching order success
  useEffect(() => {
    if (!orderSuccess) return;
    handleClose();
  }, [orderSuccess]);

  return (
    <>
      <div className='fixed bottom-0 right-6 z-chat'>
        {!chatOpened && (
          <Button
            variant='contained'
            sx={{
              backgroundColor:
                unreadChatSum > 0
                  ? 'var(--bluon--dark--red-1)'
                  : 'var(--bluon--default--blue-3)',
            }}
            onClick={handleClick}
            className={`${unreadChatSum > 0 ? 'animate-chat-unread' : ''}`}
          >
            {titleChat} <ArrowDropDownIcon />
          </Button>
        )}
      </div>
      <div className='fixed bottom-0 right-6 z-chat '>
        <Collapse in={chatOpened}>
          {chatOpened && (
            <ClickAwayListener onClickAway={handleClose}>
              <Box className='relative h-[630px] w-[958px]'>
                <Card variant='outlined' className='flex h-full flex-col'>
                  <ChatHeader onClose={handleClose} title={titleChat} />
                  <Grid container className='flex-grow'>
                    <Grid item xs={4} className='border-r'>
                      <div className='h-full'>
                        <InboxList
                          onLoadMore={handleLoadMore}
                          channelList={channelList}
                          currentChatSelected={currentChatSelected}
                          chatListHasNextPage={chatListHasNextPage}
                          isLoadingNextPageChannel={isLoadingNextPageUsers}
                          unreadChatMessages={unreadChatMessages}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      {currentChatSelected && (
                        <div className='flex h-full max-h-[585px] flex-col'>
                          <MessageHeader
                            currentChatSelected={currentChatSelected}
                            onClickViewOrder={handleClickViewOrder}
                            isLoadingOrderDetails={isLoadingOrderDetails}
                          />
                          <div className='flex-1 overflow-y-scroll'>
                            <MessageList
                              currentChatSelected={currentChatSelected}
                              fetchMessagesCount={100}
                            />
                          </div>
                          <MessageInput
                            fileUpload='image'
                            metadata={{
                              orderId: currentChatSelected?.chat.channel,
                            }}
                            placeholder='Type a message'
                            channel={currentChatSelected?.chat.channel}
                            techUserId={currentChatSelected?.userId}
                            sendButton={undefined}
                            disabled={false}
                            onSend={handleSendMessage}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </ClickAwayListener>
          )}
        </Collapse>
      </div>
    </>
  );
};

export default ChatComponent;
