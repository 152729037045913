import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';

import './styles.scss';
import { Disclaimer } from '../components/Disclaimer';
import { queryParamKeys } from '../interface';

import { IPartsRowItem } from './interface';

import { BluonIframeComponent } from '@components/BluonIframe';
import { CircularLoading } from '@components/CircularLoading';
import { NotFound } from '@components/NotFound';
import { PartDetails } from '@components/PartDetails';
import { RowItem } from '@components/RowItem';
import { SearchInput } from '@components/SearchInput';
import { SectionTitle } from '@components/SectionTitle';
import { SomethingWrong } from '@components/SomethingWrong';
import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';
import { PartsDataModel } from '@config/api/search/interface';
import { partsSpecificationsString } from '@data/parts';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  morePartReplacementRequest,
  morePartsRequest,
  partDetailRequest,
  partReplacementRequest,
  partsRequest,
  previousPartsRequest,
} from '@state/search/actions';
import {
  getHasNextPagePartReplacements,
  getHasNextPageParts,
  getPartDetails,
  getPartReplacements,
  getPartsData,
} from '@state/search/selectors';
import { handleGetString, partImage } from '@utils/functions';

export const PartTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [queryParams, setSearchParams] = useQueryParams<queryParamKeys>();

  const [searchValue, setSearchValue] = useState<string>(
    queryParams?.search ?? '',
  );
  const [page, setPage] = useState<number>(queryParams?.partPage ?? 1);
  const [previousPage, setPreviousPage] = useState<number>(
    queryParams && queryParams.partPage ? queryParams?.partPage - 1 : 0,
  );
  const [partId, setPartId] = useState<string>(queryParams?.partId ?? '');
  const [selectedPart, setSelectedPart] = useState<
    PartsDataModel | undefined
  >();
  const [needReload, setNeedReload] = useState<boolean>(false);

  //  Function to change page state
  const changePage = (newPage: number = page) => {
    setPage(newPage);
    setPreviousPage(newPage - 1);
  };

  const handleSearch = useCallback(() => {
    //  Case in which state changes directly and query params have not changed yet
    if (searchValue != queryParams.search) {
      setNeedReload(false);

      //  Building new query params
      let newPage = 1;
      let newQueryParams = { tab: queryParams?.tab, search: searchValue };

      //  Just adding part id params if they have changed, otherwise should be removed
      if (queryParams?.partId !== partId) {
        newPage = page;
        newQueryParams = { ...queryParams, ...newQueryParams };
      }

      //  Changing page and prev page values up to date before requesting parts
      changePage(newPage);
      dispatch(partsRequest({ page: newPage, number: searchValue }));
      setSearchParams(newQueryParams, false);
    } else {
      // Case in which the component is accessed directly from a URL
      dispatch(partsRequest({ page, number: searchValue }));
    }
  }, [searchValue, page, dispatch]);

  const handlePreviousPage = useCallback(() => {
    dispatch(previousPartsRequest({ page: previousPage, number: searchValue }));
    setPreviousPage(previousPage - 1);
  }, [previousPage, searchValue, dispatch]);

  const {
    parts,
    partsHasNextPage,
    isLoadingNextPageParts,
    isLoadingParts,
    isLoadingPreviousParts,
    partsRequestError,
  } = useAppSelector((state) => ({
    parts: getPartsData(state),
    partsHasNextPage: getHasNextPageParts(state),
    isLoadingNextPageParts: isRequestRunning(state, String(morePartsRequest)),
    isLoadingParts: isRequestRunning(state, String(partsRequest)),
    isLoadingPreviousParts: isRequestRunning(
      state,
      String(previousPartsRequest),
    ),
    partsRequestError: getRequestError(state, String(partsRequest)),
  }));

  const { partDetails, isLoadingPartDetails, partDetailsRequestErrror } =
    useAppSelector((state) => ({
      partDetails: getPartDetails(state),
      isLoadingPartDetails: isRequestRunning(state, String(partDetailRequest)),
      partDetailsRequestErrror: getRequestError(
        state,
        String(partDetailRequest),
      ),
    }));

  const {
    partReplacements,
    partReplacementsHasNextPage,
    isLoadingNextPagePartsReplacements,
    isLoadingMorePartReplacements,
  } = useAppSelector((state) => ({
    partReplacements: getPartReplacements(state),
    partReplacementsHasNextPage: getHasNextPagePartReplacements(state),
    isLoadingNextPagePartsReplacements: isRequestRunning(
      state,
      String(partReplacementRequest),
    ),
    isLoadingMorePartReplacements: isRequestRunning(
      state,
      String(morePartReplacementRequest),
    ),
  }));

  const handleSelectPart = (
    item: IPartsRowItem['selectedItem'],
    saveHistory = true,
  ) => {
    setSelectedPart(item);
    dispatch(partDetailRequest({ partId: item.id }));
    dispatch(partReplacementRequest({ partId: item.id })); // get part replacements
    if (saveHistory) {
      setNeedReload(false);
      //  Replacing route if a part id already exists
      setSearchParams(
        { ...queryParams, partId: item.id, partPage: item.page },
        !!queryParams?.partId,
      );
    }
  };

  const handleLoadMoreReplacementParts = () => {
    dispatch(morePartReplacementRequest({ partId: selectedPart?.id }));
  };

  //  Function to clear selected part state
  const clearSelectedPartState = () => {
    setSelectedPart(undefined);
    setPartId(queryParams?.partId || '');
  };

  const shouldShowPartsList =
    searchValue && searchValue.length >= 3 && !!parts.length && !isLoadingParts;
  const isSearchingForPart = isLoadingParts && searchValue;
  const searchError = partsRequestError && !isLoadingParts;
  const shouldShowNotFoundParts =
    parts.length === 0 &&
    searchValue.length >= 3 &&
    !isLoadingParts &&
    !partsRequestError;

  useEffect(() => {
    if (searchValue.length >= 3) handleSearch();
    clearSelectedPartState();
  }, [searchValue]);

  useEffect(() => {
    //  Just triggering updates if component state does not have the part changes yet
    if (partId !== queryParams?.partId) {
      if (!queryParams?.partId) clearSelectedPartState();
      if (queryParams?.partId) setPartId(queryParams?.partId);

      //  Reloading parts request if needed
      if (needReload) {
        //  Changing page and prev page values up to date before requesting parts
        changePage(queryParams.partPage || 1);
        dispatch(
          partsRequest({
            page: queryParams.partPage || 1,
            number: queryParams.search,
          }),
        );
      }
    }

    setNeedReload(true);
  }, [queryParams.partId]);

  //  Watching query params search changes to apply them to local state if needed
  useEffect(() => {
    if (queryParams?.search === searchValue) return;
    setSearchValue(queryParams?.search || '');
  }, [queryParams?.search]);

  useEffect(() => {
    const partResultIndex = parts.findIndex(
      (elm) => elm.id === queryParams?.partId,
    );
    const partResult = parts[partResultIndex];
    if (partResultIndex > 0)
      handleSelectPart({ ...partResult, index: partResultIndex }, false);
    else setSelectedPart(undefined);
  }, [parts]);

  return (
    <Container maxWidth='xl' className='bl-search-part-tab-container'>
      <SearchInput
        containerClassName='bl-search-input-part'
        onChange={setSearchValue}
        value={searchValue}
        placeholder='Search Part'
      />

      <Container maxWidth='xl' className='bl-search-part-tab-content'>
        {searchValue.length < 3 && (
          <>
            <Box className='bl-search-part-default-info'>
              <Typography
                className='bl-search-part-helper-title'
                color='initial'
                key='bl-search-part-title-key'
                variant='caption'
              >
                Start typing your part number. Results show after{' '}
                <strong>3</strong> characters.
              </Typography>

              <Link
                className='bl-search-part-helper-link-faq'
                color='inherit'
                href='#/help'
                underline='hover'
                rel='noopener'
              >
                FAQ: Using Search
              </Link>
            </Box>
            <Disclaimer />
            <BluonIframeComponent />
          </>
        )}

        <Grid container>
          <Grid item xs={selectedPart ? 5 : 12}>
            {shouldShowPartsList && (
              <>
                <SectionTitle title='Results' />
                <Box className='bl-part-tab-container-parts'>
                  <VirtualizedInfiniteList
                    list={parts}
                    selectedItem={selectedPart}
                    isLoading={isLoadingNextPageParts}
                    hasNextPage={partsHasNextPage}
                    isLoadPreviousButtomVisible={previousPage > 0}
                    onLoadPrevious={handlePreviousPage}
                    isLoadingPreviousParts={isLoadingPreviousParts}
                    onLoadMore={() =>
                      dispatch(morePartsRequest({ number: searchValue }))
                    }
                    onClickItem={handleSelectPart}
                    rowHeight={65}
                  >
                    {({
                      index,
                      list,
                      onClickItem,
                      selectedItem,
                    }: IPartsRowItem) => (
                      <RowItem
                        id={list[index].id}
                        nodeImage={
                          <img
                            alt='logo'
                            className='bl-part-tab-item-img'
                            loading='lazy'
                            src={partImage(list[index])}
                          />
                        }
                        key={list[index].id}
                        name={handleGetString(
                          list[index],
                          'type_title',
                          partsSpecificationsString,
                        )}
                        nameVariantStyle='small'
                        notes={list[index].number}
                        noteVariantColor='black'
                        noteVariantStyle='small'
                        onClickItem={() =>
                          onClickItem({
                            ...list[index],
                            index,
                          })
                        }
                        selected={selectedItem}
                      />
                    )}
                  </VirtualizedInfiniteList>
                </Box>
              </>
            )}
            {isSearchingForPart && (
              <Box
                data-testid='partTabLoading'
                className='bl-part-tab-loading-container'
              >
                <CircularLoading />
              </Box>
            )}
            {searchError && (
              <SomethingWrong
                height='calc(100vh - 165px)'
                onReload={() => dispatch(partsRequest({ number: searchValue }))}
              />
            )}
          </Grid>
          {selectedPart && (
            <PartDetails
              hasError={partDetailsRequestErrror}
              isLoadingDetails={isLoadingPartDetails}
              isLoadingMorePartReplacements={isLoadingMorePartReplacements}
              isLoadingNextPagePartsReplacements={
                isLoadingNextPagePartsReplacements
              }
              onClickReloadError={() =>
                dispatch(partDetailRequest({ partId: selectedPart?.id }))
              }
              onLoadMorePartReplacements={handleLoadMoreReplacementParts}
              partDetails={partDetails}
              partReplacements={partReplacements}
              partReplacementsHasNextPage={partReplacementsHasNextPage}
              showSuggestionBtn
              showTitle
            />
          )}
        </Grid>
        {shouldShowNotFoundParts && (
          <Box className='bl-search-part-not-found'>
            <NotFound />
          </Box>
        )}
      </Container>
    </Container>
  );
};
