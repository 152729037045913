import axios from 'axios';

import client from '..';

import { IframeDataProps, LoginDataProps } from './interface';

import { SendSMSMappedProps } from '@state/auth/interface';

export async function sendSMS([
  country_code,
  phone,
]: Array<string>): Promise<SendSMSMappedProps> {
  const phoneNumber = country_code + phone;
  const { env } = process;
  const { data } = await axios.post(
    `${env.REACT_APP_BLUON_API_BASE_URL}/${env.REACT_APP_BLUON_API_VERSION}/auth/phone/${phoneNumber}/login/sms`,
  );

  return data;
}

export async function login([
  email,
  password,
]: Array<string>): Promise<LoginDataProps> {
  const { data } = await client.post('/auth/email/login', {
    email,
    password,
  });

  return data;
}

export async function changePassword([
  password,
  password_confirmation,
]: Array<string>): Promise<null> {
  const { data } = await client.post('/auth/email/initial-password', {
    password,
    password_confirmation,
  });

  return data;
}

export async function forgotPassword(email: string): Promise<null> {
  const { data } = await client.post('/auth/email/forgot-password', {
    email,
  });

  return data;
}

export async function resetPassword(params: {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}): Promise<null> {
  const { data } = await client.post('/auth/email/reset-password', params);

  return data;
}

export async function getIframeUrl(
  appSetting: string,
): Promise<IframeDataProps> {
  const { data } = await client.get(`/app-settings/${appSetting}`);

  return data;
}
