import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ThumbsUpImage from '../../assets/images/thumbs-up.png';

import { List } from './components/List';
import './styles.scss';
import { ResponseTimeBadge } from './components/ResponseTimeBadge';
import { RealTimeResponseTime } from './interfaces';

import { ClaimingModal } from '@components/ClaimingModal';
import RoundedButton from '@components/RoundedButton';
import { ORDER_TYPE } from '@config/api/task/interface';
import useRealTimeOrderUpdated from '@hooks/fireBase/useRealTimeOrderUpdated';
import useRealTimeResponseTime from '@hooks/fireBase/useRealTimeResponseTime';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { isRequestRunning } from '@state/requests/selectors';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import {
  clearOrders,
  getApprovedOrders as getApprovedOrdersAction,
  getAvailabilityOrders as getAvailabilityOrdersAction,
  getMoreApprovedOrders,
  getMoreAvailabilityOrders,
  realTimeRefreshApprovedOrders,
  realTimeRefreshAvailabilityOrders,
  setSelectedClaimingModal,
} from '@state/task/actions';
import {
  getApprovedOrders,
  getApprovedOrdersHasNextPage,
  getApprovedOrdersIsDirty,
  getApprovedOrdersNextPage,
  getAvailabilityOrders,
  getAvailabilityOrdersHasNextPage,
  getAvailabilityOrdersIsDirty,
  getAvailabilityOrdersNextPage,
  getSelectedClaimingModal,
} from '@state/task/selectors';

export const Tasks = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [minutes, setMinutes] = useState<number | undefined>(0);
  const [isFlagedAsRapidResponder, setIsFlagedAsRapidResponder] =
    useState(false);
  const [hasMoreThanTenOrders, setHasMoreThanTenOrders] = useState(false);
  const { changeSelectedOrder } = useSetSelectedOrder();

  const {
    approvedOrders,
    approvedOrdersNextPage,
    hasMoreApprovedOrders,
    approvedOrdersIsLoading,
    availabilityOrders,
    availabilityOrdersNextPage,
    hasMoreAvailabilityOrders,
    availabilityOrdersIsLoading,
    availabilityOrdersInitialFetchCompleted,
    approvedOrdersInitialFetchCompleted,
    approvedOrdersLoadingAll,
    availabilityOrdersLoadingAll,
    storeInfo,
    claimingModal,
  } = useAppSelector((state) => ({
    // Approved Orders
    approvedOrders: getApprovedOrders(state),
    approvedOrdersNextPage: getApprovedOrdersNextPage(state),
    hasMoreApprovedOrders: getApprovedOrdersHasNextPage(state),
    approvedOrdersLoadingAll: isRequestRunning(
      state,
      String(getApprovedOrdersAction),
    ),
    approvedOrdersIsLoading:
      isRequestRunning(state, String(getApprovedOrdersAction)) ||
      isRequestRunning(state, String(getMoreApprovedOrders)),
    // Availability Orders
    availabilityOrders: getAvailabilityOrders(state),
    availabilityOrdersNextPage: getAvailabilityOrdersNextPage(state),
    hasMoreAvailabilityOrders: getAvailabilityOrdersHasNextPage(state),
    availabilityOrdersLoadingAll: isRequestRunning(
      state,
      String(getAvailabilityOrdersAction),
    ),
    availabilityOrdersIsLoading:
      isRequestRunning(state, String(getAvailabilityOrdersAction)) ||
      isRequestRunning(state, String(getMoreAvailabilityOrders)),
    availabilityOrdersInitialFetchCompleted:
      getAvailabilityOrdersIsDirty(state),
    approvedOrdersInitialFetchCompleted: getApprovedOrdersIsDirty(state),
    storeInfo: getStoreInfoData(state),
    claimingModal: getSelectedClaimingModal(state as any),
  }));
  const fetchMoreApprovedOrders = useCallback(() => {
    dispatch(
      getMoreApprovedOrders({
        type: ORDER_TYPE.APPROVED,
        page: approvedOrdersNextPage,
      }),
    );
  }, [approvedOrdersNextPage]);

  const fetchMoreAvailabilityOrders = useCallback(() => {
    dispatch(
      getMoreAvailabilityOrders({
        type: ORDER_TYPE.AVAILABILITY_REQUESTS,
        page: availabilityOrdersNextPage,
      }),
    );
  }, [availabilityOrdersNextPage]);

  const refreshRealTimeData = (refreshApprovedOrders: boolean) => {
    if (refreshApprovedOrders)
      dispatch(
        realTimeRefreshApprovedOrders({
          type: ORDER_TYPE.APPROVED,
          page: 1,
        }),
      );
    else
      dispatch(
        realTimeRefreshAvailabilityOrders({
          type: ORDER_TYPE.AVAILABILITY_REQUESTS,
          page: 1,
        }),
      );
  };

  const responseTimeReceived = (value: RealTimeResponseTime) => {
    const {
      average_time_response,
      forced_rapid_responder,
      has_over_ten_responses,
    } = value;
    setMinutes(average_time_response);
    setIsFlagedAsRapidResponder(forced_rapid_responder);
    setHasMoreThanTenOrders(has_over_ten_responses);
  };

  useRealTimeOrderUpdated(storeInfo, refreshRealTimeData);
  useRealTimeResponseTime(storeInfo, responseTimeReceived);

  const fetchOrdersData = () => {
    dispatch(getApprovedOrdersAction({ type: ORDER_TYPE.APPROVED, page: 1 }));
    dispatch(
      getAvailabilityOrdersAction({
        type: ORDER_TYPE.AVAILABILITY_REQUESTS,
        page: 1,
      }),
    );
  };

  useEffect(() => {
    fetchOrdersData();

    //  Clearing orders state on unrender to avoid UI issues
    return () => {
      dispatch(clearOrders());
    };
  }, []);

  const renderThumbsUp = useCallback(() => {
    if (
      availabilityOrdersInitialFetchCompleted &&
      approvedOrdersInitialFetchCompleted &&
      approvedOrders.length === 0 &&
      availabilityOrders.length === 0
    )
      return (
        <Box className='flex items-center justify-center p-20 sm:p-32'>
          <img
            className='h-20 w-20 sm:h-36 sm:w-36'
            src={ThumbsUpImage}
            alt='Thumbs Up Image'
          />
        </Box>
      );

    return null;
  }, [
    availabilityOrdersInitialFetchCompleted,
    approvedOrdersInitialFetchCompleted,
    approvedOrders,
    availabilityOrders,
  ]);

  const handleClick = () => {
    navigate('/help', { replace: true });
  };

  const handleOrderLogClicked = useCallback(() => {
    navigate('/tasks/logs');
  }, []);

  return (
    <Container
      maxWidth={false}
      className='bl-dashboard-container flex bg-[var(--bluon--ds--lightgray)]'
    >
      {claimingModal?.companyName && claimingModal?.order.id && (
        <ClaimingModal
          visible
          orderId={claimingModal.order.id}
          onClose={() => dispatch(setSelectedClaimingModal(null))}
          contractorName={claimingModal.companyName}
          techName={claimingModal.techName}
          onSuccessClaiming={() => {
            changeSelectedOrder(claimingModal.order.id, claimingModal.order);
            dispatch(setSelectedClaimingModal(null));
          }}
        />
      )}
      <Box className='align-center mb-6 mt-8 flex justify-start'>
        <Box className='ml-[47%] text-center font-normal'>
          <Typography className='title'>Tasks</Typography>
        </Box>
        <Box className='ml-auto flex items-end'>
          {!!minutes && minutes > 0 && (
            <ResponseTimeBadge
              minutes={minutes}
              isFlagedAsRapidResponder={isFlagedAsRapidResponder}
              hasMoreThanTenOrders={hasMoreThanTenOrders}
            />
          )}
          <RoundedButton
            endIcon={<AccessTimeIcon />}
            size='small'
            variant='outlined'
            color='secondary'
            additionalHeight='h-[2.5rem]'
            onClick={handleOrderLogClicked}
          >
            ORDER LOG
          </RoundedButton>
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
        justifyContent='center'
        alignItems='flex-start'
      >
        <Grid item xs={12} md={6}>
          <List
            title='Price & Availability Requests'
            emptyMessage='No new requests!'
            message='If a customer needs help it will show here.'
            items={availabilityOrders}
            isRequestLoading={availabilityOrdersIsLoading}
            loadingAllOrders={availabilityOrdersLoadingAll}
            initialFetchCompleted={availabilityOrdersInitialFetchCompleted}
            canFetchMore={hasMoreAvailabilityOrders}
            fetchMore={fetchMoreAvailabilityOrders}
            showTimer
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            title='Will Call / Approved Orders'
            emptyMessage='You’re all caught up!'
            message='Approved Orders that need your attention will show here.'
            items={approvedOrders}
            isRequestLoading={approvedOrdersIsLoading}
            loadingAllOrders={approvedOrdersLoadingAll}
            initialFetchCompleted={approvedOrdersInitialFetchCompleted}
            canFetchMore={hasMoreApprovedOrders}
            fetchMore={fetchMoreApprovedOrders}
          />
        </Grid>
      </Grid>
      {renderThumbsUp()}
      <div className='absolute bottom-5'>
        <RoundedButton
          variant='outlined'
          size='small'
          color='neutral'
          endIcon={<InfoIcon />}
          onClick={handleClick}
        >
          HELP / FEEDBACK
        </RoundedButton>
      </div>
    </Container>
  );
};
