import { createAction } from 'redux-actions';

// channel user
export const getChannelUsers = createAction('chat/get-channel-users');
export const getChannelUsersResponse = createAction(
  'chat/get-channel-users-response',
);
export const getMoreChannelUsers = createAction('chat/get-more-channel-users');
export const getMoreChannelUsersResponse = createAction(
  'chat/get-more-channel-users-response',
);

// user orders
export const getUserOrders = createAction('chat/get-user-orders');
export const getUserOrdersResponse = createAction(
  'chat/get-user-orders-response',
);
// chat
export const setCurrentChatSelected = createAction(
  'chat/set-current-chat-selected',
);
export const setChatMetaData = createAction('chat/set-chat-meta-data');
export const fillChatUnreadMessages = createAction(
  'chat/set-unread-messages-chat-list',
);
export const updateChannelListsLocally = createAction(
  'chat/set-channels-lists-locally',
);
export const setChatOpenedAction = createAction('chat/set-chat-opened');
export const setChatOpenedResolve = createAction(
  'chat/set-chat-opened-response',
);
