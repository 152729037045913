import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

import './styles.scss';
import { TabPanel } from '../../../../../../components/TabPanel';
import { CustomerTable } from '../CustomerTable';

import { CustomerDashboardProps } from './interface';

const setProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const CustomerDashboard = ({
  title,
  customerDashboardTables,
}: CustomerDashboardProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);

  // set customer counter next to title
  useEffect(() => {
    setCustomerCount(customerDashboardTables[tabIndex].customerList.length);
  }, [customerDashboardTables]);

  const handleOnChange = (e: SyntheticEvent, index: number) => {
    setTabIndex(index);
    setCustomerCount(customerDashboardTables[index].customerList.length);
  };

  const renderTabs = () => {
    const isMoreThanOneTable = customerDashboardTables.length > 1;

    return (
      isMoreThanOneTable && (
        <Tabs
          className='bl-customer-dashboard-tabs bl-customer-dashboard-tabs-spacing'
          value={tabIndex}
          onChange={handleOnChange}
        >
          {customerDashboardTables.map(({ tab }, index) => {
            return <Tab key={index} label={tab} {...setProps(index)} />;
          })}
        </Tabs>
      )
    );
  };

  const renderTabPanels = () => {
    return customerDashboardTables.map((props, index) => {
      return (
        <TabPanel key={index} value={tabIndex} index={index}>
          <CustomerTable {...props} />
        </TabPanel>
      );
    });
  };

  return (
    <Paper className='bl-customer-dashboard-container' elevation={0}>
      <Grid className='bl-customer-dashboard-header-container' container>
        <Grid item>
          <Typography variant='h6'>{title}</Typography>
        </Grid>
        <Grid className='bl-customer-dashboard-counter-container' item>
          <Box className='bl-customer-dashboard-counter'>{customerCount}</Box>
        </Grid>
      </Grid>

      {renderTabs()}
      {renderTabPanels()}
    </Paper>
  );
};
