import { Close } from '@mui/icons-material';
import ChipMui from '@mui/material/Chip';

import { ChipProps } from './interface';

export const Chip = ({ onDelete, ...props }: ChipProps): JSX.Element => {
  return (
    <ChipMui
      size='small'
      color='primary'
      variant='filled'
      {...props}
      deleteIcon={onDelete && <Close />}
      onDelete={onDelete}
    />
  );
};
