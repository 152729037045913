import React, { useCallback, useState } from 'react';

import DeclineOrCancelModal from '../../OrderDetail/components/DeclineOrCancelModal';

import { BuyerModal } from './Modal';

import Banner from '@components/Banner';
import { AvailableBanners } from '@components/Banner/constants';
import { useSnackbar } from '@components/Snackbar/hooks';
import { ORDER_TYPE } from '@config/api/task/interface';
import { useAppDispatch } from '@hooks/state';
import {
  realTimeRefreshAvailabilityOrders,
  saveNewCustomerRequest,
} from '@state/task/actions';

const NewCustomerBanner = ({
  orderId,
  userId,
}: {
  orderId: string;
  userId: number;
}) => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [openCashBuyerModal, setOpenCashBuyerModal] = useState<boolean>(false);
  const [openDeclineOrCancelModal, setOpenDeclineOrCancelModal] =
    useState<boolean>(false);
  const [openAccountBuyerModal, setOpenAccountBuyerModal] =
    useState<boolean>(false);

  const toggleCashBuyerModal = () => setOpenCashBuyerModal(!openCashBuyerModal);
  const toggleAccountBuyerModal = () =>
    setOpenAccountBuyerModal(!openAccountBuyerModal);

  const refreshAvailabilityOrders = () =>
    dispatch(
      realTimeRefreshAvailabilityOrders({
        type: ORDER_TYPE.AVAILABILITY_REQUESTS,
        page: 1,
      }),
    );

  const showSuccessToastMessage = (isAccountBuyer: boolean) => {
    if (isAccountBuyer) toggleAccountBuyerModal();
    else toggleCashBuyerModal();

    refreshAvailabilityOrders();
    showMessage('Successfully added!', {
      severity: 'success',
      autoHide: true,
    });
  };

  const showErrorToastMessage = () =>
    showMessage('Oops, something happened, please try again later!', {
      severity: 'error',
      autoHide: true,
    });

  const handleSaveAction = (
    customTagValue: string,
    isAccountBuyer: boolean,
  ) => {
    dispatch(
      saveNewCustomerRequest({
        userId,
        orderId,
        customTag: customTagValue,
        cashBuyer: !isAccountBuyer,
        successCallback: () => showSuccessToastMessage(isAccountBuyer),
        errorCallback: showErrorToastMessage,
      }),
    );
  };

  const togleDeclineOrCancel = () =>
    setOpenDeclineOrCancelModal(!openDeclineOrCancelModal);

  const bannerProps = AvailableBanners.newCostumerWarning;
  const [button1, button2, button3] = bannerProps.buttons;
  button1.onPress = toggleCashBuyerModal;
  button2.onPress = toggleAccountBuyerModal;
  button3.onPress = togleDeclineOrCancel;

  const renderModal = useCallback(() => {
    if (openCashBuyerModal)
      return (
        <BuyerModal
          title='Enter Custom Tag'
          subTitle='OPTIONAL'
          open={openCashBuyerModal}
          onClose={toggleCashBuyerModal}
          handleSaveAction={handleSaveAction}
          isLoading={false}
          isAccountBuyer={false}
        />
      );

    if (openAccountBuyerModal)
      return (
        <BuyerModal
          title='Enter Custom Tag'
          subTitle='OPTIONAL'
          open={openAccountBuyerModal}
          onClose={toggleAccountBuyerModal}
          handleSaveAction={handleSaveAction}
          isLoading={false}
          isAccountBuyer
        />
      );

    if (openDeclineOrCancelModal)
      return (
        <DeclineOrCancelModal
          orderId={String(orderId)}
          onClose={togleDeclineOrCancel}
          isOpen={openDeclineOrCancelModal}
        />
      );

    return null;
  }, [openCashBuyerModal, openAccountBuyerModal, openDeclineOrCancelModal]);

  return (
    <>
      {renderModal()}
      <Banner {...bannerProps} buttons={[button1, button2, button3]} />
    </>
  );
};

export default NewCustomerBanner;
