import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogsPageFooter = () => {
  const navigate = useNavigate();

  const onButtonClicked = () => {
    navigate('/tasks');
  };

  return (
    <div className='page-footer z-3 mt-auto flex h-[9%] max-h-20 w-full flex-row items-center justify-center bg-[var(--bluon--default--white)] p-4'>
      <Button
        className='h-[95%] px-1.5 py-4'
        variant='contained'
        color='primary'
        startIcon={<ArrowBackIcon sx={{ width: 16, height: 20 }} />}
        onClick={onButtonClicked}
      >
        BACK TO TASK HUB
      </Button>
    </div>
  );
};

export default LogsPageFooter;
