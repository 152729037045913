import { Box, Dialog, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from '../Button';
import { useSnackbar } from '../Snackbar/hooks';
import TextInput from '../TextInput';

import { SuggestionModalProps } from './interface';

import { useAppDispatch } from '@hooks/state';
import { searchPartRecommendReplacementsRequest } from '@state/search/actions';
import { emptyFunction } from '@utils/functions';
import './styles.scss';

export const SuggestionModal = ({
  onLeave,
  partId,
  visible,
}: SuggestionModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState<string>('');
  const { control, setValue, setError, watch, clearErrors } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      brand: '',
      notes: '',
      part: '',
    },
  });
  const brandValue = watch('brand');
  const partValue = watch('part');
  const noteValue = watch('notes');

  const checkIfRequiredIsNotEmpty = () => {
    if (
      brandValue &&
      brandValue.length >= 2 &&
      partValue &&
      partValue.length >= 2 &&
      ((noteValue && noteValue.length >= 2) || !noteValue)
    )
      return true;

    if (brandValue && brandValue.length < 2)
      setError('brand', {
        type: 'Invalid',
        message: 'You need to enter at least 2 characters.',
      });

    if (partValue && partValue.length < 2)
      setError('part', {
        type: 'Invalid',
        message: 'You need to enter at least 2 characters.',
      });

    if (noteValue && noteValue.length < 2)
      setError('notes', {
        type: 'Invalid',
        message: 'You need to enter at least 2 characters.',
      });

    if (!brandValue)
      setError('brand', {
        type: 'Invalid',
        message: 'This field is required',
      });
    if (!partValue)
      setError('part', {
        type: 'Invalid',
        message: 'This field is required',
      });
  };

  const clearInputs = () => {
    setValue('brand', '');
    setValue('notes', '');
    setValue('part', '');
  };

  const handleCancel = () => {
    clearInputs();
    clearErrors();
    onLeave();
  };

  const showToastMessage = (success: boolean) =>
    success
      ? showMessage('Suggestion sent successfully', {
          severity: 'success',
          autoHide: true,
        })
      : showMessage(
          'There was an error submitting your data, please try again later.',
          {
            severity: 'error',
            autoHide: true,
          },
        );

  const handleSendSuggestion = (stayForAnother: boolean) => {
    clearErrors();
    if (checkIfRequiredIsNotEmpty()) {
      setLoading(stayForAnother ? 'suggestAnother' : 'send');
      dispatch(
        searchPartRecommendReplacementsRequest({
          partId,
          brand: brandValue,
          partNumber: partValue,
          note: noteValue,
          successCallback: () => {
            showToastMessage(true);
            clearInputs();
            stayForAnother ? null : onLeave();
            setLoading('');
          },
          errorCallback: () => {
            showToastMessage(false);
            setLoading('');
          },
        }),
      );
    }
  };

  return (
    <Dialog
      className='bl-container-suggestion-modal-dialog'
      maxWidth='xs'
      onClose={emptyFunction}
      open={visible}
    >
      <Stack
        border='-moz-initial'
        className='modal-stack-container-suggestion'
        justifyContent='center'
        spacing={1}
      >
        <Box className='suggestion-modal-container-content'>
          <Box className='container-title'>
            <Typography
              className='modal-title'
              color='initial'
              variant='caption'
            >
              What should we include as Known Replacement?
            </Typography>
          </Box>
          <Box className='container-required-input'>
            <Controller
              name='brand'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextInput
                  data-testid='brand-input'
                  error={!!fieldState.error}
                  errorMessage={formState.errors.brand?.message}
                  id='brand'
                  label='Brand'
                  maxLength={255}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  placeholder='Enter your suggestion'
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box className='container-required-input'>
            <Controller
              name='part'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextInput
                  data-testid='part-input'
                  error={!!fieldState.error}
                  errorMessage={formState.errors.part?.message}
                  id='part'
                  label='Part #'
                  maxLength={255}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  placeholder='Enter your suggestion'
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box className='container-required-input'>
            <Controller
              name='notes'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextInput
                  className='notes-input'
                  data-testid='notes-input'
                  id='notes'
                  label='Notes '
                  multiline
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  placeholder='Enter your suggestion'
                  subLabel='(Optional)'
                  value={field.value}
                  maxLength={255}
                  errorMessage={formState.errors.notes?.message}
                  error={!!fieldState.error}
                />
              )}
            />
          </Box>
          <Box className='container-btns'>
            <Button
              className='cancel-btn'
              onClick={handleCancel}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              className='send-and-suggest-btn'
              color='primary'
              onClick={() => {
                !loading && handleSendSuggestion(true);
              }}
              variant='contained'
              loading={loading === 'suggestAnother'}
            >
              {'Send & Suggest Another'}
            </Button>
            <Button
              className='send-btn'
              color='primary'
              onClick={() => {
                !loading && handleSendSuggestion(false);
              }}
              variant='contained'
              loading={loading === 'send'}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Stack>
    </Dialog>
  );
};
