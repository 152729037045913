import { MessageEnvelope } from '@pubnub/react-chat-components';
import _ from 'lodash';

import { GroupedMessages } from './MessageList/interface';

import { ChannelSelectedState } from '@state/chat/interface';

export const getFilterCondition = (channelList: ChannelSelectedState[]) => {
  let string = '';
  for (const [i, v] of channelList.entries()) {
    string += `id == '${v.chat.channel}'`;
    if (i + 1 !== channelList.length) string += ' || ';
  }

  return string;
};

export const sumUnreadMessageChat = (payload: Record<string, number>) => {
  return payload != undefined
    ? (Object.values(payload) as number[]).reduce(
        (partialSum: number, a: number) => partialSum + a,
        0,
      )
    : null;
};

export const groupByHour = (array: MessageEnvelope[]): GroupedMessages[] => {
  const grouped = _.groupBy(array, (obj) => {
    const timestamp = parseInt(obj.timetoken.toString().substring(0, 10));

    return Math.floor(timestamp / 3600);
  });

  const result: GroupedMessages[] = [];

  for (const key in grouped) {
    const group = grouped[key];
    const firstTimestamp = parseInt(
      group[0].timetoken.toString().substring(0, 10),
    );
    let currentHour = firstTimestamp;

    let hourGroup = [];

    for (let i = 0; i < group.length; i++) {
      const timestamp = parseInt(
        group[i].timetoken.toString().substring(0, 10),
      );

      if (timestamp - currentHour > 3600) {
        result.push({ [group[0].timetoken]: hourGroup });
        hourGroup = [];
        currentHour = timestamp;
      }

      hourGroup.push(group[i]);
    }

    if (hourGroup.length > 0) result.push({ [group[0].timetoken]: hourGroup });
  }

  return result;
};
