import classNames from 'classnames';
import React, { useMemo } from 'react';

import ReadOnlyItem from './components/ReadOnlyItem';
import ReadOnlyPartItem from './components/ReadOnlyPartItem';
import { ReadOnlyOrderDetailProps } from './interface';

import { CustomItem, ExtraItem } from '@config/api/task/interface';
import { EItemStatus } from '@utils/enums';

const ReadOnlyOrderDetail = ({
  partsLoading = false,
  truckStockItems,
  truckStockLoading = false,
  showAddedItems = false,
  items,
  customItems,
  addedItems,
  areCustomItemsLoading = false,
  isAddedItemsLoading = false,
}: ReadOnlyOrderDetailProps) => {
  //  Getting available items
  const availableItems = useMemo(() => {
    return items?.filter((item) => item?.status === EItemStatus.Available);
  }, [items]);

  const availableTruckStockItems = useMemo(() => {
    return truckStockItems?.filter(
      (item) => item?.status === EItemStatus.Available,
    );
  }, [truckStockItems]);

  const availableCustomItems = useMemo(() => {
    return customItems?.filter(
      (item) => item?.status === EItemStatus.Available,
    );
  }, [customItems]);

  const renderPartItemsElements = () => {
    if (partsLoading) return <ReadOnlyPartItem.Skeleton />;
    if (!availableItems || availableItems.length === 0) return null;

    return availableItems.map((item, index) => {
      return (
        <ReadOnlyPartItem
          item={item}
          key={index}
          isLastItem={availableItems.length - 1 === index}
        />
      );
    });
  };

  const renderGenericElements = (
    loading = false,
    list: ExtraItem[] | CustomItem[] = [],
  ) => {
    if (loading) return <ReadOnlyItem.Skeleton />;
    if (!list || list?.length === 0) return null;

    return list.map((item, index) => {
      return (
        <ReadOnlyItem
          name={item?.item?.info?.name}
          quantity={item?.quantity}
          key={index}
          isLastItem={list.length - 1 === index}
        />
      );
    });
  };

  const renderListSection = (
    title: string,
    elements: React.ReactNode,
    isFirstListSection = false,
    subtitle?: string,
  ): React.ReactNode => {
    if (!elements) return null;

    return (
      <div
        className={classNames(
          'flex w-full flex-col items-start justify-center',
          {
            'mt-4 md:mt-2': isFirstListSection,
            'mt-7': !isFirstListSection,
          },
        )}
      >
        {subtitle ? (
          <>
            <div className='mb-2 pl-4 text-xl font-medium tracking-[.009em] text-opacity-[87%]'>
              {title}
            </div>
            <div className='pl-4 text-sm font-bold text-opacity-[87%]'>
              {subtitle}
            </div>
          </>
        ) : (
          <div className='mb-2 pl-4 text-sm font-bold text-opacity-[87%] md:mb-0'>
            {title}
          </div>
        )}
        <div className='mt-1 flex w-full flex-col items-start justify-center'>
          {elements}
        </div>
      </div>
    );
  };

  return (
    <div className='align-start mb-4 flex w-full flex-col md:justify-center'>
      <div className='pl-4 text-xl font-medium leading-8 text-black text-opacity-[87%]'>
        Initial Request
      </div>
      <div className='align-center flex w-full flex-row justify-start border-b bg-[var(--bluon--light--gray-order-detail)] px-4 py-2'>
        <div className='text-sm font-medium leading-6 text-opacity-[87%]'>
          Item
        </div>
        <div className='ml-auto text-sm font-medium leading-6 text-opacity-[87%]'>
          Qty
        </div>
      </div>
      {renderListSection('Functional Parts', renderPartItemsElements(), true)}
      {renderListSection(
        'Truck Stock',
        renderGenericElements(truckStockLoading, availableTruckStockItems),
      )}
      {renderListSection(
        'Custom Items',
        renderGenericElements(areCustomItemsLoading, availableCustomItems),
      )}
      {showAddedItems &&
        renderListSection(
          'Added Items',
          renderGenericElements(isAddedItemsLoading, addedItems),
          false,
          'Truck Stock',
        )}
    </div>
  );
};

export default ReadOnlyOrderDetail;
