import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import AdvertisementImage from '../../../../assets/images/claiming-advertisement.png';

import { ClaimingModalSelectStaffProps } from './interface';

import Button from '@components/Button';
import { CounterStaffInfo } from '@config/api/storeInfo/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import {
  getSupplierStaffRequest,
  setCounterStaffRequest,
} from '@state/task/actions';
import {
  getLastSupplierAdded,
  getSupplierStaff,
  getSupplierStaffLastAssigned,
} from '@state/task/selectors';
import './styles.scss';

export const ClaimingModalSelectStaff = ({
  techName,
  contractorName,
  onAddNewCounterStaff,
  addCounterStaffCallback,
}: ClaimingModalSelectStaffProps) => {
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = useState<CounterStaffInfo>();

  const {
    isLoadingSetCounterStaff,
    isLoadingSupplierStaff,
    lastAssigned,
    supplierStaff,
    lastSupplierAdded,
  } = useAppSelector((state) => ({
    isLoadingSupplierStaff: isRequestRunning(
      state,
      String(getSupplierStaffRequest),
    ),
    isLoadingSetCounterStaff: isRequestRunning(
      state,
      String(setCounterStaffRequest),
    ),
    lastAssigned: getSupplierStaffLastAssigned(state as any),
    lastSupplierAdded: getLastSupplierAdded(state as any),
    supplierStaff: getSupplierStaff(state as any),
  }));

  useEffect(() => {
    dispatch(getSupplierStaffRequest({ type: 'counter' }));
  }, []);

  const handleDispatchNewStaff = () => {
    if (!selectedItem) return;

    addCounterStaffCallback(selectedItem);
  };

  useEffect(() => {
    if (lastAssigned && supplierStaff.length && !lastSupplierAdded) {
      const supplierItem = supplierStaff.find(
        (supplier) => supplier.id === lastAssigned,
      );
      if (supplierItem) setSelectedItem(supplierItem);
    }
    if (supplierStaff.length && lastSupplierAdded) {
      const supplierItem = supplierStaff.find(
        (supplier) => supplier.id === lastSupplierAdded,
      );
      if (supplierItem) setSelectedItem(supplierItem);
    }
  }, [lastAssigned, supplierStaff, lastSupplierAdded]);

  const disableAddNewPersonBtn = supplierStaff.length >= 20;

  return (
    <Box className='cm-container-info'>
      <Box className='cm-container-info-title'>
        <Box className='cm-container-info-title-first'>
          <Typography variant='body1' className='claim-job-text'>
            Claim job for:
          </Typography>
        </Box>
        <Box className='cm-container-info-title-second'>
          <Typography variant='body1' className='contractor-name-text'>
            {contractorName}
          </Typography>
        </Box>
        <Box className='cm-container-info-title-third'>
          <Typography variant='body1' className='tech-name-text'>
            {techName}
          </Typography>
        </Box>
      </Box>
      {!supplierStaff.length && !isLoadingSupplierStaff ? (
        <Box className='cm-container-add-counter-staff-btn'>
          <Button
            variant='contained'
            className='add-counter-staff-btn'
            onClick={onAddNewCounterStaff}
          >
            Add counter staff to get started
          </Button>
        </Box>
      ) : (
        <>
          <Box className='cm-container-info-options'>
            {isLoadingSupplierStaff ? (
              <CircularProgress />
            ) : (
              <Box className='cm-list-container sm:max-h-40 lg:max-h-52 xl:max-h-full'>
                {supplierStaff.length &&
                  supplierStaff.slice(0, 20).map((item) => (
                    <Button
                      key={item.id}
                      variant='contained'
                      onClick={() => setSelectedItem(item)}
                      className={`item-btn ${
                        selectedItem?.id === item.id ? 'selected' : ''
                      }`}
                    >
                      <Typography variant='body1' className='supplier-btn-text'>
                        {item.name}
                      </Typography>
                    </Button>
                  ))}
              </Box>
            )}
          </Box>
          <Box className='cm-advertisement-container'>
            <img src={AdvertisementImage} alt='advertisement' />
          </Box>
          <Box className='cm-container-info-actions'>
            <Box className='cm-container-info-actions-got-it '>
              <Button
                variant='contained'
                className={`got-it-btn ${
                  !selectedItem || isLoadingSetCounterStaff ? 'disabled' : ''
                }`}
                disabled={!selectedItem || isLoadingSetCounterStaff}
                onClick={handleDispatchNewStaff}
              >
                <>
                  I got it!
                  {isLoadingSetCounterStaff && (
                    <CircularProgress
                      size={20}
                      sx={{ marginLeft: 1 }}
                      color='inherit'
                    />
                  )}
                </>
              </Button>
            </Box>
            <Box className='cm-container-info-actions-add-new-person'>
              <Button
                variant='text'
                className={`add-text-btn ${
                  disableAddNewPersonBtn ? 'disabled' : ''
                }`}
                onClick={() => {
                  if (disableAddNewPersonBtn) return;

                  return onAddNewCounterStaff();
                }}
              >
                <>
                  Add New Person
                  {disableAddNewPersonBtn && (
                    <Box className='custom-tooltip'>
                      There is a max of 20 counter staff allowed. Go to your
                      account settings to manage your counter staff.
                    </Box>
                  )}
                </>
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
