// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const modelsSpecificationsString: any = {
  system_details: {
    system_type: 'System Type',
    unit_type: 'Unit Type',
    tonnage: 'Tonnage',
    total_circuits: 'Total Circuits',
    dx_chiller: 'DX Chiller',
    cooling_type: 'Cooling Type',
    seer: 'SEER',
    eer: 'EER',
  },
  refrigerant_details: {
    refrigerant: 'Refrigerant',
    original_charge_oz: 'Original Charge (oz)',
  },
  compressor_details: {
    compressor_type: 'Compressor Type',
    compressor_brand: 'Compressor Brand',
    compressor_model: 'Compressor Model',
    total_compressors: 'Total Compressors',
    compressors_per_circuit: 'Compressors Per Circuit',
    compressor_sizes: 'Compressor Size(s)',
    rla: 'RLA',
    lra: 'LRA',
    capacity_staging: 'Capacity Staging',
    compressor_notes: 'Compressor Notes',
  },
  oil_details: {
    oil_type: 'Oil Type',
    oil_amt_oz: 'Oil Amt (oz)',
    oil_notes: 'Oil Notes',
  },
  metering_device_details: {
    device_type: 'Device Type',
    devices_per_circuit: 'Devices Per Circuit',
    total_devices: 'Total Devices',
    device_size: 'Device Size',
  },
};
