import { SearchModelsDataModel } from '../../config/api/search/interface';

export const getSelectedModel = (
  modelId: string,
  models: SearchModelsDataModel[],
) => {
  if (!modelId) return undefined;

  return models.find((model) => model.id === modelId);
};
