import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import './styles.scss';
import Button from '../../../components/Button';
import { useSnackbar } from '../../../components/Snackbar/hooks';
import TextInput from '../../../components/TextInput';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { ResetPasswordErrorMessage, ResetPasswordFormKeys } from './interfaces';
import { ResetPasswordValidationSchema } from './validations';

import { useAppSelector } from '@hooks/state';
import { resetPasswordRequest } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';

export const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const { loading, error } = useAppSelector((state) => ({
    loading: isRequestRunning(state, String(resetPasswordRequest)),
    error: getRequestError(
      state,
      String(resetPasswordRequest),
    ) as ResetPasswordErrorMessage,
  }));

  const email = searchParams.get('email');

  const initialValues: ResetPasswordFormKeys = {
    password: '',
    passwordConfirmation: '',
  };

  const { control, handleSubmit, watch, getValues, clearErrors, setError } =
    useForm<ResetPasswordFormKeys>({
      resolver: yupResolver(ResetPasswordValidationSchema),
      defaultValues: initialValues,
      mode: 'onChange',
    });

  const password = watch('password');

  useEffect(() => {
    if (!password) return;
    const confirmPassword = getValues().passwordConfirmation;
    if (confirmPassword && confirmPassword === password)
      clearErrors('passwordConfirmation');
    else if (confirmPassword)
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'Password doesn’t match',
      });
  }, [password]);

  const handleSubmitForm = (formData: ResetPasswordFormKeys) => {
    const data = { ...formData, token, email };
    dispatch(
      resetPasswordRequest({
        ...data,
        callback: () => {
          showMessage('Your password has been reset successfully.', {
            severity: 'success',
          });
          navigate('/login');
        },
      }),
    );
  };

  const handleGoBack = () => navigate('/login');

  useEffect(() => {
    if (error.email) showMessage(error.email[0], { severity: 'error' });
    if (error.password) showMessage(error.password[0], { severity: 'error' });
  }, [error]);

  return (
    <WelcomeContainer>
      <Box className='reset-password-form-container'>
        <Grid container justifyContent='center'>
          <Grid item xs={5} className='reset-password-form-title'>
            <AuthFormHeader
              title='Reset Password'
              subTitle='Your password must be at least 8 characters long.'
            />
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <Controller
                name='password'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextInput
                    id='password'
                    type='password'
                    label='New Password'
                    data-testid='password'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    error={!!fieldState.error}
                    errorMessage={formState.errors.password?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <Controller
                name='passwordConfirmation'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextInput
                    id='passwordConfirmation'
                    type='password'
                    label='Confirm Password'
                    data-testid='passwordConfirmation'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    error={!!fieldState.error}
                    errorMessage={
                      formState.errors.passwordConfirmation?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent='center'
            className='reset-password-button-container'
          >
            <Grid item xs={5}>
              <Button
                loading={loading}
                disabled={loading}
                fullWidth
                variant='contained'
                type='submit'
                color='primary'
                data-testid='resetPasswordButton'
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid container justifyContent='center'>
          <Grid item xs={5}>
            <Button
              variant='text'
              color='primary'
              data-testid='goBackButton'
              startIcon={<ArrowBackIcon />}
              onClick={handleGoBack}
            >
              Go back to log in
            </Button>
          </Grid>
        </Grid>
        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
