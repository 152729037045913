import { useEffect, useState } from 'react';

import { ISentToCustomerBannerProps } from '../UpdateQuoteBanner/interface';

import Banner from '@components/Banner';
import {
  AvailableBanners,
  YesNoButtonCombination,
} from '@components/Banner/constants';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import {
  deleteOrderToRemember,
  setOrderCompleteRequest,
  setOrderToRemember,
} from '@state/task/actions';
import { getOrderToRemember } from '@state/task/selectors';

const SentToCustomerBanner = ({ order }: ISentToCustomerBannerProps) => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const { rememberData } = useAppSelector((state) => ({
    rememberData: getOrderToRemember(state),
  }));

  const [loadingBanner, setLoadingBanner] = useState({
    orderId: '',
    loading: false,
  });

  const handleClickNoToRememberOnTime = () => {
    dispatch(
      setOrderToRemember({
        id: order.id,
        clickedTime: new Date(),
      }),
    );
  };

  const handleClickYes = () => {
    setLoadingBanner({ orderId: order.id, loading: true });
    dispatch(
      setOrderCompleteRequest({
        order: order.id,
        total: order.total,
        errorCallback: () => {
          showMessage('There was an error completing the order request.', {
            severity: 'error',
            autoHide: true,
          });
          setLoadingBanner({ orderId: order.id, loading: false });
        },
      }),
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const item = rememberData.find((item) => item.id === order.id);
      if (item) {
        const clickedTime = item?.clickedTime.getTime();
        const timeNow = new Date().getTime();
        const minutesBetweenDates = Math.abs((clickedTime - timeNow) / 60000);

        if (minutesBetweenDates >= 30)
          dispatch(
            deleteOrderToRemember(
              rememberData.filter((stateItem) => stateItem.id !== item.id),
            ),
          );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [order.id, rememberData]);

  const isThisItemOnRemember = rememberData.some(
    (item) => item.id === order.id,
  );

  if (isThisItemOnRemember)
    return (
      <div className='flex max-h-12 items-center justify-between px-2 py-3' />
    );

  return (
    <>
      <Banner
        {...AvailableBanners.sendToCustomer}
        buttons={[
          {
            loading: loadingBanner.loading,
            ...YesNoButtonCombination[0],
            onPress: () => handleClickYes(),
          },
          {
            disabled: loadingBanner.loading,
            ...YesNoButtonCombination[1],
            onPress: () => handleClickNoToRememberOnTime(),
            className: undefined,
          },
        ]}
      />
    </>
  );
};

export default SentToCustomerBanner;
