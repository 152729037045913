import { Button, CircularProgress, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { BuyerModalProps } from './interface';

import { ShellModal } from '@components/ShellModal';

export const BuyerModal = ({
  title,
  subTitle,
  open,
  onClose,
  handleSaveAction,
  isLoading,
  isAccountBuyer,
}: BuyerModalProps) => {
  const { control, watch } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      customTag: '',
    },
  });

  const customTagValue = watch('customTag');

  const handleSave = () => {
    handleSaveAction(customTagValue, isAccountBuyer);
  };

  return (
    <ShellModal
      title={title}
      subtitle={subTitle}
      open={open}
      onClose={onClose}
      bottomButtons={[
        <Button key={1} onClick={onClose} variant='outlined'>
          CANCEL
        </Button>,
        <Button key={2} onClick={handleSave} variant='contained'>
          <>
            SAVE
            {isLoading && (
              <CircularProgress
                size='small'
                className='ml-1.5 w-[1.5rem] !text-white'
              />
            )}
          </>
        </Button>,
      ]}
    >
      <div className='mb-10 flex w-[35rem] flex-col gap-4'>
        <Controller
          name='customTag'
          control={control}
          render={({ field }) => (
            <TextField
              className='w-[35rem]'
              id='customTag'
              label='Custom Tag (Optional)'
              variant='outlined'
              name={field.name}
              onChange={(e) => {
                const value = e.target.value;
                if (!!value && value.length > 255) return;
                field.onChange(e);
              }}
              value={field.value}
              onBlur={field.onBlur}
              color='textFieldPrimary'
            />
          )}
        />
      </div>
    </ShellModal>
  );
};
