import {
  brandsResponse,
  cancelReasonsResponse,
  cleanStoreInfo,
  declineReasonsResponse,
  getCountriesResponse,
  getLimitedStoreInfoResponse,
  getStatesResponse,
  moreBrandsResponse,
  postBranchHoursResponse,
  resetStore,
  returnReasonsResponse,
  setWelcomeDisplayedAt,
  storeInfoBrandsResponse,
  storeInfoGetResponse,
} from './actions';
import {
  LimitedStoreInfoResponse,
  ReasonsReducerResponse,
  StoreBrandsResponse,
  StoreInfoBrandsResponseProps,
  StoreInfoResponse,
  StoreInfoResponseActionProps,
  StoreInfoResponseBranchHours,
  StoreInfoResponseStatesCountries,
  StoreInfoStateProps,
} from './interface';

import {
  BrandsDataModel,
  BrandsDataProps,
  LimitedStoreInfoDataModel,
  SelectedHoursDataModel,
  StateCountryDataModel,
  StoreInfoDataModel,
} from '@config/api/storeInfo/interface';
import { getHasNextPageFromMeta, getNextPageFromMeta } from '@utils/apiPaging';

const initialState = {
  limitedStoreInfoResponse: {
    id: '',
    take_rate: '',
    take_rate_until: null,
  },
  storeInfoResponse: undefined,
  brands: [],
  hasNextPage: false,
  nextPage: 1,
  storeBrands: [],
  countriesData: [],
  statesData: [],
  declineReasons: undefined,
  cancelReasons: undefined,
  returnReasons: undefined,
};

const storeInfoReducer = (
  state = <StoreInfoStateProps>initialState,
  action:
    | LimitedStoreInfoResponse
    | StoreInfoResponse
    | StoreInfoBrandsResponseProps
    | StoreInfoResponseActionProps
    | StoreInfoResponseStatesCountries
    | StoreInfoResponseBranchHours
    | LimitedStoreInfoResponse
    | ReasonsReducerResponse,
): StoreInfoStateProps => {
  switch (action.type) {
    case String(getLimitedStoreInfoResponse): {
      return {
        ...state,
        limitedStoreInfoResponse: (action as LimitedStoreInfoResponse).payload
          .data as LimitedStoreInfoDataModel,
      };
    }
    case String(storeInfoGetResponse): {
      return {
        ...state,
        storeInfoResponse: (action as StoreInfoResponse).payload
          .data as StoreInfoDataModel,
      };
    }
    case String(setWelcomeDisplayedAt): {
      return {
        ...state,
        storeInfoResponse: {
          ...(state.storeInfoResponse as StoreInfoDataModel),
          welcome_displayed_at: 'displayed',
        },
      };
    }
    case String(brandsResponse): {
      const { data, meta } = (action as StoreInfoBrandsResponseProps).payload;

      return {
        ...state,
        brands: data as BrandsDataModel[],
        hasNextPage: getHasNextPageFromMeta(meta),
        nextPage: getNextPageFromMeta(meta),
      };
    }
    case String(moreBrandsResponse): {
      const { data, meta } = (action as StoreInfoBrandsResponseProps).payload;

      return {
        ...state,
        brands: [
          ...(state.brands as BrandsDataModel[]),
          ...(data as BrandsDataModel[]),
        ],
        hasNextPage: getHasNextPageFromMeta(meta),
        nextPage: getNextPageFromMeta(meta),
      };
    }
    case String(storeInfoBrandsResponse): {
      return {
        ...state,
        storeBrands: (action as StoreBrandsResponse).payload
          .data as BrandsDataModel[],
        storeInfoResponse: {
          ...(state.storeInfoResponse as StoreInfoDataModel),
          brands: action.payload as BrandsDataProps,
        },
      };
    }
    case String(postBranchHoursResponse): {
      return {
        ...state,
        storeInfoResponse: {
          ...(state.storeInfoResponse as StoreInfoDataModel),
          open_hours: action.payload.data as SelectedHoursDataModel[],
        },
      };
    }
    case String(getCountriesResponse): {
      return {
        ...state,
        countriesData: (action as StoreInfoResponseStatesCountries).payload
          .data as StateCountryDataModel[],
      };
    }
    case String(getStatesResponse): {
      return {
        ...state,
        statesData: (action as StoreInfoResponseStatesCountries).payload
          .data as StateCountryDataModel[],
      };
    }
    case String(resetStore): {
      return initialState;
    }
    case String(cancelReasonsResponse): {
      return {
        ...state,
        cancelReasons: (action as ReasonsReducerResponse).payload,
      };
    }
    case String(declineReasonsResponse): {
      return {
        ...state,
        declineReasons: (action as ReasonsReducerResponse).payload,
      };
    }
    case String(returnReasonsResponse): {
      return {
        ...state,
        returnReasons: (action as ReasonsReducerResponse).payload,
      };
    }
    case String(cleanStoreInfo): {
      return {
        ...state,
        storeInfoResponse: undefined,
      };
    }
    default:
      return state;
  }
};

export default storeInfoReducer;
