import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { OrderDetailTypes } from '../../constants';

import StatusLabel from './StatusLabel';

import RoundedButton from '@components/RoundedButton';
import { Order } from '@config/api/task/interface';
import { useAppSelector } from '@hooks/state';
import { setChatOpenedAction } from '@state/chat/actions';
import { isRequestRunning } from '@state/requests/selectors';
import { getCurrentSelectedOrder } from '@state/task/selectors';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';
import { getDateFormat } from '@utils/Strings/dateFormat';

export interface HeaderProps {
  isLoading: boolean;
  onOpenChat?: () => void;
  onAddItem?: () => void;
  orderDetailType: OrderDetailTypes;
}

export const Header = ({
  isLoading,
  onOpenChat,
  onAddItem,
  orderDetailType,
}: HeaderProps): JSX.Element => {
  const { order, isOpenChatLoading } = useAppSelector((state) => ({
    order: getCurrentSelectedOrder(state),
    isOpenChatLoading: isRequestRunning(state, String(setChatOpenedAction)),
  }));

  const companyName = order?.company
    ? capitalizeFirstLetter(order?.user?.company || order?.company.name || '')
    : "Technician's Personal Job";

  const techName = `${capitalizeFirstLetter(
    order?.user?.first_name || '',
  )} ${capitalizeFirstLetter(order?.user?.last_name || '')}`;

  const isCashBuyer = order?.supplier_user?.cash_buyer;
  const customerTier = order?.supplier_user?.customer_tier;

  return (
    <div className='mb-2 ml-3 flex flex-col justify-between md:flex-row'>
      {!isLoading && (
        <div className='flex gap-2'>
          <div className='mt-[0.313em] flex h-[3.125em] w-[3.125em] items-center justify-center'>
            <Avatar sx={{ width: 40, height: 40 }} src={order?.user?.photo} />
          </div>

          <div>
            <div className='flex max-w-[62.5em] flex-col gap-1'>
              <div className='flex gap-2'>
                <div className='text-xl'>Request for {companyName}</div>
                <StatusLabel
                  orderDetailType={orderDetailType}
                  order={order as Order}
                />
              </div>
              <div className='flex items-center gap-2 text-base leading-7 text-black opacity-60'>
                <div>{techName}</div>
                <div>
                  <FiberManualRecordIcon className='!h-2 !w-2' />
                </div>
                <div>
                  {getDateFormat(
                    order?.created_at as string,
                    'MMM dd, yyyy, hh:mm a',
                  )}
                </div>
                {isCashBuyer && (
                  <div className='text-xs font-bold text-[var(--bluon--light--blue)]'>
                    <FiberManualRecordIcon className='!h-3 !w-3' /> Cash Buyer
                  </div>
                )}
                {!isCashBuyer && (
                  <div className='text-xs font-bold text-[var(--bluon--brand--blue-2)]'>
                    <FiberManualRecordIcon className='!h-3 !w-3' /> Account
                    Buyer
                  </div>
                )}
                {customerTier && (
                  <div className='text-xs text-[var(--bluon--lighter--navy)]'>
                    <FiberManualRecordIcon className='!h-3 !w-3' />{' '}
                    {customerTier}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && <SkeletonContent />}
      <div className='mt-6 flex gap-2 md:mt-0'>
        <RoundedButton
          variant='outlined'
          color='secondary'
          size='small'
          endIcon={<EmailIcon />}
          onClick={onOpenChat}
          loading={isOpenChatLoading}
          disabled={isOpenChatLoading}
        >
          OPEN CHAT
        </RoundedButton>
        {/* Just rendering add item button for pending status */}
        {orderDetailType === OrderDetailTypes.PENDING && (
          <RoundedButton
            variant='outlined'
            color='primary'
            size='small'
            endIcon={<AddIcon />}
            onClick={onAddItem}
          >
            ADD ITEM
          </RoundedButton>
        )}
      </div>
    </div>
  );
};

export const SkeletonContent = (): JSX.Element => {
  return (
    <div className='flex gap-4'>
      <Skeleton variant='circular' width={40} height={40} />

      <div>
        <div className='flex gap-1'>
          <div>
            <Skeleton width={200} height={30} />
          </div>
          <div className='flex'>
            <Skeleton width={150} height={30} />
          </div>
        </div>
        <div className='flex'>
          <Skeleton width={150} height={15} />
        </div>
      </div>
    </div>
  );
};
