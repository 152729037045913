import client from '..';

import {
  BranchHoursResponse,
  BrandsDataProps,
  LimitedStoreInfoDataProps,
  PostBranchHours,
  ReasonsResponse,
  StoreInfoDataProps,
  StoreInfoStatesCountries,
} from './interface';
import { mapPatchPayload, prepareFormData } from './mappers';

import { StoreInfoFormKeys } from '@pages/Account/subpages/StoreInfo/interfaces';
import { PostStoreInfoParamsProps } from '@state/storeInfo/interface';

export async function getLimitedStoreInfo(): Promise<LimitedStoreInfoDataProps> {
  const { data } = await client.get('/limited-supplier');

  return data;
}

export async function getStoreInfo(): Promise<StoreInfoDataProps> {
  const { data } = await client.get('/supplier');

  return data;
}

export async function getBrands(params: {
  page: number;
  search: string;
}): Promise<BrandsDataProps> {
  const search_string = params.search ? encodeURI(params.search) : '';

  const { data } = await client.get('/brands', {
    params: { page: params.page, search_string },
  });

  return data;
}

export async function patchStoreInfo(
  payload: StoreInfoFormKeys,
): Promise<StoreInfoDataProps> {
  const formData = new FormData();
  const mappedPayload = mapPatchPayload(payload);

  prepareFormData(formData, mappedPayload);

  const { data } = await client.post('/supplier', formData);

  return data;
}

export async function postStoreInfoBrands(
  payload: PostStoreInfoParamsProps,
): Promise<BrandsDataProps> {
  const { data } = await client.post('/supplier/bulk-brand', payload);

  return data;
}

export async function getCountriesApi(): Promise<StoreInfoStatesCountries> {
  const { data } = await client.get('/address/countries');

  return data;
}

export async function getStatesApi(
  countryCode: string,
): Promise<StoreInfoStatesCountries> {
  const { data } = await client.get(`/address/countries/${countryCode}/states`);

  return data;
}

export async function postBranchHoursApi(
  payload: PostBranchHours,
): Promise<BranchHoursResponse> {
  const { data } = await client.post('/supplier/bulk-hour', payload);

  return data;
}

export async function getReasonsByStatus(
  status: string,
): Promise<ReasonsResponse> {
  const { data } = await client.get(`/substatuses/${status}/reasons`);

  return data;
}
