import { Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import './styles.scss';

import { ToolbarSearchProps } from './interface';

export const ToolbarSearch = ({
  handleChangeTab,
  searchTabs,
  tabValue,
}: ToolbarSearchProps): JSX.Element => (
  <Grid container>
    <Grid item sm={7} display='flex' alignItems='center'>
      <Tabs value={tabValue} onChange={handleChangeTab}>
        {searchTabs.map((tabItem) => (
          <Tab key={tabItem.id} data-testid={tabItem.id} {...tabItem} />
        ))}
      </Tabs>
    </Grid>
  </Grid>
);
