import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import './styles.scss';
import Button from '../../../components/Button';
import TakeRate from '../../../components/TakeRate';
import TextInput from '../../../components/TextInput';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { AcceptTermsCheckbox } from '../components/TermsCheckbox';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { SetNewPasswordFormKeys } from './interfaces';
import { SetNewPasswordValidationSchema } from './validations';

import { useAppDispatch, useAppSelector } from '@hooks/state';
import { changePasswordRequest, logout } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { getLimitedStoreInfoRequest } from '@state/storeInfo/actions';
import { getLimitedStoreInfoData } from '@state/storeInfo/selectors';

export const SetNewPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, changePasswordErrors, data } = useAppSelector((state) => ({
    data: getLimitedStoreInfoData(state),
    loading: isRequestRunning(state, String(changePasswordRequest)),
    changePasswordErrors: getRequestError(state, String(changePasswordRequest)),
    limitedStoreInfoErrors: getRequestError(
      state,
      String(getLimitedStoreInfoData),
    ),
  }));

  useEffect(() => {
    dispatch(getLimitedStoreInfoRequest());
  }, []);

  useEffect(() => {
    if (!changePasswordErrors) return;
    onHandleBack(); // TO DO: Fix error handling when service fails
  }, [changePasswordErrors]);

  useEffect(() => {
    dispatch(getLimitedStoreInfoRequest());
  }, []);

  const initialValues: SetNewPasswordFormKeys = {
    password: '',
    passwordConfirmation: '',
    acceptTerms: false,
  };

  const {
    control,
    handleSubmit,
    formState,
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm<SetNewPasswordFormKeys>({
    resolver: yupResolver(SetNewPasswordValidationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const password = watch('password');

  useEffect(() => {
    if (!password) return;
    const confirmPassword = getValues().passwordConfirmation;
    if (confirmPassword && confirmPassword === password)
      clearErrors('passwordConfirmation');
    else if (confirmPassword)
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'Password doesn’t match',
      });
  }, [password]);

  const disabledSaveButton = !(
    (!formState.errors.password &&
      !formState.errors.acceptTerms &&
      !formState.errors.passwordConfirmation &&
      formState.dirtyFields.acceptTerms &&
      formState.dirtyFields.password &&
      formState.dirtyFields.passwordConfirmation) ||
    loading
  );

  const handleSubmitForm = (values: SetNewPasswordFormKeys) => {
    dispatch(
      changePasswordRequest({
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        callback: () => navigate('/bluon-search'),
      }),
    );
  };

  const onHandleBack = () => {
    dispatch(logout(() => navigate('/login')));
  };

  return (
    <WelcomeContainer>
      <Box className='bl-set-new-password-container'>
        <Grid container justifyContent='center'>
          <Grid item xs={5} className='bl-set-new-password-title-container'>
            <Typography variant='h4' color='secondary'>
              WELCOME!
            </Typography>
            <AuthFormHeader
              title='Create New Password'
              subTitle='This is a one time deal. Your password must be at least 8 characters long.'
            />
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <Controller
                name='password'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextInput
                    id='password'
                    label='Password'
                    type='password'
                    data-testid='password'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    error={!!fieldState.error}
                    errorMessage={formState.errors.password?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <Controller
                name='passwordConfirmation'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextInput
                    id='passwordConfirmation'
                    label='Confirm Password'
                    type='password'
                    data-testid='passwordConfirmation'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    error={!!fieldState.error}
                    errorMessage={
                      formState.errors.passwordConfirmation?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <AcceptTermsCheckbox control={control} />

          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <TakeRate
                className='bl-take-rate-container'
                take_rate={data?.take_rate}
                take_rate_until={data?.take_rate_until}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent='center'
            className='bl-set-new-password-button-container'
          >
            <Grid item xs={5}>
              <Button
                fullWidth
                data-testid='saveNewPasswordButton'
                variant='contained'
                loading={loading}
                type='submit'
                color='primary'
                disabled={disabledSaveButton}
              >
                Save New Password
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid container justifyContent='center'>
          <Grid item xs={5}>
            <Button
              className='go-back-button'
              variant='text'
              color='primary'
              data-testid='goBackButton'
              startIcon={<ArrowBackIcon />}
              onClick={onHandleBack}
            >
              Go back to log in
            </Button>
          </Grid>
        </Grid>
        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
