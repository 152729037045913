import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import { EnhancedTableHeadProps, HeadCell } from './interface';

import { Supplier } from '@state/manager/interface';

export const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Store',
    width: '45%',
  },
  {
    id: 'address',
    label: 'Address',
    width: '40%',
  },
  {
    id: 'phone',
    label: 'Phone Number',
    width: '24%',
  },
  {
    id: 'branch',
    label: 'Branch ID',
    width: '20%',
  },
  {
    id: 'pending_tasks',
    label: 'Pending Tasks',
    width: '130px',
    alignRight: true,
  },
];

export const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Supplier) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className='!contents'>
      <TableRow className='bg-[var(--bluon--default--white)]'>
        {headCells.map((headCell: HeadCell, index: number) => (
          <TableCell
            key={headCell.id}
            align='left'
            className={`${
              index === headCells.length - 1 ? '!px-1' : '!px-4'
            } !py-4`}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
