import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './styles.scss';
import { isNil } from 'lodash';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { partsSpecificationsString } from '../../data/parts';
import { CircularLoading } from '../CircularLoading';
import { SectionTitle } from '../SectionTitle';
import { SomethingWrong } from '../SomethingWrong';
import { SpecItem } from '../SpecItem';
import { SuggestionModal } from '../SuggestionModal';
import { TabPanel } from '../TabPanel';

import { PartDetailsProps } from './interface';

import ChangeIcon from '@assets/icons/ChangeIcon.svg';
import SuggestionBalloonIcon from '@assets/icons/SuggestionBalloonIcon.svg';
import { PartReplacementsDataModel } from '@config/api/search/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { KeyValueInterface } from '@pages/Tasks/interfaces';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { partReplacementDetailRequest } from '@state/search/actions';
import { getPartReplacementeDetails } from '@state/search/selectors';
import { EReplacementTypes } from '@utils/enums';
import { handleGetString, partImage } from '@utils/functions';

export const PartDetails = ({
  hasError,
  isLoadingDetails,
  isLoadingMorePartReplacements,
  isLoadingNextPagePartsReplacements,
  onClickReloadError,
  onLoadMorePartReplacements,
  partDetails,
  partReplacements,
  partReplacementsHasNextPage,
  showSuggestionBtn,
  showTitle,
}: PartDetailsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [selectedReplacement, setSelectedReplacement] =
    useState<PartReplacementsDataModel>();
  const [showSpecLength, setShowSpecLength] = useState<number>(4);
  const [isSpecOpen, setIsSpecOpen] = useState<boolean>(false);
  const [hasNotes, setHasNotes] = useState<boolean>(false);
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false);
  const scrollDownRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(0);
  const [selectedSpecs, setSelectedSpecs] = useState<KeyValueInterface>();
  const [specsCount, setSpecsCount] = useState<number>(0);
  const [showSuggestionModal, setShowSuggestionModal] =
    useState<boolean>(false);

  const {
    partReplacementDetails,
    isLoadingPartReplacementDetails,
    partReplacementDetailsRequestError,
  } = useAppSelector((state) => ({
    partReplacementDetails: getPartReplacementeDetails(state),
    isLoadingPartReplacementDetails: isRequestRunning(
      state,
      String(partReplacementDetailRequest),
    ),
    partReplacementDetailsRequestError: getRequestError(
      state,
      String(partReplacementDetailRequest),
    ),
  }));

  const handleShowMoreOrLessSpecs = () => {
    setIsSpecOpen(!isSpecOpen);
    if (selectedSpecs)
      setShowSpecLength(
        showSpecLength === 4 ? Object.keys(selectedSpecs).length : 4,
      );
  };

  const openPanel = (item: PartReplacementsDataModel) => {
    setHasNotes(item.note !== null);
    setValue(item.note ? 0 : 1);
    setSelectedReplacement(item);
    setIsNotesOpen(true);
    if (item.type === EReplacementTypes.Single)
      dispatch(partReplacementDetailRequest({ partId: item.details?.id }));
  };

  const closeNotes = () => setIsNotesOpen(false);

  useEffect(() => closeNotes(), [partDetails?.id]);

  useEffect(() => {
    const specs = { ...partDetails?.specifications };
    Object.keys(specs).forEach((key) => {
      if (isNil(specs[key]) || !specs[key]) delete specs[key];
    });

    setSpecsCount(Object.entries(specs).length);
    setSelectedSpecs(specs);
    setIsSpecOpen(false);
    setShowSpecLength(4);
  }, [partDetails]);

  const isClickable = (item: PartReplacementsDataModel) => {
    return item.note || item.type === EReplacementTypes.Single;
  };

  const handleScrollToFetchMore = useCallback(() => {
    if (isLoadingNextPagePartsReplacements) return;

    if (!isLoadingNextPagePartsReplacements && partReplacementsHasNextPage)
      onLoadMorePartReplacements();
  }, [isLoadingNextPagePartsReplacements, partReplacementsHasNextPage]);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const scrollHitsBottomObs = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) handleScrollToFetchMore();
    });
    if (scrollDownRef.current)
      scrollHitsBottomObs.observe(scrollDownRef.current);
  }, [handleScrollToFetchMore]);

  const replacementsSpecifications =
    partReplacementDetails?.specifications &&
    Object.keys(partReplacementDetails?.specifications).map(
      (item) => partReplacementDetails?.specifications[item],
    );
  const hasReplacementsSpecs =
    replacementsSpecifications &&
    replacementsSpecifications.some((item: string[] | null) => item !== null);

  return (
    <Grid
      data-testid='partDetailsContainer'
      item
      xs={7}
      className='bl-part-details-container'
    >
      {!hasError && (
        <>
          {showTitle && <SectionTitle title='Part details' />}
          <div className='bl-part-tab-container-part-detail'>
            {!isLoadingDetails && (
              <>
                <Box className='bl-part-tab-part--detail-header-container'>
                  <Box className='bl-part-tab-part-detail-header'>
                    <Box className='bl-part-tab-part-detail-img'>
                      <img
                        alt='Part'
                        loading='lazy'
                        src={partImage(partDetails)}
                      />
                    </Box>
                    <Box className='bl-part-tab-part-detail-info-header'>
                      <Box className='bl-part-tab-part-detail-info-header-name'>
                        <Typography
                          className='bl-part-tab-part-detail-info-header-name'
                          color='initial'
                          variant='caption'
                        >
                          {handleGetString(
                            partDetails?.type || '',
                            'type_title',
                            partsSpecificationsString,
                          )}
                        </Typography>
                      </Box>
                      <Box className='bl-part-tab-part-detail-info-header-model'>
                        <Typography
                          className='bl-part-tab-part-detail-info-header-model'
                          color='initial'
                          variant='caption'
                        >
                          {partDetails?.brand} | {partDetails?.number}
                        </Typography>
                      </Box>
                      <Box className='bl-part-tab-part-detail-info-header-replacements'>
                        <Box className='bl-part-tab-part-detail-info-header-first-icon'>
                          <img src={ChangeIcon} alt='Change' />
                        </Box>
                        <Typography
                          className='bl-part-tab-part-detail-info-header-replacements-txt'
                          color='initial'
                          variant='caption'
                        >
                          {partDetails?.replacements_count} Known Replacements
                        </Typography>
                        {/* TODO: commenting that tab according with [GNDR-275] */}
                        {/* <Box className='bl-part-tab-part-detail-info-header-last-icon'>
                          <InfoIcon />
                        </Box> */}
                      </Box>
                    </Box>
                  </Box>
                  <Box className='bl-part-tab-part-detail-specifications'>
                    <Box className='bl-part-tab-part-detail-specifications-content'>
                      {selectedSpecs &&
                        Object.keys(selectedSpecs)
                          .slice(0, showSpecLength)
                          .map((item: string) => {
                            const humanizedString = handleGetString(
                              partDetails,
                              item,
                              partsSpecificationsString,
                            );
                            const specTitle =
                              humanizedString === ''
                                ? item.replace(/_/g, ' ')
                                : humanizedString;

                            return selectedSpecs[item] ? (
                              <SpecItem
                                key={`specification${item}`}
                                label={specTitle}
                                value={selectedSpecs[item] || ''}
                              />
                            ) : null;
                          })}
                      {specsCount > 4 && (
                        <Box className='bl-part-tab-part-detail-specifications-show-hide'>
                          <Box onClick={handleShowMoreOrLessSpecs}>
                            <Typography
                              className='bl-part-tab-part-detail-specifications-show-hide-txt'
                              color='initial'
                              variant='caption'
                            >
                              {isSpecOpen
                                ? '- Show less specs'
                                : '+ Show all specs'}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <SuggestionModal
                  onLeave={() => setShowSuggestionModal(false)}
                  partId={partDetails?.id || ''}
                  visible={showSuggestionModal}
                />
                {showSuggestionBtn && !isLoadingDetails && (
                  <Box className='bl-part-tab-part-detail-container-suggestion-btn'>
                    <button
                      className='suggestion-btn'
                      onClick={() => setShowSuggestionModal(true)}
                    >
                      <img
                        className='suggestion-btn-icon'
                        src={SuggestionBalloonIcon}
                      />
                      <Typography
                        className='suggestion-btn-txt'
                        color='initial'
                        variant='caption'
                      >
                        Inform Bluon of another Replacement
                      </Typography>
                    </button>
                  </Box>
                )}
                {partReplacements.length !== 0 && (
                  <Box className='bl-part-tab-part-detail-replacements'>
                    <Box className='bl-part-tab-part-detail-container-title-and-suggestion'>
                      <Typography
                        className='bl-part-tab-part-detail-replacements-txt'
                        color='initial'
                        variant='caption'
                      >
                        Known Replacements
                      </Typography>
                    </Box>
                    <Box className='bl-part-tab-part-detail-replacements-container'>
                      <Box
                        className={`bl-part-tab-part-detail-replacements-item-container 
                        ${isNotesOpen ? 'open-panel ' : ''}
                        ${showSuggestionBtn ? 'show-suggestion-btn' : ''}
                        `}
                      >
                        {partReplacements.map((item) => {
                          const brand =
                            item.type === EReplacementTypes.Single &&
                            item.details?.brand
                              ? `${item.details?.brand} | `
                              : '';
                          const number = item.details?.number;

                          return (
                            <Box
                              key={`replacement-${item.id}`}
                              className={`bl-part-tab-part-detail-replacements-items ${
                                isClickable(item) ? 'clickeable' : ''
                              } ${
                                item.id === selectedReplacement?.id
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={
                                isClickable(item)
                                  ? () => openPanel(item)
                                  : undefined
                              }
                            >
                              <Box className='bl-part-tab-part-detail-replacement-item-info'>
                                <Box className='bl-part-tab-part-detail-replacement-item-img'>
                                  <img
                                    src={partImage(item.details)}
                                    alt='part'
                                  />
                                </Box>
                                <Box className='bl-part-tab-part-detail-replacement-item-info'>
                                  <Typography
                                    className='bl-part-tab-part-detail-replacement-item-text'
                                    color='initial'
                                    variant='caption'
                                  >
                                    {item.type === EReplacementTypes.Single
                                      ? `${brand}${number}`
                                      : 'Known Replacements Group'}
                                  </Typography>
                                </Box>
                              </Box>
                              {isClickable(item) && (
                                <Box>
                                  <ArrowForwardIosIcon />
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                        {partReplacementsHasNextPage && (
                          <Box
                            className='bl-part-detail-loading-more-container'
                            ref={scrollDownRef}
                          >
                            {isLoadingMorePartReplacements && (
                              <CircularLoading />
                            )}
                          </Box>
                        )}
                      </Box>
                      {isNotesOpen && (
                        <Box
                          className={
                            'bl-part-tab-part-detail-replacements-notes-container'
                          }
                        >
                          <Box className='bl-part-tab-part-detail-replacements-notes-container-tabs'>
                            <Box className='bl-part-tab-part-detail-replacements-notes-icon'>
                              <ClearIcon
                                onClick={closeNotes}
                                color='primary'
                                className='close-notes-icon'
                              />
                            </Box>
                            <Tabs onChange={handleChangeTab} value={value}>
                              <Tab label='Notes' disabled={!hasNotes} />
                              <Tab label='Replacement Specs' />
                            </Tabs>
                          </Box>
                          <Box
                            className={`bl-tab-content-container ${
                              showSuggestionBtn ? 'show-suggestion-btn' : ''
                            }`}
                          >
                            <TabPanel value={value} index={0}>
                              <Box className='bl-replacements-notes'>
                                {selectedReplacement?.note}
                              </Box>
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                              {isLoadingPartReplacementDetails && (
                                <Box className='replacements-loading-container'>
                                  <CircularLoading />
                                </Box>
                              )}
                              {partReplacementDetailsRequestError &&
                                !isLoadingPartReplacementDetails &&
                                selectedReplacement?.type ===
                                  EReplacementTypes.Single && (
                                  <SomethingWrong
                                    height='100%'
                                    data-testid='somethingWrongPartsContainer'
                                    onReload={() =>
                                      dispatch(
                                        partReplacementDetailRequest({
                                          partId:
                                            selectedReplacement?.details?.id,
                                        }),
                                      )
                                    }
                                  />
                                )}
                              {!hasReplacementsSpecs &&
                                !partReplacementDetailsRequestError &&
                                !isLoadingPartReplacementDetails && (
                                  <Box className='bl-no-specs-availables'>
                                    <InfoIcon />
                                    <Typography variant='body2' color='initial'>
                                      No specs available
                                    </Typography>
                                  </Box>
                                )}
                              {!isLoadingPartReplacementDetails &&
                                !partReplacementDetailsRequestError &&
                                selectedReplacement?.type ===
                                  EReplacementTypes.Single && (
                                  <Box className='bl-part-tab-part-detail-specifications-content'>
                                    {partReplacementDetails?.specifications &&
                                      Object.keys(
                                        partReplacementDetails?.specifications,
                                      ).map((item: string) => {
                                        const humanizedString = handleGetString(
                                          partReplacementDetails,
                                          item,
                                          partsSpecificationsString,
                                        );
                                        const specTitle =
                                          humanizedString === ''
                                            ? item.replace(/_/g, ' ')
                                            : humanizedString;

                                        return partReplacementDetails
                                          ?.specifications[item] ? (
                                          <SpecItem
                                            key={`specification${item}`}
                                            label={specTitle}
                                            value={
                                              partReplacementDetails
                                                ?.specifications[item] || ''
                                            }
                                          />
                                        ) : null;
                                      })}
                                  </Box>
                                )}
                            </TabPanel>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}

            {isLoadingDetails && (
              <Box
                data-testid='partDetailsLoading'
                className='bl-part-detail-loading-container'
              >
                <CircularLoading />
              </Box>
            )}
          </div>
        </>
      )}
      {hasError && !isLoadingDetails && (
        <SomethingWrong
          height='calc(100vh - 165px)'
          onReload={onClickReloadError}
        />
      )}
    </Grid>
  );
};
