import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';

import { AcceptModalProps } from './interface';

export const AcceptModal = ({
  acceptCallback,
  denyCallback,
  open,
  content,
}: AcceptModalProps) => {
  return (
    <Dialog open={open} maxWidth='xs'>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant='text' onClick={denyCallback} color='neutral'>
          NO
        </Button>
        <Button variant='text' onClick={acceptCallback}>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};
