import { Button as ButtonMui, CircularProgress } from '@mui/material';
import classNames from 'classnames';

import { ButtonProps } from './interface';

import './styles.scss';

const Button = ({
  children,
  loading,
  'data-testid': dataTestId,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <ButtonMui {...props} data-testid={dataTestId}>
      {loading ? (
        <CircularProgress
          size='small'
          className={classNames('bl-button-loader disabled', {
            '!text-gray-400':
              props.variant === 'outlined' || props.color === 'successLight',
          })}
          data-testid={dataTestId}
        />
      ) : (
        children
      )}
    </ButtonMui>
  );
};

export default Button;
