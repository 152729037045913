import {
  clearOrdersLogsState,
  moreOrdersLogsResponse,
  ordersLogsResponse,
} from './actions';
import { OrdersLogsRequestAction, OrdersLogsState } from './interface';

import { RequestResponse } from '@config/api/interface';
import { Order } from '@config/api/task/interface';
import { getHasNextPageFromMeta, getNextPageFromMeta } from '@utils/apiPaging';

export interface OrdersLogsResponse extends RequestResponse {
  data: Order[];
}

const initialState: OrdersLogsState = {
  logs: [],
  hasNextPage: false,
  nextPage: null,
};

const ordersLogsReducer = (
  state = initialState,
  action: OrdersLogsRequestAction,
): OrdersLogsState => {
  switch (action.type) {
    case String(ordersLogsResponse): {
      const { data, meta } = action?.payload as unknown as OrdersLogsResponse;

      return {
        ...state,
        logs: [...data],
        hasNextPage: getHasNextPageFromMeta(meta),
        nextPage: getNextPageFromMeta(meta),
      };
    }
    case String(moreOrdersLogsResponse): {
      const { data, meta } = action?.payload as unknown as OrdersLogsResponse;

      return {
        ...state,
        logs: [...(state?.logs || []), ...data],
        hasNextPage: getHasNextPageFromMeta(meta),
        nextPage: getNextPageFromMeta(meta),
      };
    }
    case String(clearOrdersLogsState): {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default ordersLogsReducer;
