import { createAction } from 'redux-actions';

export const storeInfoGetRequest = createAction('store/store-info-request');

export const storeInfoResponse = createAction('store/store-info-response');

export const brandsRequest = createAction('store/brands-request');

export const moreBrandsRequest = createAction('store/more-brands-request');

export const moreBrandsResponse = createAction('store/more-brands-response');

export const brandsResponse = createAction('store/brands-response');

export const storeInfoGetResponse = createAction('store/store-info-response');

export const storeInfoPatchRequest = createAction(
  'store/store-info-patch-request',
);

export const storeInfoPostBrands = createAction('store/store-info-post-brands');

export const storeInfoBrandsResponse = createAction(
  'store/store-info-brands-response',
);

export const getCountriesRequest = createAction('store/countries-request');

export const getCountriesResponse = createAction('store/countries-response');

export const getStatesRequest = createAction('store/states-request');

export const getStatesResponse = createAction('store/states-response');

export const postBranchHours = createAction('store/post-branch-hours');

export const reasonsRequest = createAction('store/reasons-request');
export const cancelReasonsResponse = createAction(
  'store/cancel-reasons-response',
);
export const declineReasonsResponse = createAction(
  'store/decline-reasons-response',
);
export const returnReasonsResponse = createAction(
  'store/return-reasons-response',
);

export const postBranchHoursResponse = createAction(
  'store/post-branch-hours-response',
);

export const setWelcomeDisplayedAt = createAction(
  'store/set-welcome-displayed-at',
);

export const getLimitedStoreInfoRequest = createAction(
  'store/limited-store-info-request',
);

export const getLimitedStoreInfoResponse = createAction(
  'store/limited-store-info-response',
);

export const resetStore = createAction('store/reset');

export const cleanStoreInfo = createAction('store/clean-store-info');
