import { isEqual, unionWith } from 'lodash';

import {
  fillChatUnreadMessages,
  getChannelUsersResponse,
  getMoreChannelUsersResponse,
  getUserOrdersResponse,
  setChatMetaData,
  setChatOpenedResolve,
  setCurrentChatSelected,
  updateChannelListsLocally,
} from './actions';
import { ChatActionProps, ChatState } from './interface';

import { MetaDataChannelInterface } from '@components/NewChat/interface';
import { User } from '@config/api/chat/interface';
import { getHasNextPageFromMeta, getNextPageFromMeta } from '@utils/apiPaging';

const initialState: ChatState = {
  channelList: {
    list: [],
    hasNextPage: false,
    nextPage: null,
  },
  unreadChatMessages: {},
  currentChatSelected: undefined,
  userOrderList: undefined,
  chatOpened: false,
};

const chatReducer = (
  state = initialState,
  action: ChatActionProps,
): ChatState => {
  switch (action.type) {
    case String(updateChannelListsLocally): {
      const { data } = action.payload;

      return {
        ...state,
        channelList: {
          ...state.channelList,
          list: [...data],
        },
      };
    }
    case String(setChatOpenedResolve): {
      const { opened, channel, data } = action.payload;

      if (data) {
        const newChannelList = state.channelList.list;

        newChannelList.unshift({
          ...data,
          id: data.channel ?? '',
          userId: data.id,
        });

        return {
          ...state,
          chatOpened: true,
          currentChatSelected: data,
          channelList: {
            ...state.channelList,
            list: newChannelList,
          },
        };
      }

      if (channel) {
        const channelList = state.channelList.list;

        const updatedList = [
          channel,
          ...channelList.filter((item) => item.userId !== channel.userId),
        ];

        return {
          ...state,
          chatOpened: opened,
          currentChatSelected: channel,
          channelList: {
            ...state.channelList,
            list: updatedList,
          },
        };
      }

      const firstChannel = state.channelList.list
        ? state.channelList.list[0]
        : undefined;

      return {
        ...state,
        chatOpened: opened,
        currentChatSelected: firstChannel,
      };
    }
    case String(getChannelUsersResponse): {
      const { data, meta } = action.payload;
      const mappedArray = MapUserList(data);

      return {
        ...state,
        channelList: {
          list: [...mappedArray],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }
    case String(getMoreChannelUsersResponse): {
      const { data, meta } = action.payload;
      const mappedArray = MapUserList(data);

      const channelList = state.channelList?.list || [];

      const uniqueArray = unionWith(channelList, mappedArray, isEqual);

      return {
        ...state,
        channelList: {
          list: uniqueArray,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }
    case String(getUserOrdersResponse): {
      return {
        ...state,
        userOrderList: action.payload.data,
      };
    }
    case String(setChatMetaData): {
      const data = action.payload;
      const newList = state.channelList?.list.map((item) => {
        const d = data.find(
          (response: MetaDataChannelInterface) =>
            response.id === item.chat.channel,
        );
        if (d)
          return {
            ...item,
            chat: {
              ...item.chat,
              lastReadTimetoken: d.lastReadTimetoken,
              techLastReadTimetoken: d.techLastReadTimetoken,
            },
          };

        return item;
      });

      return {
        ...state,
        channelList: {
          list: newList,
          hasNextPage: state.channelList?.hasNextPage,
          nextPage: state.channelList?.nextPage,
        },
      };
    }
    case String(fillChatUnreadMessages): {
      return {
        ...state,
        unreadChatMessages: action.payload,
      };
    }
    case String(setCurrentChatSelected): {
      const unreadMessages = { ...state.unreadChatMessages };
      unreadMessages[action.payload?.chat?.channel ?? ''] = 0;

      return {
        ...state,
        currentChatSelected: action.payload,
        unreadChatMessages: unreadMessages,
      };
    }
    default:
      return state;
  }
};

export default chatReducer;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function MapUserList(array: any) {
  return array.map(({ id, channel, ...rest }: User) => ({
    id: channel ?? '',
    userId: id,
    ...rest,
  }));
}
