import { isEmpty } from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import {
  addCustomItemOrderRequest,
  addCustomItemOrderResponse,
  addNewCounterStaffRequest,
  addNewCounterStaffResponse,
  cancelOrderRequest,
  declineOrderRequest,
  deleteCustomItemOrderRequest,
  deleteInvoiceRequest,
  deleteInvoiceResponse,
  dontSellToRequest,
  getAddedItemsRequest,
  getAddedItemsResponse,
  getApprovedOrders,
  getApprovedOrdersResponse,
  getAvailabilityOrders,
  getAvailabilityOrdersResponse,
  getCustomItemsOrderRequest,
  getCustomItemsOrderResponse,
  getItemCustomReplacementRequest,
  getItemCustomReplacementResponse,
  getItemInfoRequest,
  getItemInfoResponse,
  getItemReplacementsReponse,
  getItemReplacementsRequest,
  getMoreApprovedOrders,
  getMoreApprovedOrdersResponse,
  getMoreAvailabilityOrders,
  getMoreAvailabilityOrdersResponse,
  getMoreItemReplacementsReponse,
  getMoreItemReplacementsRequest,
  getMoreOrderItems,
  getMoreOrderItemsResponse,
  getOemInfoRequest,
  getOemInfoResponse,
  getOrderItems,
  getOrderItemsResponse,
  getOrderItemsSupply,
  getOrderItemsSupplyResponse,
  getSupplierStaffRequest,
  getSupplierStaffResponse,
  patchAddedItemsRequest,
  patchAddedItemsResponse,
  patchCurrentItem,
  patchCurrentItemResponse,
  patchOrderRequest,
  patchOrderResponse,
  patchSupplyItemsRequest,
  patchSupplyItemsResponse,
  postCurriInformativePopUpRequest,
  postCurriInformativePopUpResponse,
  realTimeRefreshApprovedOrders,
  realTimeRefreshAvailabilityOrders,
  saveNewCustomerRequest,
  sendForApprovalRequest,
  sendForApprovalResponse,
  setCounterStaffRequest,
  setCounterStaffResponse,
  setInvoiceRequest,
  setInvoiceResponse,
  setItemCustomReplacementRequest,
  setItemCustomReplacementResponse,
  setOrderCompleteRequest,
  setOrderCompleteResponse,
  setOrderDetailsRequest,
  setOrderDetailsResponse,
  setSelectedOrder,
} from './actions';
import {
  DeclineOrderActionProps,
  SetSelectedOrderActionProps,
  TaskActionProps,
  TaskState,
} from './interface';
import { getItemReplacements as getItemReplacementsSelector } from './selectors';

import {
  addCustomItem,
  addNewCounterStaff,
  cancelOrder,
  declineOrder,
  deleteCustomItem,
  deleteInvoice,
  dontSellToCustomer,
  getAddedItems,
  getCustomItems,
  getItemCustomReplacement,
  getItemInfo,
  getItemReplacements,
  getOrderDetails,
  getOrderItemsData,
  getOrderItemsSupplyData,
  getOrders,
  getSupplierStaff,
  patchItemInfo,
  postCurriInformativePopUp,
  saveAddedItems,
  saveNewCustomer,
  saveSupplyItems,
  sendForApproval,
  setCompleteOrder,
  setCounterStaff,
  setInvoice,
  setItemCustomReplacement,
  updateCounterStaff,
  updateOrder,
} from '@config/api/task';
import { ORDER_TYPE } from '@config/api/task/interface';
import { ReplacementItem } from '@pages/Tasks/interfaces';
import makeRequest from '@state/requests/make-request';
import { getRequestError } from '@state/requests/selectors';

export function* workerGetOrderItems(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getOrderItemsData,
    requestAction: action,
    receiveAction: getOrderItemsResponse,
    params: action.payload,
  });
}

export function* workerPatchItemsSupply(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  const { successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: saveSupplyItems,
    requestAction: action,
    receiveAction: patchSupplyItemsResponse,
    params: action.payload,
  });

  const error = yield select((state) =>
    getRequestError(state, patchSupplyItemsRequest),
  );

  if (isEmpty(error)) yield call(successCallback);
}

export function* workerPatchAddedItems(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  const { successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: saveAddedItems,
    requestAction: action,
    receiveAction: patchAddedItemsResponse,
    params: action.payload,
  });

  const error = yield select((state) =>
    getRequestError(state, patchAddedItemsRequest),
  );

  if (isEmpty(error)) yield call(successCallback);
}

export function* workerGetAddedItems(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getAddedItems,
    requestAction: action,
    receiveAction: getAddedItemsResponse,
    params: action.payload,
  });
}

export function* workerGetOrderItemsSupply(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getOrderItemsSupplyData,
    requestAction: action,
    receiveAction: getOrderItemsSupplyResponse,
    params: action.payload,
  });
}

export function* workerMoreOrderItems(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  const state = yield select((state) => state);
  const taskReducer = (state as any).taskReducer as TaskState;
  yield call(makeRequest, {
    endpoint: getOrderItemsData,
    params: {
      order: taskReducer.currentSelectedOrder,
      page: taskReducer.orderItems.nextPage,
    },
    requestAction: action,
    receiveAction: getMoreOrderItemsResponse,
  });
}

export function* workerGetMoreOrdersByType(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  const state = yield select((state) => state);
  const taskReducer = (state as any).taskReducer as TaskState;
  const { type } = action.payload;
  yield call(makeRequest, {
    endpoint: getOrders,
    params: {
      type,
      page:
        type === ORDER_TYPE.APPROVED
          ? taskReducer.approvedOrders.nextPage
          : taskReducer.availabilityOrders.nextPage,
    },
    requestAction: action,
    receiveAction:
      type === ORDER_TYPE.APPROVED
        ? getMoreApprovedOrdersResponse
        : getMoreAvailabilityOrdersResponse,
  });
}

export function* workerGetOrdersByType(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { type, successCallback, errorCallback = () => {} } = action.payload;

  yield call(makeRequest, {
    endpoint: getOrders,
    requestAction: action,
    receiveAction:
      type === ORDER_TYPE.APPROVED
        ? getApprovedOrdersResponse
        : getAvailabilityOrdersResponse,
    params: action.payload,
  });

  const error = yield select(
    (state) =>
      getRequestError(state, realTimeRefreshApprovedOrders) ||
      getRequestError(state, realTimeRefreshAvailabilityOrders),
  );

  if (isEmpty(error) && successCallback) yield call(successCallback);
  else yield call(errorCallback);
}

export function* workerAddCustomItemOrder(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  const { name, quantity, order, errorCallback, successCallback } =
    action.payload;

  yield call(makeRequest, {
    endpoint: addCustomItem,
    params: { name, quantity, order },
    requestAction: action,
    receiveAction: addCustomItemOrderResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, addCustomItemOrderRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerGetItemInfo(action: TaskActionProps): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getItemInfo,
    params: action.payload,
    requestAction: action,
    receiveAction: getItemInfoResponse,
  });
}

export function* workerDeleteCustomItemOrder(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  const { customItem, order, errorCallback, successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: deleteCustomItem,
    params: { customItem, order },
    requestAction: action,
  });

  const error = yield select((state) =>
    getRequestError(state, deleteCustomItemOrderRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerCustomItems(action: TaskActionProps): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getCustomItems,
    params: action.payload,
    requestAction: action,
    receiveAction: getCustomItemsOrderResponse,
  });
}

export function* workerUpdateOrder(action: TaskActionProps): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: updateOrder,
    params: action.payload,
    requestAction: action,
    receiveAction: patchOrderResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, patchOrderRequest),
  );

  if (isEmpty(error)) yield call(action.payload.successCallback);
  else yield call(action.payload.errorCallback);
}

export function* workerGetItemReplacements(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getItemReplacements,
    params: { ...action.payload, page: 1 },
    requestAction: action,
    receiveAction: getItemReplacementsReponse,
  });

  const error = yield select((state) =>
    getRequestError(state, getItemReplacementsRequest),
  );
  if (!isEmpty(error)) yield call(action.payload.errorCallback);
  else yield call(action.payload.successCallback);
}

export function* workerGetMoreItemReplacements(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const state = yield select((state) => state);
  const taskReducer = (state as any).taskReducer as TaskState;
  const item = taskReducer.orderItems.data.find(
    (item) => item.id === action.payload.item,
  );

  yield call(makeRequest, {
    endpoint: getItemReplacements,
    params: { ...action.payload, page: item?.knownReplacements.nextPage },
    requestAction: action,
    receiveAction: getMoreItemReplacementsReponse,
  });
}

export function* workerSetInvoice(action: TaskActionProps): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: setInvoice,
    params: action.payload,
    requestAction: action,
    receiveAction: setInvoiceResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, setInvoiceRequest),
  );

  if (isEmpty(error)) yield call(action.payload.successCallback);
  else yield call(action.payload.errorCallback);
}

export function* workerDeleteInvoice(action: TaskActionProps): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: deleteInvoice,
    params: action.payload.order,
    requestAction: action,
    receiveAction: deleteInvoiceResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, setInvoiceRequest),
  );

  if (!isEmpty(error)) yield call(action.payload.errorCallback);
}

export function* workerPatchCurrentItem(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: patchItemInfo,
    params: action.payload,
    requestAction: action,
    receiveAction: patchCurrentItemResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, patchCurrentItem),
  );

  if (isEmpty(error)) yield call(action.payload.successCallback);
  if (!isEmpty(error)) yield call(action.payload.errorCallback);
}

export function* workerGetSupplierStaff(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getSupplierStaff,
    params: action.payload,
    requestAction: action,
    receiveAction: getSupplierStaffResponse,
  });
}

export function* workerSetCounterStaff(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { errorCallback, successCallback } = action.payload;
  yield call(makeRequest, {
    endpoint: setCounterStaff,
    params: action.payload,
    requestAction: action,
    receiveAction: setCounterStaffResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, setCounterStaffRequest),
  );

  if (!isEmpty(error)) yield call(errorCallback);
  else yield call(successCallback);
}

export function* workerPostCurriInformativePopUp(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { errorCallback, order } = action.payload;

  yield call(makeRequest, {
    endpoint: postCurriInformativePopUp,
    params: order,
    requestAction: action,
    receiveAction: postCurriInformativePopUpResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, postCurriInformativePopUpRequest),
  );

  if (!isEmpty(error)) yield call(errorCallback);
}

export function* workerSendForApproval(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: sendForApproval,
    params: action.payload,
    requestAction: action,
    receiveAction: sendForApprovalResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, sendForApprovalRequest),
  );

  if (!isEmpty(error)) yield call(action.payload.errorCallback);
  else yield call(action.payload.successCallback);
}

export function* workerSendSetCompleteOrder(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: setCompleteOrder,
    params: action.payload,
    requestAction: action,
    receiveAction: setOrderCompleteResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, setOrderCompleteRequest),
  );

  if (!isEmpty(error)) yield call(action.payload.errorCallback);
  else if (action?.payload?.successCallback)
    yield call(action.payload.successCallback);
}

export function* workerSaveNewCustomer(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  const {
    userId,
    orderId,
    customTag,
    cashBuyer,
    successCallback,
    errorCallback,
  } = action.payload;

  yield call(makeRequest, {
    endpoint: saveNewCustomer,
    params: {
      userId,
      orderId,
      customTag,
      cashBuyer,
    },
    requestAction: action,
  });

  const error = yield select((state) =>
    getRequestError(state, saveNewCustomerRequest),
  );

  if (!isEmpty(error)) yield call(errorCallback);
  else yield call(successCallback);
}

export function* workerDontSellTo(action: TaskActionProps): Generator<any> {
  const call: any = Eff.call;

  const { orderId, errorCallback, successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: dontSellToCustomer,
    params: { orderId },
    requestAction: action,
  });

  const error = yield select((state) =>
    getRequestError(state, dontSellToRequest),
  );

  if (!isEmpty(error)) yield call(errorCallback);
  else yield call(successCallback);
}

export function* workerSetOrderDetail(
  action: SetSelectedOrderActionProps,
): Generator<any> {
  const { order, id } = action.payload;
  const call: any = Eff.call;

  //  Putting initial order data (avoid extra loading time)
  if (order) yield put(setSelectedOrder(order));

  //  Getting order details and merging them with selected order
  yield call(makeRequest, {
    endpoint: getOrderDetails,
    params: { order_id: id },
    requestAction: action,
    receiveAction: setOrderDetailsResponse,
    receiveActionParams: [order],
  });

  const error = yield select((state) =>
    getRequestError(state, setOrderDetailsRequest),
  );

  if (action.payload.successCallback)
    yield call(action.payload.successCallback);

  if (error && action?.payload?.errorCallback)
    yield call(action?.payload?.errorCallback);
}

export function* workerDeclineOrder(
  action: DeclineOrderActionProps,
): Generator<any> {
  const {
    order_id,
    staff_id,
    status_detail,
    reason_id,
    errorCallback,
    successCallback,
  } = action?.payload || {};
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: declineOrder,
    params: { order_id, staff_id, status_detail, reason_id },
    requestAction: action,
  });

  const error = yield select((state) =>
    getRequestError(state, declineOrderRequest),
  );

  if (error && errorCallback) return errorCallback();

  return !!successCallback && action?.payload?.successCallback();
}

export function* workerCancelOrder(
  action: DeclineOrderActionProps,
): Generator<any> {
  const {
    order_id,
    staff_id,
    status_detail,
    reason_id,
    errorCallback,
    successCallback,
  } = action?.payload || {};
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: cancelOrder,
    params: { order_id, staff_id, status_detail, reason_id },
    requestAction: action,
  });

  const error = yield select((state) =>
    getRequestError(state, cancelOrderRequest),
  );

  if (error && errorCallback) return errorCallback();

  return !!successCallback && action?.payload?.successCallback();
}

export function* workerGetOemInfo(action: TaskActionProps): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getItemInfo,
    params: action.payload,
    requestAction: action,
    receiveAction: getOemInfoResponse,
  });
}

export function* workerSetItemCustomReplacement(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: setItemCustomReplacement,
    params: action.payload,
    requestAction: action,
    receiveAction: setItemCustomReplacementResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, setItemCustomReplacementRequest),
  );

  const itemsKnownReplacement = yield select((state) =>
    getItemReplacementsSelector(state, action.payload.itemId),
  );

  if (isEmpty(error)) {
    const item = (itemsKnownReplacement as ReplacementItem[]).find(
      (item: any) => item.type === 'generic',
    );

    return action?.payload?.successCallback(item);
  } else {
    yield call(action.payload.errorCallback);
  }
}

export function* workerGetItemCustomReplacement(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getItemCustomReplacement,
    params: action.payload,
    requestAction: action,
    receiveAction: getItemCustomReplacementResponse,
  });
}

export function* workerSetAddNewCounterStaff(
  action: TaskActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { errorCallback, successCallback, id } = action.payload;
  yield call(makeRequest, {
    endpoint: id ? updateCounterStaff : addNewCounterStaff,
    params: action.payload,
    requestAction: action,
    receiveAction: addNewCounterStaffResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, addNewCounterStaffRequest),
  );

  if (!isEmpty(error)) errorCallback(error);
  else yield call(successCallback);
}

export default function* taskSagas(): Generator<any> {
  yield all([
    takeLatest(getOrderItems, workerGetOrderItems),
    takeLatest(patchSupplyItemsRequest, workerPatchItemsSupply),
    takeLatest(patchAddedItemsRequest, workerPatchAddedItems),
    takeLatest(getAddedItemsRequest, workerGetAddedItems),
    takeLatest(getOrderItemsSupply, workerGetOrderItemsSupply),
    takeLatest(patchOrderRequest, workerUpdateOrder),
    takeLatest(setInvoiceRequest, workerSetInvoice),
    takeLatest(deleteInvoiceRequest, workerDeleteInvoice),
    takeLatest(getMoreOrderItems, workerMoreOrderItems),
    takeLatest(getApprovedOrders, workerGetOrdersByType),
    takeLatest(getAvailabilityOrders, workerGetOrdersByType),
    takeLatest(getMoreApprovedOrders, workerGetMoreOrdersByType),
    takeLatest(getMoreAvailabilityOrders, workerGetMoreOrdersByType),
    takeLatest(addCustomItemOrderRequest, workerAddCustomItemOrder),
    takeLatest(deleteCustomItemOrderRequest, workerDeleteCustomItemOrder),
    takeLatest(getCustomItemsOrderRequest, workerCustomItems),
    takeLatest(getItemInfoRequest, workerGetItemInfo),
    takeLatest(getItemReplacementsRequest, workerGetItemReplacements),
    takeLatest(getMoreItemReplacementsRequest, workerGetMoreItemReplacements),
    takeLatest(patchCurrentItem, workerPatchCurrentItem),
    takeLatest(getSupplierStaffRequest, workerGetSupplierStaff),
    takeLatest(setCounterStaffRequest, workerSetCounterStaff),
    takeLatest(sendForApprovalRequest, workerSendForApproval),
    takeLatest(setOrderCompleteRequest, workerSendSetCompleteOrder),
    takeLatest(setOrderDetailsRequest, workerSetOrderDetail),
    takeLatest(saveNewCustomerRequest, workerSaveNewCustomer),
    takeLatest(dontSellToRequest, workerDontSellTo),
    takeLatest(declineOrderRequest, workerDeclineOrder),
    takeLatest(cancelOrderRequest, workerCancelOrder),
    takeLatest(realTimeRefreshApprovedOrders, workerGetOrdersByType),
    takeLatest(realTimeRefreshAvailabilityOrders, workerGetOrdersByType),
    takeLatest(getOemInfoRequest, workerGetOemInfo),
    takeLatest(
      postCurriInformativePopUpRequest,
      workerPostCurriInformativePopUp,
    ),
    takeLatest(setItemCustomReplacementRequest, workerSetItemCustomReplacement),
    takeLatest(getItemCustomReplacementRequest, workerGetItemCustomReplacement),
    takeLatest(addNewCounterStaffRequest, workerSetAddNewCounterStaff),
  ]);
}
