import { off, onValue, ref } from 'firebase/database';
import { useEffect } from 'react';

import { database, distributorNodeUpdate } from '@config/firebase';

const useRealTimeDistributorUpdate = (
  distributorId: string | undefined,
  onValueReceived: (criteria: string) => void,
  searchCriteria: string,
) => {
  useEffect(() => {
    if (distributorId && distributorNodeUpdate) {
      const distributorUpdateRef = ref(
        database,
        distributorNodeUpdate + distributorId + '',
      );
      onValue(distributorUpdateRef, (snapshot) => {
        if (snapshot.val()) onValueReceived(searchCriteria);
      });

      return () => {
        off(distributorUpdateRef);
      };
    }
  }, [distributorId, searchCriteria]);
};

export default useRealTimeDistributorUpdate;
