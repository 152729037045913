import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { SomethingWrongProps } from './interface';

export const SomethingWrong = ({
  'data-testid': dataTestId,
  height,
  onReload,
}: SomethingWrongProps): JSX.Element => (
  <Container
    data-testid={dataTestId ? dataTestId : 'somethingWrongContainer'}
    maxWidth='xl'
    className='bl-something-wrong-container'
    style={{ height: height }}
  >
    <Box className='bl-something-wrong-info'>
      <ErrorOutlineRoundedIcon className='bl-something-wrong-icon-error' />
      <Typography
        className='something-wrong-title'
        variant='subtitle1'
        color='initial'
        textAlign='center'
      >
        Oops! something went wrong.
      </Typography>
      <Typography
        className='something-wrong-subtitle'
        variant='subtitle2'
        color='initial'
        textAlign='center'
      >
        Please try reloading.
      </Typography>
      <Button
        data-testid='reloadButton'
        onClick={onReload}
        variant='contained'
        startIcon={<RefreshIcon className='bl-something-wrong-icon-reload' />}
      >
        Reload
      </Button>
    </Box>
  </Container>
);
