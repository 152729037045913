import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import { BrandsList } from '../../../../../../components/BrandsList';
import Button from '../../../../../../components/Button';
import { useChipList } from '../../../../../../components/ChipList/hooks/useChipList';
import {
  ChipData,
  ChipKey,
} from '../../../../../../components/ChipList/interface';

import { BrandModalTypes } from './interface';

import InputSearchIcon from '@assets/images/IconSearch.png';
import { BrandsDataModel } from '@config/api/storeInfo/interface';
import { useAppDispatch } from '@hooks/state';
import { storeInfoPostBrands } from '@state/storeInfo/actions';

import './styles.scss';

export const BrandModal = (props: BrandModalTypes): JSX.Element => {
  const {
    brandsChecked,
    isOpen,
    onClose,
    brands,
    setBrandsChecked,
    canAddNewItem,
    canDelete,
    initialChips,
  } = props;

  const [filteredBrands, setFilteredBrands] =
    useState<BrandsDataModel[]>(brands);
  const [search, setSearch] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!brands || !brands.length) return;
    setFilteredBrands(brands);
  }, [brands]);

  const onHandleClose = () => {
    const initial = initialChips.map((chip: ChipData) => chip.id);
    setBrandsChecked(initial);
    resetInitialState(initialChips);
    setSearch('');
    onClose();
  };

  const onHandleSave = () => {
    if (!brandsChecked.length) return setErrorMessage(true);
    dispatch(storeInfoPostBrands({ brands: brandsChecked }));
    onClose();
  };

  const onChipDeleteClicked = (chipKey: ChipKey) => {
    if (!canDelete) return;
    const newChecked = brandsChecked.filter((c) => c !== chipKey);
    setBrandsChecked(newChecked);
  };

  useEffect(() => {
    setErrorMessage(false);
  }, [brandsChecked]);

  const { ChipListElement, addNewItem, deleteItem, resetInitialState } =
    useChipList({
      initialData: initialChips,
      onChipDeleteClicked,
      canDelete,
    });

  const handleToggle = (brandId: string) => () => {
    const currentIndex = brandsChecked.indexOf(brandId);
    const newChecked = [...brandsChecked];
    const isAddingNewItem = currentIndex === -1;

    if (canAddNewItem && isAddingNewItem) {
      newChecked.push(brandId);
      addNewItem({
        id: brandId,
        label: brands.find((b) => b.id === brandId)?.name ?? '',
      });
    }

    if (canDelete && !isAddingNewItem) {
      newChecked.splice(currentIndex, 1);
      deleteItem(brandId);
    }

    setBrandsChecked(newChecked);
  };

  const handleFilterSearchChange = (value: string) => {
    setSearch(value);
    setFilteredBrands(
      _.filter(brands, (item) => item.name.toLowerCase().indexOf(value) > -1),
    );
  };

  const onCloseDialog = (event: unknown, reason: string) => {
    if (reason !== 'backdropClick') onHandleClose();
  };

  return (
    <Dialog open={isOpen} onClose={onCloseDialog} className='bl-brands-modal'>
      <Box className='modal-header-container'>
        <Box className='modal-header-text'>
          <DialogTitle>Choose the brands you carry</DialogTitle>
          <IconButton onClick={onHandleClose} data-testid='closeIconButton'>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className='search-textfield'>
          <TextField
            fullWidth
            variant='standard'
            id='filter_brands'
            name='filter_brands'
            inputProps={{ 'data-testid': 'searchInput' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            onChange={(e) => {
              handleFilterSearchChange(e.target.value.toLowerCase());
            }}
            placeholder='Search'
          />
        </Box>
        {brandsChecked.length > 0 && (
          <Box data-testid='chipsContainer' className='modal-header-chips'>
            <ChipListElement data-testid='brandModal' />
          </Box>
        )}
      </Box>

      <DialogContent className='modal-content'>
        <AutoSizer>
          {({ height, width }) => (
            <BrandsList
              height={height}
              width={width}
              itemCount={filteredBrands.length}
              overscanCount={5}
              itemSize={64}
              data={filteredBrands}
              checked={brandsChecked}
              handleToggleBrand={handleToggle}
            />
          )}
        </AutoSizer>
        {!filteredBrands.length && search && (
          <>
            <Grid
              item
              container
              direction='column'
              justifyContent='center'
              alignContent='center'
              className='not-found-search'
              data-testid='emptyState'
            >
              <Box display='flex' flexDirection='column' alignItems='center'>
                <img src={InputSearchIcon} alt='Failed search' />
                <Typography variant='h6' color='initial'>
                  {search} not found
                </Typography>
              </Box>
              <Typography variant='body1' color='initial'>
                · Try typing one character at a time
              </Typography>
              <Typography variant='body1' color='initial'>
                · Avoid copy and paste
              </Typography>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: errorMessage ? 'space-between' : 'flex-end',
        }}
        className='modal-footer'
      >
        {errorMessage && (
          <Box className='container-error'>
            <ErrorIcon color='warning' />
            <Typography variant='body1' className='error-text' color='initial'>
              You must add at least 1 brand.
            </Typography>
          </Box>
        )}
        <Box>
          <Button
            className='cancel-btn'
            variant='outlined'
            color='neutral'
            data-testid='cancelButton'
            onClick={onHandleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            data-testid='saveButton'
            startIcon={<CheckIcon />}
            onClick={onHandleSave}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
