import { Avatar, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import ImagesList from '@components/ImagesList';
import { OrderHubTimer } from '@components/OrderHubTimer';
import { Order } from '@config/api/task/interface';
import { getBannerFromOrderInfo, getOrderStatus } from '@pages/Tasks/utils';
import { partImage } from '@utils/functions';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

const ListItem = ({
  order,
  showTimer,
  handleSelectOrder,
}: {
  order: Order;
  showTimer?: boolean;
  handleSelectOrder: (
    order: Order,
    techName: string,
    companyName: string,
  ) => void;
}) => {
  const { user, items, total_line_items, created_at } = order;

  const listOfImages = useMemo(
    () =>
      items.map((item) => ({
        source: partImage(item.info, item.info.type),
        name: item.type,
      })),
    [items],
  );

  const banner = useMemo(() => getBannerFromOrderInfo(order), [order]);

  const totalImagesLength =
    items.length === 3 && total_line_items > 3
      ? total_line_items - items.length
      : 0;

  const companyName = order?.company
    ? capitalizeFirstLetter(order?.user?.company || order.company.name || '')
    : "For Technician's Personal Job";

  const techName = `${capitalizeFirstLetter(
    user?.first_name || '',
  )} ${capitalizeFirstLetter(user?.last_name || '')}`;

  return (
    <div className='mb-4 rounded-md border border-gray-100 pt-5 shadow-md'>
      <div
        className='flex min-h-[100px] w-full cursor-pointer items-center justify-center px-4 py-5'
        onClick={() => handleSelectOrder(order, techName, companyName)}
      >
        <div className='flex flex-grow flex-row items-center justify-start'>
          <Avatar className='mr-4' src={user?.photo} />
          <div className='flex flex-grow flex-row items-center justify-between'>
            <div className='flex flex-col items-start justify-center'>
              <Typography
                variant='body1'
                className='contractor-name text-base font-normal text-[var(--bluon--ds--darkblue-01)]'
              >
                {companyName}
              </Typography>
              <Typography
                variant='body2'
                className='technician-name text-sm font-normal text-[var(--bluon--default--black)]'
              >
                {techName}
              </Typography>
              <div className='flex flex-row items-start justify-center'>
                <div className='mr-2'>
                  <Typography
                    variant='subtitle2'
                    className={classNames(
                      'status text-sm font-medium text-[var(--bluon--ds--lightgray-2)]',
                      {
                        'max-w-[18.625em] overflow-hidden truncate': showTimer,
                      },
                    )}
                  >
                    {getOrderStatus(order)}
                  </Typography>
                </div>
                {showTimer && <OrderHubTimer date={new Date(created_at)} />}
              </div>
            </div>
            <div className='images-list-wrapper flex items-center justify-start sm:justify-end sm:pt-0'>
              <ImagesList list={listOfImages} totalLength={totalImagesLength} />
            </div>
          </div>
        </div>
      </div>
      {banner}
    </div>
  );
};

export default ListItem;
