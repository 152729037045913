import client from '..';

import { NotificationSettingsDataProps } from './interface';

export async function getNotificationsSettings(): Promise<NotificationSettingsDataProps> {
  const { data } = await client.get('/settings', {
    params: {
      group: 'notification',
    },
  });

  return data;
}

export async function updateNotificationSettings(params: any): Promise<any> {
  const { data } = await client.post('/settings/bulk-notification', {
    settings: params,
  });

  return data;
}
