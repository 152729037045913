import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { IConfirmedPickupBannerProps, ITotal } from './types';
import { TotalValidationSchema } from './validation';

import Banner from '@components/Banner';
import { AvailableBanners } from '@components/Banner/constants';
import { BannerStatus } from '@components/Banner/interface';
import { ShellModal } from '@components/ShellModal';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { getRequestRunning } from '@state/requests/selectors';
import { setOrderCompleteRequest } from '@state/task/actions';
import { roundNumber } from '@utils/Numbers';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

const ConfirmedPickupBanner = ({
  contractorName,
  fee,
  orderId,
  isReadyForDelivery,
  isPersonalUse,
}: IConfirmedPickupBannerProps) => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [open, setOpen] = useState(false);

  const initialValues: ITotal = {
    total: fee ? roundNumber(fee, 2).toString() : '',
  };

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<ITotal>({
    resolver: yupResolver(TotalValidationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });
  const totalValue = watch('total');

  const { requestRunningState } = useAppSelector((state) => ({
    requestRunningState: getRequestRunning(
      state,
      String(setOrderCompleteRequest),
    ),
  }));

  const [loadingBanner, setLoadingBanner] = useState({
    orderId: '',
    loading: false,
  });
  const loadingCondition =
    ((requestRunningState.running &&
      requestRunningState.requestId == orderId) ||
      (loadingBanner.loading && loadingBanner.orderId == orderId)) &&
    !open;

  useEffect(() => {
    setValue('total', initialValues.total);
  }, [open]);

  const handleSetCompleteOrder = (orderValue: any) => {
    setLoadingBanner({ orderId: orderId, loading: true });
    dispatch(
      setOrderCompleteRequest({
        requestId: orderId,
        order: orderId,
        total: Number(orderValue.replaceAll(',', '').replace('$ ', '')),
        errorCallback: () => {
          showMessage('There was an error completing the order request.', {
            severity: 'error',
            autoHide: true,
          });
          setLoadingBanner({ orderId: '', loading: false });
        },
        successCallback: () => {
          setOpen(false);
        },
      }),
    );
  };

  const modalProps = useMemo(() => {
    return {
      title: 'Enter New Total',
      subtitle: `Enter the price of the confirmed order for ${
        contractorName
          ? capitalizeFirstLetter(contractorName)
          : 'Technician’s Personal Job'
      }.`,
    };
  }, [isReadyForDelivery, contractorName, isPersonalUse]);

  const bannerProps = useMemo(() => {
    return {
      message: '',
      status: BannerStatus.success,
      ...(!isReadyForDelivery && { ...AvailableBanners.confirmPickup }),
      ...(isReadyForDelivery && { ...AvailableBanners.isReadyForDelivery }),
      buttons: [
        {
          loading: loadingCondition,
          message: isReadyForDelivery ? 'Confirm' : 'Yes',
          onPress: () => handleSetCompleteOrder(String(fee)),
          className: 'primary-button',
        },
        {
          disabled: loadingCondition,
          message: isReadyForDelivery ? 'Different Price' : 'No',
          onPress: () => setOpen(true),
          className: loadingCondition ? undefined : 'primary-button',
        },
      ],
    };
  }, [isReadyForDelivery, loadingCondition, fee]);

  return (
    <>
      <ShellModal
        open={open}
        onClose={() => setOpen(false)}
        bottomButtons={[
          <Button key={1} onClick={() => setOpen(false)} variant='outlined'>
            CANCEL
          </Button>,
          <Button
            key={2}
            onClick={() => handleSetCompleteOrder(totalValue)}
            disabled={
              !!errors.total || !totalValue || requestRunningState.running
            }
            variant='contained'
          >
            <>
              SAVE
              {requestRunningState.running && (
                <CircularProgress
                  size='small'
                  className='ml-1.5 w-[1.5rem] !text-white'
                />
              )}
            </>
          </Button>,
        ]}
        {...modalProps}
      >
        <div className='mb-10 flex w-[39.5rem]'>
          <Controller
            name='total'
            control={control}
            render={({ field, fieldState, formState }) => (
              <NumberFormat
                customInput={TextField}
                variant='outlined'
                id='total'
                data-testid='total'
                label='Total'
                thousandSeparator
                placeholder='$'
                value={field.value?.replace('$', '')}
                onChange={field.onChange}
                error={!!fieldState.error}
                decimalScale={2}
                prefix='$ '
                helperText={formState.errors.total?.message}
                isNumericString
                name={field.name}
                onBlur={(e: any) => {
                  const plainValue = e.target.value.replace('$', '');
                  field.onBlur();
                  if (plainValue)
                    field.onChange(
                      plainValue.includes('.')
                        ? plainValue
                        : plainValue + '.00',
                    );
                }}
                defaultValue={field.value}
                fullWidth
                allowNegative={false}
              />
            )}
          />
        </div>
      </ShellModal>
      <Banner {...bannerProps} fee={roundNumber(fee, 2)} />
    </>
  );
};

export default ConfirmedPickupBanner;
