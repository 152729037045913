import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';

import { queryParamKeys } from '../../interface';
import { ISearchList } from '../interface';

import { IModelsItem, IModelsProps } from './interface';

import { CircularLoading } from '@components/CircularLoading';
import { NotFound } from '@components/NotFound';
import { RowItem } from '@components/RowItem';
import { SomethingWrong } from '@components/SomethingWrong';
import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { modelsRequest, moreModelsRequest } from '@state/search/actions';
import { getHasNextPageModels, getModelsData } from '@state/search/selectors';

export const Models = ({
  selectedModels,
  onClickItem,
  serieId,
  brandId,
  searchValue,
}: IModelsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [queryParams, setSearchParams] = useQueryParams<queryParamKeys>();
  const [page] = useState<number>(queryParams?.modelPage ?? 1);

  const {
    models,
    modelsHasNextPage,
    isLoadingNextPageModels,
    isLoadingModels,
    modelsRequestError,
  } = useAppSelector((state) => ({
    models: getModelsData(state),
    modelsHasNextPage: getHasNextPageModels(state),
    isLoadingNextPageModels: isRequestRunning(state, String(moreModelsRequest)),
    isLoadingModels: isRequestRunning(state, String(modelsRequest)),
    modelsRequestError: getRequestError(state, String(modelsRequest)),
  }));

  const checkSearchValue = useCallback(() => {
    return searchValue.length >= 2 || (!searchValue && !selectedModels);
  }, [searchValue, selectedModels]);

  const handleSelectModel = (
    item: IModelsItem['selectedItem'],
    saveHistory = true,
  ) => {
    if (saveHistory)
      setSearchParams(
        {
          ...queryParams,
          modelId: item?.id as string,
          modelPage: item?.page,
        },
        !!queryParams?.modelId,
      );

    onClickItem(item as ISearchList);
  };

  useEffect(() => {
    if (checkSearchValue())
      dispatch(modelsRequest({ page, brandId, serieId, search: searchValue }));
  }, [
    brandId,
    serieId,
    selectedModels,
    dispatch,
    checkSearchValue,
    searchValue,
  ]);

  return (
    <Grid data-testid='searchModelsContainer' item xs={6}>
      <Box className='bl-container-sections'>
        {modelsRequestError && (
          <SomethingWrong
            height='calc(100vh - 246.5px)'
            onReload={() => {
              dispatch(
                modelsRequest({
                  brandId,
                  serieId,
                  search: checkSearchValue() ? searchValue : '',
                }),
              );
            }}
          />
        )}
        {isLoadingModels && (
          <Box
            data-testid='loadingModelsContainer'
            className='bl-search-loading-section-container'
          >
            <CircularLoading />
          </Box>
        )}
        {!isLoadingModels && !modelsRequestError && (
          <VirtualizedInfiniteList
            list={models}
            hasNextPage={modelsHasNextPage}
            onLoadMore={() => {
              dispatch(
                moreModelsRequest({
                  brandId,
                  serieId,
                  search: checkSearchValue() ? searchValue : '',
                }),
              );
            }}
            isLoading={isLoadingNextPageModels}
            selectedItem={selectedModels}
            onClickItem={handleSelectModel}
            rowHeight={65}
          >
            {({ index, list, onClickItem, selectedItem }: IModelsItem) => (
              <RowItem
                id={list[index].id}
                img={list[index]?.image?.url}
                isImgRequired={false}
                key={list[index].id}
                name={list[index].model || ''}
                nameVariantStyle='small'
                notes={list[index].model_notes}
                noteVariantColor='grey'
                noteVariantStyle='small'
                onClickItem={() =>
                  onClickItem({
                    ...list[index],
                    index,
                  })
                }
                selected={selectedItem}
                item={list[index]}
              />
            )}
          </VirtualizedInfiniteList>
        )}
        {!modelsRequestError &&
          searchValue &&
          models.length === 0 &&
          !isLoadingModels && <NotFound />}
      </Box>
    </Grid>
  );
};
