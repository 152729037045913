import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect } from 'react';

import { CircularLoading } from '../../../../components/CircularLoading';
import { NotFound } from '../../../../components/NotFound';
import { RowItem } from '../../../../components/RowItem';
import { SomethingWrong } from '../../../../components/SomethingWrong';
import { VirtualizedInfiniteList } from '../../../../components/VirtualizedInfiniteList';

import { ISeriesItem, ISeriesProps } from './interface';

import { useAppDispatch, useAppSelector } from '@hooks/state';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { moreSeriesRequest, seriesRequest } from '@state/search/actions';
import { getHasNextPageSeries, getSeriesData } from '@state/search/selectors';

export const Series = ({
  selectedSerie,
  onClickItem,
  brandId,
  searchValue,
}: ISeriesProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    series,
    seriesHasNextPage,
    isLoadingNextPageSeries,
    isLoadingSeries,
    seriesRequestError,
  } = useAppSelector((state) => ({
    series: getSeriesData(state),
    seriesHasNextPage: getHasNextPageSeries(state),
    isLoadingNextPageSeries: isRequestRunning(state, String(moreSeriesRequest)),
    isLoadingSeries: isRequestRunning(state, String(seriesRequest)),
    seriesRequestError: getRequestError(state, String(seriesRequest)),
  }));

  const handleCheckSearchValue = useCallback(() => {
    return searchValue.length >= 2 || (!searchValue && !selectedSerie);
  }, [searchValue, selectedSerie]);

  useEffect(() => {
    if (handleCheckSearchValue())
      dispatch(seriesRequest({ brandId, search: searchValue }));
  }, [searchValue, brandId, selectedSerie, handleCheckSearchValue, dispatch]);

  return (
    <Grid data-testid='searchSeriesContainer' item xs={6}>
      <Box className='bl-container-sections'>
        {seriesRequestError && (
          <SomethingWrong
            height='calc(100vh - 246.5px)'
            onReload={() => {
              dispatch(
                seriesRequest({
                  brandId,
                  search: handleCheckSearchValue() ? searchValue : '',
                }),
              );
            }}
          />
        )}
        {isLoadingSeries && (
          <Box
            data-testid='loadingSeriesContainer'
            className='bl-search-loading-section-container'
          >
            <CircularLoading />
          </Box>
        )}
        {!isLoadingSeries && !seriesRequestError && (
          <VirtualizedInfiniteList
            list={series}
            hasNextPage={seriesHasNextPage}
            onLoadMore={() => {
              dispatch(
                moreSeriesRequest({
                  brandId,
                  search: handleCheckSearchValue() ? searchValue : '',
                }),
              );
            }}
            isLoading={isLoadingNextPageSeries}
            selectedItem={selectedSerie}
            onClickItem={onClickItem}
            rowHeight={65}
          >
            {({ index, list, onClickItem, selectedItem }: ISeriesItem) => (
              <RowItem
                type='search-series'
                id={list[index].id}
                img={list[index]?.image?.url}
                isImgRequired
                key={list[index].id}
                name={list[index].name}
                nameVariantStyle='small'
                noteVariantColor='grey'
                noteVariantStyle='small'
                onClickItem={() =>
                  onClickItem({
                    ...list[index],
                    index,
                  })
                }
                selected={selectedItem}
              />
            )}
          </VirtualizedInfiniteList>
        )}
        {searchValue && series.length === 0 && <NotFound />}
      </Box>
    </Grid>
  );
};
