import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FromModelButtonProps } from './interface';

import Button from '@components/Button';

import './styles.scss';

const FromModelButton = ({
  brand,
  onClick,
  model,
  modelId,
  buttonAdditionalStyle = 'transparent',
  labelAdditionalStyle = '',
}: FromModelButtonProps) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    onClick && onClick();
    navigate(
      `/bluon-search?tab=searchModel&search=${model}&modelId=${modelId}&modelPage=1`,
    );
  };

  return (
    <div className='from-model-button-wrapper mr-4 flex flex-row items-center justify-center'>
      <div
        className={`mr-2 flex items-center text-[var(--bluon--ds--button--lightgray--border)] ${labelAdditionalStyle}`}
      >
        From Model
      </div>
      <div className={`border-0 ${buttonAdditionalStyle}`}>
        <Button
          className='from-model-button h-full w-full'
          variant='outlined'
          onClick={handleButtonClick}
        >
          {brand} {model}
          <ArrowForwardIosIcon fontSize='small' />
        </Button>
      </div>
    </div>
  );
};

export default FromModelButton;
