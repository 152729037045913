import { RootState } from '..';

import { Reason } from './interface';

import {
  BrandsDataModel,
  CounterStaffDataModel,
  LimitedStoreInfoDataModel,
  StateCountryDataModel,
  StoreInfoDataModel,
} from '@config/api/storeInfo/interface';

export function getLimitedStoreInfoData(
  state: RootState,
): LimitedStoreInfoDataModel | undefined {
  return state.storeInfo.limitedStoreInfoResponse;
}

export function getStoreInfoData(
  state: RootState,
): StoreInfoDataModel | undefined {
  return state.storeInfo.storeInfoResponse;
}

export function getStoreInfoBidNumberOption(
  state: RootState,
): boolean | undefined {
  return state.storeInfo?.storeInfoResponse?.bid_number_required;
}
export function getStoreInfoAddress(
  state: RootState,
): string | undefined | null {
  return state.storeInfo.storeInfoResponse?.address;
}

export function getStoreInfoCounterStaff(
  state: RootState,
): Array<CounterStaffDataModel> | undefined {
  return state.storeInfo.storeInfoResponse?.counter_staff;
}

export function getCancelReasons(state: RootState): Array<Reason> | undefined {
  return state.storeInfo.cancelReasons?.data;
}

export function getDeclineReasons(state: RootState): Array<Reason> | undefined {
  return state.storeInfo.declineReasons?.data;
}

export function getReturnReasons(state: RootState): Array<Reason> | undefined {
  return state.storeInfo.returnReasons?.data;
}

export function getStoreInfoNotificationData(
  state: RootState,
): Pick<StoreInfoDataModel, 'id' | 'name'> | undefined {
  const storeInfoResponse = state.storeInfo.storeInfoResponse;
  if (storeInfoResponse) {
    const { id, name } = storeInfoResponse;

    return { id, name };
  }
}

export function getBrandsData(state: RootState): BrandsDataModel[] {
  return state.storeInfo.brands ?? [];
}

export function getHasNextPageBrands(state: RootState): boolean {
  return state.storeInfo.hasNextPage;
}

export function getInfoBrandsData(
  state: RootState,
): BrandsDataModel[] | undefined {
  return state.storeInfo.storeBrands;
}

export function getCountriesData(
  state: RootState,
): StateCountryDataModel[] | undefined {
  return state.storeInfo.countriesData;
}

export function getStatesData(
  state: RootState,
): StateCountryDataModel[] | undefined {
  return state.storeInfo.statesData;
}

export function getStoreInfoContacts(
  state: RootState,
):
  | Pick<
      StoreInfoDataModel,
      | 'email'
      | 'prokeep_phone'
      | 'contact_secondary_email'
      | 'contact_email'
      | 'contact_phone'
    >
  | undefined {
  const storeInfoResponse = state.storeInfo.storeInfoResponse;

  if (storeInfoResponse) {
    const {
      email,
      prokeep_phone,
      contact_secondary_email,
      contact_email,
      contact_phone,
    } = storeInfoResponse;

    return {
      email,
      prokeep_phone,
      contact_secondary_email,
      contact_email,
      contact_phone,
    };
  }
}
