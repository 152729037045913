import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { IBrandsItem, IBrandsProps } from './interface';

import { CircularLoading } from '@components/CircularLoading';
import { RowItem } from '@components/RowItem';
import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';

export const Brands = ({
  selectedBrand,
  onClickItem,
  brands,
  isLoadingBrands,
  brandsHasNextPage,
  isLoadingNextPageBrands,
  onLoadMore,
}: IBrandsProps): JSX.Element => (
  <Grid item xs={selectedBrand ? 6 : 12}>
    <Box className='bl-container-sections'>
      {!isLoadingBrands ? (
        <VirtualizedInfiniteList
          hasNextPage={brandsHasNextPage}
          isLoading={isLoadingNextPageBrands}
          list={brands}
          onClickItem={onClickItem}
          onLoadMore={onLoadMore}
          selectedItem={selectedBrand}
          rowHeight={65}
        >
          {({ index, list, onClickItem, selectedItem }: IBrandsItem) => (
            <RowItem
              id={list[index].id}
              img={list[index]?.image?.url}
              isImgRequired
              key={list[index].id}
              name={list[index].name}
              nameVariantStyle='small'
              noteVariantColor='grey'
              noteVariantStyle='small'
              onClickItem={() =>
                onClickItem({
                  ...list[index],
                  index,
                })
              }
              selected={selectedItem}
            />
          )}
        </VirtualizedInfiniteList>
      ) : (
        <Box
          data-testid='loadingContainer'
          className='bl-search-loading-section-container'
        >
          <CircularLoading />
        </Box>
      )}
    </Box>
  </Grid>
);
