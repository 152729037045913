import { Box, FormHelperText, InputLabel } from '@mui/material';

import Button from '../Button';

import { SpecialInputSelectorTypes } from './interface';

import './styles.scss';

export const SPECIAL_INPUT_SELECTOR_TEST_ID = 'specialInputSelector';
export const SPECIAL_INPUT_SELECTOR_CHILDREN_TEST_ID =
  'specialInputSelectorChildren';

export const SpecialInputSelector = ({
  buttonIcon,
  buttonText,
  children,
  label,
  onClick,
  data,
  'data-testid': dataTestId,
  errorMessage,
}: SpecialInputSelectorTypes): JSX.Element => {
  return (
    <Box
      data-testid={SPECIAL_INPUT_SELECTOR_TEST_ID}
      className='special-input-selector-form-box'
    >
      <InputLabel>{label}</InputLabel>
      {!!data.length && (
        <Box
          data-testid={SPECIAL_INPUT_SELECTOR_CHILDREN_TEST_ID}
          component='div'
          className='children-container'
        >
          {children}
        </Box>
      )}
      <Button
        variant='contained'
        color='primary'
        data-testid={dataTestId && `${dataTestId}Button`}
        startIcon={buttonIcon}
        onClick={onClick}
      >
        {buttonText}
      </Button>
      {!data.length && <FormHelperText>{errorMessage}</FormHelperText>}
    </Box>
  );
};
