import { createAction } from 'redux-actions';

const ACTION_TYPES = {
  GET_SUPPLIER_LIST_REQUEST: 'manager/get-supplier-list-request',
  GET_SUPPLIER_LIST_RESPONSE: 'manager/get-supplier-list-response',
  CLEAR_SUPPLIER_LIST: 'manager/clear-supplier-list',
  IMPERSONATE_REQUEST: 'manager/impersonate-request',
  IMPERSONATE_RESPONSE: 'manager/impersonate-response',
  IMPERSONATE_REMOVE: 'manager/impersonate-remove',
};

export const getSupplierList = createAction(
  ACTION_TYPES.GET_SUPPLIER_LIST_REQUEST,
);
export const getSupplierListResponse = createAction(
  ACTION_TYPES.GET_SUPPLIER_LIST_RESPONSE,
);
export const clearSupplierList = createAction(ACTION_TYPES.CLEAR_SUPPLIER_LIST);
export const impersonateSupplierRequest = createAction(
  ACTION_TYPES.IMPERSONATE_REQUEST,
);
export const impersonateSupplierResponse = createAction(
  ACTION_TYPES.IMPERSONATE_RESPONSE,
);
export const removeSupplierImpersonate = createAction(
  ACTION_TYPES.IMPERSONATE_REMOVE,
);
