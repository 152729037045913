import { off, onValue, ref } from 'firebase/database';
import { useEffect } from 'react';

import { StoreInfoDataModel } from '@config/api/storeInfo/interface';
import { database, databaseNodeResponseTime } from '@config/firebase';
import { RealTimeResponseTime } from '@pages/Tasks/interfaces';

const useRealTimeResponseTime = (
  storeInfo: StoreInfoDataModel | undefined,
  onValueReceived: (value: RealTimeResponseTime) => void,
) => {
  useEffect(() => {
    if (storeInfo && databaseNodeResponseTime) {
      const supplierResponseTime = ref(
        database,
        databaseNodeResponseTime + storeInfo.id,
      );
      onValue(supplierResponseTime, (snapshot) => {
        if (snapshot.val()) onValueReceived(snapshot.val());
      });

      return () => {
        off(supplierResponseTime);
      };
    }
  }, [storeInfo]);
};

export default useRealTimeResponseTime;
