/**
 * The external dependencies.
 */
import { setAutoFreeze } from 'immer';
import {
  CombinedState,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

/**
 * The internal dependencies.
 */
import authSagas from './auth/effects';
import { AuthStateProps } from './auth/interface';
import auth from './auth/reducer';
import chatSagas from './chat/effects';
import { ChatState } from './chat/interface';
import chatReducer from './chat/reducer';
import customerSagas from './customer/effects';
import { CustomerActionProps, CustomerState } from './customer/interface';
import customerReducer from './customer/reducer';
import { DashboardState } from './dashboard/interface';
import dashboardReducer from './dashboard/reducer';
import managerSagas from './manager/effects';
import { ManagerState } from './manager/interface';
import managerReducer from './manager/reducer';
import notificationSettingsSagas from './notificationSettings/effects';
import { NotificationSettingsStateProps } from './notificationSettings/interface';
import notificationSettingsReducer from './notificationSettings/reducer';
import ordersLogsSagas from './ordersLogs/effects';
import { OrdersLogsState } from './ordersLogs/interface';
import ordersLogsReducer from './ordersLogs/reducers';
import orderSummarySagas from './orderSummary/effects';
import { OrderSummaryState } from './orderSummary/interface';
import orderSummaryReducer from './orderSummary/reducer';
import { RequestStateProps } from './requests/interface';
import request from './requests/reducer';
import searchSagas from './search/effects';
import { SearchStateProps } from './search/interface';
import searchReducer from './search/reducer';
import shareableOrderSagas from './shareableOrder/effects';
import { ShareableOrderState } from './shareableOrder/interface';
import shareableOrderReducer from './shareableOrder/reducers';
import storeInfoSagas from './storeInfo/effects';
import { StoreInfoStateProps } from './storeInfo/interface';
import storeInfo from './storeInfo/reducer';
import taskSagas from './task/effects';
import { TaskState } from './task/interface';
import taskReducer from './task/reducer';
import validationSettingsSagas from './validationSettings/effects';
import { ValidationSettingsStateProps } from './validationSettings/interface';
import validationSettingsReducer from './validationSettings/reducer';

/**
 * Techinical debt
 * This is setting false because there are a lot of mutations of redux store state inside some reducers
 * happening in this project. So it needs to refactor a lot of code inside some reducers to remove these mutations
 * like the actions `resetChannelMessages` and `updateChannelTimetoken` of inbound/reducer.
 * Otherwise the app would crash as frozen state are readonly and can't be mutated like they are being mutated.
 *
 * To check this problem, uncomment the setAutoFreeze below and activate any reducer that uses immer
 */
setAutoFreeze(false);

/**
 * Setup the root reducer.
 */
const combinedReducers = combineReducers({
  auth,
  storeInfo,
  request,
  customerReducer,
  dashboardReducer,
  searchReducer,
  orderSummaryReducer,
  notificationSettingsReducer,
  validationSettingsReducer,
  taskReducer,
  ordersLogsReducer,
  chatReducer,
  shareableOrderReducer,
  managerReducer,
});

const reducer = (
  state:
    | CombinedState<{
        auth: AuthStateProps;
        storeInfo: StoreInfoStateProps;
        request: RequestStateProps;
        customerReducer: CustomerState;
        dashboardReducer: DashboardState;
        chatReducer: ChatState;
        searchReducer: SearchStateProps;
        orderSummaryReducer: OrderSummaryState;
        notificationSettingsReducer: NotificationSettingsStateProps;
        validationSettingsReducer: ValidationSettingsStateProps;
        taskReducer: TaskState;
        ordersLogsReducer: OrdersLogsState;
        shareableOrderReducer: ShareableOrderState;
        managerReducer: ManagerState;
      }>
    | undefined,
  action: CustomerActionProps,
) => {
  if (action.type === 'auth/logout') return combinedReducers(undefined, action);

  return combinedReducers(state, action);
};

function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(customerSagas),
    fork(storeInfoSagas),
    fork(searchSagas),
    fork(orderSummarySagas),
    fork(notificationSettingsSagas),
    fork(validationSettingsSagas),
    fork(taskSagas),
    fork(ordersLogsSagas),
    fork(chatSagas),
    fork(shareableOrderSagas),
    fork(managerSagas),
  ]);
}

/**
 * Setup the middlewares.
 */
const saga = createSagaMiddleware();

/**
 * Setup the store.
 */
const store = createStore(
  reducer,
  {},
  composeWithDevTools(applyMiddleware(saga)),
);

/**
 * Start the effects.
 */
saga.run(rootSaga);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
