import { Box } from '@mui/material';

import { RowItem } from '../../../../../RowItem';

import { ManualListProps } from './interface';

import ManualIcon from '@assets/icons/ManualIcon.svg';

import './styles.scss';

export const ManualList = ({
  manuals,
  label,
}: ManualListProps): JSX.Element => {
  return (
    <Box className='bl-manuals-list'>
      {manuals?.map((manual, index) => (
        <RowItem
          id={manual.id}
          nodeImage={<img alt='Manual' src={ManualIcon} />}
          key={manual.id}
          name={`${label} - ${index + 1}`}
          nameVariantStyle='small'
          noteVariantColor='grey'
          noteVariantStyle='small'
          onClickItem={() => window.open(manual.url, '_blank')}
        />
      ))}
    </Box>
  );
};
