import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';

import TextInput from '../../../../../../components/TextInput';

import { BranchManagerInputsProps } from './interface';

import { FormTextInputLengths } from '@utils/FormInputLengths';

const BranchManagerInputs = ({
  control,
  isLoading,
}: BranchManagerInputsProps): JSX.Element => (
  <Grid container justifyContent='center'>
    <Grid item xs={12} md={9}>
      <Controller
        name='manager_name'
        control={control}
        render={({ field, fieldState, formState }) => (
          <TextInput
            id='manager_name'
            data-testid='managerName'
            label='Name'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            errorMessage={formState.errors.manager_name?.message}
            isLoading={isLoading}
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='manager_phone'
        control={control}
        render={({ field, fieldState, formState }) => (
          <TextInput
            id='manager_phone'
            data-testid='managerPhoneNumber'
            label='Phone Number'
            name={field.name}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) event.preventDefault();
            }}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            maxLength={FormTextInputLengths.profile.phoneFullNumber}
            errorMessage={formState.errors.manager_phone?.message}
            isLoading={isLoading}
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='manager_email'
        control={control}
        render={({ field, fieldState, formState }) => (
          <TextInput
            id='manager_email'
            label='Manager Email'
            data-testid='managerEmail'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            maxLength={FormTextInputLengths.profile.email}
            errorMessage={formState.errors.manager_email?.message}
            isLoading={isLoading}
          />
        )}
      />
    </Grid>
  </Grid>
);

export default BranchManagerInputs;
