import { DateTime, Duration } from 'luxon';
import { useMemo } from 'react';

import { Dropdown } from '../Dropdown';
import { DropdownOptions } from '../Dropdown/interface';

import { TimePickerProps } from './interface';
import './styles.scss';

export const TimePicker = (props: TimePickerProps): JSX.Element => {
  const generateOptions = () => {
    const dtEnd = DateTime.fromFormat(props.endTime, 'h:mm a');
    const durationInterval = Duration.fromISOTime(props.interval);

    const res: DropdownOptions[] = [];
    let i = DateTime.fromFormat(props.startTime, 'h:mm a');

    while (i < dtEnd) {
      const time = i.toFormat('h:mm a').toLowerCase();
      res.push({ label: time, value: time });
      i = i.plus(durationInterval);
    }

    return res;
  };

  const options = useMemo(
    () => generateOptions(),
    [props.startTime, generateOptions],
  );

  return (
    <Dropdown className='time-picker-min-width ' {...props} options={options} />
  );
};
