import dayjs from 'dayjs';

import { OrderDetailTypes } from '../constants';

import { EOrderMainStatus } from '@utils/enums';

export const getOrderDetailType = (
  status: EOrderMainStatus,
): OrderDetailTypes => {
  if (status === EOrderMainStatus.PENDING) return OrderDetailTypes.PENDING;
  if (
    status === EOrderMainStatus.APPROVED ||
    status === EOrderMainStatus.PENDING_APPROVAL
  )
    return OrderDetailTypes.PENDING_APPROVAL_OR_APPROVED;
  if (status === EOrderMainStatus.COMPLETED) return OrderDetailTypes.COMPLETED;
  if (status === EOrderMainStatus.CANCELED) return OrderDetailTypes.CANCELED;
  if (status === EOrderMainStatus.RETURNED) return OrderDetailTypes.RETURNED;

  return OrderDetailTypes.UNKNOWN;
};

export const getLabelAvailableAtPart = ({
  message,
  format,
  available_at,
}: {
  message: string;
  format: string;
  available_at: string;
}): string => {
  const replacementMsg = 'We can get this replacement by: ';
  const getItMsg = 'We can get it by: ';
  const date = available_at?.replace(/-/g, '/');

  return `${message === '1' ? replacementMsg : getItMsg}${dayjs(date)
    .local()
    .format(format)}`;
};
