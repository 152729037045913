import { RootState } from '..';

import { TaskState } from './interface';

import {
  CustomItem,
  ExtraItem,
  Invoice,
  Order,
  OrderItem,
  SupplierStaff,
} from '@config/api/task/interface';
import { ReplacementItem } from '@pages/Tasks/interfaces';

export function getCurrentSelectedOrder(state: RootState): Order | null {
  return state.taskReducer.currentSelectedOrder;
}
export function getCurrentSelectedOrderId(
  state: RootState,
): string | undefined {
  return state.taskReducer.currentSelectedOrder?.id;
}

export function getCurrentUploadedInvoice(
  state: RootState,
): Invoice | null | undefined {
  return state.taskReducer.currentSelectedOrder?.invoice;
}
export function getOrderItems(state: RootState): OrderItem[] {
  return state.taskReducer.orderItems.data;
}

export function getOrderInvoice(state: RootState): Invoice | null | undefined {
  return state.taskReducer.currentSelectedOrder?.invoice;
}

export function getIsTruckStockExpanded(state: RootState): boolean {
  return state.taskReducer.isTruckStockExpanded;
}

export function getIsTotalExpanded(state: RootState): boolean {
  return state.taskReducer.isTotalExpanded;
}

export function getIsAddedItemsExpanded(state: RootState): boolean {
  return state.taskReducer.isAddedItemsExpanded;
}

export function getOrderSupplyItems(state: RootState): ExtraItem[] {
  return state.taskReducer.orderSupply.data;
}

export function getOrderAddedItems(state: RootState): ExtraItem[] {
  return state.taskReducer.addedItems.data;
}
export function getAvailabilityOrders(state: RootState): Array<Order> {
  return state.taskReducer.availabilityOrders.data;
}

export function getApprovedOrders(state: RootState): Array<Order> {
  return state.taskReducer.approvedOrders.data;
}

export function getAvailabilityOrdersNextPage(state: RootState): number | null {
  return state.taskReducer.availabilityOrders.nextPage;
}

export function getApprovedOrdersNextPage(state: RootState): number | null {
  return state.taskReducer.approvedOrders.nextPage;
}

export function getAvailabilityOrdersHasNextPage(state: RootState): boolean {
  return state.taskReducer.availabilityOrders.hasNextPage;
}

export function getApprovedOrdersHasNextPage(state: RootState): boolean {
  return state.taskReducer.approvedOrders.hasNextPage;
}

export function getAvailabilityOrdersIsDirty(state: RootState): boolean {
  return state.taskReducer.availabilityOrders.isDirty;
}

export function getApprovedOrdersIsDirty(state: RootState): boolean {
  return state.taskReducer.approvedOrders.isDirty;
}

export function getOrderCustomItems(state: RootState): CustomItem[] {
  return state.taskReducer.orderCustomItems.data;
}

export function getSupplierStaff(state: RootState): Array<SupplierStaff> {
  return state.taskReducer.supplierStaff.data;
}

export function getSupplierStaffLastAssigned(state: RootState): string | null {
  return state.taskReducer.supplierStaff.lastAssigned || '';
}

export function getLastSupplierAdded(state: RootState): string | null {
  return state.taskReducer.claimingModal?.lastAddedId || '';
}

export function getOrderToRemember(
  state: RootState,
): Array<{ id: string; clickedTime: Date }> {
  return state.taskReducer.orderToRemember.data;
}

export function getItemReplacementsHasNextPage(
  state: RootState,
  itemId: string,
): boolean {
  const item = state.taskReducer.orderItems.data.find(
    (item) => item.id === itemId,
  );

  return item?.knownReplacements ? item?.knownReplacements?.hasNextPage : false;
}

export function getItemReplacements(
  state: RootState,
  itemId: string,
): ReplacementItem[] {
  const item = state.taskReducer.orderItems.data.find(
    (item) => item.id === itemId,
  );

  return item?.knownReplacements ? item?.knownReplacements.data : [];
}

export function getSelectedClaimingModal(
  state: RootState,
): TaskState['claimingModal'] | null {
  return state.taskReducer.claimingModal;
}
