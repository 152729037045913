import { Button, CircularProgress, Modal } from '@mui/material';

import { getLabelAvailableAtPart } from '../../utils';
import { getItemDescription, getPartName } from '../ItemPart';

import CloseModalIcon from '@assets/icons/close.svg';
import { Label } from '@components/Label';
import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { sendForApprovalRequest } from '@state/task/actions';
import {
  getOrderCustomItems,
  getOrderInvoice,
  getOrderItems,
  getOrderSupplyItems,
} from '@state/task/selectors';
import { EItemStatus } from '@utils/enums';
import { emptyFunction, partImage } from '@utils/functions';

export const ReviewAndSendModal = ({
  open,
  onClose,
  totalValue,
  onSendAvailability,
}: {
  open: boolean;
  onClose: () => void;
  totalValue: string | undefined;
  onSendAvailability: () => void;
}) => {
  const { items, truckStockItems, orderCustomItems, isLoading, orderInvoice } =
    useAppSelector((state) => ({
      orderInvoice: getOrderInvoice(state),
      items: getOrderItems(state),
      truckStockItems: getOrderSupplyItems(state),
      orderCustomItems: getOrderCustomItems(state),
      isLoading: isRequestRunning(state, String(sendForApprovalRequest)),
    }));

  const onlyOneFunctionalPartNotAvailable =
    !!items &&
    items.length === 1 &&
    items[0].status === EItemStatus.NotAvailable;

  return (
    <Modal
      onBackdropClick={() => null}
      open={open}
      onClose={emptyFunction}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='flex items-center justify-center'
    >
      <div className='flex w-full max-w-[800px] flex-col rounded-lg bg-white px-4 py-4'>
        <div className='mb-1 flex w-full items-end justify-end'>
          <div
            onClick={onClose}
            className='mb-1 flex h-[1.75rem] w-[1.75rem] cursor-pointer items-center justify-center'
          >
            <img src={CloseModalIcon} alt='close' className='h-4 w-4' />
          </div>
        </div>
        <div className='mx-2 flex flex-col'>
          <div className='mb-2 mr-8 w-full'>
            <div className='font-[Roboto] text-[1.5rem] font-normal leading-[2rem]'>
              Review & Send
            </div>
          </div>
          <div className='flex w-full flex-row items-center justify-between bg-[var(--bluon--light--gray-21)] px-4 py-1.5'>
            <div className='font-[Roboto] text-[0.875rem] font-medium leading-[1.5rem]'>
              Items
            </div>
            <div className='font-[Roboto] text-[0.875rem] font-medium leading-[1.5rem]'>
              Qty
            </div>
          </div>
          <div className='max-h-[27.75rem] overflow-auto'>
            {items && !!items.length && !onlyOneFunctionalPartNotAvailable && (
              <div className='px-4'>
                <div className='font-[Roboto] text-[0.875rem] font-medium leading-[3rem] text-[var(--bluon--default--blue-3)]'>
                  Functional Parts
                </div>
              </div>
            )}
            {items.map((item, idx) => {
              if (item.status === EItemStatus.NotAvailable) return null;

              return (
                <div
                  key={item.id}
                  className={`flex max-h-[3.25rem] w-full flex-row items-center justify-between ${
                    idx === items.length - 1 ? '' : 'border-b '
                  } border-[var(--bluon--light--gray-21)]	p-4`}
                >
                  <div className='flex items-center'>
                    <div className='mr-[1.313rem]'>
                      <img
                        alt='logo'
                        className='h-[2rem] w-[2rem]'
                        loading='lazy'
                        src={partImage(item.item.info, item.item.type)}
                      />
                    </div>
                    <div className='flex items-center'>
                      <div className='mr-[0.5rem] flex items-center font-[Roboto] text-[0.875rem] font-bold tracking-[0.011rem]'>
                        {getPartName(item)}
                      </div>
                      <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem] text-[#00000099]'>
                        {getItemDescription(item)}
                      </div>
                      {item.status === EItemStatus.Available &&
                        item?.available_at && (
                          <div className='ml-2'>
                            <Label
                              label={getLabelAvailableAtPart({
                                message: item.replacement ? '1' : '0',
                                format: 'MM/DD/YY',
                                available_at: item?.available_at,
                              })}
                              color='brown'
                            />
                          </div>
                        )}
                      {item.status === EItemStatus.Available &&
                        item.replacement &&
                        !item.available_at && (
                          <div className='ml-2 flex items-center justify-center rounded-full border border-[var(--bluon--default--green)] bg-[var(--bluon--light--gray-21)] px-2.5 py-1.5'>
                            <div className='font-[Roboto] text-[0.75rem] font-normal leading-[0.75rem] tracking-[0.009rem] text-[var(--bluon--default--green)]'>
                              Replacement
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div>
                    <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
                      {item.quantity}
                    </div>
                  </div>
                </div>
              );
            })}

            {orderCustomItems && !!orderCustomItems.length && (
              <div className='px-4'>
                <div className='font-[Roboto] text-[0.875rem] font-medium leading-[3rem] text-[var(--bluon--default--blue-3)]'>
                  Additional Items You Added
                </div>
              </div>
            )}
            {orderCustomItems.map((customItem) => (
              <div
                key={customItem.id}
                className='flex items-center justify-between p-4'
              >
                <div className='font-[Roboto] text-[0.875rem] font-bold leading-[1.25rem]'>
                  {customItem.item.info.name}
                </div>
                <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
                  {customItem.quantity}
                </div>
              </div>
            ))}

            {truckStockItems && !!truckStockItems.length && (
              <div className='px-4'>
                <div className='font-[Roboto] text-[0.875rem] font-medium leading-[3rem] text-[var(--bluon--default--blue-3)]'>
                  Truck Stock
                </div>
              </div>
            )}
            <div className='mb-[1.5rem]'>
              {truckStockItems &&
                !!truckStockItems.length &&
                truckStockItems.map((truckStockItem) => {
                  if (truckStockItem.quantity === 0) return null;

                  return (
                    <div
                      key={truckStockItem.id}
                      className='flex items-center justify-between border-b border-[var(--bluon--light--gray-21)] px-4 py-1.5'
                    >
                      <div className='text-[0.875rem]	font-normal leading-[1.25rem] tracking-[0.011rem] text-[#000000DE]'>
                        {truckStockItem.item.info.name}
                      </div>
                      <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
                        {truckStockItem.quantity}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className='mb-2 flex w-full items-center justify-center py-4'>
            <div className='text-[1.25rem]	font-medium leading-[2rem] tracking-[0.009rem]'>
              Quote Total: ${totalValue?.replace(/\s/g, '')}
            </div>
          </div>
          <div className='mb-4 flex w-full items-center justify-center'>
            <Button onClick={onSendAvailability} variant='contained'>
              <>
                SEND AVAILABILITY
                {isLoading && (
                  <CircularProgress
                    size='small'
                    className='ml-1.5 w-[1.5rem] !text-white'
                  />
                )}
              </>
            </Button>
          </div>
          {!orderInvoice && (
            <div className='mb-4 flex w-full items-center justify-center'>
              <div className='font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] text-[var(--bluon--default--blue-3)]'>
                * You’re sending a quote without a quote / invoice attached.
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
