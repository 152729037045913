import { AppBar, Box } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

import './styles.scss';
import { ToolbarSearch } from './components/ToolbarSearch';
import { EquipmentTab } from './EquipmentTab';
import { queryParamKeys } from './interface';
import { ModelTab } from './ModelTab';
import { PartTab } from './PartTab';

import { useAppDispatch } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { partsResetRequest } from '@state/search/actions';

const searchTabs = [
  {
    label: 'Search by Part #',
    id: 'searchPart',
    'aria-controls': 'search-tabpanel-0',
  },
  {
    label: 'Search by Model #',
    id: 'searchModel',
    'aria-controls': 'search-tabpanel-1',
  },
  {
    label: 'Browse by Brand/Line',
    id: 'searchEquipment',
    'aria-controls': 'search-tabpanel-2',
  },
];

const PART_TAB = 0;
const MODEL_TAB = 1;
const EQUIPMENT_TAB = 2;

const TABS = {
  searchPart: PART_TAB,
  searchModel: MODEL_TAB,
  searchEquipment: EQUIPMENT_TAB,
};

export const BluonSearch = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useQueryParams<queryParamKeys>();
  const [tabValue, setTabValue] = useState(
    searchParams.tab ? TABS[searchParams.tab as keyof typeof TABS] : PART_TAB,
  );

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSearchParams(
      {
        tab: searchTabs[newValue].id as queryParamKeys['tab'],
      },
      false,
    );
  };

  useEffect(() => {
    dispatch(partsResetRequest());

    return () => {
      dispatch(partsResetRequest());
    };
  }, [tabValue, dispatch]);

  useEffect(() => {
    if (!searchParams.tab)
      setSearchParams(
        {
          tab: 'searchPart',
        },
        false,
      );
  }, []);

  //  Watching query params tab changes
  useEffect(() => {
    if (!searchParams?.tab || TABS[searchParams?.tab] === tabValue) return;
    setTabValue(TABS[searchParams?.tab]);
  }, [searchParams?.tab]);

  return (
    <Box className='bl-search-page'>
      <AppBar className='bl-header-search-nav' elevation={0} position='static'>
        <ToolbarSearch
          handleChangeTab={handleChangeTab}
          searchTabs={searchTabs}
          tabValue={tabValue}
        />
      </AppBar>

      {tabValue === PART_TAB && <PartTab />}
      {tabValue === MODEL_TAB && <ModelTab />}
      {tabValue === EQUIPMENT_TAB && <EquipmentTab />}

      <Box id='search-overlay' className='search-container-full-overlay' />
    </Box>
  );
};
