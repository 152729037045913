import { RootState } from '..';

import { Order } from '@config/api/task/interface';

export const getOrdersLogs = (state: RootState): Order[] => {
  return state?.ordersLogsReducer?.logs;
};

export const getHasNextPage = (state: RootState): boolean => {
  return state?.ordersLogsReducer?.hasNextPage;
};

export const getNextPage = (state: RootState): number => {
  return state?.ordersLogsReducer?.nextPage || 1;
};
