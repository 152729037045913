import client from '..';

import {
  GetSupplierListRequestProps,
  GetSupplierListResponse,
  ImpersonateSupplierRequestProps,
  ImpersonateSupplierResponse,
} from './interface';

export async function getSupplierList(
  props: GetSupplierListRequestProps,
): Promise<GetSupplierListResponse> {
  const { searchCriteria } = props;

  const { data } = await client.get(
    `/distributors/suppliers?search_string=${searchCriteria}`,
  );

  return data;
}

export async function impersonateSupplier(
  props: ImpersonateSupplierRequestProps,
): Promise<ImpersonateSupplierResponse> {
  const { supplierId } = props;

  const { data } = await client.post('/distributors/impersonate', {
    supplier: supplierId,
  });

  return data;
}
