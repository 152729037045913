import React, { useState } from 'react';

import { ChipList } from '../..';
import { ChipData } from '../../interface';

import {
  ChipListElementProps,
  UseChipListProps,
  UseChipListReturn,
} from './interface';

export const useChipList = ({
  initialData = [],
  onChipDeleteClicked,
  canDelete,
}: UseChipListProps): UseChipListReturn => {
  const [chips, setChips] = useState<ChipData[]>(initialData);

  const resetInitialState = (newData: ChipData[]) => {
    setChips(newData);
  };

  const addNewItem = React.useCallback(
    (newChip: ChipData) => {
      const itemAlreadyExists = !!chips.find((chip) => chip.id === newChip.id);
      if (itemAlreadyExists) return;

      setChips((currentChips) => [...currentChips, newChip]);
    },
    [chips],
  );

  const deleteItem = React.useCallback(
    (chipToDeleteKey: string | number) => {
      const itemFound = chips.find((chip) => chip.id === chipToDeleteKey);
      if (!itemFound) return;

      setChips((currentChips) =>
        currentChips.filter((chip) => chip.id !== chipToDeleteKey),
      );
    },
    [chips],
  );

  const handleDeleteItem = React.useCallback(
    (chipToDeleteKey: string | number) => {
      if (!canDelete) return;
      setChips((currentChips) =>
        currentChips.filter((chip) => chip.id !== chipToDeleteKey),
      );
      onChipDeleteClicked?.(chipToDeleteKey);
    },
    [onChipDeleteClicked, canDelete],
  );

  const getAllChips = React.useCallback(() => chips, [chips]);

  const clearChips = () => setChips([]);

  return {
    addNewItem,
    getAllChips,
    deleteItem,
    clearChips,
    resetInitialState,
    ChipListElement: ({ 'data-testid': dataTestId }: ChipListElementProps) => (
      <ChipList
        data={chips}
        onDeleteItem={handleDeleteItem}
        data-testid={dataTestId}
      />
    ),
  };
};
