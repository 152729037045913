import {
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react';

import Button from '../../../../../../components/Button';
import { useSnackbar } from '../../../../../../components/Snackbar/hooks';

import { ConfirmModalProps } from './interface';

import { useAppDispatch } from '@hooks/state';
import { confirmCustomerRequest } from '@state/customer/actions';

import './styles.scss';
export const ConfirmModal = ({
  customerName,
  userId,
  imgSrc,
}: ConfirmModalProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [customerTier, setCustomerTier] = useState('');

  const { showMessage } = useSnackbar();
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setValue('');
    setHelperText('');
    setError(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setHelperText('');
    setError(false);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!value) {
      setHelperText('Please select one!');
      setError(true);

      return;
    }

    const payload = {
      cash_buyer: value === 'cash_buyer',
      customer_tier: customerTier,
    };

    dispatch(
      confirmCustomerRequest({
        userId,
        payload,
        errorCallback: () => {
          showMessage('There was an error confirming the customer', {
            severity: 'error',
          });
        },
        successCallback: () => {
          showMessage('The customer has been confirmed successfully', {
            severity: 'success',
          });
        },
      }),
    );

    handleClose();
  };

  const changeInputHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCustomerTier(event.target.value);
  };

  return (
    <>
      <Button variant='contained' onClick={handleClickOpen}>
        Confirm
      </Button>
      <Dialog
        className='bl-confirm-modal-dialog-container'
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div className='bl-confirm-modal-avatar-container'>
            <Avatar src={imgSrc}></Avatar>
          </div>

          <DialogContentText
            className='bl-confirm-modal-dialog-text'
            variant='subtitle1'
          >
            What kind of customer is {customerName}?
          </DialogContentText>

          <form onSubmit={handleSubmit}>
            <FormControl
              className='bl-confirm-modal-form-control-container'
              error={error}
            >
              <RadioGroup
                id='cash_buyer_radio_group'
                className='bl-confirm-modal-radio-group-container'
                row
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  className='bl-confirm-modal-form-control-label'
                  control={<Radio />}
                  label='Account Buyer'
                  labelPlacement='start'
                  value='account_buyer'
                />

                <FormControlLabel
                  className='bl-confirm-modal-form-control-label'
                  control={<Radio />}
                  label='Cash Buyer'
                  labelPlacement='start'
                  value='cash_buyer'
                />
              </RadioGroup>
              <FormHelperText className='bl-confirm-modal-helper-text'>
                {helperText}
              </FormHelperText>
            </FormControl>

            <InputLabel>Customer Tag (optional)</InputLabel>
            <Input
              className='bl-confirm-modal-input'
              fullWidth
              disableUnderline
              placeholder='i.e.: Preferred, New, Gold'
              onChange={changeInputHandler}
            />
            <Grid container className='bl-confirm-modal-buttons-container'>
              <Grid item className='bl-confirm-modal-cancel-button-container'>
                <Button variant='outlined' onClick={handleClose}>
                  Leave for Now
                </Button>
              </Grid>
              <Grid item>
                <Button type='submit' variant='contained'>
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
