import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@hooks/state';
import { getBluonIframeUrl } from '@state/auth/actions';
import { getIframeData } from '@state/auth/selectors';

import './styles.scss';

export const BluonIframeComponent = ({
  height,
}: {
  height?: string | number;
}): JSX.Element => {
  const dispatch = useDispatch();

  const { iframeData } = useAppSelector((state) => ({
    iframeData: getIframeData(state),
  }));

  useEffect(() => {
    if (!iframeData || !iframeData.value)
      dispatch(getBluonIframeUrl('search-by-part-iframe-url'));
  }, [dispatch]);

  if (!iframeData.value) return <></>;

  return (
    <div className='iframe-container' style={{ height }}>
      <iframe
        src={iframeData.value}
        frameBorder='0'
        scrolling='yes'
        width='100%'
        height='100%'
      />
    </div>
  );
};
