import * as Yup from 'yup';

import {
  FILE_TYPES,
  MAX_SIZE,
} from '../../../../../components/FileUploaderArea';
import { StoreInfoFormKeys } from '../interfaces';

export const StoreInfoValidationSchema = Yup.object<
  Record<keyof StoreInfoFormKeys, Yup.AnySchema>
>({
  name: Yup.string().required('Store name is required'),
  address: Yup.string().required('Store Address is required'),
  branch: Yup.string()
    .notRequired()
    .test('branch', 'Branch Number is invalid', (value) => {
      if (value) {
        const schema = Yup.string().max(
          8,
          'Branch Number cannot have more than 8 digits',
        );

        return schema.isValidSync(value);
      }

      return true;
    }),
  address_2: Yup.string().notRequired(),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      'image',
      'File size is too large',
      (value: File) => !value || (value && value.size <= MAX_SIZE),
    )
    .test(
      'image',
      'Unsupported file type',
      (value: File) =>
        !value ||
        (value &&
          FILE_TYPES.some((item) => value.type.toLowerCase().includes(item))),
    ),
  logo: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      'image',
      'File size is too large',
      (value: File) => !value || (value && value.size <= MAX_SIZE),
    )
    .test(
      'image',
      'Unsupported file type',
      (value: File) =>
        !value ||
        (value &&
          FILE_TYPES.some((item) => value.type.toLowerCase().includes(item))),
    ),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  zip_code: Yup.string()
    .ensure()
    .when('country', {
      is: 'US',
      then: Yup.string().required('Zip code is required'),
    }),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phone: Yup.string()
    .min(7, 'Phone number needs to have at least 7 digits')
    .max(15, 'Phone number cannot have more than 15 digits')
    .required('Phone number is required'),
  prokeep_phone: Yup.string()
    .notRequired()
    .test('prokeep_phone', 'Phone number is invalid', (value) => {
      if (value) {
        const schema = Yup.string()
          .min(7, 'Phone number needs to have at least 7 digits')
          .max(15, 'Phone number cannot have more than 15 digits');

        return schema.isValidSync(value);
      }

      return true;
    }),
  timezone: Yup.string().required('Branch Time Zone is required'),
  offers_delivery: Yup.number().required('Delivery is required'),
  contact_email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  contact_secondary_email: Yup.string()
    .notRequired()
    .email('Invalid email format'),
  contact_phone: Yup.string()
    .min(7, 'Phone number needs to have at least 7 digits')
    .max(15, 'Phone number cannot have more than 15 digits')
    .required('Phone number is required'),
  manager_name: Yup.string().required('Branch manager name is required'),
  manager_email: Yup.string()

    .notRequired()
    .email('Invalid email format')
    .required('Email is required'),
  manager_phone: Yup.string()
    .min(7, 'Phone number needs to have at least 7 digits')
    .max(15, 'Phone number cannot have more than 15 digits')
    .required('Phone number is required'),
  accountant_name: Yup.string().required('Accountant name is required'),
  accountant_email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  accountant_phone: Yup.string()
    .min(7, 'Phone number needs to have at least 7 digits')
    .max(15, 'Phone number cannot have more than 15 digits')
    .required('Phone number is required'),
  counter_staff: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .max(20, 'Maximum 20 characters.')
          .test(
            'Test first counter staff name has value',
            'Counter staff name is required',
            function (currentValue) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const { options } = this as any;
              if (options.index === 0) return !!currentValue;

              return true;
            },
          ),
        email: Yup.string().notRequired().email('Invalid email format'),
        phone: Yup.string().notRequired(),
        sms_notification: Yup.boolean().notRequired(),
        email_notification: Yup.boolean().notRequired(),
      }),
    ),
  branch_hours: Yup.array()
    .required('Branch hours selection is required')
    .defined(),
  take_rate: Yup.string().notRequired(),
  take_rate_until: Yup.string().notRequired(),
});
