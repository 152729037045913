import './index.scss';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { PubNubProvider } from 'pubnub-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import packageJson from '../package.json';

import App from './App';
import { SnackbarProvider } from './components/Snackbar/hooks';
import { BluonTheme } from './config/mui/MuiTheme';
import { PubNubUtils } from './config/pubnub';
import reportWebVitals from './reportWebVitals';
import store from './state';

const theme = unstable_createMuiStrictModeTheme(BluonTheme);
const pubnub = PubNubUtils.getInstance().getPubNubInstance();
const environment = process ? process.env : null;

if (environment?.REACT_APP_ENV_NAME !== 'develop')
  Sentry.init({
    dsn: environment?.REACT_APP_SENTRY_DSN,
    environment: environment?.REACT_APP_ENV_NAME,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: packageJson.version,
  });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <SnackbarProvider>
            <Sentry.ErrorBoundary>
              <PubNubProvider client={pubnub}>
                <App />
              </PubNubProvider>
            </Sentry.ErrorBoundary>
          </SnackbarProvider>
        </HashRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
