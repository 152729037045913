import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Badge, IconButton, Tab, Tabs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SyntheticEvent, useEffect, useState } from 'react';

import { CircularLoading } from '../CircularLoading';
import { ImageExpander } from '../ImageExpander';
import { SomethingWrong } from '../SomethingWrong';
import { TabPanel } from '../TabPanel';

import { ManualTab } from './components/ManualTab';
import { ModelDetailPartsTab } from './components/ModelDetailPartsTab';
import { TechSpecTab } from './components/TechSpecTab';
import { IModelDetailProps } from './interface';

import { useAppDispatch, useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { queryParamKeys } from '@pages/BluonSearch/interface';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  modelPartRequest,
  modelSpecificationsRequest,
  partsResetRequest,
} from '@state/search/actions';
import {
  getManualCount,
  getModelSpecificationsData,
  getPartsTotal,
} from '@state/search/selectors';
import './styles.scss';

export const ModelDetail = ({
  selectedSerie,
  modelId,
}: IModelDetailProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [queryParams] = useQueryParams<queryParamKeys>();

  //  Page variable used for parts model on request model specifications effect
  const [page] = useState<number>(queryParams?.partPage ?? 1);
  const [value, setValue] = useState(0);
  const [expandPartImage, setExpandPartImage] = useState<boolean>(false);

  const setProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    numberOfParts,
    modelSpecifications,
    manualCount,
    isLoadingModelSpecifications,
    modelSpecificationsRequestError,
  } = useAppSelector((state) => ({
    numberOfParts: getPartsTotal(state),
    modelSpecifications: getModelSpecificationsData(state),
    manualCount: getManualCount(state),
    isLoadingModelSpecifications: isRequestRunning(
      state,
      String(modelSpecificationsRequest),
    ),
    modelSpecificationsRequestError: getRequestError(
      state,
      String(modelSpecificationsRequest),
    ),
  }));

  useEffect(() => {
    dispatch(modelSpecificationsRequest({ page, modelId }));
  }, [dispatch, modelId]);

  //  Watching value changes to refresh parts list if needed
  useEffect(() => {
    if (value !== 0 || !modelSpecifications) return;
    dispatch(partsResetRequest());
    dispatch(
      modelPartRequest({
        page: queryParams?.partPage || 1,
        modelId,
      }),
    );
  }, [value]);

  const totalLengthOfParts = numberOfParts ? numberOfParts : 0;

  useEffect(() => {
    if (numberOfParts === 0) setValue(2);
  }, [numberOfParts]);

  return (
    <Box
      data-testid='modelDetailContainer'
      className='bl-model-detail-container'
    >
      {isLoadingModelSpecifications && (
        <Box className='loading-container' data-testid='loadingContainer'>
          <CircularLoading />
        </Box>
      )}
      {!isLoadingModelSpecifications && modelSpecificationsRequestError && (
        <SomethingWrong
          data-testid='somethingWrongModelDetailsContainer'
          height='calc(100vh - 180px)'
          onReload={() => dispatch(modelSpecificationsRequest({ modelId }))}
        />
      )}
      {!isLoadingModelSpecifications && !modelSpecificationsRequestError && (
        <Box>
          <Box className='model-info-cointener'>
            <Box className='serie-data'>
              <Box className='serie-image'>
                <Box display='flex'>
                  <img
                    alt={selectedSerie?.name}
                    src={selectedSerie?.image?.url}
                    height='75%'
                  />

                  <IconButton onClick={() => setExpandPartImage(true)}>
                    <Badge
                      color='secondary'
                      overlap='circular'
                      className='custom-badge'
                      badgeContent={<ZoomInIcon />}
                    />
                  </IconButton>
                </Box>
                <Typography variant='caption'>
                  Model appearance often varies
                </Typography>
              </Box>

              <Box>
                <Typography variant='body1' color='initial'>
                  {modelSpecifications?.model}
                </Typography>
                <Typography variant='body2' color='initial'>
                  {`${
                    modelSpecifications?.model_notes
                      ? modelSpecifications?.model_notes
                      : ''
                  }`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box data-testid='modelTabsContainer'>
            <Box>
              <Grid container alignItems='center' className='bl-tabs-container'>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    className='bl-tabs'
                  >
                    <Tab
                      disabled={totalLengthOfParts === 0}
                      label='PARTS'
                      className='bl-tab-parts'
                      icon={
                        totalLengthOfParts === 0 ? (
                          <></>
                        ) : (
                          <div className='bl-tab-icon'>
                            {totalLengthOfParts > 99
                              ? '99+'
                              : totalLengthOfParts}
                          </div>
                        )
                      }
                      {...setProps(0)}
                    />
                    <Tab
                      label='MANUALS'
                      disabled={manualCount === 0}
                      icon={<div className='bl-tab-icon'>{manualCount}</div>}
                      data-testid='manualsTab'
                      {...setProps(1)}
                    />
                    <Tab
                      label='TECH SPECS'
                      {...setProps(2)}
                      data-testid='specsTab'
                    />
                  </Tabs>
                </Grid>
              </Grid>
            </Box>
            <Box className='bl-tab-content-container'>
              <TabPanel value={value} index={0}>
                <ModelDetailPartsTab modelId={modelId} />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <ManualTab manuals={modelSpecifications?.manuals} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TechSpecTab specifications={modelSpecifications} />
              </TabPanel>
            </Box>
          </Box>
          <ImageExpander
            setIsOpen={setExpandPartImage}
            isOpen={expandPartImage}
            fileLink={selectedSerie?.image?.url}
            overlayClass='search-overlay'
          />
        </Box>
      )}
    </Box>
  );
};
