import classNames from 'classnames';

import { LabelProps } from './interface';

export const Label = ({ label, color }: LabelProps): JSX.Element => {
  return (
    <div
      className={classNames('w-fit rounded-2xl border px-2 py-1 text-xs', {
        'border-[var(--bluon--default--blue-3)] bg-[var(--bluon--default--blue--gray-8)] text-[var(--bluon--default--blue-3)]':
          color === 'blue',
        'border-[var(--bluon--default--green)] bg-[var(--bluon--light--green-2)] text-[var(--bluon--default--green)]':
          color === 'green',
        'border-[var(--bluon--light--red-2)] bg-[var(--bluon--light--red-3)] text-[var(--bluon--light--red-2)]':
          color === 'red',
        'border-[var(--bluon--brown--dark)] bg-[var(--bluon--brown--light)] text-[var(--bluon--brown--dark)]':
          color === 'brown',
      })}
    >
      {label}
    </div>
  );
};
