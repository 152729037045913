import Box from '@mui/material/Box';

import './styles.scss';
import EngineManualIcon from '@assets/icons/engine-manual.svg';
import PulleyIcon from '@assets/icons/pulleyIcon.svg';

export const MANUAL_AND_PARTS_INDICATOR_TEST_ID = 'manualAndPartsIndicator';

export const ManualAndPartsIndicator = ({
  item,
}: {
  item: {
    manuals_count: number | null;
    functional_parts_count: number | null;
  };
}) => (
  <Box
    data-testid={MANUAL_AND_PARTS_INDICATOR_TEST_ID}
    className='manual-and-part-indicator'
  >
    {!!item.manuals_count && <img src={EngineManualIcon} />}
    {!!item.functional_parts_count && <img src={PulleyIcon} />}
  </Box>
);
