import AddIcon from '@mui/icons-material/Add';
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useMemo, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import Button from '../../../../../../components/Button';
import TextInput from '../../../../../../components/TextInput';

import './styles.scss';
import { ToggleInput } from './components/ToggleInput';
import { CounterStaffInputProps, CounterStaffInputsProps } from './interface';

import { FormTextInputLengths } from '@utils/FormInputLengths';

const MaxStaff = 20;

const CounterStaffInput = ({
  index,
  control,
  isLoading,
  clearErrors,
  setValue,
  watch,
}: CounterStaffInputProps) => {
  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} md={9}>
        <Controller
          name={`counter_staff.${index}.name`}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextInput
                id={`counter_staff.${index}.name`}
                data-testid='counterStaffName'
                label='Name'
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                maxLength={20}
                error={
                  !!(
                    fieldState?.error?.ref?.name ===
                    `counter_staff.${index}.name`
                  )
                }
                errorMessage={fieldState?.error?.message}
                isLoading={isLoading}
              />
            );
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        flexDirection='row'
        className='grid-container-phone-label-toggle'
      >
        <Box className='container-phone-input-labels'>
          <Typography className='phone-input-label' variant='caption'>
            Email
          </Typography>
          <Typography className='phone-input-sublabel' variant='caption'>
            {'(optional)'}
          </Typography>
        </Box>
        <Controller
          name={`counter_staff.${index}.email_notification`}
          control={control}
          render={({ field }) => (
            <ToggleInput
              title='Receive Email Notifications'
              tooltipMessage='Enable this email to receive all account/order notifications'
              id={`counter_staff.${index}.email_notification`}
              value={field.value}
              onChange={field.onChange}
              disabled={!watch(`counter_staff.${index}.email`)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name={`counter_staff.${index}.email`}
          control={control}
          render={({ field, fieldState }) => {
            if (
              !field.value &&
              watch(`counter_staff.${index}.email_notification`)
            )
              setValue(`counter_staff.${index}.email_notification`, false);

            return (
              <TextInput
                id={`counter_staff.${index}.email`}
                data-testid='counterStaffEmail'
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                error={
                  !!(
                    fieldState?.error?.ref?.name ===
                    `counter_staff.${index}.email`
                  )
                }
                maxLength={FormTextInputLengths.profile.email}
                errorMessage={fieldState?.error?.message}
                isLoading={isLoading}
              />
            );
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        flexDirection='row'
        className='grid-container-phone-label-toggle'
      >
        <Box className='container-phone-input-labels'>
          <Typography className='phone-input-label' variant='caption'>
            Phone Number
          </Typography>
          <Typography className='phone-input-sublabel' variant='caption'>
            {'(optional)'}
          </Typography>
        </Box>
        <Controller
          name={`counter_staff.${index}.sms_notification`}
          control={control}
          render={({ field }) => (
            <ToggleInput
              title='Receive SMS Notifications'
              tooltipMessage='Enable this number to receive all account/order notifications'
              id={`counter_staff.${index}.sms_notification`}
              value={field.value}
              onChange={field.onChange}
              disabled={!watch(`counter_staff.${index}.phone`)}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        flexDirection='row'
        className='grid-container-phone-input'
      >
        <Controller
          name={`counter_staff.${index}.phone`}
          control={control}
          render={({ field, fieldState }) => {
            const hasError =
              fieldState?.error?.ref?.name === `counter_staff.${index}.phone`;
            if (!field.value)
              setValue(`counter_staff.${index}.sms_notification`, false);

            return (
              <TextInput
                id={`counter_staff.${index}.phone`}
                className='phone-input'
                data-testid='counterStaffPhone'
                name={field.name}
                onBlur={field.onBlur}
                onChange={(e) => {
                  if (hasError) clearErrors(`counter_staff.${index}.phone`);
                  field.onChange(e.target.value);
                }}
                value={field.value}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                error={!!hasError}
                maxLength={FormTextInputLengths.profile.phoneFullNumber}
                errorMessage={
                  fieldState?.error?.message &&
                  fieldState?.error?.message.replace(
                    `counter_staff.${index}.phone`,
                    'field',
                  )
                }
                isLoading={isLoading}
              />
            );
          }}
        />
      </Grid>
      <div className='counter-staff-divider' />
    </Grid>
  );
};

const CounterStaffInputs = ({
  control,
  clearErrors,
  getValues,
  isLoading,
  setValue,
  watch,
}: CounterStaffInputsProps): JSX.Element => {
  const { fields, append: appendCounterStaff } = useFieldArray({
    control,
    name: 'counter_staff',
  });
  const [staffCount, setStaffCount] = useState(
    getValues().counter_staff.length,
  );

  const handleAddClick = () => {
    setStaffCount((curStaffCount) => curStaffCount + 1);
    appendCounterStaff({
      name: '',
      email: '',
      phone: '',
      sms_notification: false,
      email_notification: false,
    });
  };

  const ableToAddMore = useMemo(() => MaxStaff > staffCount, [staffCount]);

  return (
    <Grid container justifyContent='center'>
      {fields.map((x, i) => (
        <CounterStaffInput
          isLoading={isLoading}
          key={x.id}
          index={i}
          control={control}
          clearErrors={clearErrors}
          setValue={setValue}
          watch={watch}
        />
      ))}
      {ableToAddMore && (
        <Grid item xs={12}>
          <Stack spacing={3} direction='row' justifyContent='center'>
            <Button
              variant='contained'
              color='primary'
              data-testid='counterStaffAddContactButton'
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              Add other contact
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default CounterStaffInputs;
