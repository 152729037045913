import { RootState } from '..';

import {
  ModelSpecificationsDataModel,
  ModelsDataModel,
  PartDetailsDataModel,
  PartReplacementsDataModel,
  PartsDataModel,
  SearchModelsDataModel,
  SeriesDataModel,
} from '@config/api/search/interface';

export function getSeriesData(state: RootState): SeriesDataModel[] {
  return state.searchReducer.series.data;
}

export function getHasNextPageSeries(state: RootState): boolean {
  return state.searchReducer.series.hasNextPage;
}

export function getModelsData(state: RootState): ModelsDataModel[] {
  return state.searchReducer.models.data;
}

export function getHasNextPageModels(state: RootState): boolean {
  return state.searchReducer.models.hasNextPage;
}

export function getModelSpecificationsData(
  state: RootState,
): ModelSpecificationsDataModel | null {
  return state.searchReducer.modelSpecifications.data;
}

export function getManualCount(state: RootState): number {
  return state.searchReducer.modelSpecifications.manualCount;
}

export function getPartsData(state: RootState): PartsDataModel[] {
  return state.searchReducer.parts.data;
}

export function getPartsTotal(state: RootState): number | null {
  return state.searchReducer.parts.total;
}

export function getHasNextPageParts(state: RootState): boolean {
  return state.searchReducer.parts.hasNextPage;
}

export function getPartDetails(state: RootState): PartDetailsDataModel | null {
  return state.searchReducer.parts.partDetails;
}

export function getPartReplacementeDetails(
  state: RootState,
): PartDetailsDataModel | null {
  return state.searchReducer.parts.replacements.partReplacementDetails;
}

export function getPartReplacements(
  state: RootState,
): PartReplacementsDataModel[] {
  return state.searchReducer.parts.replacements.data;
}

export function getHasNextPagePartReplacements(state: RootState): boolean {
  return state.searchReducer.parts.replacements.hasNextPage;
}

export function getSearchModelsData(state: RootState): SearchModelsDataModel[] {
  return state.searchReducer.searchModel.data;
}

export function getHasNextPageSearchModels(state: RootState): boolean {
  return state.searchReducer.searchModel.hasNextPage;
}

export function getModelsResultTotal(state: RootState): number | null {
  return state.searchReducer.searchModel.total;
}

export function getModelsSearchTerm(state: RootState): string {
  return state.searchReducer.searchModel.searchTerm || '';
}
