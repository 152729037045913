import CloseIcon from '@mui/icons-material/Close';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
} from '@mui/material';

import { ModalProps } from './interface';

export const Modal = (props: ModalProps): JSX.Element => {
  const {
    open,
    title,
    position,
    children,
    handleClose,
    isImpersonatedSupplier,
  } = props;

  return (
    <div>
      <Drawer
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        anchor={position}
        className={'absolute !h-[100vh]'}
      >
        <div
          className={`absolute left-2 ${
            isImpersonatedSupplier ? 'top-9' : 'top-2'
          } cursor-pointer`}
        >
          <CloseIcon onClick={handleClose} />
        </div>

        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={`${isImpersonatedSupplier && 'mt-6'}`}>
          {children}
        </DialogContent>
        <DialogActions></DialogActions>
      </Drawer>
    </div>
  );
};
