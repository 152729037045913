import { LoginMappedProps } from './interface';

import { IframeResponseData } from '@config/api/auth/interface';
import { getLoginInfo } from '@utils/Auth/AuthUtils';

// eslint-disable-next-line
export function getLoginInfoData(state: any): LoginMappedProps | undefined {
  return state.auth.loginResponse;
}

// eslint-disable-next-line
export function getLoginInfoDataInitialPass(state: any): string | null {
  return state.auth.loginResponse.initial_password_set_at;
}

// eslint-disable-next-line
export function getChangePasswordInfoData(state: any): string {
  return state.auth.changePasswordResponse;
}

// eslint-disable-next-line
export function getShowTermsInfoData(state: any): boolean {
  return state.auth.showTerms;
}

// eslint-disable-next-line
export function getReadTermsInfoData(state: any): boolean {
  return state.auth.readTerms;
}

// eslint-disable-next-line
export function getIframeData(state: any): IframeResponseData {
  return state.auth.iframeData;
}

// eslint-disable-next-line
export function getIsManager(state: any): boolean | undefined {
  return state.auth.loginResponse.distributor || getLoginInfo().distributor;
}

// eslint-disable-next-line
export function getIsImpersonatedSupplier(): boolean | undefined {
  return !!getLoginInfo().impersonatedSupplier?.id;
}
