import {
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import Button from '../../../../../../components/Button';
import { useSnackbar } from '../../../../../../components/Snackbar/hooks';

import { EditModalProps } from './interface';

import { useAppDispatch } from '@hooks/state';
import { updateCustomerRequest } from '@state/customer/actions';

import './styles.scss';

export const EditModal = ({
  customer: { name, cash_buyer },
  customer_tier,
  userId,
  imgSrc,
}: EditModalProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [customerTier, setCustomerTier] = useState('');
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const { showMessage } = useSnackbar();
  const dispatch = useAppDispatch();

  const getCashBuyer = () => {
    return cash_buyer ? 'cash_buyer' : 'account_buyer';
  };

  useEffect(() => {
    setValue(getCashBuyer());
    setCustomerTier(customer_tier);
  }, [cash_buyer, customer_tier]);

  const handleClickOpen = () => {
    setHelperText('');
    setError(false);
    setOpen(true);
  };

  const handleCloseCancel = () => {
    setValue(getCashBuyer());
    setCustomerTier(customer_tier);
    setOpen(false);
  };

  const handleCloseError = () => {
    setValue(getCashBuyer());
    setCustomerTier(customer_tier);
    setOpen(false);
  };

  const handleCloseSuccess = () => {
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setHelperText('');
    setError(false);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!value) {
      setHelperText('Please select one!');
      setError(true);

      return;
    }

    const payload = {
      cash_buyer: value === 'cash_buyer',
      customer_tier: customerTier,
    };

    dispatch(
      updateCustomerRequest({
        userId,
        payload,
        errorCallback: () => {
          showMessage('There was an error confirming the customer', {
            severity: 'error',
          });
          handleCloseError();
        },
        successCallback: () => {
          showMessage('The customer has been confirmed successfully', {
            severity: 'success',
          });
          handleCloseSuccess();
        },
      }),
    );
  };

  const changeInputHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCustomerTier(e.target.value);
  };

  return (
    <>
      <Button
        className='bl-edit-modal-button'
        variant='outlined'
        onClick={handleClickOpen}
      >
        Edit
      </Button>
      <Dialog
        className='bl-edit-modal-dialog-container'
        open={open}
        onClose={handleCloseCancel}
      >
        <DialogContent>
          <div className='bl-edit-modal-avatar-container'>
            <Avatar src={imgSrc} />
          </div>

          <DialogContentText
            className='bl-edit-modal-dialog-text'
            variant='subtitle1'
          >
            What kind of customer is {name}?
          </DialogContentText>

          <form onSubmit={handleSubmit}>
            <FormControl
              className='bl-edit-modal-form-control-container'
              error={error}
            >
              <RadioGroup
                className='bl-edit-modal-radio-group-container'
                row
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  className='bl-edit-modal-form-control-label'
                  control={<Radio />}
                  label='Account Buyer'
                  labelPlacement='start'
                  value='account_buyer'
                />
                <FormControlLabel
                  className='bl-edit-modal-form-control-label'
                  control={<Radio />}
                  label='Cash Buyer'
                  labelPlacement='start'
                  value='cash_buyer'
                />
              </RadioGroup>
              <FormHelperText className='bl-edit-modal-helper-text'>
                {helperText}
              </FormHelperText>
            </FormControl>

            <InputLabel>Customer Tag (optional)</InputLabel>
            <Input
              className='bl-edit-modal-input'
              fullWidth
              disableUnderline
              placeholder='i.e.: Preferred, New, Gold'
              defaultValue={customerTier}
              onChange={changeInputHandler}
            />
            <Grid container className='bl-edit-modal-buttons-container'>
              <Grid item className='bl-edit-modal-cancel-button-container'>
                <Button variant='outlined' onClick={handleCloseCancel}>
                  Leave for Now
                </Button>
              </Grid>
              <Grid item>
                <Button type='submit' variant='contained'>
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
