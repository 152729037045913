import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { LogsFilterFormData } from './components/LogsFilter/interface';
import LogsFilter from './components/LogsFilter/LogsFilter';
import LogsPageFooter from './components/LogsPageFooter';
import LogsTable from './components/LogsTable';

import { ClaimingModal } from '@components/ClaimingModal';
import { GetOrdersProps } from '@config/api/task/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import {
  clearOrdersLogsState,
  moreOrdersLogsRequest,
  ordersLogsRequest,
} from '@state/ordersLogs/actions';
import { getHasNextPage, getOrdersLogs } from '@state/ordersLogs/selectors';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import './styles.scss';
import { setSelectedClaimingModal } from '@state/task/actions';
import { getSelectedClaimingModal } from '@state/task/selectors';
const OrdersLogs = () => {
  const dispatch = useAppDispatch();
  const { changeSelectedOrder } = useSetSelectedOrder();

  const [filterPayload, setFilterPayload] = useState<
    GetOrdersProps | undefined
  >(undefined);
  //  Selectors
  const { areLogsLoading, logs, hasNextPage, hasError, claimingModal } =
    useAppSelector((state) => ({
      logs: getOrdersLogs(state),
      hasNextPage: getHasNextPage(state),
      areLogsLoading:
        isRequestRunning(state, String(ordersLogsRequest)) ||
        isRequestRunning(state, String(moreOrdersLogsRequest)),
      hasError:
        getRequestError(state, String(ordersLogsRequest)) ||
        getRequestError(state, String(moreOrdersLogsRequest)),
      claimingModal: getSelectedClaimingModal(state as any),
    }));
  useEffect(() => {
    if (filterPayload) {
      dispatch(ordersLogsRequest(filterPayload));

      return () => {
        dispatch(clearOrdersLogsState());
      };
    }
  }, [filterPayload]);

  const requestMoreLogs = () => {
    dispatch(moreOrdersLogsRequest(filterPayload));
  };

  const handleSubmitLogFilter = (data: LogsFilterFormData) => {
    const payload: GetOrdersProps = {
      page: 1,
    };

    if (data.fromDate)
      payload.startDate = dayjs(data.fromDate).format('YYYY-MM-DD');

    if (data.toDate) payload.endDate = dayjs(data.toDate).format('YYYY-MM-DD');

    if (data.status !== 'all') payload.status = data.status;

    setFilterPayload(payload);
  };

  return (
    <div className='orders-logs-container flex w-full flex-col bg-[var(--bluon--ds--lightgray)]'>
      {claimingModal?.companyName && claimingModal?.order.id && (
        <ClaimingModal
          visible
          orderId={claimingModal.order.id}
          onClose={() => dispatch(setSelectedClaimingModal(null))}
          contractorName={claimingModal.companyName}
          techName={claimingModal.techName}
          onSuccessClaiming={() => {
            changeSelectedOrder(claimingModal.order.id, claimingModal.order);
            dispatch(setSelectedClaimingModal(null));
            dispatch(ordersLogsRequest());
          }}
        />
      )}
      <div className='flex h-[83%] flex-col  px-20 pt-4'>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} sm={4}>
              <span className='pl-4 text-4xl font-normal text-black text-opacity-90'>
                {' '}
                Task & Order Log
              </span>
            </Grid>
            <Grid item xs={12} md={7} sm={8}>
              <LogsFilter onSubmitLogFilter={handleSubmitLogFilter} />
            </Grid>
          </Grid>
        </div>
        <div className='mt-4 flex h-[88%] flex-col items-start bg-[var(--bluon--default--white)]'>
          <LogsTable
            areLogsLoading={areLogsLoading}
            hasError={hasError}
            hasNextPage={hasNextPage}
            logs={logs}
            requestMore={requestMoreLogs}
          />
        </div>
      </div>
      <LogsPageFooter />
    </div>
  );
};

export default OrdersLogs;
