import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import './styles.scss';
import { ImageExpanderProps } from './interface';

export const ImageExpander = (props: ImageExpanderProps): JSX.Element => {
  const { fileLink, isOpen, setIsOpen, overlayClass } = props;

  const handleSetExpandedImage = (open: boolean) => {
    const overlay = document.getElementById(overlayClass) as HTMLElement;
    if (overlay)
      overlay.setAttribute('style', `display: ${open ? 'block' : 'none'}`);
    setIsOpen(open);
  };

  useEffect(() => {
    if (isOpen) handleSetExpandedImage(isOpen);
  }, [isOpen]);

  if (!isOpen) return <></>;

  return (
    <FullImg {...{ overlayClass }}>
      <Box className='msg-container-full-img'>
        <div className='alignment'>
          <img className='full-img-file' src={fileLink} />
          <div
            onClick={() => handleSetExpandedImage(false)}
            className='close-container'
          >
            <CloseIcon color='primary' className='close-icon' />
          </div>
        </div>
      </Box>
    </FullImg>
  );
};

const FullImg = ({
  overlayClass,
  children,
}: {
  overlayClass: string;
  children: React.ReactNode;
}) =>
  ReactDOM.createPortal(
    children,
    document.getElementById(overlayClass) as HTMLElement,
  );
