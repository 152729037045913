import { createAction } from 'redux-actions';

export const ordersLogsRequest = createAction(
  'orders-logs/orders-logs-request',
);
export const ordersLogsResponse = createAction(
  'orders-logs/orders-logs-response',
);
export const moreOrdersLogsRequest = createAction(
  'orders-logs/more-orders-logs-request',
);
export const moreOrdersLogsResponse = createAction(
  'orders-logs/more-orders-logs-response',
);
export const clearOrdersLogsState = createAction(
  'orders-logs/clear-orders-logs-state',
);
export const returnOrderRequest = createAction(
  'orders-logs/return-order-request',
);
