import { yupResolver } from '@hookform/resolvers/yup';
import SmsIcon from '@mui/icons-material/Sms';
import { Box, Grid, Typography } from '@mui/material';
import gPhoneNumber from 'google-libphonenumber';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './styles.scss';
import Button from '../../../components/Button';
import { Dropdown } from '../../../components/Dropdown';
import { NumberFormat } from '../../../components/NumberFormat';
import { CountryCodeAndFlagsOptions } from '../../../data/countryCodeAndFlags';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { ContractorLoginFormKeys } from './interfaces';
import { ContractorLoginValidationSchema } from './validations';

import { useAppSelector } from '@hooks/state';
import { sendSMSRequest } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';

export const ContractorLogin = (): JSX.Element => {
  // Const Declarations
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues: ContractorLoginFormKeys = {
    countryCode: 'US',
    phoneNumber: '',
  };

  // Hooks
  const { sendSMSResponseErrors, loading } = useAppSelector((state) => ({
    loading: isRequestRunning(state, String(sendSMSRequest)),
    sendSMSResponseErrors: getRequestError(state, String(sendSMSRequest)),
  }));

  useEffect(() => {
    if (sendSMSResponseErrors && sendSMSResponseErrors.response.status == 404)
      setError('phoneNumber', {
        type: 'Invalid',
        message: 'Phone number not found',
      });

    if (sendSMSResponseErrors && sendSMSResponseErrors.response.status == 422)
      setError('phoneNumber', {
        type: 'Invalid',
        message: 'Invalid phone number',
      });
  }, [sendSMSResponseErrors]);

  const { control, setError, handleSubmit } = useForm<ContractorLoginFormKeys>({
    resolver: yupResolver(ContractorLoginValidationSchema),
    defaultValues: initialValues,
    reValidateMode: 'onSubmit',
  });

  // Methods/Functions
  const createPhoneNumberFormat = (): string => {
    return '### ### ####';
  };

  const parsePhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replaceAll(' ', '');
  };

  const handleEmailLoginClick = () => navigate('/login');

  const handleSubmitForm = (values: ContractorLoginFormKeys) => {
    const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
    const country_code = phoneUtil.getCountryCodeForRegion(values.countryCode);
    const phone = parsePhoneNumber(values.phoneNumber);

    dispatch(
      sendSMSRequest({
        country_code,
        phone,
      }),
    );
  };

  return (
    <WelcomeContainer>
      <Box className='bl-contractor-login-container'>
        <Grid container className='bl-contractor-login-header-container'>
          <Grid item xs={5}>
            <AuthFormHeader
              title='Log in to Bluon'
              subTitle='Please enter your registered mobile number to log in'
            />
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container className='bl-contractor-login-inputs-container'>
            <Grid item container xs={5}>
              <Grid item xs={4}>
                <Controller
                  name='countryCode'
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <Dropdown
                      id='countryCode'
                      name='countryCode'
                      label='Country'
                      selected={field.value}
                      value={field.value}
                      options={CountryCodeAndFlagsOptions}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      errorMessage={formState.errors.countryCode?.message}
                      data-testid='countryCodeLoginSMS'
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={8}
                className='bl-contractor-login-phone-number-container'
              >
                <Controller
                  name='phoneNumber'
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <NumberFormat
                      id='phoneNumber'
                      name='phoneNumber'
                      label='Mobile number'
                      type='tel'
                      format={createPhoneNumberFormat()}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      errorMessage={formState.errors.phoneNumber?.message}
                      data-testid='phoneNumberLoginSMS'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className='bl-contractor-login-send-sms-button-container'
          >
            <Grid item xs={5}>
              <Button
                loading={loading}
                disabled={loading}
                fullWidth
                variant='contained'
                type='submit'
                color='primary'
                startIcon={<SmsIcon />}
                data-testid='sendSMSTextButton'
              >
                Send SMS code to log in
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid container className='bl-contractor-login-text-container'>
          <Grid item xs={5}>
            <Typography variant='body2' color='secondary'>
              You will need to have your mobile phone near you.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className='bl-contractor-login-link-container'>
          <Grid item xs={5}>
            <Button
              variant='text'
              color='primary'
              data-testid='emailLoginTextButton'
              onClick={handleEmailLoginClick}
            >
              Click here to login with email instead
            </Button>
          </Grid>
        </Grid>

        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
