import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';

import './styles.scss';
import Button from '../../../../../components/Button';
import { ManualAndPartsIndicator } from '../../../../../components/ManualAndPartsIndicator';

import { SearchModelsDataModel } from '@config/api/search/interface';

export const SearchModelRow = ({
  brandName,
  brandImg,
  serieName,
  serieImg,
  item,
  onClick,
}: {
  brandName: string;
  brandImg: string;
  serieName: string;
  serieImg: string;
  item: SearchModelsDataModel;
  onClick: () => void;
}) => (
  <Button onClick={onClick} className='row-item-search-models-container'>
    <Box className='row-item-search-models-container-content'>
      <Box className='row-item-search-models-info-section'>
        <Box className='row-item-search-models-brand'>
          <img
            alt={brandName}
            className='item-img-brand'
            loading='lazy'
            src={brandImg}
          />
          <ChevronRightIcon color='primary' className='chevron-icon-info' />
        </Box>
        <Box className='row-item-search-models-series'>
          <img
            alt={serieName}
            className='item-img-series'
            loading='lazy'
            src={serieImg}
          />
          <Typography
            className='models-series-txt'
            color='initial'
            variant='caption'
          >
            {serieName}
          </Typography>
          <ChevronRightIcon color='primary' className='chevron-icon-info' />
        </Box>
        <Box className='row-item-search-models-model'>
          <Typography
            className='models-model-txt'
            color='initial'
            variant='caption'
          >
            {item.model}
          </Typography>
          <Typography
            className='models-model-notes-txt'
            color='initial'
            variant='caption'
          >
            {item.model_notes}
          </Typography>
        </Box>
      </Box>
      <ManualAndPartsIndicator item={item} />
      <ChevronRightIcon color='primary' className='chevron-icon' />
    </Box>
  </Button>
);
