import CheckIcon from '@mui/icons-material/Check';
import { FormControlLabel, Radio } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Button from '@components/Button';
import { useSnackbar } from '@components/Snackbar/hooks';

interface ItemSelectorProps {
  id: string;
  selectedId: string;
  brand: string;
  number: string;
  onSelect: () => void;
  selectPart: (id: string) => void;
}

export const ItemSelector = ({
  id,
  selectedId,
  brand,
  number,
  onSelect,
  selectPart,
}: ItemSelectorProps): JSX.Element => {
  const [isValueCopied, setIsValueCopied] = useState<boolean>(false);
  const { showMessage } = useSnackbar();
  const handleCopy = () => {
    setIsValueCopied(true);
    navigator.clipboard.writeText(number);
    onSelect();
    selectPart(id);
    showMessage(`${number} copied to clipboard`, {
      severity: 'info',
      autoHide: true,
    });
  };

  useEffect(() => {
    setIsValueCopied(selectedId === id);
    if (selectedId === id) handleCopy();
  }, [selectedId]);

  return (
    <div
      className={classNames(
        'flex w-full cursor-pointer items-center justify-between border-b border-gray-200 pl-2',
        {
          'bg-gray-200': id === selectedId,
        },
      )}
      onClick={handleCopy}
    >
      <div className='flex items-center'>
        <div className='ml-3 flex gap-2 '>
          <FormControlLabel
            value={id}
            control={<Radio size='small' />}
            label=''
          />
        </div>
        <div className='text-sm'>
          {brand} - {number}
        </div>
      </div>
      <div>
        {isValueCopied && (
          <div className='mr-4 bg-[#E9FCF0] px-2 py-1 text-xs font-medium text-[var(--bluon--default--green)]'>
            <CheckIcon className='mr-1 !text-sm font-bold' /> COPIED & SELECTED
          </div>
        )}
        {!isValueCopied && (
          <div className='mr-4'>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              className='h-6 !border-[var(--bluon--light--gray-22)]'
            >
              COPY #
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
