import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { SectionTitleProps } from './interface';

export const SectionTitle = ({ title }: SectionTitleProps): JSX.Element => {
  return (
    <Box className='bl-section-title-container-title'>
      <Typography
        className='bl-section-title-txt'
        color='initial'
        variant='caption'
      >
        {title}
      </Typography>
    </Box>
  );
};
