import client from '..';

import { ValidationSettingsDataProps } from './interface';

export async function getValidationSettings(): Promise<ValidationSettingsDataProps> {
  const { data } = await client.get('/settings', {
    params: {
      group: 'validation',
    },
  });

  return data;
}
