import { RootState } from '..';

import { Supplier } from './interface';

export function getSupplierList(state: RootState): Array<Supplier> | undefined {
  return state.managerReducer.supplierList;
}

export function getImpersonatedSupplier(
  state: RootState,
): Supplier | undefined {
  return state.managerReducer.impersonatedSupplier;
}
