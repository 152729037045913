import { ArrowBack } from '@mui/icons-material';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { useLocation } from 'react-router-dom';

import { queryParamKeys } from '../interface';

import { SearchModelRow } from './components/SearchModelRow';
import { IModelsRowItem } from './interface';

import { BluonIframeComponent } from '@components/BluonIframe';
import Button from '@components/Button';
import { CircularLoading } from '@components/CircularLoading';
import { ModelDetail } from '@components/ModelDetail';
import { NotFound } from '@components/NotFound';
import { SearchInput } from '@components/SearchInput';
import { SomethingWrong } from '@components/SomethingWrong';
import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';
import { SearchModelsDataModel } from '@config/api/search/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  previousSearchModelsRequest,
  searchModelsRequest,
  searchMoreModelsRequest,
} from '@state/search/actions';
import {
  getHasNextPageSearchModels,
  getModelsResultTotal,
  getModelsSearchTerm,
  getSearchModelsData,
} from '@state/search/selectors';
import { getSelectedModel } from '@utils/Models';

export const ModelTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [queryParams, setSearchParams] = useQueryParams<queryParamKeys>();

  const { search } = useLocation();

  const [searchValue, setSearchValue] = useState<string>(
    queryParams?.search ?? '',
  );
  const [page, setPage] = useState<number>(queryParams?.modelPage ?? 1);
  const [previousPage, setPreviousPage] = useState<number>(
    queryParams && queryParams.modelPage ? queryParams?.modelPage - 1 : 0,
  );
  const [modelId, setModelId] = useState<string>(queryParams?.modelId ?? '');
  const [selectedModel, setSelectedModel] = useState<
    SearchModelsDataModel | undefined
  >();
  const [needReload, setNeedReload] = useState<boolean>(false);

  //  Function to change page
  const changePage = (newPage = page) => {
    setPage(newPage);
    setPreviousPage(newPage - 1);
  };

  const handleSearch = useCallback(() => {
    setNeedReload(false);

    //  Changing page before making request, to manage prev state
    const newPage = queryParams?.modelPage || 1;
    changePage(newPage);

    //  Making new request
    dispatch(searchModelsRequest({ page: newPage, model: searchValue }));

    //  Changing query params if needed
    searchValue != queryParams.search &&
      setSearchParams({ ...queryParams, search: searchValue }, false);
  }, [searchValue, dispatch]);

  const handlePreviousPage = useCallback(() => {
    dispatch(
      previousSearchModelsRequest({ page: previousPage, model: searchValue }),
    );
    setPreviousPage(previousPage - 1);
  }, [previousPage, searchValue, dispatch]);

  // Function to clear selected model state
  const changeSelectedModel = (newId: string) => {
    const newSelectedModel = getSelectedModel(newId, models);
    setModelId(newId || '');
    setSelectedModel(newSelectedModel);
  };

  useEffect(() => {
    if (searchValue.length >= 3) handleSearch();
    changeSelectedModel('');
  }, [searchValue]);

  const {
    models,
    modelsHasNextPage,
    isLoadingNextPageModels,
    isLoadingModels,
    modelsRequestError,
    totalModels,
    searchTerm,
    isLoadingPreviousModels,
  } = useAppSelector((state) => ({
    models: getSearchModelsData(state),
    totalModels: getModelsResultTotal(state),
    searchTerm: getModelsSearchTerm(state),
    modelsHasNextPage: getHasNextPageSearchModels(state),
    isLoadingNextPageModels: isRequestRunning(
      state,
      String(searchMoreModelsRequest),
    ),
    isLoadingModels: isRequestRunning(state, String(searchModelsRequest)),
    modelsRequestError: getRequestError(state, String(searchModelsRequest)),
    isLoadingPreviousModels: isRequestRunning(
      state,
      String(previousSearchModelsRequest),
    ),
  }));

  const handleSelectModel = (
    item: IModelsRowItem['selectedItem'],
    saveHistory = true,
  ) => {
    setSelectedModel(item);
    if (saveHistory) {
      setNeedReload(false);
      setSearchParams(
        {
          ...queryParams,
          modelId: item.id,
          modelPage: item.page,
        },
        !!queryParams?.modelId,
      );
    }
  };

  useEffect(() => {
    if (search && search.includes('model')) {
      const modelName = search.split('=')[1];

      if (models.length && models.some((model) => model.model === modelName)) {
        const item = models.find((model) => model.model === modelName);
        setSelectedModel(item);
      }
    }
  }, [search, models, setSearchParams]);

  useEffect(() => {
    if (modelId !== queryParams?.modelId)
      changeSelectedModel(queryParams?.modelId || '');

    if (needReload) {
      //  Changing page state before requesting
      const newPage = queryParams?.modelPage || 1;
      changePage(newPage);

      //  Requesting for more models
      dispatch(
        searchModelsRequest({
          page: queryParams.modelPage,
          model: queryParams.search,
        }),
      );
    }

    setNeedReload(true);
  }, [queryParams.modelId]);

  useEffect(() => {
    changeSelectedModel(queryParams?.modelId || '');
  }, [models]);

  //  Watching query params search value to change local state if needed
  useEffect(() => {
    if (queryParams?.search === searchValue) return;
    setSearchValue(queryParams?.search || '');
  }, [queryParams?.search]);

  //  Watching selected model changes to clear routes
  useEffect(() => {
    if (!modelId) {
      const { tab, search } = queryParams;
      if (queryParams?.modelId) setSearchParams({ tab, search }, false);
    }
  }, [modelId]);

  const shouldShowModelsList =
    searchValue &&
    searchValue.length >= 3 &&
    !!models.length &&
    !isLoadingModels;
  const isSearchingForModel = isLoadingModels && searchValue;
  const searchError = modelsRequestError && !isLoadingModels && searchValue;
  const shouldShowNotFoundModels =
    models.length === 0 &&
    searchValue.length >= 3 &&
    !isLoadingModels &&
    !modelsRequestError;

  return (
    <Container maxWidth='xl' className='bl-search-model-tab-container'>
      {!selectedModel && (
        <SearchInput
          containerClassName='bl-search-input-model'
          onChange={setSearchValue}
          value={searchValue}
          placeholder='Search Model #'
        />
      )}

      <Container maxWidth='xl' className='bl-search-model-tab-content'>
        {selectedModel && (
          <Box className='back-to-search-container'>
            <Button
              className='back-to-search-btn'
              onClick={() => changeSelectedModel('')}
            >
              <ArrowBack />
              <Typography
                className='back-search-text'
                color='initial'
                variant='caption'
              >
                Go back to <strong>results</strong>
              </Typography>
            </Button>
          </Box>
        )}
        {searchValue.length < 3 && (
          <>
            <Box className='bl-search-model-default-info'>
              <Typography
                className='bl-search-model-helper-title'
                color='initial'
                key='bl-search-model-title-key'
                variant='caption'
              >
                Start typing your model number. Results show after{' '}
                <strong>3</strong> characters.
              </Typography>

              <Box>
                <Link
                  className='bl-search-model-helper-link-faq'
                  color='inherit'
                  href='#/help'
                  underline='hover'
                  rel='noopener'
                >
                  FAQ: Using Search
                </Link>
                <Typography
                  className='bl-search-model-links-divider'
                  color='initial'
                  variant='caption'
                >
                  |
                </Typography>
                <Link
                  className='bl-search-model-give-feedback'
                  href='https://share.hsforms.com/14bY4QqHaS0qYisiSsogy0g43l68'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Give Feedback
                </Link>
              </Box>
            </Box>
            <BluonIframeComponent height='calc(100% - 71px)' />
          </>
        )}

        <Grid container>
          {!selectedModel && (
            <Grid item xs={12}>
              {shouldShowModelsList && (
                <>
                  <Box className='bl-search-model-results-title-container'>
                    <Typography
                      className='bl-search-model-results-title'
                      color='initial'
                      variant='caption'
                    >
                      <strong>{totalModels}</strong>
                      {' SUGGESTED RESULTS FOR '}
                      <strong>{searchTerm}</strong>
                    </Typography>
                  </Box>
                  <Box className='bl-model-tab-container-models'>
                    <VirtualizedInfiniteList
                      list={models}
                      selectedItem={selectedModel}
                      isLoading={isLoadingNextPageModels}
                      hasNextPage={modelsHasNextPage}
                      isLoadPreviousButtomVisible={previousPage > 0}
                      onLoadPrevious={handlePreviousPage}
                      isLoadingPreviousParts={isLoadingPreviousModels}
                      onLoadMore={() =>
                        dispatch(
                          searchMoreModelsRequest({ model: searchValue }),
                        )
                      }
                      onClickItem={handleSelectModel}
                      rowHeight={76.8}
                    >
                      {({ index, list, onClickItem }: IModelsRowItem) => {
                        const item = list[index];
                        const {
                          series: { brand },
                          series,
                        } = item;

                        const brandImg =
                          brand.image.url || brand.image.conversions.thumb;
                        const brandName = brand.name;

                        const serieImg =
                          series.image.url || series.image.conversions.thumb;
                        const serieName = series.name;

                        return (
                          <SearchModelRow
                            onClick={() => onClickItem(item)}
                            brandImg={brandImg}
                            brandName={brandName}
                            serieImg={serieImg}
                            serieName={serieName}
                            item={item}
                          />
                        );
                      }}
                    </VirtualizedInfiniteList>
                  </Box>
                </>
              )}
              {isSearchingForModel && (
                <Box
                  data-testid='modelTabLoading'
                  className='bl-model-tab-loading-container'
                >
                  <CircularLoading />
                </Box>
              )}
              {searchError && (
                <SomethingWrong
                  height='calc(100vh - 165px)'
                  onReload={() =>
                    dispatch(searchModelsRequest({ model: searchValue }))
                  }
                />
              )}
            </Grid>
          )}
          {selectedModel && (
            <ModelDetail
              selectedSerie={selectedModel?.series}
              modelId={modelId}
            />
          )}
        </Grid>
        {shouldShowNotFoundModels && (
          <Box className='bl-search-model-not-found'>
            <NotFound />
          </Box>
        )}
      </Container>
    </Container>
  );
};
