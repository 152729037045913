import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { DeliveryBarProps } from './interface';
import { Message } from './message';

import LocationIcon from '@assets/icons/LocationIcon.svg';
import { useSnackbar } from '@components/Snackbar/hooks';
import { EDeliveryType } from '@utils/enums';
import { handleRoundDecimals } from '@utils/functions';
import { getShipmentAddress } from '@utils/Strings/getAddress';

const DeliveryBar = ({
  extraClassName = '',
  delivery,
  showDeliveryNotes,
  customDeliveryBarMessage = '',
}: DeliveryBarProps) => {
  const { showMessage } = useSnackbar();
  const deliveryType = delivery?.type;
  const isCurriDelivery = deliveryType === EDeliveryType.CURRI_DELIVERY;
  const isShipment = deliveryType === EDeliveryType.SHIPMENT_DELIVERY;

  const renderCopyToClipboardButton = () => {
    if (!isCurriDelivery || customDeliveryBarMessage) return null;
    const numberToCopy = delivery?.fee || 0;

    return (
      <CopyToClipboard
        text={handleRoundDecimals(numberToCopy, 2)}
        onCopy={() =>
          showMessage('Copied', {
            severity: 'success',
            autoHide: true,
          })
        }
      >
        <div className='ml-4 bg-[var(--bluon--default--white)]'>
          <Button
            className='max-h-7 text-xs font-medium leading-3'
            variant='outlined'
            startIcon={<ContentCopyIcon sx={{ width: 14, height: 16 }} />}
          >
            COPY PRICE
          </Button>
        </div>
      </CopyToClipboard>
    );
  };

  const renderTrackCurriDelivery = useCallback(() => {
    if (!isCurriDelivery || !delivery?.info.tracking_url) return null;

    const handleButtonClick = () =>
      window.open(String(delivery?.info.tracking_url), '_blank');

    return (
      <div className='curri-delivery-tracker ml-auto text-white'>
        <Button
          className='h-[2.25rem] !rounded text-xs font-medium leading-3'
          color='inherit'
          variant='outlined'
          startIcon={
            <img src={LocationIcon} alt='track' className='track-icon' />
          }
          onClick={handleButtonClick}
        >
          <span className='text-[0.875rem] font-medium leading-[1.5rem]	tracking-[0.025rem]'>
            TRACK DELIVERY
          </span>
        </Button>
      </div>
    );
  }, [isCurriDelivery]);

  return (
    <div
      className={`align-center mb-6 flex w-full flex-col justify-center ${extraClassName} ${
        isCurriDelivery
          ? 'rounded border border-[var(--bluon--ds--curri--message)]'
          : ''
      }`}
    >
      <div
        className={`align-center flex w-full flex-row items-center rounded-lg py-2 ${
          isCurriDelivery
            ? 'justify-start px-4'
            : 'justify-center bg-[var(--bluon--light-blue--background)]'
        }`}
      >
        {isCurriDelivery && (
          <div className='mr-4 text-[1.25rem] font-medium leading-[2rem] tracking-[0.009rem] text-[var(--bluon--ds--lightblack-primary)]'>
            Delivery
          </div>
        )}
        <div
          className={`text-base font-normal text-[var(--bluon--ds--${
            isCurriDelivery ? 'curri--message' : 'lightgray-1'
          })] pt-1 text-center`}
        >
          <Message
            delivery={delivery}
            customDeliveryBarMessage={customDeliveryBarMessage}
          />
          {delivery?.info?.destination_address && isShipment && (
            <span className='flex flex-1 py-2 text-base font-bold leading-6 text-primary'>
              Shipping to:{' '}
              {getShipmentAddress(delivery?.info?.destination_address, false)}
            </span>
          )}
        </div>
        {renderCopyToClipboardButton()}
        {renderTrackCurriDelivery()}
      </div>
      {showDeliveryNotes && (
        <div
          className={
            'align-center flex w-full flex-col bg-[var(--bluon--ds--lightgray-4)] px-4 pb-2 text-center'
          }
        >
          <span className='mb-1 text-sm font-bold'>Delivery Instructions</span>
          <span className='text-sm font-normal'>{delivery.note}</span>
        </div>
      )}
    </div>
  );
};

export default DeliveryBar;
