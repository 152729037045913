import { Box } from '@mui/material';

import { ManualList } from './components/ManualList';
import { ModelManualsProps } from './interface';

import { Manuals } from '@config/api/search/interface';

import './styles.scss';

const manualTypes = [
  {
    id: 'bluon_guidelines',
    label: 'Bluon Guidelines',
  },
  {
    id: 'controls_manuals',
    label: 'Controls Manuals',
  },
  {
    id: 'diagnostic',
    label: 'Diagnostic',
  },
  {
    id: 'iom',
    label: 'IOM',
  },
  {
    id: 'misc',
    label: 'Miscellaneous',
  },
  {
    id: 'product_data',
    label: 'Product Data',
  },
  {
    id: 'service_facts',
    label: 'Service Facts',
  },
  {
    id: 'wiring_diagram',
    label: 'Wiring Diagram',
  },
];

export const ManualTab = ({ manuals }: ModelManualsProps): JSX.Element => {
  return (
    <Box className='bl-manual-tab-container'>
      {manualTypes.map(
        (type) =>
          manuals && (
            <ManualList
              key={type.id}
              manuals={manuals[type.id as keyof Manuals]}
              label={type.label}
            />
          ),
      )}
    </Box>
  );
};
