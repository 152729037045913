import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { BreadCrumbLinkItemProps } from './interface';

export const BreadCrumbLinkItem = ({
  imgUrl,
  id,
  onClick,
  title,
}: BreadCrumbLinkItemProps): JSX.Element => (
  <Link
    onClick={() => onClick(id)}
    underline='hover'
    key={id}
    color='inherit'
    href='#/bluon-search?tab=searchEquipment'
    className='bl-search-breadcrumb-item'
    data-testid={id}
  >
    {!!imgUrl && <img alt={title} src={imgUrl} />}
    {!!title && (
      <Typography
        className={`bl-search-breadcrumb-select-item-txt ${
          !imgUrl ? 'no-picture' : ''
        }`}
        key={`bl-breadcrumb-item-${id}`}
        variant='caption'
        color='initial'
      >
        {title}
      </Typography>
    )}
  </Link>
);
