import { Box, Button, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ExtraItemsList } from '../TruckStock/components/list';
import { ExtraItemsProps } from '../TruckStock/interface';

import { MUIAccordion } from '@components/MUIAccordion';
import { useSnackbar } from '@components/Snackbar/hooks';
import { ExtraItem } from '@config/api/task/interface';
import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import {
  patchAddedItemsRequest,
  resetAddedItemsResponse,
  setAddedItemsIsExpanded,
  setTotalIsExpanded,
} from '@state/task/actions';
import { getIsAddedItemsExpanded } from '@state/task/selectors';

export const AddedItems = ({
  items,
  order,
  isBidRequired,
}: ExtraItemsProps) => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [selectedItems, setSelectedItems] = useState<ExtraItem[]>([]);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const { isAddedItemsExpanded, isSaving } = useAppSelector((state) => ({
    isAddedItemsExpanded: getIsAddedItemsExpanded(state),
    isSaving: isRequestRunning(state, String(patchAddedItemsRequest)),
  }));

  const successCallback = () => {
    setReadOnly(true);
    dispatch(setAddedItemsIsExpanded(!isAddedItemsExpanded));
    dispatch(setTotalIsExpanded(true));
    showMessage('Items Saved', {
      severity: 'success',
      xCloseButton: true,
      subMessage: `Please update the Total ${
        isBidRequired ? 'and Bid Number' : ''
      } to continue.`,
    });
  };

  const onSave = () => {
    const payload = items
      .reduce(
        (items, item) => {
          const notExist =
            selectedItems.findIndex((selected) => selected.id === item.id) ===
            -1;

          if (notExist) items.push({ ...item, quantity: 0 });

          return items;
        },
        [...selectedItems],
      )
      .map((item) => ({ uuid: item.id, quantity: item.quantity }));

    dispatch(
      patchAddedItemsRequest({ order, items: payload, successCallback }),
    );
  };

  const showGreenCheck = useMemo(
    () =>
      items.every(
        (item) =>
          item.status === 'available' || item.status === 'not_available',
      ),
    [items],
  );

  useEffect(() => {
    if (items.length && !showGreenCheck)
      dispatch(setAddedItemsIsExpanded(true));
    else if (showGreenCheck) setReadOnly(true);
  }, [showGreenCheck, items]);

  useEffect(() => {
    return () => {
      dispatch(setAddedItemsIsExpanded(false));
      dispatch(resetAddedItemsResponse());
    };
  }, []);

  const renderHeaderTitle = useCallback(() => {
    return (
      <div
        className={`added-items-title container flex min-w-full items-center justify-between ${
          readOnly ? 'px-4' : ''
        }`}
      >
        <Typography variant='h6'>Added Items</Typography>
      </div>
    );
  }, [showGreenCheck, readOnly]);

  const readOnlySubtitle = () => {
    return (
      <div className='flex items-center justify-between border-b border-[var(--bluon--light--gray-21)] bg-[var(--bluon--light--gray-order-detail)] px-4 py-1.5'>
        <div className='text-[0.875rem]	font-normal leading-[1.25rem] tracking-[0.011rem] text-[#000000DE]'>
          Item Availability
        </div>
        <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
          Qty Available / Requested
        </div>
      </div>
    );
  };

  const readOnlyList = () => {
    return items.map((item) => (
      <div
        key={item.id}
        className='flex items-center justify-between border-b border-[var(--bluon--light--gray-21)] px-4 py-1.5'
      >
        <div className='text-[0.875rem]	font-normal leading-[1.25rem] tracking-[0.011rem] text-[#000000DE]'>
          {item?.item?.info?.name}
        </div>
        <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
          {item?.quantity} / {item?.quantity_requested}
        </div>
      </div>
    ));
  };

  const readOnlyMode = () => {
    return (
      <div className='mb-4'>
        {renderHeaderTitle()}
        {readOnlySubtitle()}
        {readOnlyList()}
        <Box className='mx-auto my-2 mt-4 max-w-[5.125rem]'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setReadOnly(false);
              dispatch(setAddedItemsIsExpanded(true));
            }}
          >
            EDIT
          </Button>
        </Box>
      </div>
    );
  };

  return readOnly ? (
    readOnlyMode()
  ) : (
    <MUIAccordion
      className='bl-truck-stock'
      expanded={isAddedItemsExpanded}
      onChange={(e, expanded) => {
        dispatch(setAddedItemsIsExpanded(expanded));
      }}
      headerTitle={renderHeaderTitle()}
    >
      <Box className='bl-added-items-container'>
        <ExtraItemsList
          items={items}
          onSave={onSave}
          isSaving={isSaving}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </Box>
    </MUIAccordion>
  );
};
