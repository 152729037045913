import { Paper, Typography } from '@mui/material';
import './styles.scss';

export const Disclaimer = () => {
  return (
    <Paper elevation={0} className='bl-search-disclaimer'>
      <Typography className='bl-search-disclaimer-message'>
        <span className='bl-search-disclaimer-message-header'>REMINDER: </span>
        We strive to provide accurate cross-references in BluonSearch, but you
        should always confirm the interchangeability of parts before
        recommending them. Bluon can accept no responsibility for errors, which
        may be caused by, among other things, changes in a model number, part
        number, part specifications or replacement applicability provided by the
        OEM.
      </Typography>
    </Paper>
  );
};
