import { Checkbox, ListItemIcon } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { InputHTMLAttributes } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { BrandsListProps } from './interface';
import './styles.scss';

export const BrandsList = (props: BrandsListProps): JSX.Element => {
  const {
    height,
    width,
    itemCount,
    overscanCount,
    itemSize,
    data,
    checked,
    handleToggleBrand,
  } = props;

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const item = data ? data[index] : null;

    if (!item) return <div></div>;

    return (
      <ListItem
        style={style}
        key={item.id}
        component='div'
        className='bl-list-item'
        data-testid='brandsListItem'
        disablePadding
        onClick={handleToggleBrand(item.id)}
        secondaryAction={
          <Checkbox
            edge='end'
            checked={checked.some((id) => id === item.id)}
            inputProps={
              {
                'data-testid': 'brandsListItemCheckbox',
              } as InputHTMLAttributes<HTMLInputElement> | any
            }
          />
        }
      >
        <ListItemButton>
          <ListItemIcon>
            <img
              alt='logo'
              className='bl-branch-logo'
              src={data?.[index]?.image?.url}
            />
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <FixedSizeList
      height={height}
      width={width}
      itemSize={itemSize}
      itemCount={itemCount}
      overscanCount={overscanCount}
    >
      {renderRow}
    </FixedSizeList>
  );
};
