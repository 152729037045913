import {
  confirmCustomerResponse,
  getCustomerListResponse,
  getRemovedCustomerListResponse,
  removeCustomerResponse,
  unconfirmCustomerResponse,
  updateCustomerResponse,
} from './actions';
import {
  CustomerActionProps,
  CustomerListResponseProps,
  CustomerState,
  RemovedCustomerListResponseProps,
  UpdateCustomerResponseProps,
} from './interface';

const initialState: CustomerState = {
  customerList: {
    unconfirmedUsers: [],
    confirmedUsers: [],
    removedUsers: [],
  },
};

const customerReducer = (
  state = initialState,
  action: CustomerActionProps,
): CustomerState => {
  switch (action.type) {
    case String(getCustomerListResponse): {
      const { data } = (action as CustomerListResponseProps).payload;

      return {
        ...state,
        customerList: {
          confirmedUsers: data.confirmedUsers,
          unconfirmedUsers: data.unconfirmedUsers,
          removedUsers: [...state.customerList.removedUsers],
        },
      };
    }

    case String(getRemovedCustomerListResponse): {
      const { data } = (action as RemovedCustomerListResponseProps).payload;

      return {
        ...state,
        customerList: {
          ...state.customerList,
          removedUsers: data,
        },
      };
    }

    case String(confirmCustomerResponse): {
      const { data: user } = (action as UpdateCustomerResponseProps).payload;
      const unconfirmedUsers = state.customerList.unconfirmedUsers.filter(
        (unconfirmedUser) => unconfirmedUser.id !== user.id,
      );
      const confirmedUsers = [...state.customerList.confirmedUsers, user];
      const removedUsers = [...state.customerList.removedUsers];

      return {
        ...state,
        customerList: {
          confirmedUsers,
          unconfirmedUsers,
          removedUsers,
        },
      };
    }

    case String(unconfirmCustomerResponse): {
      const { data: user } = (action as UpdateCustomerResponseProps).payload;
      const unconfirmedUsers = [user, ...state.customerList.unconfirmedUsers];
      const confirmedUsers = state.customerList.confirmedUsers.filter(
        (confirmedUser) => confirmedUser.id !== user.id,
      );
      const removedUsers = [...state.customerList.removedUsers];

      return {
        ...state,
        customerList: {
          confirmedUsers,
          unconfirmedUsers,
          removedUsers,
        },
      };
    }

    case String(updateCustomerResponse): {
      const { data: user } = (action as UpdateCustomerResponseProps).payload;
      const unconfirmedUsers = [...state.customerList.unconfirmedUsers];
      const confirmedUsers = state.customerList.confirmedUsers.map(
        (confirmedUser) => {
          if (confirmedUser.id === user.id)
            return {
              ...confirmedUser,
              customer_tier: user.customer_tier,
              cash_buyer: user.cash_buyer,
            };

          return confirmedUser;
        },
      );
      const removedUsers = [...state.customerList.removedUsers];

      return {
        ...state,
        customerList: {
          confirmedUsers,
          unconfirmedUsers,
          removedUsers,
        },
      };
    }

    case String(removeCustomerResponse): {
      const { data: user } = (action as UpdateCustomerResponseProps).payload;
      const unconfirmedUsers = state.customerList.unconfirmedUsers.filter(
        (unconfirmedUser) => unconfirmedUser.id !== user.id,
      );
      const confirmedUsers = [...state.customerList.confirmedUsers];
      const removedUsers = [user, ...state.customerList.removedUsers];

      return {
        ...state,
        customerList: {
          confirmedUsers,
          unconfirmedUsers,
          removedUsers,
        },
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
