import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '@components/Modal';
import ChatComponent from '@components/NewChat';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { OrderDetail } from '@pages/Tasks/components/OrderDetail';
import { getIsImpersonatedSupplier, getIsManager } from '@state/auth/selectors';
import { setOrderDetailsRequest, setSelectedOrder } from '@state/task/actions';
import { getCurrentSelectedOrderId } from '@state/task/selectors';

export const GlobalComponents = () => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [queryParams, , clearQueryParams] = useQueryParams<{
    orderId: string | undefined;
  }>();

  const { selectedOrder, isManager, isImpersonatedSupplier } = useAppSelector(
    (state) => ({
      selectedOrder: getCurrentSelectedOrderId(state),
      isManager: getIsManager(state),
      isImpersonatedSupplier: getIsImpersonatedSupplier(),
    }),
  );

  const handleClose = () => {
    clearQueryParams(false);
  };

  const onOrderDetailError = () => {
    clearQueryParams(true);
    showMessage('Error getting order', { severity: 'error' });
  };

  //  Watching query params changes
  useEffect(() => {
    if (!queryParams?.orderId) {
      dispatch(setSelectedOrder(undefined));

      return;
    }

    dispatch(
      setOrderDetailsRequest({
        id: queryParams?.orderId,
        errorCallback: onOrderDetailError,
      }),
    );
  }, [queryParams?.orderId]);

  return (
    <>
      <Modal
        open={!!queryParams?.orderId && !!selectedOrder}
        handleClose={handleClose}
        position='right'
        isImpersonatedSupplier
      >
        <OrderDetail />
      </Modal>
      {(!isManager || isImpersonatedSupplier) && <ChatComponent />}
    </>
  );
};
