import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';
import {
  HTMLAttributes,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';

import TextInput from '../TextInput';

import {
  dropdownErrorTestId,
  dropdownLabelTestId,
  dropdownLoadingTestId,
  dropdownPlaceholderTestId,
} from './constants';
import { DropdownProps } from './interface';
import './styles.scss';

export const Dropdown = ({
  selected,
  options,
  onSelectOption,
  hasSearch,
  label,
  placeholder,
  error,
  errorMessage,
  id,
  isLoading = false,
  onDropDownItemClick,
  'data-testid': dataTestId,
  ...props
}: DropdownProps): ReactElement => {
  const [searchText, setSearchText] = useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    onSelectOption?.(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const parsedOptions = useMemo(() => {
    return options?.filter(({ label }) =>
      label.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, options]);

  const onRenderValue = useCallback(
    (selected) => {
      return selected ? (
        <>
          {options.find((option) => option.value === selected)?.label}
          <span className='dropdown-input-menu-sublabel'>
            {options.find((option) => option.value === selected)?.subLabel}
          </span>
        </>
      ) : (
        <span data-testid={dropdownPlaceholderTestId}>{placeholder}</span>
      );
    },
    [options, placeholder],
  );

  return (
    <Box>
      {label && (
        <InputLabel htmlFor={id} data-testid={dropdownLabelTestId}>
          {label}
        </InputLabel>
      )}
      {isLoading ? (
        <Skeleton
          role='progressbar'
          data-testid={dropdownLoadingTestId}
          className='dropdown-input-skeleton'
          animation='wave'
        />
      ) : (
        <Select
          fullWidth
          onChange={(event: SelectChangeEvent<any>) => handleChange(event)}
          value={selected}
          className='custom-dropdown'
          inputProps={{
            id,
            disableUnderline: true,
          }}
          SelectDisplayProps={
            {
              'data-testid': dataTestId && `${dataTestId}DropDownSelect`,
            } as HTMLAttributes<HTMLInputElement> | any
          }
          displayEmpty={true}
          MenuProps={{ variant: 'menu' }}
          renderValue={onRenderValue}
          error={error}
          endAdornment={
            <InputAdornment
              position='end'
              className='dropdown-error-end-adornment '
            >
              {error && (
                <ErrorIcon color='warning' data-testid={dropdownErrorTestId} />
              )}
            </InputAdornment>
          }
          {...props}
        >
          {hasSearch && (
            <Box className='search-container'>
              <TextInput
                type='search'
                startAdornment={<SearchIcon />}
                placeholder={'Search'}
                data-testid={dataTestId && `${dataTestId}DropDownSearchInput`}
                value={searchText}
                onChange={handleSearchChange}
              />
            </Box>
          )}
          {parsedOptions.map(
            ({ value, label, subLabel, iconStart, iconEnd, disabled }) => (
              <MenuItem
                data-testid={dataTestId && `${dataTestId}DropDownMenuItem`}
                value={value}
                key={value}
                disabled={disabled}
                onClick={() => {
                  onDropDownItemClick?.(value);
                }}
                className='dropdown-input-menu-items'
              >
                {iconStart ? iconStart : null}
                {label}
                {subLabel && (
                  <span className='dropdown-input-menu-sublabel'>
                    {subLabel}
                  </span>
                )}
                {iconEnd ? iconEnd : null}
              </MenuItem>
            ),
          )}
        </Select>
      )}
      {!props.removeErrorsLabel && (
        <FormHelperText>{(error && errorMessage) || ' '}</FormHelperText>
      )}
    </Box>
  );
};
