import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';

import { queryParamKeys } from '../interface';

import { Brands } from './Brands';
import { BreadCrumbLinkItem } from './components/BreadCrumbLinkItem';
import { SearchBreadcrumbs } from './components/SearchBreadcrumbs';
import { ISearchList } from './interface';
import { Models } from './Models';
import { Series } from './Series';

import { ModelDetail } from '@components/ModelDetail';
import { NotFound } from '@components/NotFound';
import { SearchInput } from '@components/SearchInput';
import { SomethingWrong } from '@components/SomethingWrong';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  modelSpecificationsRequest,
  partsResetRequest,
} from '@state/search/actions';
import { getModelSpecificationsData } from '@state/search/selectors';
import { brandsRequest, moreBrandsRequest } from '@state/storeInfo/actions';
import {
  getBrandsData,
  getHasNextPageBrands,
} from '@state/storeInfo/selectors';
import './styles.scss';

export const EquipmentTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [queryParams] = useQueryParams<queryParamKeys>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [clear, setClear] = useState<boolean>(true);
  const [breadcrumbSteps, setBreadCrumbSteps] = useState<Array<JSX.Element>>(
    [],
  );
  const [selectedBrand, setSelectedBrand] = useState<ISearchList | undefined>();
  const [selectedSerie, setSelectedSerie] = useState<ISearchList | undefined>();
  const [selectedModel, setSelectedModel] = useState<ISearchList | undefined>();

  const {
    brands,
    isLoadingBrands,
    brandsHasNextPage,
    isLoadingNextPageBrands,
    brandRequestError,
    modelSpecifications,
  } = useAppSelector((state) => ({
    brands: getBrandsData(state),
    brandsHasNextPage: getHasNextPageBrands(state),
    isLoadingNextPageBrands: isRequestRunning(state, String(moreBrandsRequest)),
    isLoadingBrands: isRequestRunning(state, String(brandsRequest)),
    brandRequestError: getRequestError(state, String(brandsRequest)),
    modelSpecifications: getModelSpecificationsData(state),
  }));

  const handleResetClearAllNavigation = () => {
    setBreadCrumbSteps([]);
    setSelectedBrand(undefined);
    setSelectedSerie(undefined);
    setSelectedModel(undefined);
    setSearchValue('');
    dispatch(brandsRequest());
  };

  const handleBreadcrumbItem = (
    item: ISearchList,
    type: string,
    onClickCallback: () => void,
  ) => (
    <BreadCrumbLinkItem
      key={`${type}-${item?.id}`}
      id={`${type}-${item?.id}`}
      imgUrl={item?.image ? item?.image?.url : undefined}
      title={type === 'brand' ? undefined : item?.name || item?.model}
      onClick={onClickCallback}
    />
  );

  const handleAddItemBreadCrumb = (
    item: ISearchList,
    index: number | undefined,
  ) => {
    setClear(false);
    if (!index) {
      const breadcrumbStepBrands = handleBreadcrumbItem(item, 'brand', () => {
        setSelectedSerie(undefined);
        setSelectedModel(undefined);
        handleAddItemBreadCrumb(item, index);
        setSearchValue('');
      });

      return setBreadCrumbSteps([breadcrumbStepBrands]);
    }
    if (index === 1) {
      const breadcrumbStepSerie = handleBreadcrumbItem(item, 'serie', () => {
        setSelectedModel(undefined);
        handleAddItemBreadCrumb(item, index);
        setSearchValue('');
      });

      const newArray = breadcrumbSteps.slice(0, 1);

      return setBreadCrumbSteps([...newArray, breadcrumbStepSerie]);
    }
    if (index === 2) {
      const breadcrumbStepModel = handleBreadcrumbItem(item, 'model', () => {
        // TODO: clear others
      });

      const newArray = breadcrumbSteps.slice(0, 2);

      return setBreadCrumbSteps([...newArray, breadcrumbStepModel]);
    }
  };

  const handleAddItemState = (item: ISearchList, index: number | undefined) => {
    switch (index) {
      case 1: {
        setSelectedModel(undefined);

        return setSelectedSerie(item);
      }
      case 2: {
        return setSelectedModel(item);
      }
      default: {
        setSelectedSerie(undefined);

        return setSelectedBrand(item);
      }
    }
  };

  const handleAddItem = (item: ISearchList, index?: number | undefined) => {
    setSearchValue('');
    handleAddItemState(item, index);
    handleAddItemBreadCrumb(item, index);
  };

  let searchInputPlaceholder =
    'Search Brands - Reminder: Model #s may appear in an affiliated brand (ex: Carrier <> Bryant). See “Help” for brand affiliations';
  if (selectedBrand) searchInputPlaceholder = 'Search Line of Brand Selected';
  if (selectedSerie)
    searchInputPlaceholder =
      'Search Model # of Selected Line (Reminder: Some Model #’s can be truncated)';

  const handleCheckGetBrandsWithSearch = useCallback(
    () =>
      (searchValue.length >= 2 && !selectedBrand) ||
      (!searchValue && !selectedBrand),
    [searchValue, selectedBrand],
  );

  useEffect(() => {
    if (handleCheckGetBrandsWithSearch())
      dispatch(brandsRequest({ search: searchValue }));
  }, [searchValue, dispatch, selectedBrand, handleCheckGetBrandsWithSearch]);

  useEffect(() => {
    dispatch(partsResetRequest());
  }, [selectedModel, dispatch]);

  const isNotFoundResult = searchValue && brands.length === 0;

  useEffect(() => {
    if (queryParams.modelId)
      dispatch(
        modelSpecificationsRequest({ page: 1, modelId: queryParams.modelId }),
      );
    else if (clear) handleResetClearAllNavigation();

    setClear(true);
  }, [queryParams.modelId]);

  useEffect(() => {
    if (queryParams.modelId && modelSpecifications) {
      const brand = modelSpecifications?.series?.brand;
      const serie = modelSpecifications?.series;
      const model = {
        id: modelSpecifications?.id,
        name: modelSpecifications?.model || '',
        image: modelSpecifications?.series?.image,
      };
      setSelectedBrand(brand);
      setSelectedSerie(serie);
      if (queryParams?.modelId !== selectedModel?.id) setSelectedModel(model);
    }
  }, [modelSpecifications]);

  return (
    <>
      {!selectedModel && (
        <SearchInput
          label='Search Line of Brand Selected'
          containerClassName='bl-search-input-custom-equipment'
          onChange={setSearchValue}
          value={searchValue}
          placeholder={searchInputPlaceholder}
          data-testid='searchInput'
        />
      )}
      {!!brandRequestError && (
        <SomethingWrong
          height='calc(100vh - 165px)'
          onReload={() =>
            dispatch(
              brandsRequest({
                search: handleCheckGetBrandsWithSearch() ? searchValue : '',
              }),
            )
          }
        />
      )}
      {searchValue && brands.length === 0 && (
        <NotFound height='calc(100vh - 167px)' />
      )}
      {!brandRequestError && !isNotFoundResult && (
        <Container maxWidth='xl' className='bl-search-content-container'>
          <SearchBreadcrumbs
            breadcrumbSteps={breadcrumbSteps}
            onClickDefault={handleResetClearAllNavigation}
            hasBrandSelected={!!selectedBrand}
          />

          <Grid container>
            {!selectedSerie && (
              <Brands
                selectedBrand={selectedBrand}
                onClickItem={(item) => handleAddItem(item)}
                brands={brands}
                isLoadingBrands={isLoadingBrands}
                brandsHasNextPage={brandsHasNextPage}
                isLoadingNextPageBrands={isLoadingNextPageBrands}
                onLoadMore={() =>
                  dispatch(moreBrandsRequest({ search: searchValue }))
                }
              />
            )}
            {selectedBrand && !selectedModel && (
              <Series
                searchValue={selectedBrand && !selectedSerie ? searchValue : ''}
                selectedSerie={selectedSerie}
                onClickItem={(item) => handleAddItem(item, 1)}
                brandId={selectedBrand.id}
              />
            )}
            {selectedSerie && selectedBrand && !selectedModel && (
              <Models
                searchValue={selectedSerie && !selectedModel ? searchValue : ''}
                selectedModels={selectedModel}
                onClickItem={(item) => handleAddItem(item, 2)}
                serieId={selectedSerie.id}
                brandId={selectedBrand?.id}
              />
            )}
            {selectedModel && selectedSerie && selectedBrand && (
              <ModelDetail
                selectedSerie={selectedSerie}
                modelId={selectedModel.id}
              />
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};
