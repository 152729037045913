import * as Yup from 'yup';

import { TotalsFormKeys } from '../interface';
interface TotalValidationContextInterface {
  addedItemsConfirmed: boolean;
  originalTotal: string;
}

export const TotalsValidationSchema = Yup.object<
  Record<keyof TotalsFormKeys, Yup.AnySchema>
>({
  total: Yup.string()
    .min(1, 'Required')
    .test(
      'max',
      'Max amount cannot be higher than 2,000,000',
      (_value, context) => {
        const total = context?.parent?.total?.replace(/\$|,/g, '') ?? 0;

        return !(total > 2000000);
      },
    )
    .test(
      'changed',
      'The Total must be different than the original since the order items have changed',
      function (value) {
        const { addedItemsConfirmed, originalTotal } = this.options
          .context as TotalValidationContextInterface;
        const correctValue = value?.replace(/\$|,/g, '').trim() ?? 0;
        const correctOriginalValue =
          originalTotal?.replace(/\$|,/g, '').trim() ?? 0;

        if (addedItemsConfirmed) return correctValue !== correctOriginalValue;

        return true;
      },
    )
    .required(),
  bidNumber: Yup.string()
    .max(24, 'Max amount of characters allowed is 24')
    .when('$isBidNumberRequired', {
      is: true,
      then: Yup.string().required('Required'),
    }),
  notes: Yup.string().max(255, 'error').notRequired(),
});
