import { Box } from '@mui/material';

import { TechSpecGroup } from './components/TechSpecGroup';
import { TechSpecsProps } from './interface';

import { ModelSpecificationsDataModel } from '@config/api/search/interface';
import './styles.scss';

const details = [
  {
    id: 'system_details',
    label: 'System Details',
  },
  {
    id: 'refrigerant_details',
    label: 'Refrigerant Details',
  },
  {
    id: 'compressor_details',
    label: 'Compressor Details',
  },
  {
    id: 'oil_details',
    label: 'Oil Details',
  },
  {
    id: 'metering_device_details',
    label: 'Metering Device Details',
  },
];

export const TechSpecTab = ({
  specifications,
}: TechSpecsProps): JSX.Element => {
  return (
    <Box className='bl-specs-tab-container'>
      {details.map(
        (detail) =>
          specifications && (
            <TechSpecGroup
              key={detail.id}
              group={detail.id}
              label={detail.label}
              specDetails={
                specifications[detail.id as keyof ModelSpecificationsDataModel]
              }
            />
          ),
      )}
    </Box>
  );
};
