import ConfirmedPickupBanner from '../components/Banners/ConfirmedPickupBanner';
import NewCustomerBanner from '../components/Banners/NewCustomerBanner';
import SentToCustomerBanner from '../components/Banners/SentToCustomerBanner';
import UpdateQuoteBanner from '../components/Banners/UpdateQuoteBanner';
import { ORDER_SUPPLIER_MESSAGES } from '../components/ListItem/constants';
import { LOGISTIC_OPTIONS } from '../constants';

import { AvailableBanners } from '@components/Banner/constants';
import { ORDER_STATUS, Order } from '@config/api/task/interface';
import {
  checkIfIsTodayOrTomorrow,
  transformStringDateTo12HoursFormat,
} from '@utils/Dates';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

export const getBannerFromOrderInfo = (order: Order) => {
  const { supplier_user, user, company } = order;
  const substatus = order.current_status?.substatus;
  const orderStatus = getCustomOrderStatus(order);
  const deliveryType = getOrderDeliveryType(order);
  const contractorName = user?.company || company?.name;

  if (
    !supplier_user?.cash_buyer &&
    supplier_user?.status === 'unconfirmed' &&
    (orderStatus.includes(ORDER_STATUS['pending-requested']) ||
      orderStatus.includes(ORDER_STATUS['pending-assigned']))
  )
    // New Customer Banner
    return <NewCustomerBanner orderId={order.id} userId={order.user.id} />;

  // 302 - 312 - 322
  if (
    substatus === ORDER_STATUS['approved-awaiting-delivery-quote-needed'] ||
    substatus === ORDER_STATUS['approved-ready-for-delivery-quote-needed'] ||
    substatus === ORDER_STATUS['approved-delivered-quote-needed']
  )
    // Only Curri
    return (
      <UpdateQuoteBanner {...AvailableBanners.curriDelivery} order={order} />
    );

  // 301 - 311 - 321
  if (
    substatus === ORDER_STATUS['approved-awaiting-delivery-quote-needed-ws'] ||
    substatus === ORDER_STATUS['approved-ready-for-delivery-quote-needed-ws'] ||
    substatus === ORDER_STATUS['approved-delivered-quote-needed-ws']
  )
    // Truck Store Added & Curri
    return (
      <UpdateQuoteBanner
        {...AvailableBanners.truckStockAndCurri}
        order={order}
      />
    );

  if (
    order.current_status?.substatus === 'pending-approval-quote-needed' &&
    deliveryType === 'pickup'
  )
    return (
      <UpdateQuoteBanner {...AvailableBanners.truckStockAdded} order={order} />
    );

  if (orderStatus === ORDER_STATUS['approved-ready-for-delivery-pickup'])
    return (
      <ConfirmedPickupBanner
        fee={order.paid_total ? order.paid_total : order.total}
        orderId={order?.id}
        contractorName={contractorName}
        isReadyForDelivery
        isPersonalUse={Boolean(contractorName)}
      />
    );

  if (orderStatus === ORDER_STATUS['approved-delivered-pickup'])
    return (
      <ConfirmedPickupBanner
        fee={order.paid_total ? order.paid_total : order.total}
        orderId={order?.id}
        contractorName={contractorName}
      />
    );

  if (
    orderStatus === ORDER_STATUS['pending-approval-quote-needed-shipment'] &&
    !order.had_truck_stock
  )
    // No Truck Stock & Shipment
    return (
      <UpdateQuoteBanner {...AvailableBanners.confirmShipment} order={order} />
    );

  if (
    orderStatus === ORDER_STATUS['pending-approval-quote-needed-shipment'] &&
    order.had_truck_stock
  )
    // Truck Stock & Shipment
    return (
      <UpdateQuoteBanner
        {...AvailableBanners.truckStockAndShipment}
        order={order}
      />
    );

  if (orderStatus === ORDER_STATUS['approved-awaiting-delivery-shipment'])
    // Sent To Customer
    return <SentToCustomerBanner order={order} />;

  return null;
};

export const getOrderDeliveryType = (order: Order) => {
  const { delivery } = order;

  return delivery?.type ? delivery?.type.split('_')[0] : '';
};

export const getCustomOrderStatus = (order: Order) => {
  const { current_status, delivery } = order;

  return delivery?.type
    ? `${current_status?.substatus}-${delivery?.type.split('_')[0]}`
    : `${current_status?.substatus}`;
};

export const getOrderStatus = (order: Order) => {
  const { current_status, delivery, working_on_it } = order;
  const index = delivery?.type
    ? `${current_status?.substatus}-${delivery?.type.split('_')[0]}`
    : `${current_status?.substatus}`;

  //  Try to find message by {substatus}-{delivery} or by {substatus}
  //  There are general status in which the delivery must not be included in the index
  const originalMessage =
    ORDER_SUPPLIER_MESSAGES[index as keyof typeof ORDER_STATUS] ||
    ORDER_SUPPLIER_MESSAGES[
      current_status?.substatus as keyof typeof ORDER_STATUS
    ];

  if (!originalMessage) return '';

  if (
    delivery?.type === 'shipment_delivery' ||
    delivery?.type === LOGISTIC_OPTIONS.CURRI_TRUCK
  )
    return originalMessage?.replace(
      '[Preference]',
      capitalizeFirstLetter(
        delivery?.info?.shipment_delivery_preference?.id || '',
      ),
    );

  if (index.includes('pending-assigned'))
    return originalMessage?.replace(
      '[suplier_name]',
      capitalizeFirstLetter(working_on_it?.name ?? 'supplier'),
    );

  if (originalMessage?.includes('(date time)')) {
    const formattedDate = `${checkIfIsTodayOrTomorrow(
      delivery?.date,
    )}, ${transformStringDateTo12HoursFormat(
      delivery?.start_time,
    )} - ${transformStringDateTo12HoursFormat(delivery?.end_time)}`;

    return originalMessage?.replace('(date time)', formattedDate);
  }

  return originalMessage;
};

export const isQuoteFileRequired = (order: Order | null) => {
  if (!order) return false;

  const message = getOrderStatus(order);
  if (!message) return false;

  return (
    message?.includes('Overnight Shipment Requested - Needs Quote') ||
    message?.includes('Priority Shipment Requested - Needs Quote') ||
    message?.includes('Standard Shipment Requested - Needs Quote')
  );
};
