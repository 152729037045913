import CheckIcon from '@mui/icons-material/Check';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { ThankYouModalProps } from './interface';

import BluonLogo from '@assets/images/BluonLogoSideBar.png';
import GreenCheck from '@assets/images/GreenCheck.svg';

import './styles.scss';

export const ThankYouModal = (props: ThankYouModalProps): JSX.Element => {
  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='thank-you-dialog-container'
    >
      <Box className='dialog-title'>
        <Typography variant='h5' textAlign='center'>
          Congrats! Welcome to
        </Typography>
        <img src={BluonLogo} alt='Bluon Live' />
      </Box>
      <div className='video-responsive'>
        <iframe
          width='500'
          height='280'
          src='https://www.youtube.com/embed/qp2iLDmcoTc?autoplay=1'
          frameBorder='0'
          title='Embedded youtube'
          data-testid='videoPlayer'
        />
      </div>
      <DialogContent>
        <Grid item container xs className='dialog-subtitle'>
          <Typography variant='h5' color='initial' textAlign='center'>
            Your counter staff will <b>gain decades of experience</b> -
            overnight!
          </Typography>
        </Grid>

        <Grid xs item container className='list-container'>
          <List>
            <ListItem>
              <ListItemIcon>
                <img src={GreenCheck} alt='check' />
              </ListItemIcon>
              <ListItemText primary='No more wrong parts' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={GreenCheck} alt='check' />
              </ListItemIcon>
              <ListItemText primary='Next gen cross reference tool' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={GreenCheck} alt='check' />
              </ListItemIcon>
              <ListItemText primary='Easily connect with customers' />
            </ListItem>
          </List>
        </Grid>

        <Grid xs item container justifyContent='center'>
          <Typography variant='body1' fontWeight={500} color='black'>
            Check in for weekly updates on our spring roll-out!
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <Button
          className='start-button'
          variant='contained'
          color='primary'
          data-testid='checkButton'
          onClick={handleClose}
        >
          <CheckIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
