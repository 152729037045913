import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { NavDropDownProps } from './interface';

import SupplierRating from '@components/SupplierRating';
import { NavRoute } from '@config/routing/interface';
import { DropdownRoutes, LogOutDropdownRoute } from '@config/routing/Routes';
import useRealTimeStoreRating from '@hooks/fireBase/useRealTimeStoreRating';
import { useAppSelector } from '@hooks/state';
import { logout } from '@state/auth/actions';
import { getIsImpersonatedSupplier, getIsManager } from '@state/auth/selectors';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import './styles.scss';

export const NavDropDown = ({ logo, storeInfoBoolean }: NavDropDownProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdownMenu, setDropDownMenu] = useState<null | HTMLElement>(null);

  const { storeInfo, isManager, isImpersonatedSupplier } = useAppSelector(
    (state) => ({
      storeInfo: getStoreInfoData(state),
      isManager: getIsManager(state),
      isImpersonatedSupplier: getIsImpersonatedSupplier(),
    }),
  );

  const { rating: storeRating } = useRealTimeStoreRating(storeInfo?.id || '');

  const filteredRoutes: NavRoute[] = useMemo(() => {
    if (isManager && !isImpersonatedSupplier) return LogOutDropdownRoute;

    return DropdownRoutes;
  }, [isImpersonatedSupplier, isManager]);

  const handleCloseUserMenu = () => {
    setDropDownMenu(null);
  };

  const handleExternalRedirect = (url: string, target: string) => {
    window.open(url, target);
    handleCloseUserMenu();
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setDropDownMenu(event.currentTarget);
  };

  const logoutAction = (title: string) => {
    if (title === 'Log Out') dispatch(logout(() => navigate('/login')));
  };

  const enableMenu = storeInfoBoolean || isManager;

  return (
    <Grid item xs className='container-dropdown'>
      {storeRating && <SupplierRating rating={storeRating} />}
      <IconButton onClick={handleOpenUserMenu} disabled={!enableMenu}>
        {enableMenu ? (
          <img src={logo} className='dropdown-image' />
        ) : (
          <Skeleton variant='rounded' width={'16.25rem'} height={38} />
        )}
        <ArrowDropDownIcon fontSize='large' />
      </IconButton>
      <Menu
        PaperProps={{ sx: { width: '16.25rem' } }}
        MenuListProps={{ sx: { paddingTop: 0, paddingBottom: 0 } }}
        anchorEl={dropdownMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={Boolean(dropdownMenu)}
        onClose={handleCloseUserMenu}
      >
        {
          // TO DO: USE THIS FOR FUTURE FEATURE
          /* <Box className='dropdown-counter-staff'>
          <Box className='dropdown-caption-title'>
            <Typography variant='caption' color='initial'>
              Active Sales Rep
            </Typography>
          </Box>

          {staff.map((item, index) => (
            <MenuItem
              key={item.email}
              selected={index === 0}
              className='counter-staff-item'
            >
              <ListItemText>{item.name}</ListItemText>
              {index === 0 && (
                <ListItemIcon>
                  <Check color='primary' />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Box> */
        }
        {filteredRoutes.map((route: NavRoute, i: number) => {
          if (route.type === 'external')
            return (
              <MenuItem
                key={'menu-' + i}
                onClick={() =>
                  handleExternalRedirect(
                    String(route.path),
                    route.target ?? '_self',
                  )
                }
              >
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText>{route.title}</ListItemText>
              </MenuItem>
            );

          return (
            <NavLink
              key={route.title}
              to={route.path}
              onClick={() => logoutAction(route.title)}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText>{route.title}</ListItemText>
              </MenuItem>
            </NavLink>
          );
        })}
      </Menu>
    </Grid>
  );
};
