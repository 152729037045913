import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CountersI } from './interface';
import './styles.scss';

import BluonLogo from '@assets/images/bluon-logo.png';
import { NavDropDown } from '@components/NavDropDown';
import { NavMenuItem } from '@components/NavMenuItem';
import { useSnackbar } from '@components/Snackbar/hooks';
import { NavRoute } from '@config/routing/interface';
import {
  ImpersonatedSupplierNavRoutes,
  ManagerNavRoutes,
  SupplierNavRoutes,
} from '@config/routing/Routes';
import useRealTimeNotificationSound from '@hooks/fireBase/useRealTimeNotificationSound';
import useRealTimeOrderCount from '@hooks/fireBase/useRealTimeOrderCount';
import { useAppSelector } from '@hooks/state';
import ManagerBanner from '@pages/Manager/StoreOverview/components/Banner';
import { getIsImpersonatedSupplier, getIsManager } from '@state/auth/selectors';
import { getChannelUsers } from '@state/chat/actions';
import { removeSupplierImpersonate } from '@state/manager/actions';
import { cleanStoreInfo, storeInfoGetRequest } from '@state/storeInfo/actions';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { playNotificationSound } from '@utils/functions';

const NavBar = () => {
  const [disabledMenus, setDisabledMenus] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [counters, setCounters] = useState<CountersI>({
    total_customers: 0,
    customers: 0,
    init: true,
    total_orders: 0,
  });

  const { storeInfo, isManager, isImpersonatedSupplier } = useAppSelector(
    (state) => ({
      storeInfo: getStoreInfoData(state),
      isManager: getIsManager(state),
      isImpersonatedSupplier: getIsImpersonatedSupplier(),
    }),
  );

  const staff = useMemo(() => storeInfo?.counter_staff || [], [storeInfo]);
  const logo = useMemo(() => storeInfo?.logo?.url || '', [storeInfo]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const filteredRoutes: NavRoute[] = useMemo(() => {
    if (isImpersonatedSupplier) return ImpersonatedSupplierNavRoutes;
    if (isManager) return ManagerNavRoutes;

    return SupplierNavRoutes;
  }, [isImpersonatedSupplier, isManager]);

  const approvedOrdersSuccessCallback = useCallback(() => {
    showMessage(
      'New Tasks have been loaded. Please review in the Will Call / Approved Orders column',
      {
        severity: 'success',
      },
    );
  }, []);

  const availabilityOrdersSuccessCallback = useCallback(() => {
    showMessage(
      'New Tasks have been loaded. Please review in the Price & Availability Requests column',
      {
        severity: 'success',
      },
    );
  }, []);

  useEffect(() => {
    if (storeInfo)
      if (!storeInfo.verified_at) {
        setDisabledMenus(true);
        if (pathname !== '/account') navigate('/account');
      } else {
        setDisabledMenus(false);
      }
  }, [storeInfo]);

  const setCountersValue = (values: any) => {
    if (values)
      setCounters((prevValues) => ({ ...prevValues, ...values, init: false }));
  };

  const handleOrderNotificationReceived = (isAnApprovedOrder: boolean) => {
    const isTaskScreen =
      window && window.location.href.split('#')[1] === '/tasks';
    if (!isAnApprovedOrder && (!isManager || isImpersonatedSupplier)) {
      dispatch(getChannelUsers());
      isTaskScreen && availabilityOrdersSuccessCallback();
    } else {
      isTaskScreen && approvedOrdersSuccessCallback();
    }

    playNotificationSound(isAnApprovedOrder);
  };

  useRealTimeNotificationSound(storeInfo, handleOrderNotificationReceived);
  useRealTimeOrderCount(storeInfo, setCountersValue);

  const getCounter = (title: string) => {
    if (title === 'Customers') return counters.customers;

    if (title === 'Tasks') return counters.total_orders;
  };

  const handleRemoveImpersonation = (route: NavRoute) => {
    const { path } = route;
    if (path === '/store-overview') {
      dispatch(cleanStoreInfo());
      dispatch(
        removeSupplierImpersonate({
          successCallback: () => navigate('/store-overview'),
        }),
      );
    }
  };

  useEffect(() => {
    if (!isManager || isImpersonatedSupplier) dispatch(storeInfoGetRequest());
  }, [dispatch, isManager, isImpersonatedSupplier]);

  return (
    <>
      {isImpersonatedSupplier && <ManagerBanner />}
      <AppBar
        position='sticky'
        className={`bluon-nav pl-[16px] ${
          isImpersonatedSupplier && '!top-7 mt-7'
        }`}
        color='inherit'
      >
        <Toolbar disableGutters>
          <Grid item xs className='container-logo'>
            <img
              src={BluonLogo}
              className='h-[39px] w-[84px]'
              alt='Bluon Live'
            />
            <Typography variant='subtitle2' color='primary'>
              CALL US: (657) 439-7658
            </Typography>
          </Grid>

          <Grid item xs={6} className='container-routes'>
            {filteredRoutes.map((route: NavRoute) => (
              <NavMenuItem
                key={route.title}
                path={route.path}
                text={route.title}
                icon={route.icon}
                badgeValue={getCounter(route.title)}
                onClick={(e) => {
                  if (route.path === '/store-overview' || disabledMenus)
                    e.preventDefault();
                  handleRemoveImpersonation(route);
                }}
              />
            ))}
          </Grid>
          <NavDropDown
            logo={logo}
            staff={staff}
            storeInfoBoolean={!!storeInfo}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
