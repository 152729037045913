import client from '..';

import { ApproveResponse, ItemsResponse, OrderResponse } from './interface';

export async function getOrder(params: {
  orderId: string;
}): Promise<OrderResponse> {
  const { data } = await client.get(
    `/unauthenticated/orders/${params.orderId}`,
  );

  return data;
}

export async function getItems(params: {
  orderId: string;
  page: number;
}): Promise<ItemsResponse> {
  const { data } = await client.get(
    `/unauthenticated/orders/${params.orderId}/items`,
    {
      params: { page: params.page },
    },
  );

  return data;
}

export async function approveOrderSummary(params: {
  orderId: string;
  name: string;
}): Promise<ApproveResponse> {
  const { data } = await client.post(
    `/unauthenticated/orders/${params.orderId}/approve`,
    {
      name: params.name,
    },
  );

  return data;
}
