import { Avatar, Grid } from '@mui/material';

import { MessageHeaderProps } from './interface';

import Button from '@components/Button';
const MessageHeader = ({
  currentChatSelected,
  onClickViewOrder,
  isLoadingOrderDetails,
}: MessageHeaderProps) => {
  return (
    <>
      <Grid container className='border-b border-gray-300 px-4 pb-2 pt-5'>
        <Grid item xs={6}>
          <div className='flex items-center'>
            <div className='flex basis-10 flex-col items-center justify-center gap-y-3'>
              <Avatar
                alt={currentChatSelected?.name || ''}
                src={currentChatSelected?.image?.conversions?.thumb as string}
              />
            </div>
            <div className='flex-1 pl-2'>
              <span className={'mb-1 flex text-sm  leading-4 text-black'}>
                {currentChatSelected?.name}
              </span>
              <span
                className={
                  'mb-1 flex text-xs font-normal  leading-4 text-black '
                }
              >
                {currentChatSelected?.company}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          {currentChatSelected?.last_order?.id && (
            <div className='text-right'>
              <Button
                sx={{
                  fontSize: '0.75rem',
                }}
                variant='outlined'
                color='primary'
                loading={isLoadingOrderDetails}
                onClick={onClickViewOrder}
              >
                VIEW LATEST ORDER
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MessageHeader;
