import ImageIcon from '@mui/icons-material/Image';
import {
  Button,
  FormHelperText,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { DragEvent, useEffect } from 'react';

import './styles.scss';
import {
  fileUploaderHelperTextTestId,
  fileUploaderSkeletonTestId,
} from './constants';
import { FileUploaderAreaProps } from './interface';

export const FILE_TYPES = ['jpg', 'png', 'jpeg'];
export const MAX_SIZE = 5242880; // 5 MB in bytes

const FileUploaderArea = (props: FileUploaderAreaProps): JSX.Element => {
  const {
    setFileValue,
    error,
    file,
    image,
    'data-testid': dataTestId,
    fieldName,
    isLoading,
  } = props;

  useEffect(() => {
    const reader = new FileReader();
    const logoImage = document.getElementById(
      `logoImage-${fieldName}`,
    ) as HTMLImageElement;

    if (file) {
      if (logoImage) {
        logoImage.title = file.name;

        reader.onload = (event) => {
          if (event && event.target)
            logoImage.src = event.target.result as string;
        };
      }
      setFileValue(file);
      reader.readAsDataURL(file);
    }
  }, [file, fieldName, setFileValue]);

  const uploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) setFileValue(file);
    }
  };

  const dropHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.items)
      for (let i = 0; i < event.dataTransfer.items.length; i++)
        if (event.dataTransfer.items[i].kind === 'file') {
          const file: File | null = event.dataTransfer.items[i].getAsFile();
          if (file) setFileValue(file);
        }
  };

  const dragOverHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const thisFileUpload = () => {
    if (isLoading) return;
    const file = document.getElementById(`file-upload-${fieldName}`);
    if (file) file.click();
  };

  return (
    <>
      <Box className='box-container'>
        <Grid item container xs>
          {isLoading ? (
            <Skeleton
              role='progressbar'
              data-testid={fileUploaderSkeletonTestId}
              className='drag-drop-skeleton'
              animation='wave'
            />
          ) : (
            <div
              className={
                error ? 'drag-drop-styles has-error' : 'drag-drop-styles'
              }
              data-testid={dataTestId && `${dataTestId}DragAndDropBox`}
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
            >
              {file || image ? (
                <img
                  id={`logoImage-${fieldName}`}
                  src={image ? image.conversions.thumb : ''}
                  alt='Your logo'
                  data-testid={dataTestId && `${dataTestId}Image`}
                />
              ) : (
                <>
                  <Typography variant='caption' color='initial'>
                    Drop file here
                  </Typography>
                  <Typography
                    variant='caption'
                    color='initial'
                    fontWeight='bold'
                  >
                    JPG, PNG
                  </Typography>
                </>
              )}
            </div>
          )}
        </Grid>

        <Grid item container xs className='button-area'>
          <Button
            variant='contained'
            color='primary'
            data-testid={dataTestId && `${dataTestId}UploadButton`}
            onClick={thisFileUpload}
            startIcon={<ImageIcon />}
          >
            Upload
          </Button>
          <input
            type='file'
            onChange={uploadHandler}
            data-testid={dataTestId && `${dataTestId}FileUploadInput`}
            id={`file-upload-${fieldName}`}
            name={`file-upload-${fieldName}`}
            accept='image/png, image/jpg'
            style={{ display: 'none' }}
          />
          <Typography variant='caption' lineHeight={2.5} color='initial'>
            Max size 5mb.
          </Typography>
        </Grid>
      </Box>
      <FormHelperText data-testid={fileUploaderHelperTextTestId}>
        {error && error.message}
      </FormHelperText>
    </>
  );
};

export default FileUploaderArea;
