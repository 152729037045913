import { Box } from '@mui/material';

import { modelsSpecificationsString } from '../../../../../../data/models';
import { SpecItem } from '../../../../../SpecItem';

import { TechSpecGroupProps } from './interface';

import { handleGetString } from '@utils/functions';

import './styles.scss';

export const TechSpecGroup = ({
  group,
  label,
  specDetails,
}: TechSpecGroupProps): JSX.Element => {
  return (
    <Box>
      <Box className='bl-spec-group-title'>{label}</Box>
      <Box className='bl-spec-group-items'>
        {specDetails &&
          Object.keys(specDetails).map((item: string) => {
            const humanizedString = handleGetString(
              { type: group },
              item,
              modelsSpecificationsString,
            );
            const specTitle =
              humanizedString === ''
                ? item.replace(/_/g, ' ')
                : humanizedString;

            return (
              <SpecItem
                key={`${label}${item}`}
                label={specTitle}
                value={specDetails[item]}
              />
            );
          })}
      </Box>
    </Box>
  );
};
