import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  FormControl,
  InputLabel,
  Button as MButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';

import CallForHelp from '../CallForHelp';
import DeclineOrCancelModal from '../DeclineOrCancelModal';

import Button from '@components/Button';
import { useSnackbar } from '@components/Snackbar/hooks';
import { SupplierStaff } from '@config/api/task/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { setCounterStaffRequest } from '@state/task/actions';
import {
  getCurrentSelectedOrder,
  getSupplierStaff,
} from '@state/task/selectors';
import { emptyFunction } from '@utils/functions';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

export const Footer = ({
  setModalReviewOpen,
  disabledButtonSend,
  sendButtonTooltip,
}: {
  setModalReviewOpen: () => void;
  disabledButtonSend: boolean;
  sendButtonTooltip: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const { order, supplierStaff } = useAppSelector((state) => ({
    order: getCurrentSelectedOrder(state),
    supplierStaff: getSupplierStaff(state as any),
  }));
  const [openDecline, setOpenDecline] = useState<boolean>(false);
  const techName = capitalizeFirstLetter(order?.user?.first_name || '');
  const [staff, setStaff] = useState<string>('');
  const [open, setOpen] = useState(false);

  const sendTo = `Send to ${techName}`.toUpperCase();

  //  Function to render decline modal
  const renderDeclineModal = () => {
    if (!openDecline || !order?.id) return null;

    return (
      <DeclineOrCancelModal
        orderId={String(order?.id)}
        onClose={() => setOpenDecline(false)}
        isOpen={openDecline}
      />
    );
  };

  const handleDispatchNewStaff = ({
    orderId,
    staffId,
  }: {
    orderId: string;
    staffId: string;
  }) => {
    dispatch(
      setCounterStaffRequest({
        order: orderId,
        staff: staffId,
        errorCallback: () => {
          showMessage('There was an error confirming the staff', {
            severity: 'error',
            autoHide: true,
          });
          const firstSupplierWithName = supplierStaff.find((supplier) => {
            if (supplier.name) return supplier;
          }) as SupplierStaff;
          setStaff(firstSupplierWithName.id);
        },
        successCallback: emptyFunction,
      }),
    );
  };

  const handleChange = (event: SelectChangeEvent<typeof staff>) => {
    const newStaff = supplierStaff.find(
      (item) => item.id === event.target.value,
    );
    handleDispatchNewStaff({
      orderId: order?.id as string,
      staffId: newStaff?.id as string,
    });

    setStaff(newStaff?.id as string);
  };

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (order && order.working_on_it && supplierStaff?.length) {
      const hasSomeone = supplierStaff.some(
        (item) => item.id === order?.working_on_it?.id,
      );
      setStaff(hasSomeone ? order?.working_on_it?.id : '');
    }
  }, [order, supplierStaff, order?.working_on_it]);

  return (
    <>
      {renderDeclineModal()}
      <div className='mt-6 flex w-full flex-col'>
        <div className='flex justify-center'>
          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id='demo-controlled-open-select-label'>
                Sending As
              </InputLabel>
              <Select
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={staff}
                label='Sending As'
                onChange={handleChange}
                style={{
                  maxWidth: '13.75em',
                }}
              >
                {supplierStaff?.map((item, index) => {
                  return item.name ? (
                    <MenuItem key={item.id + index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ) : null;
                })}
              </Select>
            </FormControl>
          </div>
          <div className='mt-2'>
            <Tooltip
              title={disabledButtonSend ? sendButtonTooltip : ''}
              placement='top'
            >
              <Box>
                <MButton
                  variant='contained'
                  disabled={disabledButtonSend}
                  className='h-14'
                  onClick={setModalReviewOpen}
                >
                  {sendTo}
                </MButton>
              </Box>
            </Tooltip>
          </div>
        </div>
        <div className='mb-5 flex justify-center text-xs font-normal leading-5 text-[var(--bluon--ds--lightgray-1)]'>
          All items must be completed and totals entered
        </div>
        <div className='flex justify-center'>
          <Button
            variant='text'
            color='error'
            startIcon={<CloseIcon />}
            onClick={() => setOpenDecline(true)}
          >
            Decline Request
          </Button>
        </div>
        <CallForHelp />
      </div>
    </>
  );
};
