import { Box, TextField } from '@mui/material';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';

import { OtherTextAreaProps } from './interface';

const OtherTextArea = ({
  formControl,
  maxLength,
  maxLengthReached,
  showErrorColor,
  errors,
  hideCountNumber,
}: OtherTextAreaProps) => {
  return (
    <Controller
      name='otherText'
      control={formControl}
      render={({ field }) => (
        <TextField
          id='otherText'
          label='Reason (Required)'
          placeholder='Reason (Required)'
          autoFocus
          variant='outlined'
          multiline
          inputProps={{ maxLength }}
          maxRows={5}
          name={field.name}
          onChange={(e) => {
            const value = e.target.value;
            if (!!value && value.length > maxLength) return;
            field.onChange(e);
          }}
          value={field.value}
          fullWidth
          onBlur={field.onBlur}
          className={classNames('w-full md:w-[36.125rem]', {
            red: maxLengthReached,
          })}
          color='textFieldPrimary'
          error={!!errors.otherText}
          helperText={
            <>
              {errors.otherText?.message}
              {!hideCountNumber && (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  className='float-right'
                >
                  <span
                    className={classNames('font-normal', {
                      'text-[var(--bluon--ds--lightred)]': showErrorColor,
                      'text-[var(--bluon--ds--light-black)]': !showErrorColor,
                    })}
                  >{`${Number(field.value?.length)} / ${maxLength}`}</span>
                </Box>
              )}
            </>
          }
        />
      )}
    />
  );
};

export default OtherTextArea;
