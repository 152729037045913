import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect } from 'react';
import './styles.scss';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';

import { TermsOfServiceDialog } from './interface';
import { termsText } from './terms';

import { readTermsOfService } from '@state/auth/actions';

export const TermsOfService = (props: TermsOfServiceDialog): JSX.Element => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();

  const intersectionCallback: IntersectionObserverCallback = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) dispatch(readTermsOfService(true));
  };

  const scrollDownRef = useCallback(
    (ref) => {
      if (ref === null) return;

      const scrollHitsBottomObs = new IntersectionObserver(
        intersectionCallback,
      );
      scrollHitsBottomObs.observe(ref);
    },
    [open],
  );

  useEffect(() => {
    dispatch(readTermsOfService(false));
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Box className='terms-of-service-container'>
          <Grid item container xs>
            <Typography className='terms-title' variant='h6' color='initial'>
              Terms of Service and Privacy Policies. Bluon, Inc.
            </Typography>
          </Grid>

          <Grid item container xs justifyContent='center'>
            <div className='terms-container'>
              <ReactMarkdown>{termsText}</ReactMarkdown>
              <div
                ref={scrollDownRef}
                className='intersection-observer-dummy'
              ></div>
            </div>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
