import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import './App.scss';
import Analytics from './assets/js/analytics';
import NavBar from './components/NavBar';
import { ThankYouModal } from './components/ThankYouModal';
import { ConfigRoute } from './config/routing/interface';
import {
  AuthenticatedRoutes,
  ConfigRoutes,
  RequireAuth,
} from './config/routing/Routes';
import { GlobalComponents } from './Global';
import { useAppDispatch, useAppSelector } from './hooks/state';
import { loginRequest, logout } from './state/auth/actions';
import { getLoginInfoDataInitialPass } from './state/auth/selectors';
import { dashboardSetShowThankYouModal } from './state/dashboard/actions';
import { getShowThankYouModal } from './state/dashboard/selectors';
import { isRequestRunning } from './state/requests/selectors';
import { getStoreInfoData } from './state/storeInfo/selectors';
import { emptyFunction } from './utils/functions';

function App(): JSX.Element {
  const queryClient = new QueryClient();
  const environment = process ? process.env : null;

  useEffect(() => {
    if (environment?.REACT_APP_ENV_NAME === 'production')
      Analytics.account.register();
  }, []);

  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();

  const shouldShowNavigationBar = AuthenticatedRoutes.some(({ path }) =>
    pathname.includes(path),
  );
  const { logged, loginLoading, showThankYouModal } = useAppSelector(
    (state) => ({
      logged: getLoginInfoDataInitialPass(state),
      loginLoading: isRequestRunning(state, String(loginRequest)),
      supplierLoggedId: getStoreInfoData(state)?.id,
      showThankYouModal: getShowThankYouModal(state),
    }),
  );

  useEffect(() => {
    if (pathname === '/login' && logged && !loginLoading)
      dispatch(logout(() => emptyFunction()));
  }, [pathname]);

  const handleCloseThankYouModal = () => {
    dispatch(dashboardSetShowThankYouModal(false));
  };

  return (
    <>
      {showThankYouModal && (
        <ThankYouModal
          open={showThankYouModal}
          handleClose={handleCloseThankYouModal}
        />
      )}
      {shouldShowNavigationBar && <NavBar />}

      <div className='App' data-testid='AppDiv'>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <Routes>
            {ConfigRoutes.map((route: ConfigRoute) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.loginRequired ? (
                    <RequireAuth
                      redirectTo={`/login?redirect=${route.path}${
                        search ? encodeURIComponent(search) : ''
                      }`}
                    >
                      <>
                        <route.component />
                        <GlobalComponents />
                      </>
                    </RequireAuth>
                  ) : (
                    <route.component />
                  )
                }
              />
            ))}
            <Route path='/' element={<Navigate to='/login' />} />
          </Routes>
        </QueryClientProvider>
      </div>
    </>
  );
}

export default Sentry.withProfiler(App);
