import Button from '../../../../../../components/Button';
import { useSnackbar } from '../../../../../../components/Snackbar/hooks';
import { ActionButtonProps } from '../../interface';

import { useAppDispatch } from '@hooks/state';
import { unRemoveCustomerRequest } from '@state/customer/actions';
import './styles.scss';

export const MoveToPending = ({ userId }: ActionButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const handleOnClick = async () => {
    dispatch(
      unRemoveCustomerRequest({
        userId,
        errorCallback: () => {
          showMessage('There was an error editing the customer', {
            severity: 'error',
          });
        },
        successCallback: () => {
          showMessage('The customer has been edited successfully', {
            severity: 'success',
          });
        },
      }),
    );
  };

  return (
    <Button
      className='bl-move-to-pending-button'
      variant='contained'
      onClick={handleOnClick}
    >
      Move To Pending
    </Button>
  );
};
