/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import makeRequest from '../requests/make-request';
import { getRequestError } from '../requests/selectors';

import {
  modelPartRequest,
  modelSpecificationsRequest,
  modelSpecificationsResponse,
  modelsRequest,
  modelsResponse,
  moreModelPartRequest,
  moreModelsRequest,
  moreModelsResponse,
  morePartReplacementRequest,
  morePartReplacementResponse,
  morePartsRequest,
  morePartsResponse,
  moreSeriesRequest,
  moreSeriesResponse,
  partDetailRequest,
  partDetailResetRequest,
  partDetailResetResponse,
  partDetailResponse,
  partReplacementDetailRequest,
  partReplacementDetailResetRequest,
  partReplacementDetailResetResponse,
  partReplacementDetailResponse,
  partReplacementRequest,
  partReplacementResponse,
  partsRequest,
  partsResetRequest,
  partsResetResponse,
  partsResponse,
  previousModelPartRequest,
  previousModelPartResponse,
  previousPartsRequest,
  previousPartsResponse,
  previousSearchModelsRequest,
  previousSearchModelsResponse,
  searchModelsRequest,
  searchModelsResponse,
  searchMoreModelsRequest,
  searchMoreModelsResponse,
  searchPartRecommendReplacementsRequest,
  searchPartRecommendReplacementsResponse,
  seriesRequest,
  seriesResponse,
} from './actions';
import {
  GetModelPartsActionParamsProps,
  GetModelSpecificationsActionParamsProps,
  GetModelsActionParamsProps,
  GetPartDetailsActionParamsProps,
  GetPartReplacementsActionParamsProps,
  GetPartsActionParamsProps,
  GetSearchModelsActionParamsProps,
  GetSeriesActionParamsProps,
  SearchRecommendReplacementsParamProps,
  SearchStateProps,
} from './interface';

import {
  getModelParts,
  getModelSpecifications,
  getModels,
  getPartDetail,
  getPartReplacements,
  getParts,
  getSearchModels,
  getSeries,
  recommendPartReplacements,
} from '@config/api/search';

export function* workerMoreSeriesRequest(
  action: GetSeriesActionParamsProps,
): Generator<any> {
  const { brandId, search } = action.payload;
  const call: any = Eff.call;

  const state = yield select((state) => state);
  const searchReducer = (state as any).searchReducer as SearchStateProps;

  yield call(makeRequest, {
    endpoint: getSeries,
    params: { page: searchReducer.series.nextPage, brandId, search },
    requestAction: action,
    receiveAction: moreSeriesResponse,
  });
}

export function* workerGetSeries(
  action: GetSeriesActionParamsProps,
): Generator<any> {
  const { brandId, search } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getSeries,
    params: { page: 1, brandId, search },
    requestAction: action,
    receiveAction: seriesResponse,
  });
}

export function* workerGetModels(
  action: GetModelsActionParamsProps,
): Generator<any> {
  const { page, brandId, serieId, search } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getModels,
    params: { page, brandId, serieId, search },
    requestAction: action,
    receiveAction: modelsResponse,
  });
}

export function* workerMoreModelsRequest(
  action: GetModelsActionParamsProps,
): Generator<any> {
  const { brandId, serieId, search } = action.payload;
  const call: any = Eff.call;

  const state = yield select((state) => state);
  const searchReducer = (state as any).searchReducer as SearchStateProps;

  yield call(makeRequest, {
    endpoint: getModels,
    params: { page: searchReducer.models.nextPage, brandId, serieId, search },
    requestAction: action,
    receiveAction: moreModelsResponse,
  });
}

export function* workerGetModelSpecifications(
  action: GetModelSpecificationsActionParamsProps,
): Generator<any> {
  const { page, modelId } = action.payload;
  const call: any = Eff.call;
  const put: any = Eff.put;

  yield call(makeRequest, {
    endpoint: getModelSpecifications,
    params: { modelId },
    requestAction: action,
    receiveAction: modelSpecificationsResponse,
  });

  yield put(modelPartRequest({ page, modelId }));
}

export function* workerGetModelParts(
  action: GetModelPartsActionParamsProps,
): Generator<any> {
  const { page, modelId } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getModelParts,
    params: { page, modelId },
    requestAction: action,
    receiveAction: partsResponse,
  });
}

export function* workerGetMoreModelParts(
  action: GetModelPartsActionParamsProps,
): Generator<any> {
  const { modelId } = action.payload;
  const call: any = Eff.call;

  const state = yield select((state) => state);
  const searchReducer = (state as any).searchReducer as SearchStateProps;

  yield call(makeRequest, {
    endpoint: getModelParts,
    params: { page: searchReducer.parts.nextPage, modelId },
    requestAction: action,
    receiveAction: morePartsResponse,
  });
}

export function* workerGetParts(
  action: GetPartsActionParamsProps,
): Generator<any> {
  const { page, number } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getParts,
    params: { page, number },
    requestAction: action,
    receiveAction: partsResponse,
  });
}

export function* workerResetPart(): Generator<any> {
  const put: any = Eff.put;

  yield put(partsResetResponse());
}

export function* workerMorePartsRequest(
  action: GetPartsActionParamsProps,
): Generator<any> {
  const { number } = action.payload;
  const call: any = Eff.call;

  const state = yield select((state) => state);
  const searchReducer = (state as any).searchReducer as SearchStateProps;

  yield call(makeRequest, {
    endpoint: getParts,
    params: { page: searchReducer.parts.nextPage, number },
    requestAction: action,
    receiveAction: morePartsResponse,
  });
}

export function* workerGetPartDetails(
  action: GetPartDetailsActionParamsProps,
): Generator<any> {
  const { partId } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getPartDetail,
    params: { partId },
    requestAction: action,
    receiveAction: partDetailResponse,
  });
}

export function* workerResetPartDetails(): Generator<any> {
  const put: any = Eff.put;

  yield put(partDetailResetResponse());
}

export function* workerGetPartReplacementDetails(
  action: GetPartDetailsActionParamsProps,
): Generator<any> {
  const { partId } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getPartDetail,
    params: { partId },
    requestAction: action,
    receiveAction: partReplacementDetailResponse,
  });
}

export function* workerResetPartReplacementDetails(): Generator<any> {
  const put: any = Eff.put;

  yield put(partReplacementDetailResetResponse());
}

export function* workerGetPartReplacements(
  action: GetPartReplacementsActionParamsProps,
): Generator<any> {
  const { partId } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getPartReplacements,
    params: { partId, page: 1 },
    requestAction: action,
    receiveAction: partReplacementResponse,
  });
}

export function* workerGetMorePartReplacements(
  action: GetPartReplacementsActionParamsProps,
): Generator<any> {
  const { partId } = action.payload;
  const call: any = Eff.call;

  const state = yield select((state) => state);
  const searchReducer = (state as any).searchReducer as SearchStateProps;

  yield call(makeRequest, {
    endpoint: getPartReplacements,
    params: { partId, page: searchReducer.parts.replacements.nextPage },
    requestAction: action,
    receiveAction: morePartReplacementResponse,
  });
}

export function* workerGetSearchModels(
  action: GetSearchModelsActionParamsProps,
): Generator<any> {
  const { page, model } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getSearchModels,
    params: { page, model },
    requestAction: action,
    receiveAction: searchModelsResponse,
  });
}

export function* workerGetMoreSeachModelsRequest(
  action: GetSearchModelsActionParamsProps,
): Generator<any> {
  const { model } = action.payload;
  const call: any = Eff.call;

  const state = yield select((state) => state);
  const searchReducer = (state as any).searchReducer as SearchStateProps;

  yield call(makeRequest, {
    endpoint: getSearchModels,
    params: { page: searchReducer.searchModel.nextPage, model },
    requestAction: action,
    receiveAction: searchMoreModelsResponse,
  });
}

export function* workerRecommendReplacements(
  action: SearchRecommendReplacementsParamProps,
): Generator<any> {
  const { partId, brand, partNumber, note, successCallback, errorCallback } =
    action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: recommendPartReplacements,
    params: { partId, brand, partNumber, note },
    requestAction: action,
    receiveAction: searchPartRecommendReplacementsResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, searchPartRecommendReplacementsRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerGetPreviousParts(
  action: GetPartsActionParamsProps,
): Generator<any> {
  const { page, number } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getParts,
    params: { page, number },
    requestAction: action,
    receiveAction: previousPartsResponse,
  });
}

export function* workerGetPreviousSearchModels(
  action: GetSearchModelsActionParamsProps,
): Generator<any> {
  const { page, model } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getSearchModels,
    params: { page, model },
    requestAction: action,
    receiveAction: previousSearchModelsResponse,
  });
}

export function* workerGetPreviousModelPart(
  action: GetModelPartsActionParamsProps,
): Generator<any> {
  const { page, modelId } = action.payload;
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getModelParts,
    params: { page, modelId },
    requestAction: action,
    receiveAction: previousModelPartResponse,
  });
}

export default function* searchSagas(): Generator<any> {
  yield all([
    takeLatest(seriesRequest, workerGetSeries),
    takeLatest(moreSeriesRequest, workerMoreSeriesRequest),
    takeLatest(modelsRequest, workerGetModels),
    takeLatest(moreModelsRequest, workerMoreModelsRequest),
    takeLatest(modelSpecificationsRequest, workerGetModelSpecifications),
    takeLatest(partsRequest, workerGetParts),
    takeLatest(partsResetRequest, workerResetPart),
    takeLatest(morePartsRequest, workerMorePartsRequest),
    takeLatest(partDetailRequest, workerGetPartDetails),
    takeLatest(partDetailResetRequest, workerResetPartDetails),
    takeLatest(partReplacementDetailRequest, workerGetPartReplacementDetails),
    takeLatest(
      partReplacementDetailResetRequest,
      workerResetPartReplacementDetails,
    ),
    takeLatest(partReplacementRequest, workerGetPartReplacements),
    takeLatest(morePartReplacementRequest, workerGetMorePartReplacements),
    takeLatest(modelPartRequest, workerGetModelParts),
    takeLatest(moreModelPartRequest, workerGetMoreModelParts),
    takeLatest(searchModelsRequest, workerGetSearchModels),
    takeLatest(searchMoreModelsRequest, workerGetMoreSeachModelsRequest),
    takeLatest(
      searchPartRecommendReplacementsRequest,
      workerRecommendReplacements,
    ),
    takeLatest(previousPartsRequest, workerGetPreviousParts),
    takeLatest(previousSearchModelsRequest, workerGetPreviousSearchModels),
    takeLatest(previousModelPartRequest, workerGetPreviousModelPart),
  ]);
}
