/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import makeRequest from '../requests/make-request';
import { getRequestError } from '../requests/selectors';

import {
  confirmCustomerRequest,
  confirmCustomerResponse,
  getCustomerListRequest,
  getCustomerListResponse,
  getRemovedCustomerListResponse,
  removeCustomerRequest,
  removeCustomerResponse,
  unRemoveCustomerRequest,
  unconfirmCustomerRequest,
  unconfirmCustomerResponse,
  updateCustomerRequest,
  updateCustomerResponse,
} from './actions';
import { CustomerParamsProps, UpdateCustomerActionProps } from './interface';

import {
  confirmCustomer,
  getCustomerList,
  getRemovedCustomerList,
  removeCustomer,
  unconfirmCustomer,
  unremoveCustomer,
  updateCustomer,
} from '@config/api/customer';

export function* getCustomerListHelper(
  action: CustomerParamsProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getCustomerList,
    requestAction: action,
    receiveAction: getCustomerListResponse,
  });

  yield call(makeRequest, {
    endpoint: getRemovedCustomerList,
    requestAction: action,
    receiveAction: getRemovedCustomerListResponse,
  });
}

export function* workerGetCustomerList(
  action: CustomerParamsProps,
): Generator<any> {
  yield getCustomerListHelper(action);
}

export function* workerConfirmCustomer(
  action: UpdateCustomerActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { userId, payload, errorCallback, successCallback } = action.payload;
  yield call(makeRequest, {
    endpoint: confirmCustomer,
    params: { userId, payload },
    requestAction: action,
    receiveAction: confirmCustomerResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, confirmCustomerRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerUnconfirmCustomer(
  action: UpdateCustomerActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { userId, payload, errorCallback, successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: unconfirmCustomer,
    params: { userId, payload },
    requestAction: action,
    receiveAction: unconfirmCustomerResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, unconfirmCustomerRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerUpdateCustomer(
  action: UpdateCustomerActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { userId, payload, errorCallback, successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: updateCustomer,
    params: { userId, payload },
    requestAction: action,
    receiveAction: updateCustomerResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, updateCustomerRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerRemoveCustomer(
  action: UpdateCustomerActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { userId, errorCallback, successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: removeCustomer,
    params: { userId },
    requestAction: action,
    receiveAction: removeCustomerResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, removeCustomerRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerUnremoveCustomer(
  action: UpdateCustomerActionProps,
): Generator<any> {
  const call: any = Eff.call;
  const { userId, errorCallback, successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: unremoveCustomer,
    params: { userId },
    requestAction: action,
  });

  const error = yield select((state) =>
    getRequestError(state, unRemoveCustomerRequest),
  );

  if (!error) yield getCustomerListHelper(action);

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export default function* storeSagas(): Generator<any> {
  yield all([
    takeLatest(getCustomerListRequest, workerGetCustomerList),
    takeLatest(confirmCustomerRequest, workerConfirmCustomer),
    takeLatest(unconfirmCustomerRequest, workerUnconfirmCustomer),
    takeLatest(updateCustomerRequest, workerUpdateCustomer),
    takeLatest(removeCustomerRequest, workerRemoveCustomer),
    takeLatest(unRemoveCustomerRequest, workerUnremoveCustomer),
  ]);
}
