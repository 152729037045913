import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';

import { WelcomeModalProps } from './interface';

import WelcomeGif from '@assets/gifs/WelcomeGif.gif';
import GiftBox from '@assets/images/GiftBox.png';

import './styles.scss';

export const WelcomeModal = (props: WelcomeModalProps): JSX.Element => {
  const { open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose} className='dialog-container'>
      <img src={WelcomeGif} alt='Welcome Gif' />
      <DialogContent>
        <Grid container className='title-container'>
          <Grid item xs={12}>
            <Typography
              variant='h4'
              fontWeight='bold'
              fontSize='1.75rem'
              color='initial'
              textAlign='center'
            >
              We are thrilled to have you here!
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='center'>
          <Grid item xs={10}>
            <Typography variant='body1' color='initial' textAlign='center'>
              You made it and we really appreciate your time! We just need some
              extra info about your store to ensure the best experience to our
              community.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          xs
          item
          container
          justifyContent='center'
          className='gift-box-container'
        >
          <img src={GiftBox} alt='Gift box' />
        </Grid>

        <Grid container justifyContent='center'>
          <Grid item xs={10}>
            <Typography variant='body1' color='initial' textAlign='center'>
              But don’t worry, we will take good care of you 😉.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <Button
          className='start-button'
          variant='contained'
          color='primary'
          data-testid='startButton'
          startIcon={<ArrowForwardIcon />}
          onClick={handleClose}
        >
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
};
