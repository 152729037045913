import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WindowIcon from '@mui/icons-material/Window';
import { Navigate } from 'react-router-dom';

import { ConfigRoute, NavRoute, RequireAuthInterface } from './interface';

import { Account } from '@pages/Account';
import { Customer } from '@pages/Account/subpages/Customer';
import { BluonSearch } from '@pages/BluonSearch';
import ShareableOrderDetail from '@pages/Contractor/ShareableOrderDetail';
import { Help } from '@pages/Help';
import { ContractorLogin } from '@pages/Login/ContractorLogin';
import { LoginComponent } from '@pages/Login/DefaultLogin';
import { ForgotPasswordPage } from '@pages/Login/ForgotPassword';
import { ResetPassword } from '@pages/Login/ResetPassword';
import { SetNewPassword } from '@pages/Login/SetNewPassword';
import { StoreOverview } from '@pages/Manager/StoreOverview';
import OrdersLogs from '@pages/OrdersLogs';
import { Tasks } from '@pages/Tasks';
import { isLogged } from '@utils/Auth/AuthUtils';

export const ManagerNavRoutes: NavRoute[] = [
  {
    path: '/store-overview',
    title: 'Stores',
    icon: <WarehouseIcon />,
  },
];

export const SupplierNavRoutes: NavRoute[] = [
  {
    path: '/bluon-search',
    title: 'Search',
    icon: <SearchIcon />,
  },
  {
    path: '/tasks',
    title: 'Tasks',
    icon: <WindowIcon />,
  },
  {
    path: '/customers',
    title: 'Customers',
    icon: <SupervisorAccountIcon />,
  },
];

export const ImpersonatedSupplierNavRoutes: NavRoute[] = [
  ...ManagerNavRoutes,
  ...SupplierNavRoutes,
];

export const LogOutDropdownRoute: NavRoute[] = [
  {
    path: '',
    title: 'Log Out',
    icon: <LogoutIcon />,
  },
];

export const DropdownRoutes: NavRoute[] = [
  {
    path: '/account',
    title: 'Account',
    icon: <PersonIcon />,
  },
  {
    path: 'https://www.bluon.com/BluonLiveFeedback.php',
    target: '_blank',
    type: 'external',
    title: 'Feedback',
    icon: <ChatBubbleOutlineOutlinedIcon />,
  },
  ...LogOutDropdownRoute,
];

export function RequireAuth({
  children,
  redirectTo,
}: RequireAuthInterface): JSX.Element {
  const isAuthenticated = isLogged();

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export const ManagerAuthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/store-overview',
    component: StoreOverview,
    loginRequired: true,
  },
];

export const AuthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/customers',
    component: Customer,
    loginRequired: true,
  },
  {
    path: '/tasks',
    component: Tasks,
    loginRequired: true,
  },
  {
    path: '/bluon-search',
    component: BluonSearch,
    loginRequired: true,
  },
  {
    path: '/account',
    component: Account,
    loginRequired: true,
  },
  {
    path: '/feedback',
    component: Help,
    loginRequired: true,
  },
  {
    path: '/help',
    component: Help,
    loginRequired: true,
  },
  {
    path: '/tasks/logs',
    component: OrdersLogs,
    loginRequired: true,
  },
  ...ManagerAuthenticatedRoutes,
];

export const ContractorUnauthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/contractor/login',
    component: ContractorLogin,
    loginRequired: false,
  },
  {
    path: '/order-summary',
    component: ShareableOrderDetail,
    loginRequired: false,
  },
];

export const UnauthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/login',
    component: LoginComponent,
    loginRequired: false,
  },
  {
    path: '/login/set-new-password',
    component: SetNewPassword,
    loginRequired: true,
  },
  {
    path: '/login/forgot-password',
    component: ForgotPasswordPage,
    loginRequired: false,
  },
  {
    path: '/password-reset/:token',
    component: ResetPassword,
    loginRequired: false,
  },
  ...ContractorUnauthenticatedRoutes,
];

export const ConfigRoutes: ConfigRoute[] = [
  ...UnauthenticatedRoutes,
  ...AuthenticatedRoutes,
];
