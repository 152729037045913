import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: commenting that tab according with [GNDR-272]
// import { Invoice } from './subpages/Invoice/index';
import { TabPanel } from '../../components/TabPanel';

import { Notification } from './subpages/Notification';
import { StoreInfo } from './subpages/StoreInfo';

import LogoutIcon from '@assets/icons/LogoutIconBlue.svg';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { logout } from '@state/auth/actions';
import { getStoreInfoData } from '@state/storeInfo/selectors';

import './styles.scss';

const setProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const Account = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data } = useAppSelector((state) => ({
    data: getStoreInfoData(state),
  }));

  useEffect(() => {
    if (data) if (!data.verified_at) setValue(0);
  }, [data]);

  return (
    <div className='bl-account-container '>
      <Box
        className='bl-account-container-tab-and-logout'
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab data-testid='accountStoreTab' label='Store' {...setProps(0)} />
          <Tab label='Notifications' {...setProps(1)} />
          {/* TODO: commenting that tab according with [GNDR-272] */}
          {/* <Tab label='Invoices' {...setProps(2)} /> */}
        </Tabs>
        <button
          onClick={() => dispatch(logout(() => navigate('/login')))}
          className='bl-account-log-out-container'
        >
          <img src={LogoutIcon} />
          <Typography className='logout-text' variant='body2' color='initial'>
            Log Out
          </Typography>
        </button>
      </Box>
      <Box className='bl-account-sub-container'>
        <TabPanel value={value} index={0}>
          <StoreInfo />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Notification />
        </TabPanel>
        {/* TODO: commenting that tab according with [GNDR-272] */}
        {/* <TabPanel value={value} index={2}>
          <Invoice />
        </TabPanel> */}
      </Box>
    </div>
  );
};
