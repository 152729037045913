/* eslint-disable @typescript-eslint/no-explicit-any */
import client from '..';

import {
  CustomerDataProps,
  CustomerListDataResponse,
  CustomerParams,
} from './interface';

export async function getCustomerList(): Promise<CustomerListDataResponse> {
  const { data } = await client.get('/users');

  return data;
}

export async function confirmCustomer({
  userId,
  payload,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.post(
    `/confirmed-users/${userId}/confirm`,
    payload,
  );

  return data;
}

export async function unconfirmCustomer({
  userId,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.delete(`/confirmed-users/${userId}/confirm`);

  return data;
}

export async function updateCustomer({
  userId,
  payload,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.patch(`/confirmed-users/${userId}`, payload);

  return { data: { ...data.data, id: +userId } };
}

export async function removeCustomer({
  userId,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.post(`/removed-users/${userId}`);

  return data;
}

export async function unremoveCustomer({
  userId,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.delete(`/removed-users/${userId}`);

  return data;
}

export async function getRemovedCustomerList(): Promise<CustomerDataProps> {
  const { data } = await client.get('/removed-users');

  return data;
}
