import { Chip } from '../Chip';

import { ChipListProps } from './interface';
import './styles.scss';

export const ChipList = ({
  data,
  onDeleteItem,
  'data-testid': dataTestId,
}: ChipListProps): JSX.Element => {
  return (
    <ul
      className='clip-list-container'
      data-testid={dataTestId && `${dataTestId}ClipListContainer`}
    >
      {data.map((chip) => {
        return (
          <li className='clip-list-item' key={chip.id}>
            <Chip
              data-testid={dataTestId && `${dataTestId}ChipListItem`}
              label={chip.label}
              onDelete={onDeleteItem && (() => onDeleteItem(chip.id))}
            />
          </li>
        );
      })}
    </ul>
  );
};
