import CloseIcon from '@mui/icons-material/Close';
import { Box, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import CallForHelp from '../CallForHelp';
import DeclineOrCancelModal from '../DeclineOrCancelModal';

import { MarkAsCompleteProps } from './interface';

// eslint-disable-next-line import/order
import Button from '@components/Button';

import './styles.scss';

import { EDeliveryType, EOrderSubstatus } from '@utils/enums';

export const MarkAsCompleteFooter = ({
  order,
  isUpdateLoading,
  completeDisabled,
  isCompleteLoading,
  updateButtonTooltip,
  updateButtonDisabled,
  updateButtonCallback,
  completeButtonCallback,
}: MarkAsCompleteProps) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const orderStatus = order?.current_status?.substatus;
  const deliveryType = order?.delivery?.type;
  const canSupplierCancel = orderStatus !== EOrderSubstatus.APPROVED_DELIVERED;
  const isPickUpDelivered =
    deliveryType === EDeliveryType.PICKUP &&
    (orderStatus === EOrderSubstatus.APPROVED_READY_DELIVERY ||
      orderStatus === EOrderSubstatus.APPROVED_DELIVERED);

  const getButtonLabel = () => {
    if (
      deliveryType === EDeliveryType.SHIPMENT_DELIVERY &&
      orderStatus === EOrderSubstatus.APPROVED_AWAITING_DELIVERY
    )
      return 'ORDER SHIPPED';

    return 'ORDER PICKED UP';
  };

  const isUpdateButtonDisabled = () => {
    if (isPickUpDelivered) return true;

    return updateButtonDisabled;
  };

  const renderCancelModal = () => {
    if (!showCancelModal) return null;

    return (
      <DeclineOrCancelModal
        orderId={String(order?.id)}
        onClose={() => setShowCancelModal(false)}
        isOpen={showCancelModal}
        isCancel
      />
    );
  };

  return (
    <div className='bl-mark-as-complete-container mt-6 w-full'>
      <div className='flex flex-row items-center justify-center gap-4'>
        <Tooltip
          title={
            !isPickUpDelivered && updateButtonDisabled
              ? updateButtonTooltip
              : ''
          }
          placement='top'
        >
          <Box>
            <Button
              variant='contained'
              className='update-button'
              loading={isUpdateLoading}
              disabled={
                isUpdateButtonDisabled() || isUpdateLoading || isCompleteLoading
              }
              onClick={updateButtonCallback}
            >
              UPDATE
            </Button>
          </Box>
        </Tooltip>

        <Button
          variant='contained'
          color='success'
          className='picked-up-button'
          disabled={completeDisabled || isCompleteLoading || isUpdateLoading}
          loading={isCompleteLoading}
          onClick={completeButtonCallback}
        >
          {getButtonLabel()}
        </Button>
      </div>

      {canSupplierCancel && (
        <div className='mt-4 flex flex-row items-center justify-center'>
          <Button
            variant='outlined'
            color='error'
            startIcon={<CloseIcon />}
            onClick={() => setShowCancelModal(true)}
          >
            CANCEL REQUEST
          </Button>
        </div>
      )}
      <CallForHelp />
      {renderCancelModal()}
    </div>
  );
};
