import { yupResolver } from '@hookform/resolvers/yup';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, Chip, Grid, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LogsFilterFormData, LogsFilterProps } from './interface';
import LogsDatePickerPopover from './LogsDatePickPopover'; // Asegúrate de importarlo desde la ruta correcta
const LogsFilter = ({ onSubmitLogFilter }: LogsFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const open = Boolean(anchorEl);

  const schema = yup.object().shape({
    status: yup.string().required(),
    fromDate: open
      ? yup.date().required('This field is required')
      : yup.date().nullable(),
    toDate: open
      ? yup
          .date()
          .required('This field is required')
          .when(
            'fromDate',
            (fromDate, yup) =>
              fromDate &&
              yup.min(fromDate, "To date can't be before from date"),
          )
      : yup.date().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    getValues,
    watch,
  } = useForm<LogsFilterFormData>({
    defaultValues: {
      status: 'all',
      fromDate: undefined,
      toDate: undefined,
    },
    resolver: yupResolver(schema),
  });

  const handleCalendarClick = (event: {
    currentTarget: SetStateAction<HTMLElement | null>;
  }) => {
    if (isFilterApplied) {
      reset();
      setIsFilterApplied(false);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    if (isFilterApplied || watch('status')) handleSubmit(onSubmitLogFilter)();
  }, [isFilterApplied, watch('status')]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSubmitDatePicker = () => {
    trigger(['fromDate', 'toDate']).then((isValid) => {
      if (isValid) {
        handleClose();
        setIsFilterApplied(true);
      }
    });
  };

  return (
    <>
      <form>
        <Grid
          container
          alignItems='center'
          justifyContent={'space-between'}
          spacing={2}
        >
          {/*     <Grid item xs>
            <Box display='flex' justifyContent='flex-end'>
              <h3>Filter By Status:</h3>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='flex-end'>
              <Controller
                name='status'
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id='status-label'>Filter</InputLabel>
                    <Select
                      className='w-full'
                      labelId='status-label'
                      id='status'
                      placeholder=''
                      label='Filter'
                      {...field}
                    >
                      <MenuItem value={'all'}>All</MenuItem>
                      <MenuItem value={'sent-request'}>Sent Request</MenuItem>
                      <MenuItem value={'approved'}>Approved Orders</MenuItem>
                      <MenuItem value={'invoice'}>Invoice Needed</MenuItem>
                      <MenuItem value={'completed'}>Completed Orders</MenuItem>
                      <MenuItem value={'canceled'}>Canceled Orders</MenuItem>
                      <MenuItem value={'open'}>Open Task</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          </Grid> */}
          <Grid item xs={/* isFilterApplied ? 4 : 1 */ 12}>
            <Box display='flex' justifyContent='flex-end'>
              {isFilterApplied ? (
                <Chip
                  className='!rounded-3xl !border-0 !bg-[var(--bluon--light-blue--background)] !pb-7 !pl-2 !pr-2 !pt-7'
                  size='medium'
                  color='primary'
                  icon={<CalendarTodayIcon className='!mr-1' />}
                  label={
                    <div className='!text-sm font-normal'>
                      {dayjs(getValues('fromDate')).format('MM/DD/YYYY')} -{' '}
                      {dayjs(getValues('toDate')).format('MM/DD/YYYY')}
                    </div>
                  }
                  onDelete={() => {
                    reset();
                    setIsFilterApplied(false);
                  }}
                  variant='outlined'
                  deleteIcon={<CancelIcon />}
                />
              ) : (
                <IconButton onClick={handleCalendarClick}>
                  <DateRangeIcon className='text-blue-500' />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
      <LogsDatePickerPopover
        errors={errors}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        control={control}
        watch={watch}
        onSubmit={onSubmitDatePicker}
      />
    </>
  );
};

export default LogsFilter;
