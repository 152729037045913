import { createAction } from 'redux-actions';

export const getCustomerListRequest = createAction(
  'customer/customer-list-request',
);

export const getCustomerListResponse = createAction(
  'customer/customer-list-response',
);

export const confirmCustomerRequest = createAction('customer/confirm-request');

export const confirmCustomerResponse = createAction(
  'customer/confirm-response',
);

export const unconfirmCustomerRequest = createAction(
  'customer/unconfirm-request',
);

export const unconfirmCustomerResponse = createAction(
  'customer/unconfirm-response',
);

export const updateCustomerRequest = createAction('customer/update-request');

export const updateCustomerResponse = createAction('customer/update-response');

export const removeCustomerRequest = createAction('customer/remove-request');

export const removeCustomerResponse = createAction('customer/remove-response');

export const unRemoveCustomerRequest = createAction(
  'customer/unremove-request',
);

export const unremoveCustomerResponse = createAction(
  'customer/unremove-response',
);

export const getRemovedCustomerListRequest = createAction(
  'customer/removed-customer-list-request',
);

export const getRemovedCustomerListResponse = createAction(
  'customer/removed-customer-list-response',
);
