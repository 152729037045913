import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { errorResponseAction } from './errorResponseAction';
import { log404ErrorsInTests } from './logTestErrors';

import { logout } from '@state/auth/actions';
import store from '@state/index';
import { getLoginInfo } from '@utils/Auth/AuthUtils';
import { emptyFunction } from '@utils/functions';

const environment = process ? process.env : null;

const client: AxiosInstance = axios.create({
  baseURL: `${environment?.REACT_APP_API_BASE_URL}/${environment?.REACT_APP_API_VERSION}`,
});
let abortResponseAction = false;
const handleLogout = () => {
  const { dispatch } = store;

  abortResponseAction = true;
  // restore abortResponseAction after 1 second
  setTimeout(() => {
    abortResponseAction = false;
  }, 1000);
  dispatch(logout(() => emptyFunction()));
};

client.interceptors.request.use((config: AxiosRequestConfig) => {
  const loginData = getLoginInfo();
  const token = loginData.impersonatedSupplier
    ? loginData.impersonatedSupplier.token
    : loginData.token;

  Object.defineProperty(config, 'headers', {
    value: { Authorization: `Bearer ${token}` },
  });

  return config;
});

client.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    log404ErrorsInTests(error);
    errorResponseAction(error, handleLogout, abortResponseAction);

    return Promise.reject(error);
  },
);

export default client;
