import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBack } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './styles.scss';
import Button from '../../../components/Button';
import { useSnackbar } from '../../../components/Snackbar/hooks';
import TextInput from '../../../components/TextInput';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { WithForgotPasswordModal } from '../components/ForgotPasswordModal';
import {
  ForgotPasswordModalButtonProps,
  ForgotPasswordModalProps,
} from '../components/ForgotPasswordModal/interface';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { ForgotPasswordFormKeys } from './interfaces';
import { ForgotPasswordValidationSchema } from './validations';

import { useAppSelector } from '@hooks/state';
import { forgotPasswordRequest } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { FormTextInputLengths } from '@utils/FormInputLengths';

export const ForgotPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();

  const { loading, error } = useAppSelector((state) => ({
    loading: isRequestRunning(state, String(forgotPasswordRequest)),
    error: getRequestError(state, String(forgotPasswordRequest)),
  }));

  const initialValues: ForgotPasswordFormKeys = {
    email: '',
  };

  const { control, handleSubmit, setError, reset, formState } =
    useForm<ForgotPasswordFormKeys>({
      resolver: yupResolver(ForgotPasswordValidationSchema),
      defaultValues: initialValues,
    });

  const submitButtonProps: ForgotPasswordModalButtonProps = {
    loading,
    disabled: loading,
    fullWidth: true,
    'data-testid': 'sendPasswordResetButton',
    variant: 'contained',
    color: 'primary',
    label: 'Send Password Reset',
  };

  const handleGoBackClick = () => navigate('/login');

  const handleSubmitForm = (data: ForgotPasswordFormKeys) => {
    dispatch(
      forgotPasswordRequest({
        ...data,
        callback: () => {
          showMessage(
            'Done! If you have entered a registered email you will receive a link to Create a New Password.',
            { severity: 'info' },
          );
          reset();
        },
      }),
    );
  };

  const WithForgotPasswordModalProps: ForgotPasswordModalProps = {
    handleSubmit,
    handleSubmitForm,
  };

  useEffect(() => {
    if (error.email)
      setError('email', {
        type: 'Invalid',
        message: error.email[0],
      });
  }, [error]);

  return (
    <WelcomeContainer>
      <Box className='forgot-password-form-container'>
        <Grid container justifyContent='center'>
          <Grid item xs={5} className='forgot-password-form-title'>
            <AuthFormHeader
              title='Forgot Password?'
              subTitle='Fill your email address and you will receive a link to reset your
              password.'
            />
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container justifyContent='center'>
            <Grid item xs={5}>
              <Controller
                name='email'
                control={control}
                render={({ field, fieldState }) => (
                  <TextInput
                    id='email'
                    label='Email'
                    data-testid='email'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    error={!!fieldState.error}
                    errorMessage={formState.errors.email?.message}
                    maxLength={FormTextInputLengths.profile.email}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent='center'
            className='forgot-password-button-container'
          >
            <Grid item xs={5}>
              {WithForgotPasswordModal(submitButtonProps)(Button)(
                WithForgotPasswordModalProps,
              )}
            </Grid>
          </Grid>
        </form>
        <Grid container justifyContent='center'>
          <Grid item xs={5}>
            <Button
              variant='text'
              color='primary'
              data-testid='goBackButton'
              startIcon={<ArrowBack />}
              onClick={handleGoBackClick}
            >
              Go back to log in
            </Button>
          </Grid>
        </Grid>
        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
