import { CheckCircleOutline } from '@mui/icons-material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  IconButton,
  Snackbar as SnackbarMui,
  Typography,
} from '@mui/material';

import { SnackbarIconsMapType, SnackbarProps } from './interface';

import OrderCancelIcon from '@assets/icons/cancel-order-icon.svg';

import './styles.scss';

const DEFAULT_DISMISS_TIME = 10000;

const SnackbarIconsMap: SnackbarIconsMapType = {
  info: {
    icon: (
      <InfoOutlinedIcon
        sx={{
          fontSize: '1.2rem',
        }}
      />
    ),
    color: '#3D5066',
    backgroundColor: '#E5F2FA',
  },
  error: {
    icon: (
      <ErrorOutlineIcon
        sx={{
          fontSize: '1.8rem',
        }}
        htmlColor='#D32F2F'
      />
    ),
    color: '#541314',
    backgroundColor: '#FBEAEA',
  },
  success: {
    icon: <CheckCircleOutline htmlColor='#00B246' />,
    color: '#000000',
    backgroundColor: '#E3F7EB',
  },
  order_cancelled: {
    icon: <img src={OrderCancelIcon} />,
    color: '#000000',
    secondaryColor: '#50555C',
    backgroundColor: '#FFF3F0',
  },
};

export const Snackbar = ({
  message,
  open,
  onClose,
  severity,
  subMessage,
  closeTextButton,
  position,
  xCloseButton = false,
}: SnackbarProps): JSX.Element => {
  const {
    color = '#000',
    secondaryColor = '#50555C',
    backgroundColor = '#FFF',
    icon,
  } = SnackbarIconsMap[severity];

  const [vertical, horizontal] = position.split('-');

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: vertical as 'bottom' | 'top',
        horizontal: horizontal as 'left' | 'right',
      }}
      open={open}
      autoHideDuration={DEFAULT_DISMISS_TIME}
      onClose={onClose}
      message={message}
    >
      <Box
        component='div'
        className='snackbar-content'
        maxWidth={400}
        sx={{ backgroundColor }}
      >
        <Box>{icon}</Box>

        <Box component='div' className='snackbar-message-container pl-2'>
          <Typography
            sx={{ color, fontWeight: '500', fontSize: '0.8rem' }}
            variant='body1'
            marginLeft='0.625rem'
          >
            {message}
          </Typography>
          {subMessage && (
            <Typography
              sx={{ color: secondaryColor }}
              variant='body2'
              marginLeft='0.625rem'
              marginRight='1rem'
            >
              {subMessage}
            </Typography>
          )}
        </Box>
        {xCloseButton ? (
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={onClose}
            className='close-x-button'
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        ) : (
          <Button
            sx={{
              color: '#541314',
              fontSize: '1rem',
            }}
            onClick={() => onClose()}
          >
            {closeTextButton ? (
              <Typography
                sx={{ color, fontWeight: '400', fontSize: '0.8rem' }}
                variant='body2'
                marginLeft='0.625rem'
              >
                {closeTextButton}
              </Typography>
            ) : (
              <ClearOutlinedIcon
                sx={{
                  fontSize: '1.2rem',
                }}
              />
            )}
          </Button>
        )}
      </Box>
    </SnackbarMui>
  );
};
