import React from 'react';

import { DeclinedOrCanceledBannerProps } from './interface';

import { EOrderSubstatus } from '@utils/enums';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

const DeclinedOrCanceledBanner = ({ order }: DeclinedOrCanceledBannerProps) => {
  const getMessage = () => {
    if (order?.current_status?.substatus === EOrderSubstatus.RETURNED_RETURNED)
      return 'Returned';

    if (
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_DECLINED ||
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_ABORTED
    )
      return 'Declined';

    if (
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_CANCELED ||
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_REJECTED ||
      order?.current_status?.substatus ===
        EOrderSubstatus.CANCELED_BY_CONTRACTOR
    )
      return 'Canceled';

    return '';
  };

  const getSubject = () => {
    if (
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_REJECTED ||
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_ABORTED
    )
      return 'Tech';

    if (
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_DECLINED ||
      order?.current_status?.substatus === EOrderSubstatus.CANCELED_CANCELED ||
      order?.current_status?.substatus === EOrderSubstatus.RETURNED_RETURNED ||
      order?.current_status?.substatus ===
        EOrderSubstatus.CANCELED_BY_CONTRACTOR
    )
      return capitalizeFirstLetter(
        order?.current_status?.staff
          ? order?.current_status?.staff?.name
          : 'A Counter Staff',
      );

    return '';
  };

  const getDetail = () => {
    if (!order?.current_status?.detail) return '';

    return `because: ${capitalizeFirstLetter(order.current_status.detail)}`;
  };

  //  TODO: once other canceled states are covered, this statement should not be necessary
  const message = getMessage();
  const subject = getSubject();
  const detail = getDetail();
  if (!message && !subject) return null;

  return (
    <div className='align-center mt-2 flex w-full flex-row justify-center rounded-lg bg-[var(--bluon--light--red-6)] px-4 py-2'>
      <div className='break-all text-base font-normal text-[var(--bluon--ds--red)]'>
        {`${message} by ${subject} ${detail}`}
      </div>
    </div>
  );
};

export default DeclinedOrCanceledBanner;
