type ErrorData = {
  response: {
    status: number;
    config: { url: string };
  };
};

const messageType = {
  TOKEN_SIGNATURE_COULD_NOT_BE_VERIFIED:
    'Token Signature could not be verified.',
};
export function errorResponseAction(
  error: ErrorData,
  callBackAction: () => void,
  abortResponseAction: boolean,
): void {
  if (
    error?.response?.status === 401 &&
    messageType.TOKEN_SIGNATURE_COULD_NOT_BE_VERIFIED &&
    !abortResponseAction
  )
    callBackAction();
}
