import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';

import { TermsOfService } from '../TermsOfService';

import { WelcomeContainerProps } from './interface';

import BluonLogo from '@assets/images/BluonLogo.png';
import GrowMap from '@assets/images/GrowMap.png';
import { useAppSelector } from '@hooks/state';
import { openTermsOfService } from '@state/auth/actions';
import { getShowTermsInfoData } from '@state/auth/selectors';
import './styles.scss';

export const WelcomeContainer = ({
  children,
}: WelcomeContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  const { showTerms } = useAppSelector((state) => ({
    showTerms: getShowTermsInfoData(state),
  }));

  const handleClose = () => {
    dispatch(openTermsOfService(!showTerms));
  };

  return (
    <>
      <TermsOfService open={showTerms} handleClose={handleClose} />
      <Grid container className='bl-login-container'>
        <Grid item xs={5} className='login-side-container logo-side'>
          <Grid container className='container-center'>
            <Box className='bl-logo-title-container'>
              <Typography variant='h6' color='white'>
                Welcome to Bluon!
              </Typography>
              <img className='bluon-logo' src={BluonLogo} alt='Bluon Live' />
            </Box>
          </Grid>
          <Grid container className='container-center'>
            <img className='grow-map' src={GrowMap} alt='Grow Map' />
            <Typography variant='h6' color='white' fontWeight='200'>
              More than <b>160k Techs</b> relying on Bluon!
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={7} className='login-side-container form-side'>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
