import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';

import { partsSpecificationsString } from '../../../../data/parts';
import { CircularLoading } from '../../../CircularLoading';
import { PartDetails } from '../../../PartDetails';
import { RowItem } from '../../../RowItem';
import { SomethingWrong } from '../../../SomethingWrong';
import { VirtualizedInfiniteList } from '../../../VirtualizedInfiniteList';

import { IModelPartsRowItem, ModelDetailPartsTabProps } from './interface';

import { useAppDispatch, useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { queryParamKeys } from '@pages/BluonSearch/interface';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  modelPartRequest,
  moreModelPartRequest,
  morePartReplacementRequest,
  partDetailRequest,
  partReplacementDetailResetResponse,
  partReplacementRequest,
  previousModelPartRequest,
} from '@state/search/actions';
import {
  getHasNextPagePartReplacements,
  getHasNextPageParts,
  getPartDetails,
  getPartReplacements,
  getPartsData,
} from '@state/search/selectors';
import { handleGetString, partImage } from '@utils/functions';
import './styles.scss';

export const ModelDetailPartsTab = ({
  modelId,
}: ModelDetailPartsTabProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [queryParams, setSearchParams] = useQueryParams<queryParamKeys>();
  const [page, setPage] = useState<number>(queryParams?.partPage ?? 1);
  const [previousPage, setPreviousPage] = useState<number>(
    queryParams && queryParams.partPage ? queryParams?.partPage - 1 : 0,
  );
  const [partId, setPartId] = useState<string>(queryParams?.partId ?? '');
  const [selectedPart, setSelectedPart] = useState<
    IModelPartsRowItem['selectedItem'] | undefined
  >();
  const [needReload, setNeedReload] = useState<boolean>(false);

  const {
    parts,
    partsHasNextPage,
    isLoadingNextPageParts,
    isLoadingParts,
    partsRequestError,
  } = useAppSelector((state) => ({
    parts: getPartsData(state),
    partsHasNextPage: getHasNextPageParts(state),
    isLoadingNextPageParts: isRequestRunning(
      state,
      String(moreModelPartRequest),
    ),
    isLoadingParts: isRequestRunning(state, String(modelPartRequest)),
    partsRequestError: getRequestError(state, String(modelPartRequest)),
  }));

  const { partDetails, isLoadingPartDetails, partDetailsRequestErrror } =
    useAppSelector((state) => ({
      partDetails: getPartDetails(state),
      isLoadingPartDetails: isRequestRunning(state, String(partDetailRequest)),
      partDetailsRequestErrror: getRequestError(
        state,
        String(partDetailRequest),
      ),
    }));

  const {
    partReplacements,
    partReplacementsHasNextPage,
    isLoadingNextPagePartsReplacements,
    isLoadingMorePartReplacements,
    isLoadingPreviousModelPart,
  } = useAppSelector((state) => ({
    partReplacements: getPartReplacements(state),
    partReplacementsHasNextPage: getHasNextPagePartReplacements(state),
    isLoadingNextPagePartsReplacements: isRequestRunning(
      state,
      String(partReplacementRequest),
    ),
    isLoadingMorePartReplacements: isRequestRunning(
      state,
      String(morePartReplacementRequest),
    ),
    isLoadingPreviousModelPart: isRequestRunning(
      state,
      String(previousModelPartRequest),
    ),
  }));

  const handleSelectPart = (
    item: IModelPartsRowItem['selectedItem'],
    saveHistory = true,
  ) => {
    dispatch(partReplacementDetailResetResponse());
    setSelectedPart(item);
    dispatch(partDetailRequest({ partId: item.id }));
    dispatch(partReplacementRequest({ partId: item.id }));
    if (saveHistory) {
      setNeedReload(false);
      setSearchParams(
        { ...queryParams, partId: item.id, partPage: item.page },
        !!queryParams?.partId,
      );
    }
  };

  //  Function to change page state
  const changePage = (newPage = page) => {
    setPage(newPage);
    setPreviousPage(newPage - 1);
  };

  //  Function to get selected part info
  const getSelectedPart = (id: string) => {
    if (!id) return undefined;

    const matchingIndex = parts.findIndex((part) => part.id === id);
    const matchingPart = parts[matchingIndex];
    if (matchingPart) return { ...matchingPart, index: matchingIndex };

    return undefined;
  };

  //  Function to change selected part state
  const changeSelectedPart = (id: string) => {
    const matchingPart = getSelectedPart(id);
    setPartId(id);
    if (matchingPart) handleSelectPart(matchingPart, false);
    if (!matchingPart) setSelectedPart(undefined);
  };

  const handleLoadMoreReplacementParts = () => {
    dispatch(morePartReplacementRequest({ partId: selectedPart?.id }));
  };

  const handlePreviousPage = useCallback(() => {
    dispatch(previousModelPartRequest({ page: previousPage, modelId }));
    setPreviousPage(previousPage - 1);
  }, [previousPage, dispatch]);

  const shouldShowParts = !partsRequestError && !isLoadingParts;

  useEffect(() => {
    if (queryParams?.partId !== partId)
      changeSelectedPart(queryParams?.partId || '');

    if (needReload) {
      changePage(queryParams?.partPage || 1);
      dispatch(
        modelPartRequest({
          page: queryParams?.partPage || 1,
          modelId,
        }),
      );
    }
    setNeedReload(true);
  }, [queryParams.partId]);

  useEffect(() => {
    changeSelectedPart(queryParams?.partId || '');
  }, [parts]);

  return (
    <Container className='bl-model-detail-parts-tab-container'>
      <Grid container>
        <Grid item xs={selectedPart ? 5 : 12}>
          {!partsRequestError && (
            <Box className='bl-model-detail-parts-container-title' />
          )}

          {shouldShowParts && (
            <Box className='bl-model-detail-parts-container-parts'>
              <VirtualizedInfiniteList
                list={parts}
                selectedItem={selectedPart}
                isLoading={isLoadingNextPageParts}
                hasNextPage={partsHasNextPage}
                isLoadPreviousButtomVisible={previousPage > 0}
                onLoadPrevious={handlePreviousPage}
                isLoadingPreviousParts={isLoadingPreviousModelPart}
                onLoadMore={() => {
                  dispatch(moreModelPartRequest({ modelId }));
                }}
                onClickItem={handleSelectPart}
                rowHeight={65}
              >
                {({
                  index,
                  list,
                  onClickItem,
                  selectedItem,
                }: IModelPartsRowItem) => (
                  <RowItem
                    id={list[index].id}
                    nodeImage={
                      <img
                        alt='logo'
                        className='bl-part-tab-item-img'
                        loading='lazy'
                        src={partImage(list[index])}
                      />
                    }
                    key={list[index].id}
                    name={handleGetString(
                      list[index],
                      'type_title',
                      partsSpecificationsString,
                    )}
                    nameVariantStyle='small'
                    notes={list[index].number}
                    noteVariantColor='black'
                    noteVariantStyle='small'
                    onClickItem={() =>
                      onClickItem({
                        ...list[index],
                        index,
                      })
                    }
                    selected={selectedItem}
                  />
                )}
              </VirtualizedInfiniteList>
            </Box>
          )}
          {isLoadingParts && (
            <Box
              data-testid='modelDetailPartsTabLoading'
              className='bl-model-detail-parts-container-loading'
            >
              <CircularLoading />
            </Box>
          )}
          {partsRequestError && (
            <SomethingWrong
              height='calc(100vh - 392px)'
              onReload={() => {
                dispatch(modelPartRequest({ page, modelId }));
              }}
            />
          )}
        </Grid>
        {selectedPart && (
          <Grid item xs={7}>
            <Box className='bl-model-detail-parts-container-title' />

            <Box className='bl-model-detail-container-parts-details'>
              <PartDetails
                partDetails={partDetails}
                partReplacements={partReplacements}
                isLoadingDetails={isLoadingPartDetails}
                hasError={partDetailsRequestErrror}
                onClickReloadError={() =>
                  dispatch(partDetailRequest({ partId: selectedPart?.id }))
                }
                partReplacementsHasNextPage={partReplacementsHasNextPage}
                isLoadingNextPagePartsReplacements={
                  isLoadingNextPagePartsReplacements
                }
                isLoadingMorePartReplacements={isLoadingMorePartReplacements}
                onLoadMorePartReplacements={handleLoadMoreReplacementParts}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
