import { Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import DeclineModal from './components/DeclineModal';
import InvoicePreview from './components/InvoicePreview';
import InvoiceSentApprovalModal from './components/InvoiceSentApprovalModal';
import OrderHeader from './components/OrderHeader';

import Button from '@components/Button';
import { useSnackbar } from '@components/Snackbar/hooks';
import { SomethingWrong } from '@components/SomethingWrong';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import ReadOnlyOrderDetail from '@pages/Tasks/components/OrderDetail/components/ReadOnlyOrderDetail';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  approveShareableOrderRequest,
  getShareableOrderAddedItemsRequest,
  getShareableOrderCustomItemsRequest,
  getShareableOrderExtraItemsRequest,
  getShareableOrderPartsRequest,
  getShareableOrderRequest,
  resetShareableOrder,
} from '@state/shareableOrder/actions';
import {
  getShareableOrder,
  getShareableOrderAddedItems,
  getShareableOrderCustomItems,
  getShareableOrderExtraItems,
  getShareableOrderParts,
} from '@state/shareableOrder/selectors';
import { EOrderStatus, EOrderSubstatus } from '@utils/enums';
import { handleRoundDecimals } from '@utils/functions';

const ShareableOrderStatus = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('order');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const [showInvoiceSentApprovalModal, setShowInvoiceSentApprovalModal] =
    useState<boolean>(false);
  const [isDeclineOpen, setIsDeclineOpen] = useState<boolean>(false);

  const onDeclineClicked = useCallback(() => {
    setIsDeclineOpen(true);
  }, []);

  const closeDecline = useCallback(() => {
    setIsDeclineOpen(false);
  }, []);

  const onDenyClicked = useCallback(() => {
    setShowInvoiceSentApprovalModal(false);
    setIsDeclineOpen(true);
  }, []);

  const getOrderItems = useCallback(() => {
    dispatch(getShareableOrderPartsRequest({ orderId }));
    dispatch(
      getShareableOrderExtraItemsRequest({ orderId, initial_request: 1 }),
    );
    dispatch(
      getShareableOrderAddedItemsRequest({ orderId, initial_request: 0 }),
    );
    dispatch(getShareableOrderCustomItemsRequest({ orderId }));
  }, [orderId]);

  //  Requesting data
  useEffect(() => {
    dispatch(getShareableOrderRequest({ orderId }));
    getOrderItems();
  }, [orderId]);

  //  Component will unmount
  useEffect(() => {
    return () => {
      dispatch(resetShareableOrder());
    };
  }, []);

  //  Getting store data
  const {
    order,
    parts,
    extraItems,
    customItems,
    addedItems,
    partsLoading,
    truckStockLoading,
    customItemsLoading,
    isOrderLoading,
    isItemsError,
    isOrderError,
    isLoadingApproveOrder,
  } = useAppSelector((state) => ({
    order: getShareableOrder(state),
    parts: getShareableOrderParts(state),
    extraItems: getShareableOrderExtraItems(state),
    customItems: getShareableOrderCustomItems(state),
    addedItems: getShareableOrderAddedItems(state),
    partsLoading: isRequestRunning(
      state,
      String(getShareableOrderPartsRequest),
    ),
    truckStockLoading: isRequestRunning(
      state,
      String(getShareableOrderExtraItemsRequest),
    ),
    customItemsLoading: isRequestRunning(
      state,
      String(getShareableOrderCustomItemsRequest),
    ),
    isOrderLoading: isRequestRunning(state, String(getShareableOrderRequest)),
    isItemsError:
      getRequestError(state, String(getShareableOrderPartsRequest)) ||
      getRequestError(state, String(getShareableOrderExtraItemsRequest)) ||
      getRequestError(state, String(getShareableOrderCustomItemsRequest)) ||
      getRequestError(state, String(getShareableOrderAddedItemsRequest)),
    isOrderError: getRequestError(state, String(getShareableOrderRequest)),
    isLoadingApproveOrder: isRequestRunning(
      state,
      String(approveShareableOrderRequest),
    ),
  }));

  //  Watching order error to redirect to login
  useEffect(() => {
    if (!isOrderError) return;
    navigate('/login', { replace: true });
    showMessage('Error getting order.', { severity: 'error', autoHide: true });
  }, [isOrderError]);

  //  Watching statuses changes to make sure that pending orders are not supported
  useEffect(() => {
    if (!order?.current_status?.status) return;
    if (order?.current_status?.status !== EOrderStatus.Pending) return;
    navigate('/login', { replace: true });
    showMessage('Unable to access order.', {
      severity: 'error',
      autoHide: true,
    });
  }, [order?.current_status?.status]);

  const onLooksGoodClicked = useCallback(() => {
    //  Handling case for PENDING_APPROVAL_QUOTE_UPDATED
    if (
      order?.current_status?.substatus ===
      EOrderSubstatus.PENDING_APPROVAL_QUOTE_UPDATED
    )
      return setShowInvoiceSentApprovalModal(true);

    if (
      order?.current_status?.substatus ===
      EOrderSubstatus.PENDING_APPROVAL_FULFILLED
    )
      dispatch(
        approveShareableOrderRequest({
          orderId,
          successCallback: () => {
            showMessage('Tech has been notified of your approval', {
              severity: 'success',
              autoHide: true,
            });
          },
          errorCallback: () => {
            showMessage('Something went wrong. Please try again!', {
              severity: 'error',
              autoHide: true,
            });
          },
        }),
      );

    //  TODO: handle rest of cases
  }, [order?.current_status?.substatus]);

  const renderItemsSection = () => {
    if (isItemsError) return <SomethingWrong onReload={getOrderItems} />;

    return (
      <div className='flex w-full flex-col items-center justify-center px-4 md:px-8'>
        <ReadOnlyOrderDetail
          truckStockItems={extraItems}
          showAddedItems
          items={parts}
          customItems={customItems}
          addedItems={addedItems}
          partsLoading={partsLoading}
          truckStockLoading={truckStockLoading}
          areCustomItemsLoading={customItemsLoading}
        />
        <div className='flex w-full flex-row items-center px-[1.125rem] md:px-0'>
          <div className='ml-auto flex flex-row items-center justify-center text-base font-bold leading-5'>
            {isOrderLoading ? (
              <Skeleton width={150} height={30} />
            ) : (
              `Price: $${handleRoundDecimals(order?.total || 0, 2)}`
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderNote = () => {
    if (!order?.note) return null;

    return (
      <div className='mb-10 flex w-full flex-row items-center justify-center px-4 md:px-8'>
        <div className='font-sm max-w-full break-words text-center font-normal italic leading-5 text-bluon_dark_gray'>
          {isOrderLoading ? (
            <Skeleton width={500} height={30} />
          ) : (
            `Note: "${order?.note}"`
          )}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    if (isOrderLoading)
      return (
        <div className='flex w-full flex-row items-center justify-center bg-[var(--bluon--default--white)] md:mt-6 md:bg-[var(--bluon--ds--lightgray)]'>
          <Skeleton width={100} height={50} className='mr-4' />
          <Skeleton width={100} height={50} />
        </div>
      );

    if (
      order?.current_status?.substatus !==
        EOrderSubstatus.PENDING_APPROVAL_FULFILLED &&
      order?.current_status?.substatus !==
        EOrderSubstatus.PENDING_APPROVAL_QUOTE_UPDATED
    )
      return null;

    //  Rendering footer just for PENDING_APPROVAL_FULFILLED and PENDING_APPROVAL_QUOTE_UPDATED
    return (
      <div className='md:pb-none flex w-full flex-col items-center justify-center bg-[var(--bluon--default--white)] px-2 pb-6 md:mt-6 md:flex-row md:bg-[var(--bluon--ds--lightgray)]'>
        <div className='mb-4 w-full md:mb-0 md:mr-4 md:w-auto'>
          <Button
            variant='contained'
            className='w-full !bg-[var(--bluon--default--red)]'
            onClick={onDeclineClicked}
          >
            DECLINE
          </Button>
        </div>
        <div className='w-full md:w-auto'>
          <Button
            color='success'
            variant='contained'
            className='w-full'
            onClick={onLooksGoodClicked}
            loading={isLoadingApproveOrder}
            disabled={isLoadingApproveOrder}
          >
            LOOKS GOOD - LET’S GO!
          </Button>
        </div>
        {orderId && (
          <DeclineModal
            isOpen={isDeclineOpen}
            onClose={closeDecline}
            orderId={orderId}
          />
        )}
      </div>
    );
  };

  const renderInvoiceSentApprovalModal = () => {
    if (!showInvoiceSentApprovalModal) return null;

    return (
      <InvoiceSentApprovalModal
        orderId={order?.id || ''}
        onClose={() => setShowInvoiceSentApprovalModal(false)}
        onDenyClicked={onDenyClicked}
      />
    );
  };

  return (
    <>
      <div className='flex h-[100vh] w-full flex-col items-center justify-start bg-[var(--bluon--default--white)] pb-[3.125rem] md:h-auto md:min-h-screen md:bg-[var(--bluon--ds--lightgray)] md:px-10 md:py-14 '>
        <div className='flex w-full flex-col items-center justify-start bg-[var(--bluon--default--white)] pt-[4.625rem] md:h-full md:px-8 md:pb-10 md:pt-6'>
          <OrderHeader isLoading={isOrderLoading} order={order} />
          {renderItemsSection()}
          <div className='mt-12 flex w-full flex-col items-center justify-center'>
            {renderNote()}
          </div>
          <InvoicePreview isLoading={isOrderLoading} order={order} />
        </div>
        {renderFooter()}
      </div>
      {renderInvoiceSentApprovalModal()}
    </>
  );
};

export default ShareableOrderStatus;
