import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { NotFoundProps } from './interface';

import NotFoundImage from '@assets/images/NotFound.png';
export const NOT_FOUND_TEST_ID = 'notFound';

export const NotFound = ({ height = '100%' }: NotFoundProps): JSX.Element => (
  <Container
    maxWidth='xl'
    className='bl-not-found-container'
    data-testid={NOT_FOUND_TEST_ID}
    style={{ height: height }}
  >
    <Box className='bl-not-found-img-container'>
      <img src={NotFoundImage} alt='Not Found' />
    </Box>
    <Typography
      className='not-found-title'
      variant='subtitle1'
      color='initial'
      textAlign='center'
    >
      Oops, no items were found :(
    </Typography>
    <Typography
      className='not-found-subtitle'
      variant='subtitle2'
      color='initial'
      textAlign='center'
    >
      That query didn’t bring any results. Try using fewer characters or revise
      your spelling.
    </Typography>
  </Container>
);
