import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';

import TextInput from '../../../../../../components/TextInput';

import { StoreContactInputsProps } from './interface';

import { FormTextInputLengths } from '@utils/FormInputLengths';

const StoreContactInputs = ({
  control,
  isLoading,
}: StoreContactInputsProps): JSX.Element => (
  <Grid container justifyContent='center'>
    <Grid item xs={12} md={9}>
      <Controller
        name='contact_phone'
        control={control}
        render={({ field, fieldState, formState }) => (
          <TextInput
            id='contact_phone'
            label='Store Phone Number'
            subLabel='(how techs call your branch)'
            data-testid='contactPhoneNumber'
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) event.preventDefault();
            }}
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            maxLength={FormTextInputLengths.profile.phoneFullNumber}
            errorMessage={formState.errors.contact_phone?.message}
            isLoading={isLoading}
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='contact_email'
        control={control}
        render={({ field, fieldState, formState }) => (
          <TextInput
            id='contact_email'
            label='Primary Store Email'
            data-testid='contactPrimaryEmail'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            maxLength={FormTextInputLengths.profile.email}
            errorMessage={formState.errors.contact_email?.message}
            isLoading={isLoading}
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='contact_secondary_email'
        control={control}
        render={({ field, fieldState, formState }) => (
          <TextInput
            id='contact_secondary_email'
            label='Secondary Email'
            subLabel='(optional)'
            data-testid='contactSecondaryEmail'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            maxLength={FormTextInputLengths.profile.email}
            errorMessage={formState.errors.contact_secondary_email?.message}
            isLoading={isLoading}
          />
        )}
      />
    </Grid>
  </Grid>
);

export default StoreContactInputs;
