import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import { getItemDescription, getPartName } from '../../../ItemPart';

import { ReadOnlyPartItemProps } from './interface';

import { Label } from '@components/Label';
import { getLabelAvailableAtPart } from '@pages/Tasks/components/OrderDetail/utils';
import { partImage } from '@utils/functions';

//  COMPONENT
const ReadOnlyPartItem = ({ item, isLastItem }: ReadOnlyPartItemProps) => {
  const getDataToDisplay = () => {
    const data = [];
    const name = getPartName(item);
    const description = getItemDescription(item);
    if (name) data.push(name);
    if (description) data.push(description);

    return data?.join(' - ');
  };

  return (
    <div
      className={classNames(
        'flex w-full flex-row items-center justify-start px-4 py-2',
        {
          'border-b border-solid border-[var(--bluon--ds--lightgray-3)]':
            !isLastItem,
        },
      )}
    >
      <div>
        <img
          alt='logo'
          className='h-9 w-9'
          loading='lazy'
          src={partImage(item.item.info, item.item.type)}
        />
      </div>
      <div className='flex flex-col md:flex-row'>
        <div className='ml-4 flex items-center text-sm font-normal text-[var(--bluon--ds--black)]'>
          {getDataToDisplay()}
        </div>
        {item?.available_at && (
          <div className='ml-3'>
            <Label
              label={getLabelAvailableAtPart({
                message: item.replacement ? '1' : '0',
                format: 'MM/DD/YY',
                available_at: item?.available_at,
              })}
              color='brown'
            />
          </div>
        )}
        {!!item?.replacement && !item?.available_at && (
          <div className='ml-3 w-min'>
            <Label color='green' label='Replacement' />
          </div>
        )}
      </div>
      <div className='ml-auto text-sm font-normal text-[var(--bluon--ds--black)]'>
        {item?.quantity || 0}
      </div>
    </div>
  );
};

//  LOADING SKELETON
const ANIMATION_DURATION = { animationDuration: '0.1s' };
const LoadingSkeleton = () => {
  return (
    <div className='flex w-full flex-row items-center justify-start border-b border-solid border-[var(--bluon--ds--lightgray-3)] px-4 py-2'>
      <Skeleton
        variant='circular'
        animation='wave'
        sx={ANIMATION_DURATION}
        width={40}
        height={40}
      />
      <Skeleton
        className='ml-4'
        animation='wave'
        sx={ANIMATION_DURATION}
        width={75}
        height={12}
      />
      <Skeleton
        className='ml-auto'
        animation='wave'
        sx={ANIMATION_DURATION}
        width={20}
        height={12}
      />
    </div>
  );
};

ReadOnlyPartItem.Skeleton = LoadingSkeleton;
export default ReadOnlyPartItem;
