import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EItemStatus } from '../../../../../../utils/enums';
import { ItemPart } from '../ItemPart';

import { useAppSelector } from '@hooks/state';
import {
  setTotalIsExpanded,
  setTruckStockIsExpanded,
} from '@state/task/actions';
import {
  getOrderCustomItems,
  getOrderItems,
  getOrderSupplyItems,
} from '@state/task/selectors';

interface ItemListProps {
  isLoading: boolean;
}

export const ItemList = ({ isLoading }: ItemListProps): JSX.Element => {
  const dispatch = useDispatch();
  const { customItems, items, truckStockItems } = useAppSelector((state) => ({
    customItems: getOrderCustomItems(state),
    items: getOrderItems(state),
    truckStockItems: getOrderSupplyItems(state),
  }));

  const totalItems = items.length + truckStockItems.length + customItems.length;

  const [selectedId, setSelectedId] = useState<string | null>(null);

  useEffect(() => {
    if (selectedId) return;
    const selectedItem = items?.find(
      (item) => item.status === EItemStatus.Pending,
    );
    setSelectedId(selectedItem?.item?.id || null);
  }, [selectedId, items]);

  const handleLastItemAction = () => {
    if (truckStockItems.length) {
      const hasPendingTruckStockItems = truckStockItems.some(
        (truckItem) => truckItem.status === 'pending',
      );
      hasPendingTruckStockItems
        ? dispatch(setTruckStockIsExpanded(true))
        : null;
    } else {
      dispatch(setTotalIsExpanded(true));
    }
  };

  useEffect(() => {
    const hasNoTruckStockAndItemsAreOk =
      !truckStockItems.length &&
      items.length &&
      !items.some((item) => item.status === 'pending');

    if (hasNoTruckStockAndItemsAreOk) dispatch(setTotalIsExpanded(true));
  }, [truckStockItems, items]);

  return (
    <>
      {isLoading && <SkeletonContent />}
      {!isLoading && (
        <div className='flex flex-col'>
          <div className='text-md mb-2 ml-4'>
            {`Checking for ${totalItems} Item${totalItems !== 1 ? 's' : ''}`}
          </div>

          {items.map((item, index) => {
            return (
              <ItemPart
                item={item}
                key={item.item.id}
                expanded={item?.item?.id === selectedId}
                changeSelectedId={setSelectedId}
                isLastItem={index === items.length - 1}
                handleLastItemAction={handleLastItemAction}
                selectedId={selectedId}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const SkeletonContent = (): JSX.Element => {
  const items = [1, 2, 3, 4];

  return (
    <div className='flex flex-col'>
      <div className='mb-2 ml-4'>
        <Skeleton width={150} height={30} />
      </div>

      <div className='flex flex-col gap-1'>
        {items.map((item) => (
          <div
            className='flex w-full justify-between gap-1 rounded-md border p-2 pr-4'
            key={item}
          >
            <div className='flex items-center gap-2'>
              <Skeleton variant='rounded' width={40} height={40} />
              <Skeleton width={150} height={15} />
              <Skeleton width={150} height={15} />
            </div>
            <div className='flex items-center gap-1'>
              <Skeleton width={50} height={15} />
              <ArrowDropDownIcon />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
