/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import makeRequest from '../requests/make-request';
import { getRequestError } from '../requests/selectors';
import { resetStore } from '../storeInfo/actions';

import {
  changePasswordRequest,
  forgotPasswordRequest,
  getBluonIframeUrl,
  getBluonIframeUrlResponse,
  loginRequest,
  loginResponse,
  logout,
  resetPasswordRequest,
  resetUser,
  sendSMSRequest,
  sendSMSResponse,
} from './actions';
import {
  ChangePasswordActionProps,
  ForgotPasswordActionProps,
  LoginParamsProps,
  LoginResponseProps,
  LogoutParamsProps,
  ResetPasswordActionProps,
  SendSMSParamsProps,
  getIframeUrlProps,
} from './interface';
import { getIsManager, getLoginInfoDataInitialPass } from './selectors';

import {
  changePassword,
  forgotPassword,
  getIframeUrl,
  login,
  resetPassword,
  sendSMS,
} from '@config/api/auth';
import { setAuthToken } from '@utils/Auth/AuthUtils';

export function* workerSendSMS(action: SendSMSParamsProps): Generator<any> {
  const { country_code, phone } = action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: sendSMS,
    params: [country_code, phone],
    requestAction: action,
    receiveAction: sendSMSResponse,
  });
}

export function* workerGetBluonIframe(
  action: getIframeUrlProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getIframeUrl,
    params: action.payload,
    requestAction: action,
    receiveAction: getBluonIframeUrlResponse,
  });
}

export function* workerAuthLogin(action: LoginParamsProps): Generator<any> {
  const { email, password, callback } = action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: login,
    params: [email, password],
    requestAction: action,
    receiveAction: loginResponse,
  });
  const error = yield select((state) => getRequestError(state, loginRequest));
  if (isEmpty(error)) {
    const initial_password_set_at = yield select(getLoginInfoDataInitialPass);
    const isManager = yield select(getIsManager);
    const route = isManager
      ? '/store-overview'
      : initial_password_set_at
      ? '/bluon-search'
      : '/login/set-new-password';
    yield call(callback, route);
  }
}

export function* workerAuthLoginResponse(
  action: LoginResponseProps,
): Generator<any> {
  const { data } = action.payload;
  const call: any = Eff.call;
  yield call(setAuthToken, data);
}

export function* workerAuthChangePassword(
  action: ChangePasswordActionProps,
): Generator<any> {
  const { password_confirmation, password, callback } = action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: changePassword,
    params: [password, password_confirmation],
    requestAction: action,
  });
  const error = yield select((state) =>
    getRequestError(state, changePasswordRequest),
  );
  if (isEmpty(error)) yield call(callback);
}

export function* workerAuthLogout(action: LogoutParamsProps): Generator<any> {
  const call: any = Eff.call;
  yield call([sessionStorage, sessionStorage.clear]);
  yield put(resetStore());
  yield put(resetUser());
  yield call(action.payload);
}

export function* workerAuthForgotPassword(
  action: ForgotPasswordActionProps,
): Generator<any> {
  const { email, callback } = action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: forgotPassword,
    params: email,
    requestAction: action,
  });
  const error = yield select((state) =>
    getRequestError(state, forgotPasswordRequest),
  );
  if (isEmpty(error)) yield call(callback);
}

export function* workerAuthResetPassword(
  action: ResetPasswordActionProps,
): Generator<any> {
  const { callback, email, password, passwordConfirmation, token } =
    action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: resetPassword,
    params: {
      email,
      password,
      token,
      password_confirmation: passwordConfirmation,
    },
    requestAction: action,
  });
  const error = yield select((state) =>
    getRequestError(state, resetPasswordRequest),
  );
  if (isEmpty(error)) yield call(callback);
}

export default function* authSagas(): Generator<any> {
  yield all([
    takeLatest(sendSMSRequest, workerSendSMS),
    takeLatest(loginRequest, workerAuthLogin),
    takeLatest(loginResponse, workerAuthLoginResponse),
    takeLatest(logout, workerAuthLogout),
    takeLatest(changePasswordRequest, workerAuthChangePassword),
    takeLatest(forgotPasswordRequest, workerAuthForgotPassword),
    takeLatest(resetPasswordRequest, workerAuthResetPassword),
    takeLatest(getBluonIframeUrl, workerGetBluonIframe),
  ]);
}
