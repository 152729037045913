import React from 'react';

import Banner from '@components/Banner';
import { BannerProps } from '@components/Banner/interface';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';

const UpdateQuoteBanner = ({
  buttons,
  message,
  status,
  order,
}: BannerProps) => {
  const { changeSelectedOrder } = useSetSelectedOrder();

  const handleSelectOrder = () => {
    changeSelectedOrder(order?.id, order);
  };

  return (
    <Banner
      buttons={[
        {
          ...buttons[0],
          onPress: () => handleSelectOrder(),
        },
      ]}
      message={message}
      status={status}
    />
  );
};

export default UpdateQuoteBanner;
