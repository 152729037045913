import { createTheme } from '@mui/material';

const themePalette = createTheme({
  palette: {
    action: {
      disabledBackground: '',
    },
    primary: {
      main: '#0567D1',
      contrastText: '#FFFFFF',
    },
    primaryLight: {
      main: '#FFFFFF',
      contrastText: '#FFFFFF',
    },
    primaryDark: {
      main: '#004596',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#3D5066',
    },
    success: {
      main: '#16A64E',
      contrastText: '#FFFFFF',
    },
    successLight: {
      main: '#E9FCF0',
      contrastText: '#00B246',
    },
    successDark: {
      main: '#068B3A',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF8765',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
    error: {
      main: '#DD3217',
    },
    neutral: {
      main: '#00000099',
      contrastText: '#FFFFFF',
    },
    textFieldPrimary: {
      main: '#00000099',
      contrastText: '#FFFFFF',
    },
    textFieldLight: {
      main: 'rgba(255, 255, 255, 0.7)',
      contrastText: '#FFFFFF',
    },
  },
});

export const BluonTheme = createTheme(themePalette, {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(ownerState.color === 'textFieldPrimary' && {
            label: {
              color: themePalette.palette.textFieldPrimary.main,
              letterSpacing: 0.15,
              fontSize: '1rem',
            },
            input: {
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '1rem',
              lineHeight: '24px',
            },
          }),
          ...(ownerState.color === 'textFieldLight' && {
            label: {
              color: themePalette.palette.textFieldLight.main,
              letterSpacing: 0.15,
              fontSize: '1rem',
            },
            input: {
              color: '#FFFFFF',
              fontSize: '1rem',
              letterSpacing: 0.15,
              lineHeight: '24px',
            },
            fieldset: {
              borderColor: themePalette.palette.textFieldLight.main,
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: themePalette.palette.textFieldLight.main,
              },
            },
          }),
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#EFEFEF',
          height: '44px',
          border: '1px solid transparent',
          borderRadius: '3px',
          padding: '0px 16px',
          '&.Mui-error': {
            border: `1px solid ${themePalette.palette.warning.main}`,
          },
          '&.Mui-focused': {
            backgroundColor: themePalette.palette.background.paper,
            border: '1px solid #94A8C5',
          },
          '&.Mui-disabled': {
            opacity: '0.32',
          },
          '&:hover': {
            border: '1px solid #D6D9DC',
          },
        },
        input: {
          '&::placeholder': {
            fontStyle: 'italic',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '0.8125rem',
          lineHeight: '20px',
          color: themePalette.palette.warning.main,
          marginTop: '4px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '1rem',
          lineHeight: '28px',
          color: '#000000',
          marginBottom: '4px',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          display: 'flex',
          fontSize: '1rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '1rem',
          lineHeight: '28px',
          color: '#000000',
          marginBottom: '4px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-gutters': {
            backgroundColor: themePalette.palette.background.default,
            borderBottom: '1px solid #EAEDF3',
            height: '48px',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(5, 103, 208, 0.08)',
          },
          '&:hover': {
            backgroundColor: 'rgba(5, 103, 208, 0.12)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '0.875rem',
          '&.MuiSvgIcon-root': {
            color: '#FFFFFF',
          },
          '&.MuiButtonBase-root.MuiButton-contained.Mui-disabled': {
            color: '#a5a6a6',
            backgroundColor: '#e0e0df',
            opacity: 0.7,
          },
          '&.MuiButton-sizeLarge': {
            padding: '12.5px 70px',
          },
          '&.MuiButton-text': {
            padding: 0,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: '2rem',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: '2rem',
          [themePalette.breakpoints.up('md')]: {
            paddingLeft: '4.0625em',
            paddingRight: '4.0625em',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&.MuiChip-filled': {
            '.MuiChip-deleteIcon': {
              color: '#FFFFFF',
            },
          },
          '.MuiChip-deleteIconSmall': {
            fontSize: '1.2rem',
          },
          '.MuiChip-deleteIconMedium': {
            fontSize: '1.375rem',
          },
          '.MuiChip-label': {
            fontWeight: '500',
          },
          '&.MuiChip-filled.MuiChip-label': {
            color: '#FFFFFF',
          },
          '.MuiChip-labelSmall': {
            fontSize: '0.875rem',
          },
          '.MuiChip-labelMedium': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#0567D1',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#0567D1',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000',
          '&:hover': {
            color: '#979797',
            backgroundColor: '#FFF',
          },
        },
      },
    },
  },
});

// Create new colors
declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary'];
    primaryDark: Palette['primary'];
    successLight: Palette['primary'];
    successDark: Palette['primary'];
    neutral: Palette['primary'];
    textFieldPrimary: Palette['primary'];
    textFieldLight: Palette['primary'];
  }
  interface PaletteOptions {
    primaryLight: PaletteOptions['primary'];
    primaryDark: PaletteOptions['primary'];
    successLight: PaletteOptions['primary'];
    successDark: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
    textFieldPrimary: PaletteOptions['primary'];
    textFieldLight: PaletteOptions['primary'];
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    textFieldPrimary: true;
    textFieldLight: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryLight: true;
    primaryDark: true;
    successLight: true;
    successDark: true;
    neutral: true;
  }
}
