import client from '..';

import {
  ModelPartsDataProps,
  ModelSpecificationsDataProps,
  ModelsDataProps,
  PartDetailsDataProps,
  PartReplacementsDataProps,
  PartsDataProps,
  SearchModelsDataProps,
  SeriesDataProps,
} from './interface';

import { ISearchList } from '@pages/BluonSearch/EquipmentTab/interface';

export async function getSeries(params: {
  brandId: ISearchList['id'];
  search: string;
  page: number;
}): Promise<SeriesDataProps> {
  const search_string = params.search ? encodeURI(params.search) : '';

  const { data } = await client.get(`/brands/${params.brandId}/series`, {
    params: { page: params.page, search_string },
  });

  return data;
}

export async function getModels(params: {
  brandId: ISearchList['id'];
  serieId: ISearchList['id'];
  page: number;
  search?: string;
}): Promise<ModelsDataProps> {
  const search_string = params.search ? encodeURI(params.search) : '';

  const { data } = await client.get(
    `/brands/${params.brandId}/series/${params.serieId}/oems`,
    {
      params: { page: params.page, search_string },
    },
  );

  return data;
}

export async function getModelSpecifications(params: {
  modelId: ISearchList['id'];
}): Promise<ModelSpecificationsDataProps> {
  const { data } = await client.get(`/oems/${params.modelId}`);

  return data;
}

export async function getModelParts(params: {
  modelId: ISearchList['id'];
  page: number;
}): Promise<ModelPartsDataProps> {
  const { data } = await client.get(`/oems/${params.modelId}/parts`, {
    params: { page: params.page },
  });

  return data;
}

export async function getParts(params: {
  number: string;
  page: number;
}): Promise<PartsDataProps> {
  const { data } = await client.get('/parts', {
    params: { page: params.page, number: params.number },
  });

  return data;
}

export async function getPartDetail(params: {
  partId: string;
}): Promise<PartDetailsDataProps> {
  const { data } = await client.get(`/parts/${params.partId}`);

  return data;
}

export async function getPartReplacements(params: {
  partId: string;
  page: number;
}): Promise<PartReplacementsDataProps> {
  const { data } = await client.get(`/parts/${params.partId}/replacements`, {
    params: { page: params.page },
  });

  return data;
}

export async function getSearchModels(params: {
  model: string;
  page: number;
}): Promise<SearchModelsDataProps> {
  const { data } = await client.get(`/oems?model=${params.model}`, {
    params: { page: params.page },
  });

  return data;
}

export async function recommendPartReplacements(params: {
  partId: string;
  brand: string;
  partNumber: string;
  note?: string;
}): Promise<SearchModelsDataProps> {
  const { partId, brand, partNumber, note } = params;

  const { data } = await client.post(
    `/parts/${partId}/recommended-replacements`,
    {
      part: partId,
      brand,
      part_number: partNumber,
      note: note || null,
    },
  );

  return data;
}
