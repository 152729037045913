import { Delivery, OrderCurrentStatus } from '@config/api/interface';
import { WorkingOnIt } from '@config/api/interfaceV2';
import { ORDER_STATUS, Order } from '@config/api/task/interface';
import { ORDER_SUPPLIER_MESSAGES } from '@pages/Tasks/components/ListItem/constants';
import { ShareableOrder } from '@state/shareableOrder/interface';
import {
  checkIfIsTodayOrTomorrow,
  transformStringDateTo12HoursFormat,
} from '@utils/Dates';
import { EOrderSubstatus } from '@utils/enums';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

export const getLogStatusMessage = (
  delivery: Delivery,
  current_status: OrderCurrentStatus,
  working_on_it: WorkingOnIt,
): string => {
  //  Getting base message
  let index = current_status?.substatus;
  if (
    (current_status?.substatus ===
      EOrderSubstatus.PENDING_APPROVAL_QUOTE_NEEDED ||
      current_status?.substatus ===
        EOrderSubstatus.APPROVED_AWAITING_DELIVERY ||
      current_status?.substatus === EOrderSubstatus.APPROVED_READY_DELIVERY ||
      current_status?.substatus === EOrderSubstatus.APPROVED_DELIVERED) &&
    delivery?.type
  )
    index = `${current_status?.substatus}-${delivery?.type.split('_')[0]}`;

  const statusMessage = ORDER_SUPPLIER_MESSAGES[index as ORDER_STATUS];
  if (!statusMessage) return '';

  if (`${index.split('-')[0]}-${index.split('-')[1]}` === 'pending-assigned')
    return statusMessage?.replace(
      '[suplier_name]',
      capitalizeFirstLetter(working_on_it?.name ?? 'supplier'),
    );

  if (index === 'pending-approval-quote-needed-shipment') {
    //  Overriding shipment preference
    const shipmentPreference =
      delivery?.info?.shipment_delivery_preference?.id || '';

    return statusMessage?.replace(
      '[Preference]',
      capitalizeFirstLetter(shipmentPreference),
    );
  }

  //  Overriding delivery times
  if (
    index === 'approved-awaiting-delivery-pickup' ||
    index === 'approved-awaiting-delivery-curri' ||
    index === 'approved-awaiting-delivery-quote-updated'
  ) {
    //  Building date
    const { start_time, date } = delivery;
    const formattedDate = `${checkIfIsTodayOrTomorrow(
      date,
    )} ${transformStringDateTo12HoursFormat(start_time)}`;

    return statusMessage?.replace('(date time)', formattedDate);
  }

  //  Overriding shipment preference for approved-awaiting-delivery-shipment
  if (index === 'approved-awaiting-delivery-shipment') {
    const shipmentPreference =
      delivery?.info?.shipment_delivery_preference?.id || '';

    return statusMessage?.replace(
      '[Preference]',
      capitalizeFirstLetter(shipmentPreference),
    );
  }

  return statusMessage;
};

export const getLogColorClassName = (substatus: string) => {
  switch (substatus) {
    case EOrderSubstatus.PENDING_REQUESTED:
    case EOrderSubstatus.PENDING_APPROVAL_QUOTE_NEEDED:
    case EOrderSubstatus.APPROVED_AWAITING_DELIVERY:
    case EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED_WS:
    case EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED:
    case EOrderSubstatus.APPROVED_READY_DELIVERY:
    case EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED_WS:
    case EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED:
    case EOrderSubstatus.APPROVED_DELIVERED:
    case EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED:
    case EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED_WS:
      return 'red-cell';
    case EOrderSubstatus.PENDING_ASSIGNED:
    case EOrderSubstatus.PENDING_APPROVAL_FULFILLED:
    case EOrderSubstatus.PENDING_APPROVAL_QUOTE_UPDATED:
    case EOrderSubstatus.APPROVED_DELIVERED_QUOTE_UPDATED:
      return 'gray-cell';
    case EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_UPDATED:
    case EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_UPDATED:
      return 'blue-cell';
    case EOrderSubstatus.COMPLETED_DONE:
      return 'green-cell';
    case EOrderSubstatus.CANCELED_REJECTED:
    case EOrderSubstatus.CANCELED_DECLINED:
    case EOrderSubstatus.CANCELED_ABORTED:
    case EOrderSubstatus.CANCELED_CANCELED:
    case EOrderSubstatus.CANCELED_BLOCKED_USER:
    case EOrderSubstatus.CANCELED_DELETED_USER:
    case EOrderSubstatus.CANCELED_BY_CONTRACTOR:
    case EOrderSubstatus.RETURNED_RETURNED:
      return 'yellow-cell';
    default:
      return '';
  }
};

export const getOrderTechName = (order: Order) => {
  return `${capitalizeFirstLetter(
    order?.user?.first_name || '',
  )} ${capitalizeFirstLetter(order?.user?.last_name || '')}`;
};

export const getInvoiceFileName = (invoiceUrl = '') => {
  if (!invoiceUrl) return '';

  return decodeURI(invoiceUrl.split('/').pop() ?? '')?.replace('-', ' ');
};

export const getInvoiceFileType = (invoiceUrl = '') => {
  if (!invoiceUrl) return '';
  const fileName = getInvoiceFileName(invoiceUrl);

  return fileName.split('.').pop()?.toUpperCase();
};

export const getOrderSupplierName = (order: ShareableOrder | null) => {
  return order?.supplier?.name || 'Supplier';
};
