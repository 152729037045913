import {
  getBluonIframeUrlResponse,
  loginResponse,
  openTermsOfService,
  readTermsOfService,
  resetUser,
  sendSMSResponse,
} from './actions';
import {
  AuthStateProps,
  IframeResponseProps,
  LoginResponseProps,
  ReadTermsProps,
  SendSMSResponseProps,
} from './interface';

import {
  IframeDataProps,
  IframeResponseData,
} from '@config/api/auth/interface';
import { getLoginInfo } from '@utils/Auth/AuthUtils';

const initialState: AuthStateProps = {
  loginResponse: getLoginInfo(),
  changePasswordResponse: '',
  showTerms: false,
  readTerms: false,
  iframeData: {
    id: '',
    label: '',
    type: '',
    value: '',
  },
  sendSMSResponse: {
    phone: '',
    next_request_available_at: '',
    type: '',
  },
};

const authReducer = (
  state = initialState,
  action:
    | LoginResponseProps
    | ReadTermsProps
    | SendSMSResponseProps
    | IframeResponseProps,
): AuthStateProps => {
  switch (action.type) {
    case String(loginResponse):
      return {
        ...state,
        loginResponse: (action as LoginResponseProps).payload.data,
      };
    case String(openTermsOfService):
      return {
        ...state,
        showTerms: action.payload as boolean,
      };

    case String(readTermsOfService):
      return {
        ...state,
        readTerms: action.payload as boolean,
      };
    case String(getBluonIframeUrlResponse): {
      const { data } = action.payload as IframeDataProps;

      return {
        ...state,
        iframeData: data as IframeResponseData,
      };
    }
    case String(sendSMSResponse):
      return {
        ...state,
        sendSMSResponse: (action as SendSMSResponseProps).payload.data,
      };
    case String(resetUser):
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
