import client from '..';

import {
  ChannelUsers,
  OrderDetailRequest,
  PubnubSendMessageAPI,
  User,
} from './interface';

export async function getUsersRequest(requestData?: {
  page: number;
}): Promise<ChannelUsers> {
  const { data } = await client.get('/supplier/users', {
    params: { page: requestData ? requestData.page : '' },
  });

  return data;
}

export async function getChannelInfo(channel: string): Promise<User> {
  const { data } = await client.get(`/supplier/users/${channel}`);

  return data;
}

export async function getUserOrdersRequest(
  user: string,
): Promise<OrderDetailRequest> {
  const { data } = await client.get(`/users/${user}/orders`, {});

  return data;
}

export async function sendMessageRequest(
  payload: PubnubSendMessageAPI,
): Promise<any> {
  const { userId, message } = payload;

  const { data } = await client.post(`/users/${userId}/new-message`, {
    message,
  });

  return data;
}
