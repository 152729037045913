import AddIcon from '@mui/icons-material/Add';
import {
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import HoursModal from '../BranchHoursModal';
import { BrandModal } from '../BrandModal';

import { StoreInfoInputsProps } from './interface';

import { BranchHours } from '@components/BranchHours';
import { ChipList } from '@components/ChipList';
import { ChipData } from '@components/ChipList/interface';
import { Dropdown } from '@components/Dropdown';
import FileUploaderArea from '@components/FileUploaderArea';
import { SpecialInputSelector } from '@components/SpecialInputSelector';
import TextInput from '@components/TextInput';
import { SelectedHoursDataModel } from '@config/api/storeInfo/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import {
  getCountriesRequest,
  getStatesRequest,
} from '@state/storeInfo/actions';
import {
  getBrandsData,
  getCountriesData,
  getStatesData,
} from '@state/storeInfo/selectors';
import { FormTextInputLengths } from '@utils/FormInputLengths';
import { timeZones } from 'src/constants';

const MAX_NUMBER_BRANDS = 20;

const StoreInfoInputs = ({
  setValue,
  control,
  open_hours,
  clearErrors,
  watch,
  formState,
  image,
  logo,
  isLoading,
  selectedBrands = [],
  errorMessageBrandModal,
}: StoreInfoInputsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [brandModal, setBrandModal] = useState(false);
  const [brandsChecked, setBrandsChecked] = useState<string[]>([]);
  const watchCountry = watch('country');
  const watchImage = watch('image');
  const watchLogo = watch('logo');

  const { countriesData, statesData, brands, stateLoading } = useAppSelector(
    (state) => ({
      countriesData: getCountriesData(state) ?? [],
      statesData: getStatesData(state) ?? [],
      brands: getBrandsData(state) ?? [],
      stateLoading: isRequestRunning(state, String(getStatesRequest), false),
    }),
  );

  useEffect(() => {
    if (!countriesData.length) dispatch(getCountriesRequest());
  }, []);

  useEffect(() => {
    if (watchCountry && countriesData.length) {
      dispatch(getStatesRequest(watchCountry));
      if (watchCountry !== 'US') {
        setValue('zip_code', '');
        clearErrors('zip_code');
      }
    }
  }, [watchCountry, countriesData, clearErrors, dispatch, setValue]);

  const stateOptions = useMemo(
    () => statesData.map(({ code, name }) => ({ label: name, value: code })),
    [statesData],
  );

  const countryOptions = useMemo(
    () => countriesData.map(({ code, name }) => ({ label: name, value: code })),
    [countriesData],
  );

  const initialChips = useMemo(() => {
    const initial = selectedBrands.map((c) => ({ id: c.id, label: c.name }));

    return initial;
  }, [selectedBrands]);

  const dataToCheck = useMemo(
    () =>
      brandsChecked.length || brandModal
        ? brandsChecked
        : initialChips.map((c) => c.id),
    [brandsChecked, initialChips],
  );

  const canAddNewItem = dataToCheck.length < MAX_NUMBER_BRANDS;

  const handleOnPress = () => {
    if (isLoading) return;
    setVisible((prevState) => !prevState);
  };

  const handleHourChange = useCallback(
    (branchHours: SelectedHoursDataModel[]) => {
      setValue('branch_hours', branchHours);
    },
    [setValue],
  );

  const onHandleBrandModal = () => {
    if (isLoading) return;
    setBrandModal(!brandModal);
  };

  const handleFileChange = useCallback(
    (file: File) => {
      clearErrors('image');
      setValue('image', file);
    },
    [clearErrors, setValue],
  );

  const handleLogoChange = useCallback(
    (file: File) => {
      clearErrors('logo');
      setValue('logo', file);
    },
    [clearErrors, setValue],
  );

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} md={9}>
        <Controller
          name='name'
          control={control}
          render={({ field, fieldState, formState }) => (
            <TextInput
              id='name'
              label='Store name'
              data-testid='storeName'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              errorMessage={formState.errors.name?.message}
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container item columnSpacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={12} lg={6}>
            <InputLabel>
              Company/Store Logo <em className='lb-subtitle'>(optional)</em>
            </InputLabel>
            <FileUploaderArea
              data-testid='storeCompanyLogo'
              file={watchLogo}
              setFileValue={handleLogoChange}
              error={formState.errors.logo}
              image={logo}
              fieldName='logo'
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <InputLabel>
              Store Image <em className='lb-subtitle'>(optional)</em>
            </InputLabel>
            <FileUploaderArea
              data-testid='storeCompanyImage'
              file={watchImage}
              setFileValue={handleFileChange}
              error={formState.errors.image}
              image={image}
              fieldName='image'
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={9}>
        <Controller
          name='branch'
          control={control}
          render={({ field, fieldState, formState }) => (
            <TextInput
              id='branch'
              data-testid='branchIdentifier'
              label='Unique Branch Identifier'
              name={field.name}
              onBlur={field.onBlur}
              subLabel='(optional)'
              maxLength={FormTextInputLengths.branch}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              errorMessage={formState.errors.branch?.message}
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='address'
          control={control}
          render={({ field, fieldState, formState }) => (
            <TextInput
              id='address'
              label='Store Address'
              data-testid='storeAddress'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              errorMessage={formState.errors.address?.message}
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='address_2'
          control={control}
          render={({ field, fieldState, formState }) => (
            <TextInput
              id='address_2'
              label='Address line 2'
              subLabel='(optional)'
              data-testid='storeAddressLine2'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              errorMessage={formState.errors.address_2?.message}
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container item columnSpacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Controller
              name='country'
              control={control}
              render={({ field, fieldState, formState }) => (
                <Dropdown
                  id='country'
                  label='Country'
                  placeholder='Select one please'
                  data-testid='storeCountry'
                  name={field.name}
                  onBlur={field.onBlur}
                  options={countryOptions}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('state', '');
                  }}
                  selected={field.value}
                  value={field.value}
                  error={!!fieldState.error}
                  errorMessage={formState.errors.country?.message}
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='state'
              control={control}
              render={({ field, fieldState, formState }) => (
                <Dropdown
                  id='state'
                  label='State'
                  data-testid='storeState'
                  name={field.name}
                  placeholder='Select one please'
                  onBlur={field.onBlur}
                  options={stateOptions}
                  onChange={field.onChange}
                  selected={field.value}
                  value={field.value}
                  error={!!fieldState.error}
                  errorMessage={formState.errors.state?.message}
                  isLoading={stateLoading || isLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container item columnSpacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Controller
              name='city'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextInput
                  id='city'
                  label='City'
                  data-testid='storeCity'
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  errorMessage={formState.errors.city?.message}
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='zip_code'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextInput
                  id='zip_code'
                  label='Zip code'
                  data-testid='storeZipCode'
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  maxLength={FormTextInputLengths.address.zipCode}
                  errorMessage={formState.errors.zip_code?.message}
                  disabled={watchCountry !== 'US'}
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={9}>
        <Controller
          name='email'
          control={control}
          render={({ field, fieldState, formState }) => (
            <TextInput
              id='email'
              label='Email for Login'
              data-testid='storeEmail'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              maxLength={FormTextInputLengths.profile.email}
              errorMessage={formState.errors.email?.message}
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container item columnSpacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Controller
              name='phone'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextInput
                  id='phone'
                  label='Phone number'
                  data-testid='storePhoneNumber'
                  name={field.name}
                  onBlur={field.onBlur}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  maxLength={FormTextInputLengths.profile.phoneFullNumber}
                  errorMessage={formState.errors.phone?.message}
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='prokeep_phone'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextInput
                  id='prokeep_phone'
                  label='Prokeep Phone Number'
                  subLabel='(optional)'
                  data-testid='storeProkeepPhoneNumber'
                  name={field.name}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  maxLength={FormTextInputLengths.profile.phoneFullNumber}
                  errorMessage={formState.errors.prokeep_phone?.message}
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container item columnSpacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <SpecialInputSelector
              label={'Branch Hours'}
              buttonText={'See/Edit Hours'}
              buttonIcon={<AddIcon />}
              onClick={handleOnPress}
              data={open_hours ?? []}
              data-testid='storeBranchHours'
            >
              <BranchHours
                data-testid='storeBranchHours'
                dataHours={open_hours ?? []}
              />
            </SpecialInputSelector>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='timezone'
              control={control}
              render={({ field, fieldState, formState }) => (
                <Dropdown
                  id='timezone'
                  label='Branch Time Zone'
                  placeholder='Please select one'
                  data-testid='timeZoneInput'
                  name={field.name}
                  onBlur={field.onBlur}
                  options={timeZones}
                  onChange={field.onChange}
                  selected={field.value}
                  value={field.value}
                  error={!!fieldState.error}
                  errorMessage={formState.errors.timezone?.message}
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      {visible && (
        <HoursModal
          data={open_hours}
          visible={visible}
          onAccept={handleOnPress}
          onCancel={handleOnPress}
          setFieldValue={handleHourChange}
        />
      )}
      <Grid item xs={12} md={9}>
        <SpecialInputSelector
          label={'Brands Carried at Store'}
          buttonText={'See/Edit Brands'}
          buttonIcon={<AddIcon />}
          onClick={onHandleBrandModal}
          data={initialChips as ChipData[]}
          data-testid='storeBrands'
          errorMessage={errorMessageBrandModal}
        >
          <ChipList
            data={initialChips as ChipData[]}
            data-testid='storeBrands'
          />
        </SpecialInputSelector>
      </Grid>
      {brands && brandModal && (
        <BrandModal
          isOpen={brandModal}
          onClose={onHandleBrandModal}
          brands={brands}
          brandsChecked={dataToCheck}
          setBrandsChecked={setBrandsChecked}
          canAddNewItem={canAddNewItem}
          canDelete
          initialChips={initialChips}
        />
      )}
      <Grid item xs={12} md={9}>
        <Controller
          name='offers_delivery'
          control={control}
          render={({ field }) => (
            <>
              <FormLabel component='legend'>
                Do you offer Job Site Delivery?
              </FormLabel>
              <RadioGroup
                row
                aria-label='Do you offer Job Site Delivery?'
                defaultValue={0}
                value={field.value}
                name='offers_delivery'
              >
                <FormControlLabel
                  id='offers_delivery'
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={1}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  id='offers_delivery'
                  value={0}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default StoreInfoInputs;
