import { clearSupplierList, getSupplierListResponse } from './actions';
import { ManagerState, TaskActionProps } from './interface';

const initialState: ManagerState = {
  supplierList: undefined,
  impersonatedSupplier: undefined,
};

const managerReducer = (
  state = initialState,
  action: TaskActionProps,
): ManagerState => {
  switch (action.type) {
    case String(getSupplierListResponse): {
      return {
        ...state,
        supplierList: action.payload.data,
      };
    }

    case String(clearSupplierList): {
      return {
        ...state,
        supplierList: [],
      };
    }

    default:
      return state;
  }
};

export default managerReducer;
