import { Box, Grid } from '@mui/material';

import { SpecItemProps } from './interface';
import './styles.scss';

export const SPEC_ITEM_TEST_ID = 'specItem';

export const SpecItem = ({ label, value }: SpecItemProps): JSX.Element => {
  return (
    <Box data-testid={SPEC_ITEM_TEST_ID} className='bl-spec-item'>
      <Grid item xs={6} className='bl-spec-label'>
        {label}
      </Grid>
      <Grid item xs={6} className='bl-spec-value'>
        {value}
      </Grid>
    </Box>
  );
};
