import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './styles.scss';
import Button from '../Button';
import { ManualAndPartsIndicator } from '../ManualAndPartsIndicator';

import { RowItemProps } from './interface';

export const RowItem = ({
  name,
  img,
  onClickItem,
  id,
  selected,
  notes,
  noteVariantColor,
  nameVariantStyle,
  noteVariantStyle,
  isImgRequired = true,
  nodeImage,
  type,
  item,
}: RowItemProps): JSX.Element => (
  <Button
    data-testid={`itemBtn-${name}`}
    variant='text'
    onClick={onClickItem}
    className={`bl-search-sections-item-btn ${
      id === selected?.id ? 'selected' : ''
    }`}
  >
    <Box data-testid='searchItem' className='bl-search-sections-item-container'>
      <Box className='bl-search-sections-info-container'>
        {nodeImage}
        {isImgRequired && img && !nodeImage && (
          <img alt='logo' className={`bl-item-img ${type ?? ''}`} src={img} />
        )}
        {isImgRequired && !img && !nodeImage && (
          <Box className='bl-item-img-place' />
        )}

        <Box className='bl-search-sections-info-title-notes-container'>
          <Typography
            data-testid={`searchItem-${name}`}
            className={`bl-item-name ${nameVariantStyle}`}
            variant='caption'
            color='initial'
          >
            {name}
          </Typography>
          {!!notes && (
            <Typography
              data-testid={`searchItemNote-${name}`}
              className={`bl-item-notes ${noteVariantStyle} ${noteVariantColor}`}
              variant='caption'
              color='initial'
            >
              {notes}
            </Typography>
          )}
        </Box>
      </Box>
      {item && <ManualAndPartsIndicator item={item} />}
      <ChevronRightIcon color='primary' className='chevron-icon' />
    </Box>
  </Button>
);
