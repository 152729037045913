import client from '..';
import { OrderDetail } from '../interfaceV2';

import {
  CounterStaffProps,
  CustomReplacementResponse,
  ExtraItem,
  GetItemReplacements,
  GetOrderItems,
  GetOrdersProps,
  GetReplacementsResponse,
  Order,
  OrderItem,
  OrderItemDetail,
  OrderItemsDetail,
  PatchItemInfo,
  PayloadPatchInfo,
  SaveNewCustomerProps,
  SaveTruckStockItemsProps,
  SetInvoiceProps,
  SupplierStaff,
  updateOrderProps,
} from './interface';

export async function getOrderItemsData(
  props: GetOrderItems,
): Promise<OrderItemsDetail<OrderItem>> {
  const { order, page } = props;

  const { data } = await client.get(`/orders/${order}/parts`, {
    params: { page },
  });

  return data;
}

export async function getOrderItemsSupplyData(
  props: GetOrderItems,
): Promise<OrderItemsDetail<ExtraItem>> {
  const { order, page } = props;

  const { data } = await client.get(`/orders/${order}/extra-items`, {
    params: { page },
  });

  return data;
}

export async function saveSupplyItems(
  props: SaveTruckStockItemsProps,
): Promise<OrderItemsDetail<ExtraItem>> {
  const { order, items } = props;
  const { data } = await client.patch(`/orders/${order}/extra-items`, {
    items,
  });

  return data;
}

export async function saveAddedItems(
  props: SaveTruckStockItemsProps,
): Promise<OrderItemsDetail<ExtraItem>> {
  const { order, items } = props;
  const { data } = await client.patch(
    `/orders/in-progress/${order}/extra-items`,
    {
      items,
    },
  );

  return data;
}

export async function getOrders(props: GetOrdersProps): Promise<Order> {
  //  Building params
  const { type, page, startDate, endDate, status } = props;
  const pageParams = { page };
  const typeParams = type ? { type } : {};
  const otherParams = { start_date: startDate, end_date: endDate, status };
  //  Calling endpoint
  const { data } = await client.get('/orders', {
    params: { ...pageParams, ...typeParams, ...otherParams },
  });

  return data;
}

export async function addCustomItem(props: any): Promise<any> {
  const { order, name, quantity } = props;

  const { data } = await client.post(`/orders/${order}/custom-items`, {
    name,
    quantity,
  });

  return data;
}

export async function deleteCustomItem(props: any): Promise<any> {
  const { order, customItem } = props;

  const { data } = await client.delete(
    `/orders/${order}/custom-items/${customItem}`,
  );

  return data;
}

export async function getCustomItems(props: any): Promise<any> {
  const { order } = props;

  const { data } = await client.get(`/orders/${order}/custom-items`);

  return data;
}

export async function postCurriInformativePopUp(order: string): Promise<Order> {
  const { data } = await client.post(
    `/orders/${order}/curri-charge-acknowledge`,
  );

  return data;
}

export async function getItemInfo(
  props: GetItemReplacements,
): Promise<OrderItemDetail> {
  const { item, part } = props;

  const { data } = await client.get(`/parts/${part}`);

  return { ...data, item_id: item };
}

export async function getItemReplacements(
  props: GetItemReplacements,
): Promise<GetReplacementsResponse> {
  const { order, item, page } = props;

  const { data } = await client.get(
    `/orders/${order}/parts/${item}/replacements`,
    {
      params: { page },
    },
  );

  return { item_id: item, data };
}

export async function patchItemInfo({
  order,
  item,
  replacement,
  status,
  quantity,
  price,
  available_at,
  part_number,
}: PatchItemInfo): Promise<OrderItemDetail> {
  const payload: PayloadPatchInfo = {
    status,
    replacement: part_number ? null : replacement,
    quantity,
    price,
    ...(available_at && { available_at }),
    ...(part_number && { part_number }),
  };

  const { data } = await client.patch(
    `/orders/${order}/parts/${item}`,
    payload,
  );

  return data;
}

export async function getSupplierStaff(props: {
  type: string;
}): Promise<OrderItemsDetail<SupplierStaff>> {
  const { data } = await client.get('/supplier/staff', {
    params: { type: props.type },
  });

  return data;
}

export async function setCounterStaff(
  props: CounterStaffProps,
): Promise<Order> {
  const { order, staff } = props;

  const { data } = await client.post(`/orders/${order}/assignment`, {
    staff,
  });

  return data;
}

export async function sendForApproval(props: {
  order: string;
  totalValue: string;
  bidNumberOption: string;
  notesValue: string;
}): Promise<Order> {
  const { order, totalValue, bidNumberOption, notesValue } = props;
  const numberValue = Number(totalValue.replaceAll(',', '').replace('$ ', ''));

  const { data } = await client.post(`/orders/${order}/send-for-approval`, {
    total: numberValue,
    bid_number: bidNumberOption,
    note: notesValue,
  });

  return data;
}

export async function setInvoice(props: SetInvoiceProps): Promise<Order> {
  const { order, file } = props;

  const formData = new FormData();
  formData.append('file', file);

  const { data } = await client.post(`/orders/${order}/invoice`, formData);

  return data;
}

export async function deleteInvoice(order: string): Promise<Order> {
  const { data } = await client.delete(`/orders/${order}/invoice`);

  return data;
}

export async function saveNewCustomer({
  userId,
  orderId,
  customTag,
  cashBuyer,
}: SaveNewCustomerProps): Promise<any> {
  const payload = {
    cash_buyer: cashBuyer,
    customer_tier: customTag,
  };

  const { data } = await client.post(`/users/${userId}/confirm`, payload);

  return { data, orderId };
}

export async function setCompleteOrder(props: {
  order: string;
  total: string;
  bid_number?: string;
  note?: string;
}): Promise<any> {
  const { order, total, bid_number, note } = props;

  const { data } = await client.post(`/orders/${order}/complete`, {
    total,
    bid_number,
    note,
  });

  return data;
}

export async function dontSellToCustomer(props: {
  orderId: string;
}): Promise<any> {
  const { orderId } = props;
  await client.post(`/orders/${orderId}/cancel`);

  return { orderId };
}

export const getOrderDetails = async ({
  order_id,
}: {
  order_id: string;
}): Promise<OrderDetail | object> => {
  const { data } = await client.get(`/orders/${order_id}`);

  return { data };
};

export const declineOrder = async (props: {
  order_id: string;
  staff_id: string;
  status_detail: string;
  reason_id: string;
}): Promise<OrderItemsDetail<Order>> => {
  const { order_id, staff_id, status_detail, reason_id } = props;
  const { data } = await client.post(`/orders/${order_id}/cancel`, {
    staff: staff_id,
    status_detail,
    reason: reason_id,
  });

  return data;
};

export const returnOrder = async (props: {
  order_id: string;
  staff_id: string;
  status_detail: string;
  reason_id: string;
}): Promise<OrderItemsDetail<Order>> => {
  const { order_id, staff_id, status_detail, reason_id } = props;
  const { data } = await client.post(`/orders/${order_id}/return`, {
    staff: staff_id,
    status_detail,
    tos_accepted: true,
    reason: reason_id,
  });

  return data;
};

export const cancelOrder = async (props: {
  order_id: string;
  staff_id: string;
  status_detail: string;
  reason_id: string;
}): Promise<OrderItemsDetail<Order>> => {
  const { order_id, staff_id, status_detail, reason_id } = props;
  const { data } = await client.post(`/orders/in-progress/${order_id}/cancel`, {
    staff: staff_id,
    status_detail,
    reason: reason_id,
  });

  return data;
};

export async function getAddedItems(
  order: string,
): Promise<OrderItemsDetail<ExtraItem[]>> {
  const { data } = await client.get(`/orders/in-progress/${order}/extra-items`);

  return data;
}

export async function updateOrder(
  props: updateOrderProps,
): Promise<OrderItemsDetail<ExtraItem[]>> {
  const { bid_number, note, order, total } = props;
  const { data } = await client.patch(`/orders/${order}`, {
    total,
    bid_number,
    note,
  });

  return data;
}

export async function addNewCounterStaff(props: any): Promise<any> {
  const { email, name, phone } = props;

  const { data } = await client.post('/supplier/staff', {
    email,
    name,
    phone,
    type: 'counter',
  });

  return data;
}

export async function updateCounterStaff(props: any): Promise<any> {
  const { email, name, phone, id } = props;

  const { data } = await client.patch(`/supplier/staff/${id}`, {
    email,
    name,
    phone,
  });

  return data;
}

export async function getItemCustomReplacement(props: {
  orderId: string;
  itemId: string;
}): Promise<CustomReplacementResponse> {
  const { orderId, itemId } = props;

  const { data } = await client.get(
    `/orders/${orderId}/parts/${itemId}/custom-replacement`,
  );

  return { item_id: itemId, data: data.data };
}

export async function setItemCustomReplacement(props: {
  orderId: string;
  itemId: string;
  brand: string;
  part_number: string;
}): Promise<CustomReplacementResponse> {
  const { orderId, itemId, brand, part_number } = props;

  const { data } = await client.post(
    `/orders/${orderId}/parts/${itemId}/custom-replacement`,
    {
      brand,
      part_number,
    },
  );

  return { item_id: itemId, data: data.data };
}
